import Decimal from "decimal.js";

export function isNumberValid(num) {
  if (!isNaN(num)
    && isFinite(num)
    && num !== null
    && num !== undefined) {
    return true;
  }
  return false;
}


export function zeroIfNumberInvalid(num) {
  if (!isNaN(num)
    && isFinite(num)
    && num !== null
    && num !== undefined) {
    return num;
  }
  return 0;
}


export function parseNumberOrZero(str) {
  const result = parseFloat(str);
  return isNaN(result) ? 0 : result;
}


/**
 * Creates a Decimal object from the given value.
 * If the value is undefined or NaN, it returns a Decimal object representing 0.
 *
 * @param {number} value - The value to be converted to a Decimal object.
 * @returns {Decimal} A Decimal object representing the given value or 0 if the value is undefined or NaN.
 */
export function createDecimal(value) {
  // Check if the value is undefined or NaN
  if (value === undefined || Number.isNaN(value)) {
    // Return a Decimal object representing 0
    return new Decimal(0);
  }
  // Return a Decimal object representing the given value
  return new Decimal(value);
}
