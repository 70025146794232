import _ from "lodash";
import {
  calculatePercentageGrowth,
  isValidNumber,
  totalUpItems
} from "../../RevenueDashboard/calculations/Calculations";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";


export function calculateHeadsData(headcounts, pnlAnnuals) {

  let headsData = [];

  let departmentAnalysis = getHeadcountDepartmentData(headcounts);

  let headcountAnalysis = getHeadcountHeadsData(headcounts);

  let lastMonthIncome = getHeadcountLastPriceData(headcounts);

  let absoluteChange = getAbsoluteChangeHeads(headcountAnalysis);

  let salariesPerHead = getSalariesPerHead(headcountAnalysis, departmentAnalysis, lastMonthIncome);

  let revenuePerHead = getRevenuePerHead(headcountAnalysis, pnlAnnuals);

  headsData.push(headcountAnalysis, absoluteChange, salariesPerHead, revenuePerHead);

  return headsData;
}


export function calculateDepartmentData(headcounts, pnlAnnuals) {

  let headsData = [];

  let departmentAnalysis = getHeadcountDepartmentData(headcounts);

  let growthRates = getDepartmentGrowthRates(departmentAnalysis);

  let percentageOfRevenue = getPercentageOfRevenue(departmentAnalysis, pnlAnnuals);

  let absoluteChanges = getAbsoluteChangeCost(departmentAnalysis);

  headsData.push(departmentAnalysis, growthRates, percentageOfRevenue, absoluteChanges);

  return headsData;
}

function getAbsoluteChangeCost(departments) {

  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = departments[i].headCountMonthDtoList[x].value - departments[i].headCountMonthDtoList[x - 1].value;

      category.headCountMonthDtoList.push({
        id: x,
        value:  yearTotal ,
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
        showMovementArrow: true,
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;
}

function getPercentageOfRevenue(departments, pnlAnnuals) {
  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = percentage( departments[i].headCountMonthDtoList[x].value, pnlAnnuals.pnLCategoryDtoList[0].pnLMonthDtoList[x].value);

      category.headCountMonthDtoList.push({
        id: x,
        value:  yearTotal ,
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
        valueFormat: "PERCENTAGE",
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;
}


function getDepartmentGrowthRates(departments) {


  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = calculatePercentageGrowth(departments[i].headCountMonthDtoList[x - 1].value, departments[i].headCountMonthDtoList[x].value);

      category.headCountMonthDtoList.push({
        id: x,
        value:  isValidNumber(yearTotal),
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
        valueFormat: "PERCENTAGE",
        showMovementArrow: true,
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;
}

/**
 * Retrieves all the head data (Average FTEs)
 *
 * @param headcounts
 */
export function getHeadcountLastPriceData(headcounts) {

  //for each headcount (of type Price X Quantity)
  const priceXQuantityHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "PRICE_X_QUANTITY");

  let headsYearTotals = [];

  //build up a list of departments from all of the headcounts
  _.forEach(priceXQuantityHeadcounts, function(priceXQuantityHeadcount, i) {

    //number of heads per department - per month
    const departmentQuantities = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price");

    //for each department
    _.forEach(departmentQuantities, function(department, i) {

      let category = {
        id: department.id,
        name: department.departmentName,
        headCountMonthDtoList : []
      }

      //sum up the year values
      let yearIndex = 0;
      for (let i=0; i < 72;){

        let yearTotal = department ? department.headCountMonthDtoList[i + 11].value : 0;

        category.headCountMonthDtoList.push({
          id: yearIndex,
          value:  yearTotal ,
          month: yearIndex,
          monthIndex: yearIndex,
          cellType: "CALCULATED",
        })
        i = i + 12;
        yearIndex++;

      }

      headsYearTotals.push(category);

    });

  });

  return headsYearTotals;

}

/**
 * Retrieves all the head data (Average FTEs)
 *
 * @param headcounts
 */
export function getHeadcountHeadsData(headcounts) {

  //for each headcount (of type Price X Quantity)
  const priceXQuantityHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "PRICE_X_QUANTITY");

  let headsYearTotals = [];

  //build up a list of departments from all of the headcounts
  _.forEach(priceXQuantityHeadcounts, function(priceXQuantityHeadcount, i) {

    //number of heads per department - per month
    const departmentQuantities = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Quantity");

    //for each department
    _.forEach(departmentQuantities, function(department, i) {

      let category = {
        id: department.id,
        name: department.departmentName,
        headCountMonthDtoList : []
      }

      //sum up the year values
      let yearIndex = 0;
      for (let i=0; i < 72;){

        let yearTotal = department ? department.headCountMonthDtoList[i + 11].value : 0;

        category.headCountMonthDtoList.push({
          id: yearIndex,
          value:  yearTotal ,
          month: yearIndex,
          monthIndex: yearIndex,
          cellType: "CALCULATED",
        })
        i = i + 12;
        yearIndex++;

      }

      headsYearTotals.push(category);

    });

  });

  return headsYearTotals;

}



export function getHeadcountDepartmentData(headcounts) {

  let headsYearTotals = [];

  let totalSalaries = [0,0,0,0,0,0];

  //build up a list of departments from all of the headcounts
  _.forEach(headcounts, function(headcount, i) {

    let departmentQuantities;

    if (headcount.headCountDto.assumption === "PRICE_X_QUANTITY") {
      departmentQuantities = headcount.headCountCategoryDtos
        .filter(headCountCategoryDto => headCountCategoryDto.name === "Total base salary costs" && headCountCategoryDto.departmentName !== "");
    } else {
      departmentQuantities = headcount.headCountCategoryDtos
        .filter(headCountCategoryDto => headCountCategoryDto.name === "Price" && headCountCategoryDto.departmentName !== "");
    }

    //for each department
    _.forEach(departmentQuantities, function(department, i) {

      let category = {
        id: department.id,
        name: department.departmentName,
        headCountMonthDtoList : []
      }

      //sum up the year values
      let yearIndex = 0;
      for (let i=0; i < 72;){

        let yearTotal = department ? totalUpItems(department.headCountMonthDtoList, i) : 0;

        category.headCountMonthDtoList.push({
          id: yearIndex,
          value:  Math.abs(yearTotal) ,
          month: yearIndex,
          monthIndex: yearIndex,
          cellType: "CALCULATED",
        })

        totalSalaries[yearIndex] += Math.abs(yearTotal)

        i = i + 12;
        yearIndex++;

      }

      headsYearTotals.push(category);

    });

  });


  let categoryTotals = {
    id: 999,
    name: "Total salaries",
    headCountMonthDtoList : [],
    totalField : true
  }

  let yearIndex = 0;
  for (let i=0; i < 6; i++){
    categoryTotals.headCountMonthDtoList.push({
      id: yearIndex,
      value:  totalSalaries[i] ,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    yearIndex++;
  }

  headsYearTotals.push(categoryTotals);




  return headsYearTotals;

  //Department salaries

  //Absolute change - heads

  //Salaries per head

  //Revenue per head

}


export function buildYearTotalCategory(department, categoryName, displayName=categoryName, resolution = 0) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  let quantitySoldCateogryOriginal = department.productCategoryDtoList.find(category => category.name === categoryName);

  //console.log(product.productDto.name)

  let yearIndex = 0;
  for (let i=0; i < 72;){

    let yearTotal = quantitySoldCateogryOriginal ? totalUpItems(quantitySoldCateogryOriginal.productMonthDtoList, i) : 0;

    category.productMonthDtoList.push({
      id: yearIndex,
      value:  yearTotal ,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
      resolution: resolution,
    })
    i = i + 12;
    yearIndex++;

  }

  return category;
}





function getAbsoluteChangeHeads(departments) {

  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = departments[i].headCountMonthDtoList[x].value - departments[i].headCountMonthDtoList[x - 1].value;

      category.headCountMonthDtoList.push({
        id: x,
        value:  yearTotal ,
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
        showMovementArrow: true,
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;
}

function getSalariesPerHead(departments, departmentAnalysis, lastMonthIncome) {

  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = lastMonthIncome[i].headCountMonthDtoList[x].value * 12;

      category.headCountMonthDtoList.push({
        id: x,
        value:  Math.abs(yearTotal) ,
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;
}

function getRevenuePerHead(departments, pnlAnnuals) {

  let absoluteHeadChange = [];

  //build up the year on year changes

  for (let i=0; i < departments.length; i++) {

    let category = {
      id: departments[i].id,
      name: departments[i].name,
      headCountMonthDtoList : []
    }

    category.headCountMonthDtoList.push({
      id: 0,
      value: 0,
      month: 0,
      monthIndex: 0,
      cellType: "DISABLED",
      valueFormat: "PERCENTAGE"
    })

    for (let x=1; x < departments[i].headCountMonthDtoList.length; x++) {

      let yearTotal = pnlAnnuals.pnLCategoryDtoList[0].pnLMonthDtoList[x].value / departments[i].headCountMonthDtoList[x - 1].value;

      category.headCountMonthDtoList.push({
        id: x,
        value:  yearTotal ,
        month: x,
        monthIndex: x,
        cellType: "CALCULATED",
      })

    }

    absoluteHeadChange.push(category);
  }

  return absoluteHeadChange;

}


/**
 * Retrieves all the head data (Average FTEs)
 *
 * @param headcounts
 */
export function getHeadcountDepartmentsData(headcounts) {

  //for each headcount (any assumptions)

  //total up the year for each departments (Total base salary costs)

    //Department salaries

    //Absolute change - heads

    //Salaries per head

    //Revenue per head

}