import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditProduct from "./EditProduct";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { Skeleton } from "@mui/material";
import { currentLiabilitiesState } from "../../../SharedState/CurrentLiabilitiesState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { refreshVatPackage, vatPackageState } from "../../../SharedState/VatPackageState";
import { vatState } from "../../../SharedState/VatState";
import { overheadsState } from "../../../SharedState/OverheadsState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

export default function ProductInput(props) {
  const { key } = useProject();

  const { project } = useProject();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);

  const headcountsAtom = useRecoilValue(headcountsState);

  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);
  const setCurrentLiabilitiesAtom = useSetRecoilState(currentLiabilitiesState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);
  const overheadsAtom = useRecoilValue(overheadsState);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  const {
    productItem,
    products,
    refreshProductData,
    setEditData,
    editData,
    setProducts,
    loadProductData,

  } = props;

  const deleteProduct = (handleClose) => {
    var config = {
      method: "delete",
      url: "revenuecos/product",
      data: productItem.productDto,
    };

    axios(config)
      .then(async function(response) {

        let products_ = response.data;
        setProductsAtom(structuredClone(products_));

        let vatPackage = refreshVatPackage(structuredClone(products_), structuredClone(overheadsAtom), structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage);

        await axios.get("currentassets/" + project.id).then(function(response) {
          setCurrentAssetsAtom(response.data);
        });

        await axios.get("currentliabilities/" + project.id).then(function(response) {
          setCurrentLiabilitiesAtom(response.data);
        });

        //Dev notes: updating the productsAtom will cause the product summary to update the P&L with the new products total, so no code is needed to handle this in delete.

        handleClose()
      })
      .catch(function(error) {
        console.log(error);
      });
  };



  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function editThisProduct() {
  //   setEditData(productItem.productDto.id);
  // }

  function handleClick(id) {
    setEditData(id);
  }


  function getRowName(row, productItem) {

    //if productItem assumption type is PRICE_X_QUANTITY_BUILDER
    if (["PRICE_X_QUANTITY_BUILDER", "REVENUE_AS_A_PERCENTAGE_OF_VALUE"].includes(productItem.productDto.assumption)) {

      if (row.name === "Max Units") {
        return _.isEmpty(productItem.productDto.variableA) || productItem.productDto.variableA === undefined ? "Max Units" : productItem.productDto.variableA
      }

      if (row.name === "Units Sold") {
        return _.isEmpty(productItem.productDto.variableB) || productItem.productDto.variableB === undefined ? "Unit Sold" : productItem.productDto.variableB
      }

      if (row.name === "Percentage of quantity") {
        return _.isEmpty(productItem.productDto.variableC) || productItem.productDto.variableC === undefined ? "Percentage of quantity" : productItem.productDto.variableC
      }

      return row.name;
    } else {
      return row.name;
    }

  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={productItem.productDto}
          categories={productItem.productCategoryDtoList}
          deleteMethod={deleteProduct}
          refreshDataMethod={loadProductData}
          editAssumptionComponent={<EditProduct productNames={[]}
                                                loadProductData={loadProductData}
                                                product={productItem.productDto}
                                                open={openEdit}
                                                setOpen={setOpenEdit}
                                                products={structuredClone(productsAtom)}
                                                headcounts={structuredClone(headcountsAtom)}
          >
          </EditProduct>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{
            fileName: "Powdr-Product-" + productItem.productDto.name + "-" + new Date().toDateString(),
            sheet: "Product",
            currentTableRef: tableRef.current
          }}
          shallowUpdateUrl={'revenuecos/productShallow'}
          type={"PRODUCT"}
        ></PowdrNavBar>


        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={productItem.productDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0, zoom: zoomAtom }}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={productItem.productDto.name + "-ROW"}
                  id={productItem.productDto.name + "-ROW"}
                >
                  <TableCell
                    key={productItem.productDto.name + "-BASE"}
                    id={productItem.productDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{productItem.productDto.name}</h2>
                    <AssumptionLabel label={productItem.productDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={productItem.productDto.name}
                                 index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {productItem.productCategoryDtoList.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={getRowName(row, productItem)}></RowTitle>

                    {row.productMonthDtoList.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={products}
                        refreshItemData={refreshProductData}
                        setItems={setProducts}
                        canOverride={row.allowValueOverride}
                        owner={productItem.productDto}
                        assumption={productItem}
                        categoryName={row.name}
                        parentName={productItem.productDto.name}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}