import React from "react";

import {
  Breadcrumbs,
  Typography
} from "@mui/material";

function PageHeader({ pageName, parentName }) {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        {pageName}
      </Typography>
      <Breadcrumbs mt={2}>
        <Typography>{parentName}</Typography>
        <Typography>{pageName}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
}

export default PageHeader;
