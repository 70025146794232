/**
 * This method will determine if the move up/down command is a valid one.
 * For instance it will return false if the command moves Exceptionals above 'Depreciation/Amortisation' or below 'EBIT'
 *
 * returns a boolean flag to indicate if the move is valid or not.
 */
export function moveCategory(moveCmd, pnl, overheadId_, headcountId_) {

  let objectCategory;

  if (overheadId_ !== null) {
    objectCategory = pnl.pnLCategoryDtoList.find(obj => obj.overheadId === overheadId_);
  }

  if (headcountId_ !== null) {
    objectCategory = pnl.pnLCategoryDtoList.find(obj => obj.headcountId === headcountId_);
  }

  // Return false if objectId is not found
  if (objectCategory === undefined) {
    return pnl;
  }

  switch (objectCategory.position) {
    case "ABOVE":
      findAndMoveObject(moveCmd, pnl, objectCategory, 'Revenue from products', 'Direct product costs')
      break;
    case "BELOW":
      findAndMoveObject(moveCmd, pnl, objectCategory, 'Gross Profit Margin', 'Total Overheads')
      break;
    case "EBITDA":
      findAndMoveObject(moveCmd, pnl, objectCategory, 'Depreciation/Amortisation', 'EBIT')
      break;
    default:
      break;
  }
  return pnl;
}

function findAndMoveObject(moveCmd, pnl, objectCategory, inValidOverrideNameUpperBoundary, inValidOverrideNameLowerBoundary) {

  // If were moving UP is this a valid move?
  let indexOffset = moveCmd === 'UP' ? objectCategory.outputOrder - 1 : objectCategory.outputOrder + 1;

  let objectToMoveTo = pnl.pnLCategoryDtoList.find(obj => obj.outputOrder === indexOffset);

  // Check object above is not 'Direct product costs'
  if (moveCmd === 'UP' && objectToMoveTo.overrideName === inValidOverrideNameUpperBoundary) {
    return false;
  }

  // Check object above is not 'Direct product costs'
  if (moveCmd === 'DOWN' && objectToMoveTo.overrideName === inValidOverrideNameLowerBoundary) {
    return false;
  }

  //swap the outputOrder values
  let initialOrder = objectCategory.outputOrder;
  objectCategory.outputOrder = objectToMoveTo.outputOrder;
  objectToMoveTo.outputOrder = initialOrder;

  //do the swap in the array
  let objectCategoryId = pnl.pnLCategoryDtoList.findIndex(obj => obj.id === objectCategory.id);
  let objectToMoveToId = pnl.pnLCategoryDtoList.findIndex(obj => obj.id === objectToMoveTo.id);

  swapArrayElements(pnl.pnLCategoryDtoList, objectCategoryId, objectToMoveToId)
}



function swapArrayElements(array, indexA, indexB) {
  let temp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = temp;
}
