import React from "react";

export default function DashboardKey({ hideChangeKey = false }) {
  return (
    <React.Fragment>
      <div style={{ marginLeft: '5px' }}>
        {!hideChangeKey && (
          <>
            <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: 'green',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '5px',
              }}
            ></span>
            = a favourable change

            <br />

            <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: 'red',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '5px',
              }}
            ></span>
            = an adverse change

            <br />
          </>
        )}
        ↑ = an increasing number

        <br />

        ↓ = a decreasing number
      </div>
    </React.Fragment>
  );
}
