import { getCurrentProject } from "../ProjectServices";
import { createDecimal, parseNumberOrZero } from "../utils/NumbersUtils";

/**
 * FOR BALANCE ASSUMPTIONS ONLY
 * (FOR PNL THIS WILL NOT WORK AS ITS LOOKING AT THE BALANCE REFERENCE POINT)
 * @param assumptions
 * @param itemName
 */
export function calculateCorkscrewWithDA(assumptions, itemName) {

  // all the assumptions that are type 'SIMPLE_CORKSCREW_WITH_INPUT_DA'
  const assumptionCorkScrewDA = assumptions
    .filter(assumption => assumption[itemName + 'Dto'].assumption === "SIMPLE_CORKSCREW_WITH_INPUT_DA");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  assumptionCorkScrewDA.forEach(assumption => {
    assumption[itemName + 'CategoryDtos'][0][itemName + 'MonthDtos'].forEach((assumptionMonthDto, i) => {

      // only for forecast values
      if (i >= getCurrentProject().firstBalanceForecast) {

        assumption[itemName + 'CategoryDtos'][0][itemName + 'MonthDtos'][i].value = assumption[itemName + 'CategoryDtos'][4][itemName + 'MonthDtos'][i - 1].value;

        //get the value of the closing, from the previous month
        assumption[itemName + 'CategoryDtos'][4][itemName + 'MonthDtos'][i].value = parseNumberOrZero(

            createDecimal(assumption[itemName + 'CategoryDtos'][0][itemName + 'MonthDtos'][i].value).plus(
            createDecimal(assumption[itemName + 'CategoryDtos'][1][itemName + 'MonthDtos'][i].value)).plus(
            createDecimal(assumption[itemName + 'CategoryDtos'][2][itemName + 'MonthDtos'][i].value)).plus(
            createDecimal(assumption[itemName + 'CategoryDtos'][3][itemName + 'MonthDtos'][i].value)).toString()
        );
      }

    });
  });

}