import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUpDisabled() {
  return (
    <React.Fragment>

      <Wrapper>
        <Helmet title="Sign Up Complete" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Account Not Active
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Your account is not currently active. Please contact a Powdr representative for further help.
        </Typography>
        <Button
          component={Link}
          to="/"
          fullWidth
          color="primary"
        >
          OK
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUpDisabled;
