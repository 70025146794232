import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import _ from "lodash";

export function actualisePnl(pnl) {

  let firstForecast = getCurrentProject().firstForecast;

  _.forEach(pnl.pnLCategoryDtoList, function(category, x) {


    if (_.isEmpty(category.pnLMonthDtoList)) {
      return;
    }

    category.pnLMonthDtoList[firstForecast].cellType =
      category.pnLMonthDtoList[firstForecast - 1].cellType;

    category.pnLMonthDtoList[firstForecast].financialTypeEnum =
      category.pnLMonthDtoList[firstForecast - 1].financialTypeEnum;

  });

  return pnl;
}