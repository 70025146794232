import Card from "@mui/material/Card";
import { CardContent, Typography } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useState } from "react";
import HeadcountDashboardInput from "./HeadcountDashboardInput";
import DashboardKey from "../../Shared/DashboardKey";

export default function HeadcountAnalysis({ heads }) {

  const [selectedView, setSelectedView] = useState('0'); // Initialize with the index as a string

  const handleViewChange = (event, newView) => {
    if (newView !== null) { // Prevent deselection
      setSelectedView(newView);
    }
  };

  return (
    <React.Fragment>
      <Card mb={1} sx={{ marginBottom: 5 }}>
        <CardContent>


            <Typography variant="h6" gutterBottom>
              Headcount Analysis
            </Typography>




          {/* Flex Container for ToggleButtonGroup and possible additional content */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
            <ToggleButtonGroup
              exclusive
              value={selectedView}
              onChange={handleViewChange}
              aria-label="dashboard view"
            >
              <ToggleButton value="0" aria-label="Departmental salaries">
                Number of heads by department at year end
              </ToggleButton>
              <ToggleButton value="1" aria-label="Absolute change - heads">
                Absolute change - heads
              </ToggleButton>
              <ToggleButton value="2" aria-label="Salaries per head">
                Salaries per head
              </ToggleButton>
              <ToggleButton value="3" aria-label="Revenue per head">
                Revenue per head
              </ToggleButton>
            </ToggleButtonGroup>

            <DashboardKey hideChangeKey={true}/> {/* Right-aligned component */}
          </div>

          {heads.map((item, index) => (
            <div key={index} style={{ display: selectedView === String(index) ? 'block' : 'none' }}>
              <HeadcountDashboardInput headcountItem={item} name={"Headcount Analysis"}/>
            </div>
          ))}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
