
export async function copyTextToInputs() {
  // Get the text from the clipboard
  const text = await navigator.clipboard.readText();

  // Split the text into an array delimited by spaces
  const textArray = splitPastedNumbers(text);

  const idsAndValues = new Map();

  // Get the currently focused input box
  const focusedInput = document.activeElement;
  focusedInput.value = textArray.shift();

  const parentTd = getParentTd(focusedInput)
  idsAndValues.set(parentTd.id.split("-")[0], focusedInput.value);

  // Find the next input boxes and set their values from the array
  let currentInput = focusedInput;
  let arrayIndex = 0;


  while (currentInput && arrayIndex < textArray.length) {
    // Find the next sibling table cell and input box
    const nextTableCell = getParentTd(currentInput).nextElementSibling;
    if (!nextTableCell) {
      break; // Exit the loop if there are no more cells
    }
    const nextInput = nextTableCell.querySelector("input");
    if (!nextInput) {
      break; // Exit the loop if there are no more inputs
    }

    // Set the value of the next input box from the array
    const nextValue = textArray[arrayIndex];
    nextInput.value = nextValue;

    idsAndValues.set(nextTableCell.id.split("-")[0], nextValue)

    // Move to the next input box and array index
    currentInput = nextInput;
    arrayIndex++;
  }

  return idsAndValues;
}

/**
 * This takes a pasted list and then converts it to numbers, we have one special case
 * where negative numbers can be represented as (1100) so the brackets are signifying this is a negative number
 * so we will swap them out for a - sign.
 * Everything else works as expected.
 *
 * @param textToSplit
 * @returns {*}
 */
function splitPastedNumbers(textToSplit) {

  const textArray = textToSplit.split("\t").map((formattedNumber) => {
    let numberString = formattedNumber.replace(/,/g, ""); // Remove commas
    numberString = numberString.replace("(-", "-"); // Remove closed bracket ")"
    numberString = numberString.replace(")", ""); // Remove closed bracket ")"
    numberString = numberString.replace("(", "-"); // Replace "(" with -
    numberString = numberString.replace("%", ""); // Replace "%" with
    if (isNaN(numberString)){
     return 0;
    }
    return Number(numberString);
  });

  return textArray;

}


function getParentTd(element) {
  let parent = element.parentElement;
  while (parent !== null && parent.tagName !== "TD") {
    parent = parent.parentElement;
  }
  return parent;
}

