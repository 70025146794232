import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";



export function calculateVatForProductsAndExpenses(projectId, products, overheads, vatRates, currentAssets) {

  let currentAssetsList = currentAssets;

  let vatPackage;

  // get all products
  //let vatRates = await getVatRates(projectId);
  // get all the vat rates
  //let products = await getTopLevelProducts(projectId);
  // get all overheads
  //let overheads = await getTopLevelOverheads(projectId);

  vatPackage = calculateOutputVatForProducts(projectId, products, vatRates.vatCategoryDtos);

  vatPackage = calculateInputVatForProducts(projectId, vatPackage, currentAssetsList);

  vatPackage = calculateInputVatForOverheads(projectId, overheads, vatPackage);

  vatPackage = calculateNetVat(vatPackage);

  //todo Calculate Direct Product costs
  //for each product, calculate the direct cost including VAT for that particular product
  //create a monthly list that adds up all the product costs with VAT included.
  //For the first Actuals, simply return them
  //For the forecast, apply the vat

  //console.log('NEW vatPackage', vatPackage)

  return vatPackage;
}

function calculateNetVat(vatPackage) {

  let netVat = [];

  //console.log('calculateNetVat', vatPackage)

  _.forEach(vatPackage.productVatLookup, function(productVat, i) {
    //netVat[i] = productVat + vatPackage.expenseVatLookup[i];
    netVat[i] = -Math.abs(productVat) + Math.abs(vatPackage.expenseVatLookup[i]); //when calculating net vat, productVat (sales) should always be a negative value & expensesVat should always be a positive value

  });

  vatPackage.netVat = netVat;

  return vatPackage;
}


export function calculateOutputVatForProducts(projectId, products, vatRates) {


  let productVatLookup = [];

  // for each product we need to
  // group products by the vat rates
  // calculate the vat for each product
  // create an object { product: 1, expense: 1000, vat: 200, cost: 1200 }
  // repeat this for all vat types
  // remember we need to do this for each month
  _.forEach(vatRates, function(vatRate, x) {

    const productsForVatRate = products
      .filter(product => product.productDto.vatOutputRate === vatRate.id);

    _.forEach(productsForVatRate, function(productForVatRate) {

      // get the revenue category
      // apply the vat
      // store both the vat difference i.e. if product cost 1000 and vat is 20%, then store 200
      // but also store the total cost, i.e. 1200 in our example
      let productRevenue = productForVatRate.productCategoryDtoList
        .filter(productCategoryDto => productCategoryDto.name === "Revenue" || productCategoryDto.name === "Closing recurring revenue")[0];

      _.forEach(productRevenue.productMonthDtoList, function(productRevenueMonth, i) {

        productRevenueMonth.valueIncludingVat = addPercentage(getValueOrOverriddenValue(productRevenueMonth), vatRate.vatMonthDtos[i].value);
        productRevenueMonth.vatApplied = getPercentageAdded(getValueOrOverriddenValue(productRevenueMonth), vatRate.vatMonthDtos[i].value);

        if (vatRate.vatMonthDtos[i].totalProductVat === undefined) {
          vatRate.vatMonthDtos[i].totalProductVat = 0;
        }

        if (vatRate.vatMonthDtos[i].totalProductRevenue === undefined) {
          vatRate.vatMonthDtos[i].totalProductRevenue = 0;
        }

        vatRate.vatMonthDtos[i].totalProductVat += productRevenueMonth.vatApplied;
        vatRate.vatMonthDtos[i].totalProductRevenue += getValueOrOverriddenValue(productRevenueMonth);

        if (productVatLookup[i] === undefined) {
          productVatLookup[i] = 0;
        }

        productVatLookup[i] += ifNanThenZero(productRevenueMonth.vatApplied); // total Output VAT, by month

      });

    });

  });

  // total up all of the vat for each vat type
  return { products: products,
           vatRates: vatRates,
           productVatLookup: productVatLookup};
}



export function calculateInputVatForProducts(projectId, vatPackage, currentAssets) {

  let project = getCurrentProject();

  let expenseVatLookup = [];

  let directProductCostsIncVat = []; //this contains totalIncludingVat for each month. So it adds up all the Direct product costs (after having the appropriate Vat Rate applied) and then stores them in the array.
  let directProductCostsIncVatAndOffset = []; //this contains the same as directProductCostsIncVat only that is also takes into account the offset from the Current Asset - Inventory assumption.

  //find the Inventory assumption
  let inventory = currentAssets.find(currentAssets => currentAssets.currentAssetDto.name === 'Inventory')
  let inventoryHoldingPeriod = inventory.currentAssetCategoryDtos.find(category => category.name === 'Inventory holding period in months')
  // for each product we need to
  // group products by the vat rates
  // calculate the vat for each product
  // create an object { product: 1, expense: 1000, vat: 200, cost: 1200 }
  // repeat this for all vat types
  // remember we need to do this for each month
  _.forEach(vatPackage.vatRates, function(vatRate, x) {

    const productsForVatRate = vatPackage.products
      .filter(product => product.productDto.vatInputRate === vatRate.id);

    _.forEach(productsForVatRate, function(productForVatRate) {
      // get the revenue category
      // apply the vat
      // store both the vat difference i.e. if product cost 1000 and vat is 20%, then store 200
      // but also store the total cost, i.e. 1200 in our example
      let productCosts = productForVatRate.productCategoryDtoList
        .filter(productCategoryDto => productCategoryDto.name === "Direct product costs")[0];

      _.forEach(productCosts.productMonthDtoList, function(productRevenueMonth, i) {

        productRevenueMonth.expenseIncludingVat = addPercentage(getValueOrOverriddenValue(productRevenueMonth), vatRate.vatMonthDtos[i].value);
        productRevenueMonth.expenseVatApplied = getPercentageAdded(getValueOrOverriddenValue(productRevenueMonth), vatRate.vatMonthDtos[i].value);

        addToArrayAtIndex(directProductCostsIncVat, i, productRevenueMonth.expenseIncludingVat)



        if (vatRate.vatMonthDtos[i].expenseTotalProductVat === undefined) {
          vatRate.vatMonthDtos[i].expenseTotalProductVat = 0;
        }

        if (vatRate.vatMonthDtos[i].expenseTotalProductRevenue === undefined) {
          vatRate.vatMonthDtos[i].expenseTotalProductRevenue = 0;
        }

        vatRate.vatMonthDtos[i].expenseTotalProductVat += productRevenueMonth.expenseVatApplied;
        vatRate.vatMonthDtos[i].expenseTotalProductRevenue += getValueOrOverriddenValue(productRevenueMonth);

        if (expenseVatLookup[i] === undefined) {
          expenseVatLookup[i] = 0;
        }

        expenseVatLookup[i] += ifNanThenZero(productRevenueMonth.expenseVatApplied); // total Output VAT, by month

      });

    });

  });


  _.forEach(directProductCostsIncVat, function(productRevenueMonth, i) {

    //directProductCostsIncVatAndOffset section (used for current Liabilities 'Direct Product Costs' which needs the offset from inventory.
    if (i < project.firstBalanceForecast) {
      addToArrayAtIndex(directProductCostsIncVatAndOffset, i, directProductCostsIncVat[i]);
    } else {

      let offset = i + inventoryHoldingPeriod.currentAssetMonthDtos[i].value;
      if (offset >= directProductCostsIncVat.length) {
        offset = directProductCostsIncVat.length - 1;
      }

      //if it's a forecast value then we look at the newInventoryBought
      addToArrayAtIndex(directProductCostsIncVatAndOffset, i, directProductCostsIncVat[offset]);
    }
  });

  // total up all of the vat for each vat type
  vatPackage.expenseVatLookup = expenseVatLookup;
  vatPackage.directProductCostsIncVat = directProductCostsIncVat;
  vatPackage.directProductCostsIncVatAndOffset = directProductCostsIncVatAndOffset;

  return vatPackage;
}




export function calculateInputVatForOverheads(projectId, overheads, vatPackage) {
  // for each product we need to
  // group products by the vat rates
  // calculate the vat for each product
  // create an object { product: 1, expense: 1000, vat: 200, cost: 1200 }
  // repeat this for all vat types
  // remember we need to do this for each month
  _.forEach(vatPackage.vatRates, function(vatRate, x) {

    const overheadsForVatRate = overheads
      .filter(overhead => overhead.overheadDto.vatInputRate === vatRate.id);

    _.forEach(overheadsForVatRate, function(overheadForVatRate) {
      // get the revenue category
      // apply the vat
      // store both the vat difference i.e. if product cost 1000 and vat is 20%, then store 200
      // but also store the total cost, i.e. 1200 in our example
      let overheadCategoryValueInPnL = overheadForVatRate.overheadCategoryDtoList
        .filter(overheadCategoryDto => overheadCategoryDto.name === "Value In P&L")[0];

      //TODO 6 Aug - overheadCategoryValueInPnL is undefined some times
      _.forEach(overheadCategoryValueInPnL.overheadMonthDtoList, function(overheadCostMonth, i) {

        overheadCostMonth.expenseIncludingVat = addPercentage(getValueOrOverriddenValue(overheadCostMonth), vatRate.vatMonthDtos[i].value);
        overheadCostMonth.expenseVatApplied = getPercentageAdded(getValueOrOverriddenValue(overheadCostMonth), vatRate.vatMonthDtos[i].value);

        if (vatRate.vatMonthDtos[i].expenseTotalProductVat === undefined) {
          vatRate.vatMonthDtos[i].expenseTotalProductVat = 0;
        }

        if (vatRate.vatMonthDtos[i].expenseTotalProductRevenue === undefined) {
          vatRate.vatMonthDtos[i].expenseTotalProductRevenue = 0;
        }

        vatRate.vatMonthDtos[i].expenseTotalProductVat += overheadCostMonth.expenseVatApplied;
        vatRate.vatMonthDtos[i].expenseTotalProductRevenue += getValueOrOverriddenValue(overheadCostMonth);

        if (vatPackage.expenseVatLookup[i] === undefined) {
          vatPackage.expenseVatLookup[i] = 0;
        }

        vatPackage.expenseVatLookup[i] += ifNanThenZero(overheadCostMonth.expenseVatApplied); // total Output VAT, by month

      });

    });

  });

  vatPackage.overheads = overheads;

  return vatPackage;
}


export function ifNanThenZero(value) {
  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
}

function addPercentage(value, percentageToAdd) {

  let valueWithPercentageAdded = value + ((value / 100) * percentageToAdd);

  if (isNaN(valueWithPercentageAdded)) {
    return 0;
  } else {
    return valueWithPercentageAdded;
  }
}

function getPercentageAdded(value, percentageToAdd) {

  let valueWithPercentageAdded = ((value / 100) * percentageToAdd);

  if (isNaN(valueWithPercentageAdded)) {
    return 0;
  } else {
    return valueWithPercentageAdded;
  }
}

// Function to set null indexes to 0 and add a value
function addToArrayAtIndex(array, index, value) {
  // Use _.defaultTo() to replace null with 0 at the given index
  const currentValue = _.defaultTo(array[index], 0);

  // Use _.set() to add the value to the array at the specified index
  _.set(array, index, currentValue + value);
}