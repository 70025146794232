import {
  Settings,
  Calendar,
  CheckSquare,
  Sliders,
  PenTool,
  BarChart, Users, Crosshair, BookOpen, Camera
} from "react-feather";


const pagesSection = [

  {
    href: "/home",
    icon: CheckSquare,
    title: "Home",
  },
  {
    href: "/analysis",
    icon: Sliders,
    title: "Analysis",
    children: [
      {
        href: "/dashboard",
        title: "Project Dashboard",
      },
      {
        href: "/revenuedashboard",
        title: "Revenue Dashboard",
      },
      {
        href: "/overheaddashboard",
        title: "Overhead Dashboard",
      },
      {
        href: "/headcountdashboard",
        title: "Headcount Dashboard",
      },
      {
        href: "/cashflowdashboard",
        title: "Cashflow Dashboard",
      },
      {
        href: "/covenant",
        title: "Covenant",
      },
      // {
      //   href: "/valuation",
      //   title: "Valuation",
      // }
    ],
  },
  {
    href: "/annualoutput",
    icon: PenTool,
    title: "Annual Output",
    children: [
      {
        href: "/pnlannual",
        title: "P & L",
      },
      {
        href: "/balancesheetannual",
        title: "Balance Sheet",
      },
      {
        href: "/cashflowannual",
        title: "Cash Flow",
      },
    ],
  },
  {
    href: "/monthlyoutput",
    icon: Calendar,
    title: "Monthly Output",
    children: [
      {
        href: "/pnl",
        title: "P & L",
      },
      {
        href: "/balancesheet",
        title: "Balance Sheet",
      },
      {
        href: "/cashflow",
        title: "Cash Flow",
      },
    ],
  },
  {
    href: "/",
    icon: BarChart,
    title: "Assumptions",
    children: [
      {
        href: "/revenueandcos",
        title: "Revenue & Direct Costs",
      },
      {
        href: "/overheads",
        title: "Overheads & Other Costs",
        badge: "",
      },
      {
        href: "/headcount",
        title: "People costs & Headcount",
        badge: "",
      },
      {
        href: "/fixedassets",
        title: "Fixed Assets",
        badge: "",
      },
      {
        href: "/currentassets",
        title: "Current Assets",
        badge: "",
      },
      {
        href: "/currentliabilities",
        title: "Current Liabilities",
        badge: "",
      },
      {
        href: "/debt",
        title: "Debt",
        badge: "",
      },
      {
        href: "/equity",
        title: "Equity",
        badge: "",
      },
    ],
  },
  {
    href: "/export",
    icon: Crosshair,
    title: "Export",
  },
];

const settingsSection = [
  {
    href: "/config",
    icon: Settings,
    title: "Config",
    children: [
      // {
      //   href: "/projectsetup",
      //
      //   title: "Project Setup",
      // },
      {
        href: "/vatconfig",

        title: "VAT",
      },
      {
        href: "/corporationtaxconfig",

        title: "Corporation Tax",
      },
      {
        href: "/overdrafttaxconfig",

        title: "Overdraft Interest",
      },
    ]
  },
  {
    href: "/snapshot",
    icon: Camera,
    title: "Snapshots",
  },
  {
    href: "/company",
    icon: Users,
    title: "Your Account",
  }
];


const documentationSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "User Guide",
  },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
  {
    title: "Documentation",
    pages: documentationSection,
  },
];

// export let overheadsBadges = navItems[0].pages[4].children[1]['badge'] ;
// export let headcountBadges = navItems[0].pages[4].children[2]['badge'] ;
// export let fixedAssetBadges = navItems[0].pages[4].children[3['badge'] ];
// export let currentAssetBadges = navItems[0].pages[4].children[4]['badge'] ;
// export let currentLiabilitiesBadges = navItems[0].pages[4].children[5]['badge'] ;
// export let debtBadges = navItems[0].pages[4].children[6]['badge'] ;
// export let equityBadges = navItems[0].pages[4].children[7]['badge'] ;

export default navItems;
