import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function VerifyUserInput() {
  const navigate = useNavigate();
  const { verifySecurityCode, signOut } = useAuth();
  const { user } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  return (
    <Formik
      initialValues={{
        securityCode: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        securityCode: Yup.string().max(255).required("Security code is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let verified = await verifySecurityCode("" + values.securityCode, user.id);

          // if return false, then let the user know that the code was incorrect
          if (verified) {
            navigate("/home");
          } else {
            window.alert('Incorrect Security Code, Please Try Again')
          }

        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField

            name="securityCode"
            label="Enter security code"
            value={values.securityCode}
            error={Boolean(touched.securityCode && errors.securityCode)}
            fullWidth
            helperText={touched.securityCode && errors.securityCode}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Verify Account
          </Button>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
            onClick={handleSignOut}
          >
            Sign into another account
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default VerifyUserInput;
