import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import {
  Fab as MuiFab,
} from "@mui/material";

import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import InfoIcon from '@mui/icons-material/Info';
import getProjectId from "../pages/powdr/SharedComponents/ProjectServices";
import axios from "axios";

import { useRecoilValue } from "recoil";
import { pnlState } from "../pages/powdr/SharedState/PnLState";

import { calculatePnl } from "../pages/powdr/PLGroup/PL/calculations/Calculations";
import { calculateBalanceSheet } from "../pages/powdr/BalanceSheetGroup/BalanceSheet/calculations/Calculations";

import LoadingModal from "../pages/powdr/SharedComponents/LoadingModal";

import UpdatePowdrModelGlobal from "../pages/powdr/SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { balanceSheetState } from "../pages/powdr/SharedState/BalanceSheetState";
import { drawerState } from "../pages/powdr/SharedState/DrawerState";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(24)};
  bottom: ${(props) => props.theme.spacing(4)};
  z-index: 1;
`;


const InfoFab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(44)};
  bottom: ${(props) => props.theme.spacing(4)};
  z-index: 1;
`;

// export async function balanceRecalculation() {
//
//   let project = await getProjectId();
//
//   let pnl = await axios.get(`pnl/${project.id}`);
//
//   let balance = await axios.get(`balance/${project.id}`);
//
//   calculateBalanceSheet(balance.data[0], pnl.data);
//
//   await axios.put(`balance/${project.id}`, balance.data);
//
//   console.log("updated balance");
//
//   await axios.get(`cashflow/${project.id}`);
//
//   console.log("updated cashflow");
//
// }

/**
 * This method does the same of balanceRecalculation except it will also recalc the pnl
 * @returns {Promise<void>}
 */
export async function balanceCashflowPnlRecalculation(setPnlAtom) {

  let project = await getProjectId();

  let pnl = await axios.get(`pnl/${project.id}`);

  calculatePnl(pnl.data)

  setPnlAtom(pnl.data);

  await axios.put("pnl/" + pnl.data.pnLDto.project, pnl.data);

  let balance = await axios.get(`balance/${project.id}`);

  calculateBalanceSheet(balance.data[0], pnl.data);

  await axios.put(`balance/${project.id}`, balance.data);

  console.log("updated balance");

  await axios.get(`cashflow/${project.id}`);

  console.log("updated cashflow");

}


function Settings(props) {

  const updatePowdrModelGlobalRef = useRef();

  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const [updating, setUpdating] = useState(false);
  const drawerAtom = useRecoilValue(drawerState);
  const drawerContentAtom = useRecoilValue(drawerState);

  async function recalculate(methodToInvoke) {
    try {

      setUpdating(true);

      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      const start = new Date().getTime();

      if (methodToInvoke) {
        // if methodToInvoke is not null or undefined
        await methodToInvoke();

        window.alert('Recalculate finished')
      }

      const end = new Date().getTime();

      const elapsedTime = end - start;

      if (elapsedTime < 1000) {
        await delay(1000 - elapsedTime);
      }

      setUpdating(false);
    } catch (e) {
      setUpdating(false);
    }
  }

  const toggleDrawer = (open) => () => {
    //setState({ ...state, isOpen: open });
  };

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
  }

  return (
    <React.Fragment>
      <LoadingModal open={updating} title={'Recalculating Powdr Model'} description={'Assumptions are recalculating, please wait...'} />

      <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)} style={{zIndex: 1300}}>

        <CloudCircleIcon fontSize={"large"} onClick={() => recalculate(updatePowdrModelBalance)} />
      </Fab>


      { drawerAtom && (
        <InfoFab variant="extended" color="primary" aria-label="Edit" style={{zIndex: 1300}}>
          <span>{drawerContentAtom}</span>
          <InfoIcon fontSize={"large"} />
        </InfoFab>
      )}


      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}

export default Settings;
