import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);



const ClosingCashChart = ({cashflow, theme }) => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  let data = [];

  async function loadProductData() {

    if (cashflow !== undefined) {
      let closingBalance = cashflow.cashflowCategoryDtos.find(cashflow => cashflow.name === 'Closing Cash Balance');
      // get an array of all the monthly values
      let closingBalanceVals = closingBalance.cashflowMonthDtos.map(month => month.value);

      let startDate = new Date(getCurrentProject().startDate);
      startDate.setDate(1);
      //data structure = [date_in_milliseconds, value]

      _.forEach(closingBalanceVals, function(closingBalance, i) {

        data.push([startDate.getTime(), closingBalance]);

        startDate.setMonth(startDate.getMonth() + 1);

      });


      // data = generateDayWiseTimeSeries(new Date('11 Feb 2017').getTime(), 185, {
      //   min: 30,
      //   max: 9000
      // })

      //data.push({ name: 'Closing Balance', data: closingBalanceVals })

      setTableData(data);
      setShowTable(true);
    }
  }

  useEffect(() => {
    loadProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflow]);
  
  // loop through and get the gross profit for each year for each product
  // function generateDayWiseTimeSeries(baseval, count, yrange) {
  //   var i = 0;
  //   var series = [];
  //   while (i < count) {
  //     var x = baseval;
  //     var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  //
  //     series.push([x, y]);
  //     baseval += 86400000;
  //     i++;
  //   }
  //   return series;
  // }






  let state = {
    series: [{
      data: tableData
    }],
    options: {
      chart: {
        id: 'chart2',
        type: 'line',
        height: 230,
        toolbar: {
          autoSelected: 'pan',
          show: true
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#546E7A'],
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return "£" + value.toLocaleString();
          }
        },
      },
      tooltip: {
        x: {
          formatter: function(value) {
            return new Date(value).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: '2-digit'
            }).replace(/ \d+$/, ''); // Ensure tooltip shows '01' as the day
          },
        },
      },
    },

    seriesLine: [{
      data: tableData
    }],
    optionsLine: {
      chart: {
        id: 'chart1',
        height: 130,
        type: 'area',
        brush:{
          target: 'chart2',
          enabled: true
        },
        selection: {
          enabled: true,
          // xaxis: {
          //   min: new Date('19 Jun 2017').getTime(),
          //   max: new Date('14 Aug 2017').getTime()
          // }
        },
      },
      colors: ['#008FFB'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.91,
          opacityTo: 0.1,
        }
      },
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        forceNiceScale: true,
        tickAmount: 4,
        labels: {
          formatter: function (value) {
            return "£" + value.toLocaleString();
          }
        },
      },
      tooltip: {
        x: {
          formatter: function(value) {
            return new Date(value).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: '2-digit'
            }).replace(/ \d+$/, '');
          },
        },
      },
    },


  };









  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Closing Cash By Month Chart
        </Typography>
        <Typography variant="body2" gutterBottom>
          This chart shows the closing cash balance month by month.
        </Typography>

        <Spacer mb={6} />


        { showTable !== false && (
          <div id="wrapper">
            <div id="chart-line2">
              <Chart options={state.options} series={state.series} type="line" height={430} />
            </div>
            {/*<div id="chart-line">*/}
            {/*  <Chart options={state.optionsLine} series={state.seriesLine} type="area" height={130} />*/}
            {/*</div>*/}
          </div>
        )}

        {/*{ showTable !== false && (*/}
        {/*  <ChartWrapper>*/}
        {/*    <Chart options={options} series={tableData} type="line" height="350" />*/}
        {/*  </ChartWrapper>*/}
        {/*)}*/}
      </CardContent>
       
    </Card>
  );
};

export default withTheme(ClosingCashChart);
