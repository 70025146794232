/**
 * This method will determine if the move up/down command is a valid one.
 * For instance it will return false if the command moves Exceptionals above 'Depreciation/Amortisation' or below 'EBIT'
 *
 * returns a boolean flag to indicate if the move is valid or not.
 */
export function moveCategory(moveCmd, balance, balanceId) {

  let objectCategory;

  if (balanceId !== null) {
    objectCategory = balance.balanceCategoryDtos.find(obj => obj.monthlyOutput === balanceId);
  }

  // Return false if objectId is not found
  if (objectCategory === undefined) {
    return balance;
  }

  switch (objectCategory.balanceType) {
    case "FIXED_ASSETS":
      findAndMoveObject(moveCmd, balance, objectCategory, '', 'TOTAL FIXED ASSETS')
      break;
    case "CURRENT_ASSETS":
      findAndMoveObject(moveCmd, balance, objectCategory, '', 'Cash at bank')
      break;
    case "CURRENT_LIABILITIES":
      findAndMoveObject(moveCmd, balance, objectCategory, '', 'TOTAL CURRENT LIABILITIES')
      break;
    case "DEBT":
      findAndMoveObject(moveCmd, balance, objectCategory, '', 'TOTAL DEBT')
      break;
    case "EQUITY":
      findAndMoveObject(moveCmd, balance, objectCategory, '', 'P&L reserve')
      break;
    default:
      break;
  }
  return balance;
}

function findAndMoveObject(moveCmd, balance, objectCategory, inValidOverrideNameUpperBoundary, inValidOverrideNameLowerBoundary) {

  // If were moving UP is this a valid move?
  let indexOffset = moveCmd === 'UP' ? objectCategory.outputOrder - 1 : objectCategory.outputOrder + 1;

  let objectToMoveTo = balance.balanceCategoryDtos.find(obj => obj.outputOrder === indexOffset);

  if (objectToMoveTo === undefined){
    return false;
  }

  // Check object above is not 'Direct product costs'
  if (moveCmd === 'UP' && objectToMoveTo.overrideName === inValidOverrideNameUpperBoundary) {
    return false;
  }

  // Check object above is not 'Direct product costs'
  if (moveCmd === 'DOWN' && objectToMoveTo.overrideName === inValidOverrideNameLowerBoundary) {
    return false;
  }

  //swap the outputOrder values
  let initialOrder = objectCategory.outputOrder;
  objectCategory.outputOrder = objectToMoveTo.outputOrder;
  objectToMoveTo.outputOrder = initialOrder;

  //do the swap in the array
  let objectCategoryId = balance.balanceCategoryDtos.findIndex(obj => obj.id === objectCategory.id);
  let objectToMoveToId = balance.balanceCategoryDtos.findIndex(obj => obj.id === objectToMoveTo.id);

  swapArrayElements(balance.balanceCategoryDtos, objectCategoryId, objectToMoveToId)
}



function swapArrayElements(array, indexA, indexB) {
  let temp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = temp;
}
