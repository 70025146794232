import { atom } from "recoil";
import { calculateVatForProductsAndExpenses } from "../AssumptionsGroup/CurrentLiabilities/calculations/CalculateVat";
import axios from "axios";
import axiosInstance from "../../../utils/axios";

export const vatPackageState = atom({
  key: 'vatPackageState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    async ({ setSelf }) => {

      //we need to get the products and overheads and vat rates
      let vatPackage = await refreshVatPackageAPIcall();

      //console.log('SET SELF VAT PACKAGE', vatPackage );
      //we then setSelf with this
      setSelf(vatPackage);

    },
    // ({ onSet}) => {
    //   onSet( (oldVal, newVal) => {
    //     console.log('new val for vat rates package', newVal)
    //     //compare old to knew and only send the changes
    //     //this can happen any time the Atom is set
    //     //should be pretty slick!
    //   });
    // },
  ]
});

export function refreshVatPackage(products, overheads, vatRates, currentAssets) {

  //console.log('refreshVatPackage', 'products', products,  'overheads', overheads);
  const project = JSON.parse(sessionStorage.getItem("project"));
  //we then call the calculateVatForProductsAndExpenses method
  let vatPackage = calculateVatForProductsAndExpenses(project, products, overheads, vatRates[0], currentAssets);
  return vatPackage;
}

/**
 * The initial call to setSelf uses API values, after that refreshVatPackage(products, overheads) is used instead
 * for performance reasons.
 *
 * @returns {Promise<*>}
 */
export async function refreshVatPackageAPIcall() {
  const project = JSON.parse(sessionStorage.getItem("project"));

  var accessToken = window.localStorage.getItem("accessToken");
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  let vatRates = await axios.get("/vat/vatrate/" + project.id);
  let products = await axios.get("/revenuecos/" + project.id);
  let overheads = await axios.get("/overheads/" + project.id);
  let currentAssets = await axios.get("/currentassets/" + project.id);

  //we then call the calculateVatForProductsAndExpenses method
  let vatPackage = calculateVatForProductsAndExpenses(project, products.data, overheads.data, vatRates.data[0], currentAssets.data);
  return vatPackage;
}


