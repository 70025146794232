import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil";

import { getCurrentProject, setCurrentProject } from "../SharedComponents/ProjectServices";
import axios from "axios";
import { actualiseBalance } from "./calculations/balance";
import { balanceSheetState } from "../SharedState/BalanceSheetState";
import { currentAssetsState } from "../SharedState/CurrentAssetsState";
import { fixedAssetsState } from "../SharedState/FixedAssetsState";
import { currentLiabilitiesState } from "../SharedState/CurrentLiabilitiesState";
import { debtsState } from "../SharedState/DebtsState";
import { equitiesState } from "../SharedState/EquitiesState";
import { actualiseFixedAssets } from "./calculations/fixedAssets";
import { actualiseCurrentAssets } from "./calculations/currentAssets";
import { actualiseCurrentLiabilities } from "./calculations/currentLiabilities";
import { actualiseDebts } from "./calculations/debts";
import { actualiseEquity } from "./calculations/equity";
import { projectState } from "../SharedState/ProjectState";
import { actualiseCovenants } from "./calculations/covenants";

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const ActualiseBalance = forwardRef((props, ref) => {

  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const [fixedAssetAtom, setFixedAssetAtom] = useRecoilState(fixedAssetsState);
  const [currentAssetAtom, setCurrentAssetAtom] = useRecoilState(currentAssetsState);
  const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);
  const [debtAtom, setDebtAtom] = useRecoilState(debtsState);
  const [equityAtom, setEquityAtom] = useRecoilState(equitiesState);
  const setProjectAtom = useSetRecoilState(projectState);

  async function updateFirstForecastValue() {
    let project = getCurrentProject();
    project.firstBalanceForecast = project.firstBalanceForecast + 1;
    setCurrentProject(project);
    //do a put to the database
    await axios.put("/project", project).then(r => console.log("project first forecast updated"));
    setProjectAtom(project);
  }

  useImperativeHandle(ref, () => ({

    async actualiseBalance() {

      const tick = performance.now();

      //call take snapshot
      await axios.get('/actualise/' + getCurrentProject().id).then(function(response) {
        console.log('snapshot created! Now we can actualise this project');
      });

      let balanceActualised = actualiseBalance(structuredClone(balanceAtom));
      setBalanceAtom(balanceActualised);

      let fixedAssetActualised = actualiseFixedAssets(structuredClone(fixedAssetAtom));
      setFixedAssetAtom(fixedAssetActualised);

      let currentAssetActualised = actualiseCurrentAssets(structuredClone(currentAssetAtom));
      setCurrentAssetAtom(currentAssetActualised);

      let currentLiabilitiesActualised = actualiseCurrentLiabilities(structuredClone(currentLiabilitiesAtom));
      setCurrentLiabilitiesAtom(currentLiabilitiesActualised);

      let debtActualised = actualiseDebts(structuredClone(debtAtom));
      setDebtAtom(debtActualised);

      let equityActualised = actualiseEquity(structuredClone(equityAtom));
      setEquityAtom(equityActualised);

      await actualiseCovenants(structuredClone(equityAtom));

      //increment the first forecast by 1
      await updateFirstForecastValue();

      const tock = performance.now();

      console.log(`Main full stack thread took ${tock - tick} ms`);

    }

  }));

  return (
    <div></div>
  );
});

export default ActualiseBalance;
