import { getProjectIdNotAsync } from "../pages/powdr/SharedComponents/ProjectServices";
import axios from "axios";

export async function loadPnL() {

  let project = await getProjectIdNotAsync();

  const data = await axios.get("pnl/" + project.id);

  return data;
}

export async function loadShallowOverheads() {

  let project = await getProjectIdNotAsync();

  const data = await axios.get("overheads/" + project.id + "/items");

  return data;
}

export async function loadShallowHeadcounts() {

  let project = await getProjectIdNotAsync();

  const data = await axios.get("headcounts/shallow/" + project.id);

  return data;
}