import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider, LinearProgress
} from "@mui/material";
import { spacing } from "@mui/system";

import PageHeader from "../../SharedComponents/PageHeader";
import OverheadAnalysisContainer from "./components/OverheadsAboveGrossProfit";
import OverheadsGrossMarginChart from "./components/OverheadsGrossMarginChart";
import {
  calculateOverheadsAboveAnalysis,
  calculateOverheadsAnalysisChart, calculateOverheadsBelowAnalysis
} from "./calculations/Calculations";

import { useRecoilValue } from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { calculatePnlAnnuals } from "../../PLGroup/PLAnnual/calculations/Calculations";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import { overheadsState } from "../../SharedState/OverheadsState";


const Divider = styled(MuiDivider)(spacing);

function OverheadDashboard() {

  const pnlAtom = useRecoilValue(pnlState);

  const overheadsAtom = useRecoilValue(overheadsState);

  const [pnlLoaded, setPnlLoaded] = useState(false);

  const [directCosts, setDirectCosts] = useState([]);

  const [overheadsBelow, setOverheadsBelow] = useState([]);

  const [chart, setChart] = useState();


  async function loadPnL(){

    let overheadsToIgnore = isEmptyDefaultGroup(overheadsAtom);

    let pnlAnnuals = calculatePnlAnnuals(structuredClone(pnlAtom));

    let res = calculateOverheadsAboveAnalysis(pnlAnnuals, "Total costs of sales", overheadsToIgnore);
    setDirectCosts(res)

    let below = calculateOverheadsBelowAnalysis(pnlAnnuals, "Total Overheads", overheadsToIgnore);
    setOverheadsBelow(below);

    let chart_ = calculateOverheadsAnalysisChart(pnlAnnuals, overheadsToIgnore);
    setChart(chart_);

    setPnlLoaded(true);

  }

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.overheadDto.name.endsWith("(Default Overhead Group)") && item.overheadDto.overheads.length === 0).map(item => item.overheadDto.name);
    } else {
      return [];
    }

  };

  useEffect(() => {
    loadPnL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlAtom]);


  return (
    <React.Fragment>

        <PageHeader pageName={"Overhead Dashboard"} parentName={"Dashboard"}/>

        <Divider my={6} />

        {!pnlLoaded &&  (

          <div>
            <h1>Retrieving P & L...</h1>
            <LinearProgress my={2} />
          </div>

        )}

        <OverheadsGrossMarginChart overheads={chart}/>

        <OverheadAnalysisContainer pnlOverheadsAboveProfitGroup={directCosts} name={"Direct Costs"}></OverheadAnalysisContainer>

        <OverheadAnalysisContainer pnlOverheadsAboveProfitGroup={overheadsBelow} name={"Overheads"}></OverheadAnalysisContainer>


    </React.Fragment>
  );
}

export default OverheadDashboard;
