import _, { isArray } from "lodash";
import { loadPnL } from "../../../../../apiPowdr/pnl";
import axios from "axios";
import { createDecimal, parseNumberOrZero } from "../../../SharedComponents/utils/NumbersUtils";
import { isValidNumber } from "../../../Analysis/RevenueDashboard/calculations/Calculations";
const Decimal = require('decimal.js');

function calculateTotals(pnl, totalToCalculate, positionValue) {

  const overheadCategories = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Overheads"
      && pnlCategory.totalField === false
      && pnlCategory.position === positionValue);

  const overheadTotal = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === totalToCalculate
      && pnlCategory.totalField === true);

  // reset the values for the totals to 0 before calculating them.
  overheadTotal.pnLMonthDtoList.forEach(pnLMonthDto => {
    pnLMonthDto.value = new Decimal(0);
  });

  overheadCategories.forEach(overheadCategory => {
    overheadCategory.pnLMonthDtoList.forEach((pnLMonthDto, i) => {
      // Use Decimal.js for summing up the numbers
      overheadTotal.pnLMonthDtoList[i].value = Decimal.add(createDecimal(overheadTotal.pnLMonthDtoList[i].value), createDecimal(pnLMonthDto.value));
    });
  });

  // Convert Decimal objects back to numbers
  overheadTotal.pnLMonthDtoList.forEach(pnLMonthDto => {
    pnLMonthDto.value = parseNumberOrZero(pnLMonthDto.value.toString());
  });
}

function calculateTotalCostOfSales(pnl) {

  const overheadCategories = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Overheads"
      && pnlCategory.totalField === false
      && pnlCategory.position === "ABOVE");

  const directProductCosts = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Direct product costs");

  overheadCategories.push(directProductCosts);

  const overheadTotal = pnl.pnLCategoryDtoList
    .find(pnlCategory => pnlCategory.overrideName === "Total costs of sales"
      && pnlCategory.totalField === true);

  // reset the values for the totals to 0 before calcuating them.
  _.forEach(overheadTotal.pnLMonthDtoList, function(pnLMonthDto) {
    pnLMonthDto.value = 0;
  });

  _.forEach(overheadCategories, function(overheadCategory) {

    _.forEach(overheadCategory.pnLMonthDtoList, function(pnLMonthDto, i) {

      let val_ = isValidNumber(pnLMonthDto.value);

      overheadTotal.pnLMonthDtoList[i].value += val_;
    });

  });

}


function calculateGrossProfit(pnl) {

  const totalRevenue = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  const totalCos = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Total costs of sales");

  const totalGrossProfit = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Gross Profit");

  _.forEach(totalGrossProfit[0].pnLMonthDtoList, function(pnLMonthDto, i) {

    let costOfSalesValue_  = _.isNil(totalCos[0].pnLMonthDtoList[i].value) ? 0 : totalCos[0].pnLMonthDtoList[i].value;
    let totalRevenueValue_ = _.isNil(totalRevenue[0].pnLMonthDtoList[i].value) ? 0 : totalRevenue[0].pnLMonthDtoList[i].value;

    pnLMonthDto.value = totalRevenueValue_ +
                          costOfSalesValue_;
    
  });
  
}

function calculateEbitda(pnl) {

  //find the gross profit
  const totalGrossProfit = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Gross Profit");

  //find the total overheads
  const totalOverheads = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Total Overheads");

  const EBITDA = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "EBITDA");

  //deduct total overheads from gross profit to get ebitda
  EBITDA[0].pnLMonthDtoList.forEach((pnLMonthDto, i) => {
    // Use Decimal.js for summing up the numbers
    pnLMonthDto.value = Decimal.add(createDecimal(totalGrossProfit[0].pnLMonthDtoList[i].value), createDecimal(totalOverheads[0].pnLMonthDtoList[i].value));
  });

  // Convert Decimal objects back to numbers
  EBITDA[0].pnLMonthDtoList.forEach(pnLMonthDto => {
    pnLMonthDto.value = parseNumberOrZero(pnLMonthDto.value.toString());
  });
}

function calculatePBT(pnl) {

  //find the EBIT
  const EBIT = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "EBIT");

  //find the net interest expense
  const netInterestExpense = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Net Interest Expense");


  //find the net interest expense
  const pikInterest = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "PIK Interest");

  //find the PBT
  const PBT = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "PBT");

  //deduct net interest expense from EBIT to get PBT
  PBT[0].pnLMonthDtoList.forEach((pnLMonthDto, i) => {

    if (_.isEmpty(pikInterest)) {
      // Use Decimal.js for summing up the numbers
      pnLMonthDto.value = Decimal.add(createDecimal(EBIT[0].pnLMonthDtoList[i].value), createDecimal(netInterestExpense[0].pnLMonthDtoList[i].value));
    } else {

      // Use Decimal.js for summing up the numbers
      pnLMonthDto.value = Decimal.add(createDecimal(EBIT[0].pnLMonthDtoList[i].value), createDecimal(netInterestExpense[0].pnLMonthDtoList[i].value)).add(createDecimal(pikInterest[0].pnLMonthDtoList[i].value));
    }


  });

  // Convert Decimal objects back to numbers
  PBT[0].pnLMonthDtoList.forEach(pnLMonthDto => {
    pnLMonthDto.value = parseNumberOrZero(pnLMonthDto.value.toString());
  });
}

function calculateNetProfit(pnl) {

  //console.log('net calc')

  //get the Corporate Tax field
  const corporationTax = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Corporation Tax");

  //get the Dividends field
  const dividends = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Dividends");

  //get the PBT
  const PBT = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "PBT");

  //get the Net Profit
  const netProfit = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Net Profit");

  //If this project has dividents (older projects don't include them)
  if (isArray(dividends) && dividends.length > 0) {

    //Net profit is corporation tax + dividend + PBT
    netProfit[0].pnLMonthDtoList.forEach((pnLMonthDto, i) => {
      // Use Decimal.js for summing up the numbers
      pnLMonthDto.value =
        createDecimal(corporationTax[0].pnLMonthDtoList[i].value).plus(
          createDecimal(PBT[0].pnLMonthDtoList[i].value)).plus(
          createDecimal(dividends[0].pnLMonthDtoList[i].value))
    });

  } else {
    //Net profit is corporation tax + dividend + PBT
    netProfit[0].pnLMonthDtoList.forEach((pnLMonthDto, i) => {
      // Use Decimal.js for summing up the numbers
      pnLMonthDto.value =
        createDecimal(corporationTax[0].pnLMonthDtoList[i].value).plus(
          createDecimal(PBT[0].pnLMonthDtoList[i].value))
    });
  }

  // Convert Decimal objects back to numbers
  netProfit[0].pnLMonthDtoList.forEach(pnLMonthDto => {
    pnLMonthDto.value = parseNumberOrZero(pnLMonthDto.value.toString());
  });
}

function calculateGrossProfitMargin(pnl) {

  //find gross profit
  const totalGrossProfit = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Gross Profit");

  //find revenue
  const totalRevenue = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  //find gross profit margin
  const grossProfitMargin = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Gross Profit Margin");

  //“Gross profit margin”. This is a percentage and = Gross profit / revenue
  _.forEach(grossProfitMargin[0].pnLMonthDtoList, function(pnLMonthDto, i) {

    let val = (totalGrossProfit[0].pnLMonthDtoList[i].value /
      totalRevenue[0].pnLMonthDtoList[i].value) * 100;

    pnLMonthDto.value = isNumberValid(val) ? val : 0;

  });

}

function isNumberValid(num) {
  if (!isNaN(num)
        && isFinite(num)
        && num !== null
        && num !== undefined) {
    return true;
  }
  return false;
}

function calculateEBITDAMargin(pnl) {

  //find EBITDA
  const EBITDA = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "EBITDA");


  //find revenue
  const totalRevenue = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  //find EBITDA  margin
  const ebitdaMargin = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "EBITDA Margin");

  //“EBITDA margin”. This is a percentage and = EBITDA/ revenue
  _.forEach(ebitdaMargin[0].pnLMonthDtoList, function(pnLMonthDto, i) {

    let val = (EBITDA[0].pnLMonthDtoList[i].value /
      totalRevenue[0].pnLMonthDtoList[i].value) * 100;

    pnLMonthDto.value = isNumberValid(val) ? val : 0;

  });

}


function calculateNetProfitMargin(pnl) {

  //find Net Profit
  const netProfit = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Net Profit");


  //find revenue
  const totalRevenue = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Revenue from products");

  //find Net Profit margin
  const netProfitMargin = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Net Profit Margin");

  //“Net profit margin”. This is a percentage and = Net profit/ revenue
  _.forEach(netProfitMargin[0].pnLMonthDtoList, function(pnLMonthDto, i) {

    let val = (netProfit[0].pnLMonthDtoList[i].value /
      totalRevenue[0].pnLMonthDtoList[i].value) * 100;

    pnLMonthDto.value = isNumberValid(val) ? val : 0;

  });

}

function calculatePercentageComplete(pnl) {
  
  pnl.pnLDto.PercentageComplete = 0;
  var totalNumberOfEntries = 0;
  var percentageComplete = 0

  _.forEach(
    pnl.pnLCategoryDtoList,
    function (pnLCategoryDto) {

      _.forEach(
        pnLCategoryDto.pnLMonthDtoList,
        function (pnLMonthDto) {
          
          if (pnLMonthDto.value !== 0 && pnLMonthDto.cellType !== "DISABLED") {
            percentageComplete++;
          }

          if (pnLMonthDto.cellType === "DISABLED") {
            percentageComplete++;
          }

          totalNumberOfEntries++;

        }
      );

    }
  );
  pnl.pnLDto.percentageComplete = ((percentageComplete / totalNumberOfEntries) * 100)


}


function calculateEbit(pnl) {


  const overheadCategories = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "Overheads"
      && pnlCategory.totalField === false
      && pnlCategory.position === "EBITDA");

  let overheadsBelowEBITDATotals = Array(overheadCategories[0].pnLMonthDtoList.length).fill(0);


  overheadCategories.forEach(overheadCategory => {
    overheadCategory.pnLMonthDtoList.forEach((pnLMonthDto, i) => {
      overheadsBelowEBITDATotals[i] += pnLMonthDto.value;
    });
  });

  //find the depreciation
  const depreciation = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "Depreciation/Amortisation")[0];

  //find the Amortisation
  // const amortisation = pnl.pnLCategoryDtoList
  //   .filter(pnlCategory => pnlCategory.overrideName === "Amortisation / impairment")[0];

  const EBITDA = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.name === "EBITDA")[0];

  const EBIT = pnl.pnLCategoryDtoList
    .filter(pnlCategory => pnlCategory.overrideName === "EBIT")[0];

  //deduct total overheads from gross profit to get ebitda
  _.forEach(EBIT.pnLMonthDtoList, function(pnLMonthDto, i) {

    //nb We += the value because we've already calculated all the Overheads for the EBITDA section earlier and want to preserve them.
    pnLMonthDto.value =
      EBITDA.pnLMonthDtoList[i].value
        + depreciation.pnLMonthDtoList[i].value
        + overheadsBelowEBITDATotals[i];
        //- amortisation.pnLMonthDtoList[i].value;
  });

}

export function calculatePnl(pnl) {

  if (pnl !== undefined) {

    calculateTotals(pnl, "Total Overheads", "BELOW");
    calculateTotalCostOfSales(pnl);
    calculateTotals(pnl, "EBIT", "EBITDA");
    calculateGrossProfit(pnl);
    calculateEbitda(pnl);
    calculateEbit(pnl);
    calculatePBT(pnl);
    calculateNetProfit(pnl);
    calculateGrossProfitMargin(pnl);
    calculateEBITDAMargin(pnl);
    calculateNetProfitMargin(pnl);
    calculatePercentageComplete(pnl);
  }
}

/**
 * get pnl from DB
 * call calculate pnl
 * update pnl in DB
 *
 * @returns {Promise<void>}
 */
export async function updatePnl(setPnlAtom) {

  loadPnL().then(pnl => {

    calculatePnl(pnl.data)

    setPnlAtom(pnl.data);

    axios.put("pnl/" + pnl.data.pnLDto.project, pnl.data);
  })

}