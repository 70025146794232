import _ from "lodash";

/**
 * Get totals based on dynamic criteria groups for a given data object.
 *
 * Each criteria group is an array of criteria. All criteria within a group must be satisfied
 * for the group to be considered a match. Only one group needs to match for the category to be considered a match.
 *
 * Example usage:
 *
 * const criteriaGroups = [
 *   [
 *     { name: "Overheads", operator: "EQUALS" },
 *     { name: "EBITDA" , operator: "NOT_EQUALS"}
 *   ],
 *   [
 *     { name: "Total Overheads" , operator: "EQUALS"}
 *   ]
 * ];
 *
 * const result = getTotals(criteriaGroups, samplePnL, 'pnLCategoryDtoList', 'pnLMonthDtoList');
 *
 * @param {Array<Array<Object>>} criteriaGroups - List of criteria groups to filter categories.
 * @param {Object} dataObject - The main object containing data (e.g., PnL, BalanceSheet).
 * @param {string} categoryDtoName - Name of the property in dataObject that holds categories (e.g., 'pnLCategoryDtoList', 'balanceCategoryDtoList').
 * @param {string} monthDtoName - Name of the property in categories that holds monthly data (e.g., 'pnLMonthDtoList', 'balanceMonthDtoList').
 * @returns {Array<number>} - An array of totals based on the provided criteria groups.
 */
export function getTotals(criteriaGroups, dataObject, categoryDtoName, monthDtoName, sumAcrossGroups = true) {
  if (_.isEmpty(criteriaGroups)) {
    return [];
  }

  // Helper function to check if a category matches a given criterion
  function matchesCriterion(category, criterion) {
    return _.every(criterion, (value, key) => {
      if (key === 'operator') return true; // Skip the operator key

      const operator = criterion.operator || 'EQUALS'; // Default to EQUALS if no operator is provided

      switch (operator) {
        case 'EQUALS':
          return _.get(category, key) === value;
        case 'NOT_EQUALS':
          return _.get(category, key) !== value;
        default:
          throw new Error(`Unknown operator: ${operator}`);
      }
    });
  }



  // Helper function to get categories that match all criteria in a group
  function getMatchingCategories(criteria) {
    return _.filter(dataObject[categoryDtoName], category => {
      return _.every(criteria, criterion => matchesCriterion(category, criterion));
    });
  }

  function isOverridden(category, monthIndex) {
    let valueToUse;

    //if there is an overridden value then we use that instead
    let value = _.get(category, `${monthDtoName}[${monthIndex}].value`)
    let valueOverriden = _.get(category, `${monthDtoName}[${monthIndex}].valueOverriden`)

    if (valueOverriden !== undefined && valueOverriden !== null) {
      valueToUse = valueOverriden;
    } else {
      valueToUse = value;
    }

    return valueToUse

  }

  // Helper function to sum values for a given month index across all matching categories
  function sumForMonth(matchingCategories, monthIndex) {
    return _.sumBy(matchingCategories, category => isOverridden(category, monthIndex));
  }

  // Map each criteria group to an array of summed values for each month
  const monthlySumsPerCriteria = _.map(criteriaGroups, criteria => {
  const matchingCategories = getMatchingCategories(criteria);

    if (_.isEmpty(matchingCategories)) {
      return _.fill(Array(_.size(_.get(dataObject, `${categoryDtoName}[0].${monthDtoName}`))), 0);
    }

    return _.map(matchingCategories[0][monthDtoName], (_, index) => {
      return sumForMonth(matchingCategories, index);
    });
  });


  if (monthlySumsPerCriteria.length === 1) {
    return monthlySumsPerCriteria[0];
  }

  return _.reduce(monthlySumsPerCriteria, (acc, curr) => {
    return _.map(acc, (value, index) => value + curr[index]);
  }, _.fill(Array(_.size(_.get(dataObject, `${categoryDtoName}[0].${monthDtoName}`))), 0));

}













