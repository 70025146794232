import { atom } from 'recoil';
import { refreshApiCall } from "./RecoilUtils";

export const projectState = atom({
  key: 'projectState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({setSelf}) => {
      refreshApiCall(setSelf, "pnl");
    },
  ]
});
