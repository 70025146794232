import axios from "axios";
import axiosInstance from "../../../utils/axios";

export default async function getProjectId() {

  var accessToken = window.localStorage.getItem("accessToken");

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  //check if the project id is in the localstore
  const storedProject = sessionStorage.getItem("project");


  if (storedProject !== "undefined") {
    return JSON.parse(storedProject);
  } else {
    return undefined;
  }
  //return JSON.parse(storedProject);
  // if so then return it, if not then call getProject
  // also we need to check that the bearer token has been set, so
  // we check in the local storage, if its there then we set it in axios as a global
}

export function getProjectIdNotAsync() {

  var accessToken = window.localStorage.getItem("accessToken");

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  //check if the project id is in the localstore
  const storedProject = sessionStorage.getItem("project");

  if (storedProject !== "undefined") {
    return JSON.parse(storedProject);
  } else {
    return undefined;
  }

  //return JSON.parse(storedProject);
  // if so then return it, if not then call getProject
  // also we need to check that the bearer token has been set, so
  // we check in the local storage, if its there then we set it in axios as a global
}

export function getCurrentProject() {

  //check if the project id is in the localstore
  const storedProject = sessionStorage.getItem("project");

  if (storedProject !== "undefined") {
    return JSON.parse(storedProject);
  } else {
    return undefined;
  }

}

export function setCurrentProject(project) {

  //check if the project id is in the localstore
  sessionStorage.setItem("project", JSON.stringify(project));

}
