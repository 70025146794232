import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilValue } from "recoil";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { calculateRevenueDashboard } from "./calculations/Calculations";
import RevenueDashboardGroup from "./components/RevenueDashboardGroup";
import RevenueBarChart from "./components/RevenueBarChart";
import ProductMarginChart from "./components/ProductMarginChart";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";

const Divider = styled(MuiDivider)(spacing);

// const BlurredContainer = styled.div`
//   filter: blur(5px); /* Adjust the value to control the amount of blur */
// `;

function RevenueDashboard() {

  const [products, setProducts] = useState([]);

  const [productsForMargin, setProductsForMargin] = useState([]);

  const productsAtom = useRecoilValue(revenueAndCostState);

  useEffect(() => {
    let productsCalculated = calculateRevenueDashboard(structuredClone(productsAtom));

    let filteredRevenue = isEmptyDefaultGroup(productsCalculated)
    setProducts(filteredRevenue);

    let productsForMarginCalculated = calculateRevenueDashboard(structuredClone(productsAtom), true);

    let productsForMarginCalculatedFiltered = isEmptyDefaultGroup(productsForMarginCalculated);
    setProductsForMargin(productsForMarginCalculatedFiltered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom]);


  const isEmptyDefaultGroup = (arr) => {
    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(subArray => {
        // Check if every item in the sub-array has canDelete !== false
        return !subArray.some(item => item.productDto.name.endsWith("(Default Product Group)") && item.productDto.products.length === 0);
      });
    } else {
      return arr;
    }
  };

  return (
    // <BlurredContainer>
    //   <UpgradeModal open={true} title={'Unlock Revenue Analytics'} description={'Click here to unlock Powdr Analytics'} />
      <React.Fragment >
        <PageHeader pageName={"Revenue Dashboard"} parentName={"Dashboard"} />
        <Divider my={6} />
        <Card sx={{ marginBottom: 5 }}>
          <CardContent>
            <h4>This dashboard gives a breakdown of revenue by product. <br /><br />Each product is analyzed individually on an annual basis, showing both the financial contribution of each product, and the key assumptions driving performance in the model</h4>
          </CardContent>
        </Card>
        <RevenueBarChart products={products}></RevenueBarChart>
        <ProductMarginChart products={productsForMargin}></ProductMarginChart>
        {!!products &&
          products.map((product) => (
            <RevenueDashboardGroup key={Math.random() * 1000}
                                   productItemGroup={product}
            ></RevenueDashboardGroup>
          ))}
      </React.Fragment>
    // {/*</BlurredContainer>*/}
  );
}

export default RevenueDashboard;
