import React, { useState, useEffect } from "react";
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress, List, ListItem, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import {  Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";

function EditProduct(props) {
  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();

  const [products, setProducts] = useState([]);
  const [headcounts, setHeadcounts] = useState([]);

  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const headcountsAtom = useRecoilValue(headcountsState);
  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);

  const [projects, setProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [productsForProject, setProductsForProject] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { open, setOpen, product} = props;


  useEffect(() => {

    setProducts(structuredClone(productsAtom));
    setHeadcounts(structuredClone(headcountsAtom));

    loadProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom, headcountsAtom, open]);


  function loadProjects() {

    axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {
      if (product.assumption === "REVENUE_DIVISIONAL_CONSOLIDATION" && product.canDelete === false && product.name.endsWith("(Default Product Group)")) {
        setProjects(response.data.filter(project => project.id === product.linkedProjectId))
      } else {
        setProjects(response.data); //bespoke groups will display all the linked projects

        if (response.data.length > 0) {
          setSelectedProject(response.data.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0)[0]);
        }
      }

    })

    if (selectedProject !== undefined && selectedProject !==  undefined && product.linkedProjectId !== null) {

      axios.get("revenuecos/" + product?.linkedProjectId).then(function(response) {

        setProductsForProject(response.data);
        setSelectedProducts(product?.products);
      });

    }


  }

  const validationSchema = Yup.object().shape({
    productName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(productsAtom.filter(product_ => product_?.productDto?.name !== product?.name).map(function(i) {
        return i.productDto.name;
      }), "Products must have a unique name"),
  });

  const initialValues = {
    productName: product?.name,
    assumptionFormat: product?.assumption,
    variableA: product?.variableA,
    variableB: product?.variableB,
    variableC: product?.variableC,
    dpcAssumptionType: product?.dpcAssumptionType,
    selectedProject_: product?.linkedProjectId,
  };

  const handleChangeProduct = (event) => {


    if (event.target.checked) {
      if (!product.products.includes(parseInt(event.target.id))) {
        product.products.push(parseInt(event.target.id))
      }
    } else {
      let productsChosenUpdate = product.products.filter(function(e) { return e !== parseInt(event.target.id) })
      product.products = productsChosenUpdate
    }


  };

  const handleSelectModel = (model) => {

    const currentIndex = selectedProducts.indexOf(model.productDto.id);
    const newChecked = [...selectedProducts];

    if (currentIndex === -1) {
      newChecked.push(model.productDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedProducts(newChecked);
  };

  function findParentProduct(row) {
    const found = products.find(product =>   product.productDto.products.includes(row.productDto.id));
    return found ? found.productDto.name : '';
  }

  const handleChangeHeadcount = (event) => {


    if (event.target.checked) {
      if (!product.headcounts.includes(parseInt(event.target.id))) {
        product.headcounts.push(parseInt(event.target.id))
      }
    } else {
      let headcountsChosenUpdate = product.headcounts.filter(function(e) { return e !== parseInt(event.target.id) })
      product.headcounts = headcountsChosenUpdate
    }


  };

  useEffect( () => {
    if (selectedProject !== undefined && selectedProject !==  undefined) {

      axios.get("revenuecos/" + selectedProject.id).then(function(response) {
        setProductsForProject(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  function updateProduct(values, project, product, setOpen, setSubmitting, setErrors, selectedProducts) {

    try {

      setSubmittingForm(true)

      let productsToInclude = [];

      if (values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION" ) {
        productsToInclude = selectedProducts;
      } else {
        productsToInclude = product.products;
      }

      console.log(productsToInclude);

      axios
        .put("revenuecos/product", {
          name: values.productName,
          project: project.id,
          assumption: values.assumptionFormat,
          percentageComplete: 0,
          overrideComplete: false,
          vatInputRate: product.vatInputRate,
          vatOutputRate: product.vatOutputRate,
          id: product.id,

          variableA: values.variableA,
          variableB: values.variableB,
          variableC: values.variableC,
          dpcAssumptionType: product.dpcAssumptionType,

          linkedProjectId: values.selectedProject_,

          products: productsToInclude || [],
          headcounts: product.headcounts || []
        })
        .then(async function(response) {

          //if you've edited an assumption and the assumptions type has changed, then we need to do a global recalc
          //we need to reload the p&l because the assumption has changed
          //we need to reload the 'current assets' because the assumptions might be nolonger applicable to current asset 'percentage of revenue'
          //we need to reload the 'current liabilities' because the assumptions might be nolonger applicable to current asset 'percentage of revenue'

          //update the atoms - products & current assets (has list of products)
          await axios.get("revenuecos/" + project.id).then(function(response) {
            setProductsAtom(response.data);
          });

          await axios.get("currentassets/" + project.id).then(function(response) {
            setCurrentAssets(response.data);
          });

          setOpen(false);
          setSubmittingForm(false)
          setSelectedProducts([]);
          setSelectedProject({ id : 0 })
        })
        .catch(function (error) {
          console.log(error);;
          setSubmittingForm(false)
        });


    } catch (error) {
      setErrors({ submit: error.message });

    }
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateProduct(values, project, product, setOpen, setSubmitting, setErrors, selectedProducts);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateProduct(values, project, product, setOpen, setSubmitting, setErrors, selectedProducts);
    }

  };

  return (
    <React.Fragment>
      <Grid container>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Product</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Editing Product...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      update the product name:
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.productName && errors.productName)}
                      name="productName"
                      autoFocus
                      placeholder={"Product Name"}
                      margin="dense"
                      id="productName"
                      label="Product Name"
                      value={values.productName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.productName && errors.productName}
                      fullWidth
                      disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="DIRECT_INPUT"
                        control={<Radio />}
                        label="Direct Input"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY"
                        control={<Radio />}
                        label="Price x Quantity"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_COST_INFLATION"
                        control={<Radio />}
                        label="Price X Quantity (Cost Inflation)"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="SUBSCRIPTION_MODEL"
                        control={<Radio />}
                        label="Subscription modelling"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="ANNUAL_GROWTH_RATE"
                        control={<Radio />}
                        label="Annual Growth Rate"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="MONTHLY_GROWTH_RATE"
                        control={<Radio />}
                        label="Monthly Growth Rate"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="HEADS_TO_REVENUE"
                        control={<Radio />}
                        label="Sales Per Person"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="HEADCOUNT_UTILISATION"
                        control={<Radio />}
                        label="Headcount Utilisation"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="NON_HEADCOUNT_UTILISATION"
                        control={<Radio />}
                        label="Non-Headcount Utilisation"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_BUILDER"
                        control={<Radio />}
                        label="Price x Quantity (Builder)"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="REVENUE_AS_A_PERCENTAGE_OF_VALUE"
                        control={<Radio />}
                        label="Revenue as a percentage of a value (Builder)"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="SALES_PER_DAY_REVENUE"
                        control={<Radio />}
                        label="Sales Per Day"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PERCENTAGE_OF_ANOTHER_PRODUCT"
                        control={<Radio />}
                        label="Percentage of Another Product"
                        disabled={values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION"}
                      />

                      <FormControlLabel
                        value="REVENUE_DIVISIONAL_CONSOLIDATION"
                        control={<Radio />}
                        label="Revenue Consolidation"
                        disabled={true}
                      />

                    </RadioGroup>


                      </Grid>

                      {values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select a linked project to create your product consolidation group</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <br/>
                                <Typography variant="h6">Projects</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                      <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        onClick={() => setSelectedProject(row)}
                                        disabled={product.name.includes("(Default Product Group)")}
                                      >
                                        <ListItemText primary={row.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }


                      {values.assumptionFormat === "REVENUE_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select products you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Products</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {productsForProject.map((row) => (
                                      <ListItem
                                        key={row.productDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        disabled={product.name.includes("(Default Product Group)")}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedProducts.indexOf(row.productDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.productDto.name} secondary={`Current Group: ${(findParentProduct(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }


                      {values.assumptionFormat === "PRICE_X_QUANTITY_BUILDER" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Price x Quantity (Builder)
                            calculation</FormLabel>
                          <FormGroup>

                            <DialogContentText>
                              <br/>
                              Variable A Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableA && errors.variableA)}
                              name="variableA"
                              autoFocus
                              margin="dense"
                              id="variableA"
                              label="Max Unit"
                              value={values.variableA}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableA && errors.variableA}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable B Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableB && errors.variableB)}
                              name="variableB"
                              autoFocus
                              margin="dense"
                              id="variableB"
                              label="Units Sold"
                              value={values.variableB}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableB && errors.variableB}
                              fullWidth
                            />



                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {values.assumptionFormat === "REVENUE_AS_A_PERCENTAGE_OF_VALUE" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Price x Quantity (Builder)
                            calculation</FormLabel>
                          <FormGroup>

                            <DialogContentText>
                              <br/>
                              Variable A Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableA && errors.variableA)}
                              name="variableA"
                              autoFocus
                              margin="dense"
                              id="variableA"
                              label="Max Unit"
                              value={values.variableA}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableA && errors.variableA}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable B Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableB && errors.variableB)}
                              name="variableB"
                              autoFocus
                              margin="dense"
                              id="variableB"
                              label="Units Sold"
                              value={values.variableB}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableB && errors.variableB}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable C Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableC && errors.variableC)}
                              name="variableC"
                              autoFocus
                              margin="dense"
                              id="variableC"
                              label="Percentage of quantity"
                              value={values.variableC}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableC && errors.variableC}
                              fullWidth
                            />


                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {values.assumptionFormat === "HEADS_TO_REVENUE" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in headcount to revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox
                                                        defaultChecked={product?.headcounts?.includes(category?.id)}
                                                        onChange={handleChangeHeadcount}
                                                        name={category.departmentName} id={category.id + ""} />
                                                    }
                                                    label={category.departmentName}
                                  />
                                ))

                              ))}


                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {["HEADS_TO_REVENUE","NON_HEADCOUNT_UTILISATION","PRICE_X_QUANTITY_BUILDER","SALES_PER_DAY_REVENUE"].includes(values.assumptionFormat) && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">

                          <FormLabel component="legend">Please select the direct product cost assumption type:</FormLabel>
                          <FormGroup>

                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="assumptionFormat"
                              value={values.dpcAssumptionType}
                              onChange={handleChange}

                            >
                              <FormControlLabel
                                value="MARGIN"
                                control={<Radio />}
                                label="Margin"
                                disabled
                              />
                              <FormControlLabel
                                value="UNIT_COST"
                                control={<Radio />}
                                label="Unit Cost"
                                disabled
                              />
                            </RadioGroup>
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      }

                      {values.assumptionFormat === "HEADCOUNT_UTILISATION" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in the headcount utilisation
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox
                                                        defaultChecked={product?.headcounts?.includes(category?.id)}
                                                        onChange={handleChangeHeadcount}
                                                        name={category.departmentName} id={category.id + ""} />
                                                    }
                                                    label={category.departmentName}
                                  />
                                ))

                              ))}


                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {values.assumptionFormat === "PERCENTAGE_OF_ANOTHER_PRODUCT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Product(s) to include in the percentage of another product
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.filter(productF => productF.productDto.id !== product.id).map((productA) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={product?.products?.includes(productA.productDto.id)}
                                                              onChange={handleChangeProduct}
                                                              name={productA.productDto.name} id={productA.productDto.id + ""} />
                                                  }
                                                  label={productA.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                    </Grid>



                  </DialogContent>


                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default EditProduct;
