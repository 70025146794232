
import {
  buildDivisionOfTwoCategories,
  buildPercentageOfTwoCategories,
  buildYearDifferenceCategory, buildYearDifferencePercentageCategory, buildYearPercentageDifferenceCategory,
  buildYearTotalCategory
} from "./Calculations";

export function createAnnualSummary(product_) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";

  let quantitySoldCategory = buildYearTotalCategory(product, "Quantity Sold");
  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");

  let salesPricePerUnitSold = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySoldCategory, 2);
  let costPricePerUnitSold  = buildDivisionOfTwoCategories("Cost price per unit sold (ex-VAT)", directProductCosts, quantitySoldCategory, 2);
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit );

  annualSummary.productCategoryDtoList.push(quantitySoldCategory, salesPricePerUnitSold, revenueCategory, costPricePerUnitSold, directProductCosts, grossProfit, productMargin);


  return annualSummary;
}


export function createGrowthRates(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";

  let quantitySoldCategory = buildYearTotalCategory(product, "Quantity Sold");
  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");
  let salesPricePerUnitSold = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySoldCategory);
  let costPricePerUnitSold  = buildDivisionOfTwoCategories("Cost price per unit sold (ex-VAT)", directProductCosts, quantitySoldCategory);
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit );


  let quantitySoldGrowth = buildYearPercentageDifferenceCategory("Quantity Sold", quantitySoldCategory);
  let revenueGrowth = buildYearPercentageDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth = buildYearPercentageDifferenceCategory("Direct product costs", directProductCosts, 0, "#FF0000", "#008000");
  let grossProfitGrowth = buildYearPercentageDifferenceCategory("Gross Profit", grossProfit);
  let salesPricePerUnitSoldGrowth = buildYearPercentageDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold);
  let costPricePerUnitSoldGrowth = buildYearPercentageDifferenceCategory("Cost price per unit sold (ex-VAT)", costPricePerUnitSold, 0, "#FF0000", "#008000");
  let productMarginGrowth = buildYearPercentageDifferenceCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(quantitySoldGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}

export function createYearOnYearChange(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";

  let quantitySoldCategory = buildYearTotalCategory(product, "Quantity Sold");
  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");
  let salesPricePerUnitSold = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySoldCategory);
  let costPricePerUnitSold  = buildDivisionOfTwoCategories("Cost price per unit sold (ex-VAT)", directProductCosts, quantitySoldCategory);
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit );


  let quantitySoldGrowth = buildYearDifferenceCategory("Quantity Sold", quantitySoldCategory);
  let revenueGrowth = buildYearDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth = buildYearDifferenceCategory("Direct product costs", directProductCosts, 0, "↑", "↓");
  let grossProfitGrowth = buildYearDifferenceCategory("Gross Profit", grossProfit);
  let salesPricePerUnitSoldGrowth = buildYearDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold, 2);
  let costPricePerUnitSoldGrowth = buildYearDifferenceCategory("Cost price per unit sold (ex-VAT)", costPricePerUnitSold, 2, "↑", "↓");
  let productMarginGrowth = buildYearDifferencePercentageCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(quantitySoldGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}


