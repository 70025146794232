import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import axios from "axios";


export async function actualiseCovenants() {
  let covenants;

  try {
    covenants = await loadCovenant();
  } catch (error) {
    console.error('Error loading covenants:', error);
    // Handle the error appropriately, possibly return or throw a custom error
    return;
  }

  const firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(covenants, (covenant) => {
    _.forEach(covenant.covenantCategoryDtos, (category) => {
      if (_.isEmpty(category.covenantMonthDtos)) {
        return;
      }

      category.covenantMonthDtos[firstBalanceForecast].cellType =
        category.covenantMonthDtos[firstBalanceForecast - 1].cellType;

      category.covenantMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.covenantMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.covenantMonthDtos[firstBalanceForecast].valueOverriden = null;
    });
  });

  try {
    await saveCovenant(covenants);
  } catch (error) {
    console.error('Error saving covenants:', error);
    // Handle the error appropriately, possibly return or throw a custom error
  }
}


export async function loadCovenant() {
  let id = getCurrentProject().id;

  const config = {
    method: "get",
    url: "covenant/" + id
  };

  try {
    let response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error loading covenant:', error);
    // Handle the error appropriately, possibly rethrow it or return a default value
    throw error;
  }
}

async function saveCovenant(covenants) {

  let id = getCurrentProject().id;

  // Implementation of the saveCovenant function
  const config = {
    method: "put",
    url: "covenant/" + id,
    data: covenants
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error saving covenant:', error);
    // Handle the error appropriately, possibly rethrow it or return a default value
    throw error;
  }
}