import React from 'react';
import CashflowMovement from "../../../Analysis/CashflowDashboard/components/CashflowMovement";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";


// Style for the modal content box
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,

  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CashflowAnnualMovementModal({rowData, open=false, onClose}) {

return (
  <Modal
    open={open}
    onClose={onClose} // This is triggered on clicking away as well
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <CashflowMovement row={rowData}></CashflowMovement>
    </Box>
  </Modal>
);


}