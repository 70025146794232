import React, { useEffect, useState } from "react";
import useProject from "../../../../../hooks/useProject";
import { Skeleton } from "@mui/material";

import RevenueDashboardInput from "./RevenueDashboardInput";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { tableWidth } from "../../../SharedComponents/styles/SharedStyles";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import DashboardKey from "../../Shared/DashboardKey";



export default function RevenueDashboardGroup(props) {

  const { key } = useProject();

  const [isLoading, setIsLoading] = useState(true);
  // State to keep track of the currently active RevenueDashboardInput
  const [selectedView, setSelectedView] = useState('0'); // Initialize with the index as a string


  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const { productItemGroup } = props;



  const handleViewChange = (event, newView) => {
    if (newView !== null) { // Prevent deselection
      setSelectedView(newView);
    }
  };

  function renderHeavyContent() {
    return (
      <div>
        <Card mb={6} sx={{...tableWidth, marginBottom: 5}} id={"table"}>
          <CardContent sx={{ paddingTop: 5 }}>

            {/* Flex Container for aligning ToggleButtonGroup and DashboardKey */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
              <ToggleButtonGroup
                exclusive
                value={selectedView}
                onChange={handleViewChange}
                aria-label="dashboard view"
              >
                <ToggleButton value="0" aria-label="annual summary">
                  Annual Summary
                </ToggleButton>
                <ToggleButton value="1" aria-label="growth rates">
                  Growth Rates
                </ToggleButton>
                <ToggleButton value="2" aria-label="year on year absolute change">
                  Year on year absolute change
                </ToggleButton>
              </ToggleButtonGroup>

              <DashboardKey></DashboardKey>
            </div>

            <div>
              {productItemGroup.map((item, index) => (
                <div key={Math.random() * 1000} style={{ display: selectedView === String(index) ? 'block' : 'none' }}>
                  <RevenueDashboardInput productItem={item} key={Math.random() * 1000} />
                </div>
              ))}
            </div>

          </CardContent>
        </Card>
      </div>
    );
  }


  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}