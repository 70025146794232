export function calcPercent(num, percentage){
  if (num === 0) {
    return 0;
  }

  const result = num * (percentage / 100);

  if (!isFinite(result)) {
    return 0;
  }

  return parseFloat(result.toFixed(8));
}

export function percentage(partialValue, totalValue) {

  let val = (100 * partialValue) / totalValue;
  if (isFinite(val)) {
    return val;
  }
  return 0;
  // if (isNaN(val) || !isFinite(val)){
  //   return 0;
  // } else {
  //   return val;
  // }
}

/**
 * Calculates the percentage value of a total.
 * Allows negative percentages, which will result in a negative value.
 * If the inputs are invalid, the function returns 0.
 *
 * // Example usage
 *
 * const result = calculatePercentageOfTotal(20, 200); // should return 40
 * const result = calculatePercentageOfTotal(-20, 200); // should return -40
 *
 * @param {number} percentage - The percentage to be calculated.
 * @param {number} total - The total value from which the percentage is calculated.
 * @returns {number} - The calculated percentage of the total, or 0 if inputs are invalid.
 */
export function calculatePercentageOfTotal(percentage, total) {
  // Check if percentage or total is null, undefined, or not a number
  if (percentage == null || total == null || isNaN(percentage) || isNaN(total)) {
    console.warn('Invalid input: percentage or total is null/undefined or not a number');
    return 0;
  }

  // Calculate the percentage of the total
  let val = (percentage / 100) * total;

  if (isFinite(val)) {
    return val;
  }
  return 0;
}

/**
 * Calculates the percentage increase of a given number. A negative percentage is interpreted as a percentage decrease.
 *
 * @param {number} num - The original number.
 * @param {number} percentage - The percentage change (increase if positive, decrease if negative).
 * @returns {number} The number after applying the percentage change.
 *
 * @throws {Error} If the num or percentage is not a finite number.
 *
 * @example
 * // returns 150
 * calculatePercentageIncrease(100, 50);
 * // returns 50
 * calculatePercentageIncrease(100, -50);
 */
export function calculatePercentageIncrease(num, percentage) {
  // Check if the inputs are numbers
  if (typeof num !== 'number' || typeof percentage !== 'number') {
    throw new Error('Both num and percentage must be numbers');
  }

  // Check if the inputs are finite numbers
  if (!isFinite(num) || !isFinite(percentage)) {
    throw new Error('Both num and percentage must be finite numbers');
  }

  return num * (1 + percentage / 100);
}

/**
 * Calculates the value represented by a given percentage of a number.
 *
 * @param {number} num - The original number.
 * @param {number} percentage - The percentage to calculate.
 * @returns {number} The value represented by the percentage of the number.
 *
 * @throws {Error} If the num or percentage is not a finite number.
 *
 * @example
 * // returns 2
 * calculatePercentageValue(100, 2);
 * // returns 1.5
 * calculatePercentageValue(75, 2);
 */
export function calculatePercentageValue(num, percentage) {
  // Check if the inputs are numbers
  if (typeof num !== 'number' || typeof percentage !== 'number') {
    throw new Error('Both num and percentage must be numbers');
  }

  // Check if the inputs are finite numbers
  if (!isFinite(num) || !isFinite(percentage)) {
    throw new Error('Both num and percentage must be finite numbers');
  }

  return num * (percentage / 100);
}


/**
 * Calculates a given percentage decrease of a number, then reverses the sign.
 *
 * @param {number} num - The original number.
 * @param {number} percentage - The percentage to decrease by.
 * @returns {number} The number after decreasing by the percentage and reversing the sign.
 *
 * @throws {Error} If the num or percentage is not a finite number.
 *
 * @example
 * // returns -5312919.75
 * calculateDecreaseAndReverseSign(7083893, 25);
 */
export function calculateDecreaseAndReverseSign(num, percentage) {
  // Check if the inputs are numbers
  if (typeof num !== 'number' || typeof percentage !== 'number') {
    throw new Error('Both num and percentage must be numbers');
  }

  // Check if the inputs are finite numbers
  if (!isFinite(num) || !isFinite(percentage)) {
    throw new Error('Both num and percentage must be finite numbers');
  }

  // Calculate the reduced amount and reverse its sign
  return (num - num * (percentage / 100)) * -1;
}
