import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import SignUpComponent from "../../components/auth/SignUp";



const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  return (
    <React.Fragment>

      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Sign Up To Powdr
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign Up To Powdr We will arrange a introduction meeting at your convenience  <strong>free of charge to help you understand what Powdr can do for you.</strong>
        </Typography>

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
