import React from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider
} from "@mui/material";
import { spacing } from "@mui/system";

import PageHeader from "../../SharedComponents/PageHeader";
import { useState, useEffect } from "react";
import HeadcountAnalysis from "./components/HeadcountAnalysis";
import DepartmentAnalysis from "./components/DepartmentAnalysis";
import { useRecoilValue } from "recoil";
import { headcountsState } from "../../SharedState/HeadcountsState";
import {
  calculateDepartmentData,
  calculateHeadsData, getHeadcountHeadsData
} from "./calculations/Calculations";
import HeadcountBarChart from "./components/HeadcountBarChart";
import { pnlState } from "../../SharedState/PnLState";
import { calculatePnlAnnuals } from "../../PLGroup/PLAnnual/calculations/Calculations";


const Divider = styled(MuiDivider)(spacing);

function HeadcountDashboard() {

  const headcountAtom = useRecoilValue(headcountsState);

  const pnlAtom = useRecoilValue(pnlState);

  const [heads, setHeads] = useState([[]]);

  const [chartData, setChartData] = useState([]);

  const [departments, setDepartments] = useState([[]]);

  useEffect(() => {

    loadPnL();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlAtom]);


  async function loadPnL(){

    let pnlAnnuals = calculatePnlAnnuals(structuredClone(pnlAtom));

    let chart_ = getHeadcountHeadsData(structuredClone(headcountAtom));
    setChartData(chart_);

    let heads_ = calculateHeadsData(structuredClone(headcountAtom), pnlAnnuals);
    setHeads(heads_);

    let departments_ = calculateDepartmentData(structuredClone(headcountAtom), pnlAnnuals);
    setDepartments(departments_)

  }


  return (
    <React.Fragment>

        <PageHeader pageName={"Headcount Dashboard"} parentName={"Dashboard"}/>

        <Divider my={6} />

        <HeadcountBarChart heads={chartData}></HeadcountBarChart>

        <HeadcountAnalysis heads={heads}></HeadcountAnalysis>

        <DepartmentAnalysis departments={departments}></DepartmentAnalysis>

    </React.Fragment>
  );
}

export default HeadcountDashboard;
