import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel, Paper
} from "@mui/material";
import { styled } from '@mui/system';
import { useEffect, useState } from "react";
import DisclaimerContent from "./DisclaimerContent";

// Add this style
const DialogPaper = styled(Paper)({
  minWidth: '50vw',
  minHeight: '50vh',
  maxWidth: '50vw',
  maxHeight: '50vh',
});

export default function DisclaimerModal() {
  const [open, setOpen] = useState(false);
  const [dontShow, setDontShow] = useState(false);

  useEffect(() => {
    const disclaimerShown = localStorage.getItem('disclaimerShown');
    if (disclaimerShown === 'true') {
      setOpen(false);
    } else {
      setOpen(true)
    }
  }, []);

  const handleOk = () => {
    if (dontShow) {
      localStorage.setItem('disclaimerShown', 'true');
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleOk}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperComponent={DialogPaper} // Use this line
    >
      <DialogTitle id="scroll-dialog-title">Disclaimer</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >

          <DisclaimerContent/>


        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={<Checkbox onChange={() => setDontShow(!dontShow)} color="primary" />}
          label="Don't show this again"
        />
        <Button onClick={handleOk} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
