import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
  Stack, Button, Skeleton
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../hooks/useProject";
import PnlInput from "./components/PnLInput";
import AddOverhead from "../../AssumptionsGroup/Overheads/components/AddOverhead";
import Settings from "../../../../components/Settings";
import AddHeadCount from "../../AssumptionsGroup/HeadCount/components/AddHeadCount";
import DeleteModal from "../../SharedComponents/DeleteModal";
import PageHeader from "../../SharedComponents/PageHeader";
import { moveCategory } from "./calculations/MoveUpDown";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { overheadsState } from "../../SharedState/OverheadsState";
import { pnlState } from "../../SharedState/PnLState";
import { headcountsState } from "../../SharedState/HeadcountsState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { createExport } from "../../Export/calculations/Calculations";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import UpdatePowdrModelPnl from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelPnl";
import { refreshVatPackage, vatPackageState } from "../../SharedState/VatPackageState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { vatState } from "../../SharedState/VatState";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { projectLoadingState } from "../../SharedState/ProjectLoadingState";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { headcountAssumptionMap, overheadAssumptionMap } from "../../SharedComponents/AssumptionMaps";

const Divider = styled(MuiDivider)(spacing);


function PL() {

  const updatePowdrModelPnlRef = useRef();

  const updatePowdrModelGlobalRef = useRef();

  const [pnl, setPnl] = useState([]);

  const [editTableEnabled, setEditTableEnabled] = useState("");
  const [pnlCategoryToDelete, setPnlCategoryToDelete] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { getProject } = useProject();

  const [overheadsAtom, setOverheadsAtom] = useRecoilState(overheadsState);
  const [headCountAtom, setHeadcountAtom] = useRecoilState(headcountsState);

  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);

  const balanceAtom = useRecoilValue(balanceSheetState);
  const setCurrentLiabilitiesAtom = useSetRecoilState(currentLiabilitiesState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);
  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);

  const loadingProject = useRecoilValue(projectLoadingState);

  const tableRef = useRef(null);
  const { project } = useProject();

  function loadPnL(){

    setPnl([structuredClone(pnlAtom)]);
  }

  function moveOverhead(overheadId_, headcountId_, direction) {

    let pnlUpdated = moveCategory(direction, pnl[0], overheadId_, headcountId_)

    setPnl([...pnlUpdated]);

    if (getProject() !== undefined) {
      var config = {
        method: "put",
        url: "pnl/" + getProject().id + "/categoriesOnly",
        data : pnlUpdated
      };

      axios(config)
        .then(async function(response) {

          await axios.get("pnl/" + project.id).then(function(response) {
            setPnlAtom(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function deletePnlCategoryApiCall(handleClose) {

    if (pnlCategoryToDelete.name === "Overheads" && pnlCategoryToDelete.headcountId === null) {

      deleteOverhead(handleClose, pnlCategoryToDelete.overheadId);

    } else if (pnlCategoryToDelete.headcountId !== null) {

      deleteHeadCount(handleClose, pnlCategoryToDelete.headcountId);

    }

  }

  const deleteHeadCount = (handleClose, headcountId) => {

    let headcountToDelete = headCountAtom.find(headcount => headcount.headCountDto.id === headcountId);

    var config = {
      method: "delete",
      url: "headcounts",
      data: headcountToDelete.headCountDto,
    };

    axios(config)
      .then(async function(response) {

        setHeadcountAtom(structuredClone(response.data))

        await axios.get("revenuecos/" + project.id).then(function(response) {
          setProductsAtom(response.data);
        });

        await axios.get("pnl/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, response.data);
        });

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteOverhead = (handleClose, overheadId) => {

    let overheadToDelete = overheadsAtom.find(overhead => overhead.overheadDto.id === overheadId);

    var config = {
      method: "delete",
      url: "overheads",
      data: overheadToDelete.overheadDto,
    };

    axios(config)
      .then(async function(response) {

        let overheads_ = response.data;
        setOverheadsAtom(structuredClone(overheads_));

        let vatPackage = refreshVatPackage(structuredClone(productsAtom), structuredClone(overheads_), structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage);

        await axios.get("pnl/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, response.data);
        });

        await axios.get("currentassets/" + project.id).then(function(response) {
          setCurrentAssetsAtom(response.data);
        });

        await axios.get("currentliabilities/" + project.id).then(function(response) {
          setCurrentLiabilitiesAtom(response.data);
        });



        // deduct the count from the notifications
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function deletePnlCategory(pnlCategory) {
    //set the pnlCategoryToDelete to pnlCategory
    setPnlCategoryToDelete(pnlCategory)
    //set the setOpenDeleteModal to true
    setOpenDeleteModal(true)
  }

  function updatePowdrModelPnl(cellData) {
    updatePowdrModelPnlRef.current.updatePowdrModelPnl(cellData,
      structuredClone(pnl[0]),
      overheadAssumptionMap,
      headcountAssumptionMap
    );
  }


  function refreshPnLData(cellData) {

    if (hasCellValueChanged(cellData)) {

      updatePowdrModelPnl(cellData);

    }

  }

  useEffect(() => {
    loadPnL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlAtom]);

  useEffect(() => {
    if (updatePowdrModelGlobalRef !== undefined && updatePowdrModelGlobalRef.current !== undefined) {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
    }
  },[]);


  function renderHeavyContent() {
    return (
      <React.Fragment>
        <PageHeader pageName={"Profits & Loss"} parentName={"Monthly Output"}/>

        <Divider my={6} />

        <ControlsBar
          addAssumptionElement={
            <Stack spacing={4} direction="row">
              <AddOverhead/>
              <AddHeadCount/>
            </Stack>
          }
          element2={
            <Button type="submit" variant="contained" onClick={() => createExport(pnl[0].pnLCategoryDtoList, pnl[0].pnLDto)} sx={{marginBottom: 5}}>Export Excel</Button>
          }
          hideZoom={true}
        />


        {!!pnl &&
          pnl.map((pnlitem) => (
            <PnlInput
              tableRef={tableRef}
              key={pnlitem.pnLDto.id}
              pnLItem={pnlitem}
              pnl={pnl}
              refreshPnLData={refreshPnLData}
              setPnl={setPnl}
              loadPnLData={loadPnL}
              moveOverhead={moveOverhead}
              setEditData={setEditTableEnabled}
              editData={editTableEnabled}
              deletePnlCategory={deletePnlCategory}
            ></PnlInput>
          ))}


        <UpdatePowdrModelPnl ref={updatePowdrModelPnlRef}/>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

        <Settings/>

        <DeleteModal
          itemToDelete={pnlCategoryToDelete}
          deleteMethod={deletePnlCategoryApiCall}
          name={pnlCategoryToDelete.overrideName}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
        ></DeleteModal>



      </React.Fragment>
    );


  }


  return (
    <React.Fragment key={"pnl"}>
      {loadingProject ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default PL;
