import React, { useState } from "react";
import { CardContent, Typography, Card } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import OverheadsDashboardInput from "./OverheadsDashboardInput";
import DashboardKey from "../../Shared/DashboardKey";


export default function OverheadAnalysisContainer({ pnlOverheadsAboveProfitGroup, name }) {
  const [selectedView, setSelectedView] = useState('0'); // Initialize with the index as a string

  const handleViewChange = (event, newView) => {
    if (newView !== null) { // Prevent deselection
      setSelectedView(newView);
    }
  };

  return (
    <React.Fragment>
      <Card mb={1} sx={{ marginBottom: 5 }}>
        <CardContent>

          <Typography variant="h6" gutterBottom>
            Overhead Analysis - {name}
          </Typography>

          {/* Flex Container for aligning ToggleButtonGroup and another component */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <ToggleButtonGroup
              exclusive
              value={selectedView}
              onChange={handleViewChange}
              aria-label="dashboard view"
            >
              <ToggleButton value="0" aria-label="percentage of revenue">
                Percentage of revenue
              </ToggleButton>
              <ToggleButton value="1" aria-label="growth rates">
                Growth Rates
              </ToggleButton>
              <ToggleButton value="2" aria-label="year on year absolute change">
                Year on year absolute change
              </ToggleButton>
            </ToggleButtonGroup>

            <DashboardKey /> {/* This is your right-aligned component */}
          </div>

          {pnlOverheadsAboveProfitGroup.map((item, index) => (
            <div key={index} style={{ display: selectedView === String(index) ? 'block' : 'none' }}>
              <OverheadsDashboardInput overheadItem={item} name={name}/>
            </div>
          ))}

        </CardContent>
      </Card>
    </React.Fragment>
  );
}
