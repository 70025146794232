import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { refreshVatPackage, vatPackageState } from "../../SharedState/VatPackageState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { cashFlowState } from "../../SharedState/CashflowState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";

import { calculateCashflow } from "../../CashflowGroup/CashFlow/calculations/Calculations";
import { calculatePnl } from "../../PLGroup/PL/calculations/Calculations";
import { vatState } from "../../SharedState/VatState";
import { corporationTaxState } from "../../SharedState/CorporationTaxState";
import { overheadsState } from "../../SharedState/OverheadsState";
import { headcountsState } from "../../SharedState/HeadcountsState";
import { calculateHeadCounts } from "../../AssumptionsGroup/HeadCount/calculations/Calculations";
import { getCurrentProject } from "../ProjectServices";
import { calculateOverheads } from "../../AssumptionsGroup/Overheads/calculations/Calculations";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import {
  headcountAssumptionMap,
  overheadAssumptionMap
} from "../AssumptionMaps";
import { debtsState } from "../../SharedState/DebtsState";
import { equitiesState } from "../../SharedState/EquitiesState";
import { fixedAssetsState } from "../../SharedState/FixedAssetsState";
import {
  calculateLoanAsset,
  calculateRevenueAndCOS, calculateRevenueSummaryTotals
} from "../../AssumptionsGroup/RevenueAndCos/calculations/Calculations";
import {
  copyAllPnlToHeadcounts,
  copyAllPnlToOverheads,
  refreshAndCalculateCurrentAssets,
  refreshAndCalculateCurrentLiabilities,
  refreshAndCalculateDebts, refreshAndCalculateEquity,
  refreshAndCalculateFixedAssets,
  updatePnl
} from "./UpdatePowdrModelUtils";
import { calculateBalanceSheet } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";
import { overheadsConsolidationState } from "../../SharedState/OverheadsConsoliationState";
import { revenueAndCostConsolidationState } from "../../SharedState/RevenueAndCostConsolidationState";
import { headcountsConsolidationState } from "../../SharedState/HeadcountsConsolidationState";



/**
 * Used to globally recalculate
 *
 * This will recalculate all the assumptions and then  update the P&L and Balance Sheet with the latest values from the assumptions
 *
 * Steps:
 *
 * 1.  Access the revenue atom, call recalculate on it.
 * 1.1 Update the atom
 * 1.2 Copy all the values to the P&L (This happens as part of the recalculate, so no need to do it explicity)
 *
 * 2.  Access the overheads atom, call recalculate on it.
 * 2.1 Update the atom
 * 2.2 Copy all the values to the P&L
 *
 * 3.  Headcount - Repeat the exact same steps
 *
 * 4.  Recalculate the P&L and save
 * 5.  Recalculate the VatPackage and save
 * 6.  Recalculate the cashflow and save
 *
 * 7.  Now repeat steps 2 - 2.2 for Fixed Assets, Current Assets, Current Liabilities, Debts & Equities
 *
 * 8.  Recalculate the P&L and save
 * 9.  Recalculate the VatPackage and save
 * 10. Recalculate the cashflow and save
 * 11. Recalculate and Save the cashflow
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const UpdatePowdrModelGlobal = forwardRef((props, ref) => {

  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const [cashflowAtom, setCashflowAtom] = useRecoilState(cashFlowState);

  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);
  const [overheadAtom, setOverheadAtom] = useRecoilState(overheadsState);
  const [headcountAtom, setHeadcountAtom] = useRecoilState(headcountsState);

  const [debtAtom, setDebtAtom] = useRecoilState(debtsState);
  const [equityAtom, setEquityAtom] = useRecoilState(equitiesState);
  const [fixedAssetAtom, setFixedAssetAtom] = useRecoilState(fixedAssetsState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);

  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  const corporationTaxAtom = useRecoilValue(corporationTaxState);

  const overheadsConsolidations = useRecoilValue(overheadsConsolidationState);

  const revenueAndCostConsolidation = useRecoilValue(revenueAndCostConsolidationState);
  const headcountConsolidation = useRecoilValue(headcountsConsolidationState);


  useImperativeHandle(ref, () => ({


    globalRecalculatinCaclulation: function(pnlFromComponent,
                                            balanceFromComponent,
                                            productsFromComponent,
                                            overheadsFromComponent,
                                            headCountsFromComponent,
                                            currentLiabilitiesFromComponent) {

      const tick = performance.now();

      let pnl = pnlFromComponent ? structuredClone(pnlFromComponent) : structuredClone(pnlAtom);
      let balance = balanceFromComponent ? structuredClone(balanceFromComponent) : structuredClone(balanceAtom);
      let cashflow = structuredClone(cashflowAtom);

      let products = productsFromComponent ? structuredClone(productsFromComponent) : structuredClone(productsAtom);
      let overheads = overheadsFromComponent ? structuredClone(overheadsFromComponent) : structuredClone(overheadAtom);
      let headcounts = headCountsFromComponent ? structuredClone(headCountsFromComponent) : structuredClone(headcountAtom);

      let fixedAssets = structuredClone(fixedAssetAtom);
      let currentAssets = structuredClone(currentAssetAtom);
      let currentLiabilities = currentLiabilitiesFromComponent ? structuredClone(currentLiabilitiesFromComponent) : structuredClone(currentLiabilitiesAtom);
      let debts = structuredClone(debtAtom);
      let equities = structuredClone(equityAtom);

      let corporationTax = structuredClone(corporationTaxAtom);

      //Firstly we have to copy over the P&L actuals to their respective assumptions
      copyAllPnlToOverheads(pnl, overheads);
      copyAllPnlToHeadcounts(pnl, headcounts);

      /**
       * 1.  Access the revenue atom, call recalculate on it.
       * 1.1 Update the atom
       * 1.2 Copy all the values to the P&L (This happens as part of the recalculate, so no need to do it explicity)
       */
      calculateRevenueAndCOS(products, headcounts, revenueAndCostConsolidation);
      setProductsAtom(structuredClone(products));
      pnl = calculateRevenueSummaryTotals(products, pnl, setPnlAtom);

      /**
       * 2.  Access the overheads atom, call recalculate on it.
       * 2.1 Update the atom
       * 2.2 Copy all the values to the P&L
       */
      calculateOverheads(overheads, pnl, products, headcounts, overheadsConsolidations);
      setOverheadAtom(overheads);
      updatePnl(overheads, overheadAssumptionMap, "overhead", pnl);

      /**
       * 3.  Access the headcounts atom, call recalculate on it.
       * 3.1 Update the atom
       * 3.2 Copy all the values to the P&L
       */
      calculateHeadCounts(headcounts, products, pnl, setPnlAtom, revenueAndCostConsolidation, headcountConsolidation);
      updatePnl(headcounts, headcountAssumptionMap, "headCount", pnl);
      setHeadcountAtom(headcounts);


      //4.  Recalculate the P&L and save
      calculatePnl(pnl, getCurrentProject().id);

      calculateBalanceSheet(balance, pnl);

      //5.  Recalculate the VatPackage and save
      let vatPackage = refreshVatPackage(structuredClone(products), structuredClone(overheads), structuredClone(vatRateAtom), currentAssetAtom);
      setVatPackageAtom(vatPackage); //basically this just triggers a refresh

      //7.  Now repeat steps 2 - 2.2 for Fixed Assets, Current Assets, Current Liabilities, Debts & Equities
      refreshAndCalculateFixedAssets(balance, fixedAssets);
      refreshAndCalculateCurrentAssets(balance, currentAssets, vatPackage, pnl); //todo - after this, we need to update the Revenue & P&L so that Asset Loan values have been copied across
      refreshAndCalculateCurrentLiabilities(balance, currentLiabilities, vatPackage, pnl, products, overheads, corporationTax, cashflow);
      refreshAndCalculateDebts(balance, debts);
      refreshAndCalculateEquity(balance, equities);

      //required if we have Asset Loans in Current Assets
      calculateLoanAsset(products, currentAssets);
      setProductsAtom(structuredClone(products));
      pnl = calculateRevenueSummaryTotals(products, pnl, setPnlAtom);

      calculatePnl(pnl, getCurrentProject().id);

      calculateBalanceSheet(balance, pnl);

      let cashflow_ = calculateCashflow(cashflow, balance, pnl);

      calculateBalanceSheet(balance, pnl);

      setPnlAtom(structuredClone(pnl));
      setBalanceAtom(structuredClone(balance));
      setCashflowAtom(cashflow_);

      setFixedAssetAtom(structuredClone(fixedAssets));
      setCurrentAssetsAtom(structuredClone(currentAssets));
      setCurrentLiabilitiesAtom(structuredClone(currentLiabilities));
      setDebtAtom(structuredClone(debts));
      setEquityAtom(structuredClone(equities));

      const tock = performance.now();

      console.log(`Global stack thread took ${tock - tick} ms`);
    },

    updatePowdrModelGlobal(balanceFromComponent,
                           pnlFromComponent,
                           headCountsFromComponent,
                           overheadsFromComponent,
                           productsFromComponent,
                           currentLiabilitiesFromComponent) {

      this.globalRecalculatinCaclulation(pnlFromComponent, balanceFromComponent, productsFromComponent, overheadsFromComponent, headCountsFromComponent, currentLiabilitiesFromComponent);
    }

  }));

  return (
    <div></div>
  );
});

export default UpdatePowdrModelGlobal;
