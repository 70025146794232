import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import useProject from "../../../hooks/useProject";

export default function RefreshProjectModal(props) {
    const { open, setOpen } = props;

  const { refreshProject } = useProject();

    //If you are viewing project 1
    //and you check viewsViewing array
    //and get the lastEdit values, if any is greater than the Projects lastEdit, then trigger the modal


    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Another user has updated this project"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Click refresh to update your project.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {refreshProject(false); setOpen(false)}} color="secondary">
              Ignore
            </Button>
            <Button onClick={() => refreshProject(true)} color="primary" autoFocus>
              Refresh
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

