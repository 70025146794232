import React from "react";

import {
  Collapse, Alert, Button
} from "@mui/material";

function AssumptionNotification({ assumptionName, openAlert, setOpenAlert }) {
  return (
    <React.Fragment>
      <Collapse in={openAlert}>
        <Alert mb={4} onClose={() => {setOpenAlert(false)}}
           action={
             <Button color="inherit" size="small" onClick={() => {setOpenAlert(false)}}>
               DISMISS
             </Button>
           }
        >
          New {assumptionName} have been added to the Balance Sheet, fill in the assumptions here.
        </Alert>
      </Collapse>
    </React.Fragment>
  );
}

export default AssumptionNotification;
