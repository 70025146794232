import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Toolbar, AppBar as MuiAppBar, Typography
} from "@mui/material";



const AppBarContent = styled(MuiAppBar)`
  background: #346da6;
  color: ${(props) => props.theme.header.color};
`;


const ExportBarFooter = () => (
  <React.Fragment>
    <AppBarContent position="relative" color="transparent" elevation={0} sx={{marginTop: 5, display: "none"}} id={"myFOOTER"}>
      <Toolbar>

        <Grid container spacing={3}>
          <Grid item xs>
            <img src="/static/img/brands/logo-powdr.png" width={150} alt={"Powdr Logo"}/>
          </Grid>
          <Grid item xs={6} sx={{textAlign: "center"}}>
            <Typography variant={"h6"}>
              This dataset was created using Powdr.
              <br/>For more information visit www.powdr.co.uk
            </Typography>
          </Grid>
          <Grid item xs sx={{textAlign: "right"}}>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBarContent>
  </React.Fragment>
);

export default ExportBarFooter;
