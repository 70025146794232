import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";


export function actualiseDebts(debts) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(debts, function(debt, i) {

    _.forEach(debt.debtCategoryDtos, function(category, x) {

      if (_.isEmpty(category.debtMonthDtos)) {
        return;
      }

      category.debtMonthDtos[firstBalanceForecast].cellType =
        category.debtMonthDtos[firstBalanceForecast - 1].cellType;

      category.debtMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.debtMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.debtMonthDtos[firstBalanceForecast].valueOverriden = null;

    });

  });

  return debts;
}