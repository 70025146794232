import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";
import { getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";


export function calculateOverheads(overheads, pnl, products, headcounts, consolidatedOverheads) {

  try {
    calculatePriceXQuantityDepartment(overheads, headcounts);
    calculatePercentageOfSpecificRevenue(overheads, products);
    calculatePriceXQuantityVolume(overheads, products);
    calculatePriceXQuantityBasic(overheads);
    calculateAnnualGrowthRate(overheads);
    calculateMonthlyGrowthRate(overheads)
    calculatePercentageOfRevenue(overheads, pnl);
    calculateConsolidatedOverheads(overheads, consolidatedOverheads);
  } catch (e) {
    console.log('an error occurred when calculating overheads', e)
  }

}

function calculateConsolidatedOverheads(overheads, consolidatedOverheads) {
  let consolidatedOverheadsAssumptions = overheads.filter(overhead =>
    overhead.overheadDto.assumption === "OVERHEAD_DIVISIONAL_CONSOLIDATION"
  );

  _.forEach(consolidatedOverheadsAssumptions, function(consolidatedOverheadAssumption) {
    // Get all the overheads (from the consolidated list) that link to this assumption
    let overheadsToSum = consolidatedOverheads.filter(overhead =>
      consolidatedOverheadAssumption.overheadDto.overheads.includes(overhead.overheadDto.id)
    );

    let overheadTotals = new Array(72).fill(0);

    _.forEach(overheadsToSum, function(overheadToSum) {
      // Find the category 'Value In P&L' from overheadCategoryDtoList
      let categoryToTotal = overheadToSum.overheadCategoryDtoList.find(category =>
        category.name === 'Value In P&L'
      );

      if (categoryToTotal && categoryToTotal.overheadMonthDtoList) {
        _.forEach(categoryToTotal.overheadMonthDtoList, function(overheadMonthToSum, i) {
          if (overheadTotals[i] === undefined) {
            overheadTotals[i] = 0; // Initialize to 0 if undefined
          }
          overheadTotals[i] += getValueOrOverriddenValue(overheadMonthToSum);
        });
      }


    });

    let consolidatedValueInPnL = consolidatedOverheadAssumption.overheadCategoryDtoList.find(category =>
      category.name === 'Value In P&L'
    );

    _.forEach(consolidatedValueInPnL.overheadMonthDtoList, function(overheadMonth, i) {

      overheadMonth.value = overheadTotals[i];
    });

  });
}


function calculateAnnualGrowthRate(overheads) {

  let firstForecast = Number(getCurrentProject().firstForecast);

  //loop through and find only pure annual growth rate overheads
  let pureAnnualGrowthOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "ANNUAL_GROWTH_RATE");
  // times the forecast August 2023 (percentage of revenue) by the actual (value in P & L) value
  _.forEach(pureAnnualGrowthOverheads, function(pureAnnualGrowthOverhead, i) {

    _.forEach(pureAnnualGrowthOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadAnnualGrowthRateMonthDto, i) {

        if (i >= 12 ) {  //annual growth rates always have at least 12 months of actuals

          if (i >= firstForecast ) {

            // to get the forecast (value in P & L)
            // repeat this process for all 5 years
            pureAnnualGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value
              = getValueOrOverriddenValue(pureAnnualGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i - 12])
              + ((overheadAnnualGrowthRateMonthDto.value / 100) * getValueOrOverriddenValue(pureAnnualGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i - 12]))

          }
        }

    });

  });

}


function calculatePriceXQuantityBasic(overheads) {

  // loop through and find only pure annual growth rate overheads
  let priceQuantityBasicOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "PRICE_X_QUANTITY_BASIC");

  _.forEach(priceQuantityBasicOverheads, function(priceQuantityBasicOverhead, i) {

    _.forEach(priceQuantityBasicOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {
      if (i >= getCurrentProject().firstForecast) {


        priceQuantityBasicOverhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value
          = priceQuantityBasicOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value
          * priceQuantityBasicOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value

      }
    });
  });

}

function calculateProductsVolume(priceQuantityVolumeOverhead, specificIndex, products) {

  //find all products for this overhead
  const productsForOverhead = products.filter(product => priceQuantityVolumeOverhead.overheadDto.products.includes(product.productDto.id));

  //get the volume category for each
  let totalForProduct = productsForOverhead.reduce((acc, product) => acc + ((product.productCategoryDtoList
    .find(category => category.name === 'Quantity Sold' || category.name === 'Total Customers')?.productMonthDtoList[specificIndex]?.value) || 0), 0);

  //return the total
  return totalForProduct;
}

function calculateHeadcountsDepartments(priceQuantityHeadcountOverhead, specificIndex, headcounts) {

  //find all departments that the overhead uses (they may in in several headcounts)
  let departments = getDepartmentsForOverhead(headcounts, priceQuantityHeadcountOverhead);

  //total up all the base salary costs for each department
  let totalForDepartment = departments.reduce((acc, department) => acc + ((department.headCountMonthDtoList[specificIndex]?.value) || 0), 0);

  //return the total
  return totalForDepartment;
}

function getDepartmentsForOverhead(headcounts, overhead) {

  const extractedDtos = headcounts.reduce((acc, headcount) => acc.concat(headcount.headCountCategoryDtos), [])
    .filter(category => overhead.overheadDto.headcounts.includes(category.id));

  return extractedDtos
}

function calculatePriceXQuantityVolume(overheads, products) {

  // loop through and find only pure annual growth rate overheads
  let priceQuantityVolumeOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "PRICE_X_QUANTITY_VOLUME_DRIVEN");

  _.forEach(priceQuantityVolumeOverheads, function(priceQuantityVolumeOverhead, i) {

    _.forEach(priceQuantityVolumeOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {

      priceQuantityVolumeOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value = calculateProductsVolume(priceQuantityVolumeOverhead, i, products)


      if (i >= getCurrentProject().firstForecast) {

        priceQuantityVolumeOverhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value
          = priceQuantityVolumeOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value
          * priceQuantityVolumeOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value

      }
    });
  });

}

function calculatePriceXQuantityDepartment(overheads, headcounts) {

  // loop through and find only pure annual growth rate overheads
  let priceQuantityDepartmentOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "PRICE_X_QUANTITY_DEPARTMENT_DRIVEN");

  _.forEach(priceQuantityDepartmentOverheads, function(priceQuantityDepartmentOverhead, i) {

    _.forEach(priceQuantityDepartmentOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {

      priceQuantityDepartmentOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value = calculateHeadcountsDepartments(priceQuantityDepartmentOverhead, i, headcounts)


      if (i >= getCurrentProject().firstForecast) {

        priceQuantityDepartmentOverhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value
          = priceQuantityDepartmentOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value
          * priceQuantityDepartmentOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value

      }
    });
  });

}



function calculateMonthlyGrowthRate(overheads) {

  // loop through and find only pure annual growth rate overheads
  let pureMonthlyGrowthOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "MONTHLY_GROWTH_RATE");
  // times the forecast August 2023 (percentage of revenue) by the actual (value in P & L) value
  _.forEach(pureMonthlyGrowthOverheads, function(pureMonthlyGrowthOverhead, i) {

    _.forEach(pureMonthlyGrowthOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {
      if (i >= getCurrentProject().firstForecast) {

        // to get the forecast (value in P & L)
        // repeat this process for all 5 years
        pureMonthlyGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value
          = getValueOrOverriddenValue(pureMonthlyGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i - 1])
          + ((overheadMonthDto.value / 100) * getValueOrOverriddenValue(pureMonthlyGrowthOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i - 1]))

      }
    });
  });

}


function calculatePercentageOfRevenue(overheads, pnl) {
  // loop through and find only price times quantity overheads
  let priceTimesQuantityOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "PERCENTAGE_OF_REVENUE");

  _.forEach(priceTimesQuantityOverheads, function(priceTimesQuantityOverhead, i) {

    let pnlRevenue = pnl.pnLCategoryDtoList.find(category => category.name === "Revenue");

    //Add the Revenue Row
    _.forEach(priceTimesQuantityOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {
      priceTimesQuantityOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value = pnlRevenue.pnLMonthDtoList[i].value;
    });

    _.forEach(priceTimesQuantityOverhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {

      if (i <= getCurrentProject().firstForecast - 1) {

        //set the value of the PAYE value
        priceTimesQuantityOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value =
          percentage(priceTimesQuantityOverhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value,
            priceTimesQuantityOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value);

      }

      if (i > getCurrentProject().firstForecast - 1) {

        // to get the forecast (value in P & L)
        // repeat this process for all 5 years
        let res = (priceTimesQuantityOverhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value / 100) *
          priceTimesQuantityOverhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value;

        priceTimesQuantityOverhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value
          = getSafeValue(res);
        
      }

    });
  });
}


function getSafeValue(res) {
  if (isNaN(res)) {
    return 0;
  } else {
    return res;
  }
}


function calculateProductsRevenue(priceQuantityVolumeOverhead, specificIndex, products) {

  //find all products for this overhead
  const productsForOverhead = products.filter(product => priceQuantityVolumeOverhead.overheadDto.products.includes(product.productDto.id));

  //get the volume category for each (or closing recurring revenue for subscriptions)
  let totalForProduct = productsForOverhead.reduce((acc, product) => acc + (getValueOrOverriddenValue(product.productCategoryDtoList
    .find(category => category.name === 'Revenue' || category.name === 'Closing recurring revenue')?.productMonthDtoList[specificIndex]) || 0), 0);

  //return the total
  return totalForProduct;
}


function calculatePercentageOfSpecificRevenue(overheads, products) {
  // loop through and find only price times quantity overheads
  let percentageOfSpecificRevenueOverheads = overheads
    .filter(overhead => overhead.overheadDto.assumption === "PRICE_X_QUANTITY_PERCENTAGE_PRODUCT");

  _.forEach(percentageOfSpecificRevenueOverheads, function(overhead, i) {

    _.forEach(overhead.overheadCategoryDtoList[0].overheadMonthDtoList, function(overheadMonthDto, i) {

      overhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value = calculateProductsRevenue(overhead, i, products)

      if (i <= getCurrentProject().firstForecast - 1) {

        //set the value of the PAYE value
        overhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value =
          percentage(overhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value,
            overhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value);

      }

      if (i > getCurrentProject().firstForecast - 1) {

        // to get the forecast (value in P & L)
        // repeat this process for all 5 years
        overhead.overheadCategoryDtoList[2].overheadMonthDtoList[i].value
          = (overhead.overheadCategoryDtoList[0].overheadMonthDtoList[i].value / 100) *
          overhead.overheadCategoryDtoList[1].overheadMonthDtoList[i].value

      }

    });
  });
}
