import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const cashFlowState = atom({
  key: 'cashFlowState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        //console.log('ON SET CASHFLOW', newAssumptions, oldAssumptions)

        const project = JSON.parse(sessionStorage.getItem("project"));

        if (newAssumptions !== undefined && oldAssumptions !== undefined) {

          handleDataUpdate([structuredClone(newAssumptions)],
            [oldAssumptions],
            'cashflow/' + project.id + '/months',
            'cashflowMonthDtos',
            'cashflowCategoryDtos',
            'cashflowDto');

        }

      });
    },
  ]
});


export const cashFlowDebtMovementState = atom({
  key: 'cashFlowDebtMovementState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});