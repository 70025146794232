import React from "react";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  Paper,
} from "@mui/material";
import YearNav from "../../../SharedComponents/YearNav";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingBottom: 10,
  color: theme.palette.text.secondary,
}));

function VatConfigNavBar(props) {
  const { item } = props;

  return (
    <React.Fragment>
      <Card
        key={item.name.replace(" ", "-") + "PI"}
        sx={{ pb: 0 }}
        style={{ pb: 0 }}
        id={item.name.replace(" ", "-") + "PI"}
      >
        <CardContent sx={{ pb: 0 }}>
          <Grid
            container
            spacing={2}
            style={{ position: "sticky", left: 0, paddingBottom: 0 }}
          >
            {/* NAVIGATION A,1,2,3,4,5 */}
            <Grid item xs={4}>
              <Item
                style={{ position: "sticky", minWidth: 272 }}
                id={"YEAR-NAV"}
                key={"YEAR-NAV"}
              >
                <YearNav productName={item.name.replaceAll(" ", "-")}></YearNav>
              </Item>
            </Grid>
            {/* PERCENTAGE COMPLETE */}
            <Grid item xs={3}>

            </Grid>
            {/* DELETE PRODUCT */}
            <Grid item xs={2} id={"BLANK"} key={"BLANK"}></Grid>
            <Grid item xs={3} >

            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default VatConfigNavBar;
