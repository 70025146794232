import { isValidNumber, totalUpItems } from "../../../Analysis/RevenueDashboard/calculations/Calculations";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";
import _ from "lodash";

export function calculatePnlAnnuals(pnlAtom) {
  if (_.isEmpty(pnlAtom)){
    return [];
  }

  let pnLRevenueCategory = pnlAtom.pnLCategoryDtoList.find(category => category.name === "Revenue");

  //go through each category and create annuals
  for (const pnLCategoryDtoListElement of pnlAtom.pnLCategoryDtoList) {

    buildYearTotalCategory(pnLCategoryDtoListElement)

    //percentage categories need extra calculations
    if (pnLCategoryDtoListElement?.overrideName?.endsWith("Margin") ) {

      if (pnLCategoryDtoListElement.overrideName === "Gross Profit Margin") {
        let parentOfMargin = pnlAtom.pnLCategoryDtoList.find(category => category.name === "Gross Profit");
        buildPercentageOfTwoCategories(pnLCategoryDtoListElement, parentOfMargin, pnLRevenueCategory);
      }

      if (pnLCategoryDtoListElement.overrideName === "EBITDA Margin") {
        let parentOfMargin = pnlAtom.pnLCategoryDtoList.find(category => category.name === "EBITDA" && category.overrideName === "");
        buildPercentageOfTwoCategories(pnLCategoryDtoListElement, parentOfMargin, pnLRevenueCategory);
      }

      if (pnLCategoryDtoListElement.overrideName === "Net Profit Margin") {
        let parentOfMargin = pnlAtom.pnLCategoryDtoList.find(category => category.name === "Net Profit" && category.overrideName === "");
        buildPercentageOfTwoCategories(pnLCategoryDtoListElement, parentOfMargin, pnLRevenueCategory);
      }

    }
  }

  //return annuals
  return pnlAtom;
}


function buildPercentageOfTwoCategories(category1, parentOfMargin, category2) {

  let categoryMonths = [];

  for (let i=0; i < category1.pnLMonthDtoList.length; i++) {

    let yearTotal = percentage(parentOfMargin.pnLMonthDtoList[i].value, category2.pnLMonthDtoList[i].value);

    //  Double res = ((double) numerator / (double) denominator) * 100;

    categoryMonths.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      resolution: 2,
    })
  }

  category1.pnLMonthDtoList = categoryMonths;
}

function buildYearTotalCategory(pnlCategory) {

  let categoryMonths = [];

  if (pnlCategory.name === "" || pnlCategory.name === "space") {
    pnlCategory.pnLMonthDtoList = [];
  } else {

    let yearIndex = 0;
    for (let i = 0; i < 72;) {

      let yearTotal = pnlCategory ? totalUpItems(pnlCategory.pnLMonthDtoList, i) : 0;

      categoryMonths.push({
        id: yearIndex,
        value: yearTotal,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: 0,
      })
      i = i + 12;
      yearIndex++;

    }

    pnlCategory.pnLMonthDtoList = categoryMonths;
  }
}