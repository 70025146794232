import React, { useEffect, useState } from "react";

import useProject from "../../../../../hooks/useProject";


import Chart from "react-apexcharts";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

function getYearsForChart() {

  let years = [];

  let startDateStr = getCurrentProject().startDate;
  let startDate = new Date(startDateStr);

  startDate.setMonth(startDate.getMonth() + 11);

  years.push(Math.floor(startDate.getFullYear()));
  years.push(Math.floor(startDate.getFullYear() + 1));
  years.push(Math.floor(startDate.getFullYear() + 2));
  years.push(Math.floor(startDate.getFullYear() + 3));
  years.push(Math.floor(startDate.getFullYear() + 4));
  years.push(Math.floor(startDate.getFullYear() + 5));

  return years;

}

function getDataForChart(products) {

  const data = [];

  for (let i = 0; i < products.length; i++) {

    let product_ = {
      name: products[i][0].productDto.name,
      data : [],
    }

    for (let y = 0; y < products[i][0].productCategoryDtoList[0].productMonthDtoList.length; y++) {


      let productMargin = products[i][0].productCategoryDtoList.find(category => category.name === "Product Margin");

      product_.data.push(
        Math.floor(productMargin.productMonthDtoList[y].value)
      );

    }

    data.push(product_);
  }

  //console.log(data)
  return data;
}

export default function ProductMarginChart(props) {


  const { products } = props;

  const [data, setData] = useState([]);

  const { key } = useProject();


  useEffect(() => {
    setData(getDataForChart(products));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);



// Define a unique color palette
  const uniqueColorPalette = [
'#2449D1',
'#5867F5',
'#8186FF',
'#A8A7FF',
'#CEC9FF',
'#F7EB06',
'#BFB800',
'#898800',
'#585B00',
'#373000',
'#939CA4',
'#727B83',
'#535B63',
'#363E44',
'#1A2228',
'#9AE9AE',
'#6BB880',
'#3D8A55',
'#035E2D',
'#003507',
'#EC9B34',
'#C17602',
'#965400',
'#6E3300',
'#4C1200'
  ];


  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    markers: {
      size: 0,
      style: "hollow", // full, hollow, inverted
    },
    xaxis: {
      categories: getYearsForChart(),
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: function (value) {
          return value + "%"
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + "%"
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    colors: uniqueColorPalette, // Assigning the unique color palette to the chart

  };


  return (
    <React.Fragment key={key}>

      <Card mb={1}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Margins
          </Typography>
          <Typography variant="body2" gutterBottom>
            This chart shows a breakdown product margins
          </Typography>

          <Spacer mb={6} />

          <Chart options={options} series={data} type="line" height="350" />

        </CardContent>
      </Card>



    </React.Fragment>
  );
}