import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { calculatePercentageIncrease, percentage } from "../../../SharedComponents/utils/PercentageUtils";
import { formatNumber, getNumberOfDaysInMonth, getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";
import { isValidNumber } from "../../../Analysis/RevenueDashboard/calculations/Calculations";


export function calculatePriceXQuantity(products) {

  let priceXQuantities = products
    .filter(product => product.productDto.assumption === "PRICE_X_QUANTITY");

  _.forEach(priceXQuantities, function(priceXQuantity, i) {

    _.forEach(priceXQuantity.productCategoryDtoList[0].productMonthDtoList, function(monthDto, i) {

      //calculate revenue
      let revenue =
        monthDto.value * getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i]);

      priceXQuantity.productCategoryDtoList[3].productMonthDtoList[i].value = formatNumber(revenue); //Revenue


      //calculate direct product costs
      let directProductCost =
        -Math.abs(getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[3].productMonthDtoList[i]) -
          (getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[3].productMonthDtoList[i]) *  //revenue
            (priceXQuantity.productCategoryDtoList[4].productMonthDtoList[i].value / 100))) //Product Margin

      priceXQuantity.productCategoryDtoList[5].productMonthDtoList[i].value = formatNumber(directProductCost); //direct product costs

      //First 12 months of Sales price per unit sold (ex-VAT) is NOT calculated, it is a manual entry (regardless of the number of actuals in the project)
      if (i >= getCurrentProject().firstForecast && i > 11) {

        //calculate sales price per unit sold (ex-VAT)
        priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i].value =
          calculatePercentageIncrease(getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i - 1]), getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[1].productMonthDtoList[i]));

      }

      if (i < getCurrentProject().firstForecast && i > 1) {

        priceXQuantity.productCategoryDtoList[1].productMonthDtoList[i].value //growth rate
          = isValidNumber(((priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i].value - priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i - 1].value)
          / priceXQuantity.productCategoryDtoList[2].productMonthDtoList[i - 1].value) * 100);
      }

      //calculate gross profit
      priceXQuantity.productCategoryDtoList[6].productMonthDtoList[i].value =
        getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[3].productMonthDtoList[i]) +  //revenue
        getValueOrOverriddenValue(priceXQuantity.productCategoryDtoList[5].productMonthDtoList[i]);   //costs

    })

  });

}


export function calculateMonthlyGrowthRate(products) {

  // loop through and find only pure annual growth rate overheads
  let monthlyGrowthRates = products
    .filter(product => product.productDto.assumption === "MONTHLY_GROWTH_RATE");

  // times the forecast August 2023 (percentage of revenue) by the actual (value in P & L) value
  _.forEach(monthlyGrowthRates, function(monthlyGrowthRate, i) {

    _.forEach(monthlyGrowthRate.productCategoryDtoList[0].productMonthDtoList, function(monthDto, i) {

      //calculate direct product costs
      let directProductCost =
        getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) -
        (getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[2].productMonthDtoList[i]) *  //Product Margin
          (getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) / 100)) //revenue

      monthlyGrowthRate.productCategoryDtoList[3].productMonthDtoList[i].value = -Math.abs(formatNumber(directProductCost)); //direct product costs


      if (i >= getCurrentProject().firstForecast) {

        //revenue
        monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i].value
          = getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 1])
          + ((monthDto.value / 100) * getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 1]))

      }

      if (i < getCurrentProject().firstForecast && i > 1) {

        monthlyGrowthRate.productCategoryDtoList[0].productMonthDtoList[i].value //growth rate
          = isValidNumber(((monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i].value - monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 1].value)
          / monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 1].value) * 100);
      }

      //calculate Gross Profit
      monthlyGrowthRate.productCategoryDtoList[4].productMonthDtoList[i].value =
        getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) +  //revenue
        getValueOrOverriddenValue(monthlyGrowthRate.productCategoryDtoList[3].productMonthDtoList[i]);   //costs

    });
  });
}



export function calculateAnnualGrowthRate(products) {

  let firstForecast = Number(getCurrentProject().firstForecast);

  let annualGrowthRates = products
    .filter(product => product.productDto.assumption === "ANNUAL_GROWTH_RATE");

  _.forEach(annualGrowthRates, function(annualGrowthRate, i) {

    _.forEach(annualGrowthRate.productCategoryDtoList[0].productMonthDtoList, function(monthDto, i) {

      //calculate direct product costs
      let directProductCost =
        getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) -
        (getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[2].productMonthDtoList[i]) *  //Product Margin
          (getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) / 100)) //revenue

      annualGrowthRate.productCategoryDtoList[3].productMonthDtoList[i].value = -Math.abs(formatNumber(directProductCost)); //direct product costs


      if (i >= 12 ) {  //annual growth rates always have at least 12 months of actuals

        if (i >= firstForecast ) {

          //set the revenue
          annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i].value
            = getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 12])
            + ((monthDto.value / 100) * getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 12]))

        }

        //for growth rates that are > 12 months and < the first forecast, we should calculate and set the growth rate
        if (i < firstForecast) {

          annualGrowthRate.productCategoryDtoList[0].productMonthDtoList[i].value //growth rate
            = isValidNumber(((annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i].value - annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 12].value)
                / annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i - 12].value) * 100);
        }
      }




      //calculate Gross Profit
      annualGrowthRate.productCategoryDtoList[4].productMonthDtoList[i].value =
        getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[1].productMonthDtoList[i]) +  //revenue
        getValueOrOverriddenValue(annualGrowthRate.productCategoryDtoList[3].productMonthDtoList[i]);   //costs


    });

  })
}


export function calculateHeadcountUtilisation(products, headcounts) {

  let headcountsUtilisation = products
    .filter(product => product.productDto.assumption === "HEADCOUNT_UTILISATION");

  _.forEach(headcountsUtilisation, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //get the total FTEs
        product.productCategoryDtoList[0].productMonthDtoList[i].value = calculateHeadcountsDepartments(product, i, headcounts);

        //Now do the regular price x quantity calcs
        if (i >= getCurrentProject().firstForecast) {
          //calculate Sales price per unit sold (ex-VAT)
          product.productCategoryDtoList[5].productMonthDtoList[i].value =
            calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i - 1]), product.productCategoryDtoList[4].productMonthDtoList[i].value);
        }

        //Calculate Number of Sales
        product.productCategoryDtoList[3].productMonthDtoList[i].value =    //Number of sales
          product.productCategoryDtoList[0].productMonthDtoList[i].value *  //Headcounts
          product.productCategoryDtoList[1].productMonthDtoList[i].value *  //Max productive hours
          (product.productCategoryDtoList[2].productMonthDtoList[i].value / 100);  //Utilisation


        //Calculate Revenue
        product.productCategoryDtoList[6].productMonthDtoList[i].value =    //Revenue
          getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]) *  //Sales price per unit ex VAT
          product.productCategoryDtoList[3].productMonthDtoList[i].value;  //Number of Sales




      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", e,product)
    }


  });

}



export function calculatePercentageOfAnotherProduct(products) {

  let salesPerDayRevenue = products
    .filter(product => product.productDto.assumption === "PERCENTAGE_OF_ANOTHER_PRODUCT");

  _.forEach(salesPerDayRevenue, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //Get the revenues from the selected products and add them all together
        product.productCategoryDtoList[0].productMonthDtoList[i].value = calculateProductsRevenue(product, i, products);


        //Calculate Revenue
        if (i >= getCurrentProject().firstForecast) {

          let revenue = product.productCategoryDtoList[0].productMonthDtoList[i].value *  //Sales price per unit ex VAT
            (product.productCategoryDtoList[1].productMonthDtoList[i].value / 100);  //Number of Sales

          product.productCategoryDtoList[2].productMonthDtoList[i].value = formatNumber(revenue); //Revenue
        }

        //calculate Direct product costs
        let directProductCost =
          -Math.abs(product.productCategoryDtoList[2].productMonthDtoList[i].value -
          (getValueOrOverriddenValue(product.productCategoryDtoList[2].productMonthDtoList[i]) *  //revenue
            (product.productCategoryDtoList[3].productMonthDtoList[i].value / 100))) //Product Margin

        product.productCategoryDtoList[4].productMonthDtoList[i].value = -Math.abs(formatNumber(directProductCost)); //direct product costs

          //calculate Gross Profit
        product.productCategoryDtoList[5].productMonthDtoList[i].value =
          getValueOrOverriddenValue(product.productCategoryDtoList[2].productMonthDtoList[i]) +  //revenue
          getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]);   //costs

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }


  });

}


export function calculatePriceXQuantityDays(products) {

  let salesPerDayRevenue = products
    .filter(product => product.productDto.assumption === "SALES_PER_DAY_REVENUE");

  _.forEach(salesPerDayRevenue, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {


        let projectStartDate = new Date(getCurrentProject().startDate);
        projectStartDate.setDate(1);
        projectStartDate.setMonth(projectStartDate.getMonth() + productMonthDto.monthIndex);
        const daysInMonth = getNumberOfDaysInMonth(projectStartDate.getMonth(), projectStartDate.getFullYear()); // Returns number of days for the month

        product.productCategoryDtoList[0].productMonthDtoList[i].value = daysInMonth;

        //Calculate Number of Sales
        product.productCategoryDtoList[2].productMonthDtoList[i].value =    //Quantity sold
          getValueOrOverriddenValue(product.productCategoryDtoList[0].productMonthDtoList[i]) *  //Days in month
          product.productCategoryDtoList[1].productMonthDtoList[i].value;  //Sales Per Day

        if (product.productDto.dpcAssumptionType === 'UNIT_COST') {

          unitCostCalculations(i, product);
        }

        if (product.productDto.dpcAssumptionType === 'MARGIN') {

          marginCostCalculations(product, i);
        }


      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }


  });

}

export function calculatePriceXQuantityBuilder(products) {

  let priceXQuantityBuilder = products
    .filter(product => product.productDto.assumption === "PRICE_X_QUANTITY_BUILDER");

  _.forEach(priceXQuantityBuilder, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //Calculate Number of Sales
        product.productCategoryDtoList[2].productMonthDtoList[i].value =    //Number of sales
          product.productCategoryDtoList[0].productMonthDtoList[i].value *  //Max units available
          product.productCategoryDtoList[1].productMonthDtoList[i].value;  //Utilisation

        if (product.productDto.dpcAssumptionType === 'UNIT_COST') {

          unitCostCalculations(i, product);
        }

        if (product.productDto.dpcAssumptionType === 'MARGIN') {

          marginCostCalculations(product, i);
        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }

  });

}



export function calculateRevenueAsAPercentageOfAValueBuilder(products) {

  let revenueAsAPercentageOfAValueBuilder = products
    .filter(product => product.productDto.assumption === "REVENUE_AS_A_PERCENTAGE_OF_VALUE");

  _.forEach(revenueAsAPercentageOfAValueBuilder, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {


        if (product.productDto.dpcAssumptionType === 'UNIT_COST') {

          //Calculate Revenue
          product.productCategoryDtoList[3].productMonthDtoList[i].value =    //Revenue
            (product.productCategoryDtoList[2].productMonthDtoList[i].value / 100) * product.productCategoryDtoList[1].productMonthDtoList[i].value;

          if (i >= getCurrentProject().firstForecast) {
            //calculate Cost price per unit sold (ex-VAT)
            product.productCategoryDtoList[5].productMonthDtoList[i].value =
              calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i - 1]), getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]));
          }


          if (i < getCurrentProject().firstForecast && i > 1) {

            product.productCategoryDtoList[4].productMonthDtoList[i].value //cost price growth rates
              = isValidNumber(((product.productCategoryDtoList[5].productMonthDtoList[i].value - product.productCategoryDtoList[5].productMonthDtoList[i - 1].value)
              / product.productCategoryDtoList[5].productMonthDtoList[i - 1].value) * 100);
          }

          //calculate Direct product costs
          product.productCategoryDtoList[6].productMonthDtoList[i].value =    //direct product costs

            product.productCategoryDtoList[3].productMonthDtoList[i].value //units sold

            -Math.abs(product.productCategoryDtoList[3].productMonthDtoList[i].value -  //revenue
              Math.abs(getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]))); //cost price growth rates (or cost price per unit sold)

          //calculate Gross Profit
          product.productCategoryDtoList[7].productMonthDtoList[i].value =
            getValueOrOverriddenValue(product.productCategoryDtoList[3].productMonthDtoList[i]) +  //revenue
            getValueOrOverriddenValue(product.productCategoryDtoList[6].productMonthDtoList[i]);   //costs

          //calculate Product Margin
          product.productCategoryDtoList[8].productMonthDtoList[i].value =
            percentage(product.productCategoryDtoList[7].productMonthDtoList[i].value, getValueOrOverriddenValue(product.productCategoryDtoList[3].productMonthDtoList[i]));


        }

        if (product.productDto.dpcAssumptionType === 'MARGIN') {

          //Calculate Number of Sales
          product.productCategoryDtoList[3].productMonthDtoList[i].value =    //Number of sales
            product.productCategoryDtoList[0].productMonthDtoList[i].value *  //Max units available
            product.productCategoryDtoList[1].productMonthDtoList[i].value;  //Utilisation


          //Calculate Revenue
          product.productCategoryDtoList[4].productMonthDtoList[i].value =    //Revenue
            (product.productCategoryDtoList[3].productMonthDtoList[i].value / 100) * product.productCategoryDtoList[2].productMonthDtoList[i].value;

          //calculate Direct product costs
          product.productCategoryDtoList[6].productMonthDtoList[i].value =    //direct product costs
            -Math.abs(getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]) -  //revenue
              (getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]) *  //revenue
                (product.productCategoryDtoList[5].productMonthDtoList[i].value / 100))); //Product Margin

          //calculate Gross Profit
          product.productCategoryDtoList[7].productMonthDtoList[i].value = //gross profit
            getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]) +  //revenue
            product.productCategoryDtoList[6].productMonthDtoList[i].value;


        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }

  });

}

export function calculateHeadcountNonUtilisation(products) {

  let nonHeadcountsUtilisation = products
    .filter(product => product.productDto.assumption === "NON_HEADCOUNT_UTILISATION");

  _.forEach(nonHeadcountsUtilisation, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //Calculate Number of Sales
        product.productCategoryDtoList[2].productMonthDtoList[i].value =    //Quantity Sold
          product.productCategoryDtoList[0].productMonthDtoList[i].value *  //Max units available
          (product.productCategoryDtoList[1].productMonthDtoList[i].value / 100);  //Utilisation Occupancy

        if (product.productDto.dpcAssumptionType === 'UNIT_COST') {

          unitCostCalculations(i, product);
        }

        if (product.productDto.dpcAssumptionType === 'MARGIN') {

          marginCostCalculations(product, i);
        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }

  });

}

/**
 * Common code for uni cost calcutions that applies so all the revenue assumptions that include the option to chose unit costs
 * @param i
 * @param product
 */
function unitCostCalculations(i, product) {
  //Now do the regular price x quantity calcs
  if (i >= getCurrentProject().firstForecast) {
    //calculate Sales price per unit sold (ex-VAT)
    product.productCategoryDtoList[4].productMonthDtoList[i].value =
      calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i - 1]), product.productCategoryDtoList[3].productMonthDtoList[i].value);
  }

  //calculate revenue
  product.productCategoryDtoList[5].productMonthDtoList[i].value =
    product.productCategoryDtoList[2].productMonthDtoList[i].value * //quantity sold
    getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]);  //sales price per unit sold


  if (i >= getCurrentProject().firstForecast) {
    //calculate Cost price per unit sold (ex-VAT)
    product.productCategoryDtoList[7].productMonthDtoList[i].value =
      calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[7].productMonthDtoList[i - 1]), getValueOrOverriddenValue(product.productCategoryDtoList[6].productMonthDtoList[i]));
  }


  if (i < getCurrentProject().firstForecast && i > 1) {

    product.productCategoryDtoList[3].productMonthDtoList[i].value //sales price growth rates
      = isValidNumber(((product.productCategoryDtoList[4].productMonthDtoList[i].value - product.productCategoryDtoList[4].productMonthDtoList[i - 1].value)
      / product.productCategoryDtoList[4].productMonthDtoList[i - 1].value) * 100);

    product.productCategoryDtoList[6].productMonthDtoList[i].value //cost price growth rates
      = isValidNumber(((product.productCategoryDtoList[7].productMonthDtoList[i].value - product.productCategoryDtoList[7].productMonthDtoList[i - 1].value)
      / product.productCategoryDtoList[7].productMonthDtoList[i - 1].value) * 100);
  }

  //calculate Direct product costs
  product.productCategoryDtoList[8].productMonthDtoList[i].value =    //direct product costs
    -Math.abs(product.productCategoryDtoList[2].productMonthDtoList[i].value *  //quantity sold
    getValueOrOverriddenValue(product.productCategoryDtoList[7].productMonthDtoList[i])); //cost price growth rates (or cost price per unit sold)

  //calculate Gross Profit
  product.productCategoryDtoList[9].productMonthDtoList[i].value =
    getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]) +  //revenue
    getValueOrOverriddenValue(product.productCategoryDtoList[8].productMonthDtoList[i]);   //costs

  //calculate Product Margin
  product.productCategoryDtoList[10].productMonthDtoList[i].value =
    percentage(product.productCategoryDtoList[9].productMonthDtoList[i].value, getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]));
}

/**
 * Common code for uni cost calcutions that applies so all the revenue assumptions that include the option to chose margin
 * @param product
 * @param i
 */
function marginCostCalculations(product, i) {


  //Calculate Revenue
  product.productCategoryDtoList[5].productMonthDtoList[i].value =    //Revenue
    getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i]) *  //Sales price per unit ex VAT
    product.productCategoryDtoList[2].productMonthDtoList[i].value;  //Number of Sales


  if (i >= getCurrentProject().firstForecast) {
    //calculate Cost price per unit sold (ex-VAT)
    product.productCategoryDtoList[4].productMonthDtoList[i].value =
      calculatePercentageIncrease(getValueOrOverriddenValue(product.productCategoryDtoList[4].productMonthDtoList[i - 1]),
        product.productCategoryDtoList[3].productMonthDtoList[i].value);
  }

  if (i < getCurrentProject().firstForecast && i > 1) {

    product.productCategoryDtoList[3].productMonthDtoList[i].value //sales price growth rates
      = isValidNumber(((product.productCategoryDtoList[4].productMonthDtoList[i].value - product.productCategoryDtoList[4].productMonthDtoList[i - 1].value)
      / product.productCategoryDtoList[4].productMonthDtoList[i - 1].value) * 100);

  }


  //calculate Direct product costs
  product.productCategoryDtoList[7].productMonthDtoList[i].value =    //direct product costs
    -Math.abs(getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]) -

      ((getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]) / 100) *  //revenue
        product.productCategoryDtoList[6].productMonthDtoList[i].value)

    ); //Product Margin

  //calculate Product Margin
  product.productCategoryDtoList[8].productMonthDtoList[i].value =
    getValueOrOverriddenValue(product.productCategoryDtoList[5].productMonthDtoList[i]) +  //revenue
    product.productCategoryDtoList[7].productMonthDtoList[i].value;
}

/**
 * This works!
 *
 * @param products
 * @param pnl
 * @param headcounts
 */
export function calculateHeadcountsToRevenue(products, headcounts) {

  // loop through and find only pure annual growth rate productsDouble click to override value
  let headcountsToRevenues = products
    .filter(product => product.productDto.assumption === "HEADS_TO_REVENUE");

  _.forEach(headcountsToRevenues, function(product, i) {

    try {

      _.forEach(product.productCategoryDtoList[0].productMonthDtoList, function(productMonthDto, i) {

        //get the total FTEs
        product.productCategoryDtoList[0].productMonthDtoList[i].value = calculateHeadcountsDepartments(product, i, headcounts);

        //Calculate quantity sold
        product.productCategoryDtoList[2].productMonthDtoList[i].value =  //quantity sold
          product.productCategoryDtoList[0].productMonthDtoList[i].value * //selected headcounts (quantity ftes)
          product.productCategoryDtoList[1].productMonthDtoList[i].value; //Number of sales per person each month

        if (product.productDto.dpcAssumptionType === 'UNIT_COST') {

          unitCostCalculations(i, product);
        }

        if (product.productDto.dpcAssumptionType === 'MARGIN') {

          marginCostCalculations(product, i);
        }

      });

    } catch (e) {
      console.error("Unable to apply calculation for: ", product)
    }


  });

}

function calculateProductsRevenue(percentageProducts, specificIndex, products) {

  //find all departments that the headcountsToRevenue uses (they may in in several headcounts)
  let revenues = getRevenuesForProduct(products, percentageProducts);

  //total up all the base salary costs for each revenue
  let totalForRevenues = revenues.reduce((acc, department) => acc + (getValueOrOverriddenValue(department.productMonthDtoList[specificIndex]) || 0), 0);

  //return the total
  return formatNumber(totalForRevenues);
}

function getRevenuesForProduct(products, percentageProducts) {

  const extractedDtos = products.reduce((acc, product) => acc.concat(product.productCategoryDtoList), [])
    .filter(category => percentageProducts.productDto.products.includes(category.product))
    .filter(category => ["Revenue", "Closing recurring revenue"].includes(category.name));

  return extractedDtos
}


function calculateHeadcountsDepartments(headcountsToRevenue, specificIndex, headcounts) {

  //find all departments that the headcountsToRevenue uses (they may in in several headcounts)
  let departments = getDepartmentsForProduct(headcounts, headcountsToRevenue);

  //total up all the base salary costs for each department
  let totalForDepartment = departments.reduce((acc, department) => acc + (getValueOrOverriddenValue(department.headCountMonthDtoList[specificIndex]) || 0), 0);

  //return the total
  return totalForDepartment;
}

function getDepartmentsForProduct(headcountsA, headcountsToRevenue) {

  const extractedDtos = headcountsA.reduce((acc, headcount) => acc.concat(headcount.headCountCategoryDtos), [])
    .filter(category => headcountsToRevenue.productDto.headcounts.includes(category.id));

  return extractedDtos
}
