import React, { useState } from 'react';
import { Fab, Paper, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';
import CloseIcon from '@mui/icons-material/Close';

const Chatbot = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const toggleChatbot = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Fab color="primary" aria-label="chat" onClick={toggleChatbot} sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: theme.zIndex.tooltip }}>
        {open ? <CloseIcon /> : <CommentIcon />}
      </Fab>
      {open && (
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 80, // Adjust based on FAB's size and desired spacing
            right: 16,
            width: 350, // Or your desired width
            height: 600, // Or your desired height
            overflow: 'hidden',
            zIndex: theme.zIndex.drawer,
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 0, top: 0, color: 'gray' }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            src="https://share.chatling.ai/s/S3U9Y5J9o25qxez"
            title="Chatbot"
            style={{ width: '100%', height: '100%', border: 'none' }}
            allow="autoplay"
          />
        </Paper>
      )}
    </div>
  );
};

export default Chatbot;
