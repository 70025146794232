import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const pnlState = atom({
  key: 'pnlState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        // Disabled for now, this was just a POC to see if it would work.
        //console.log('ON SET PNL', newAssumptions)

        const project = JSON.parse(sessionStorage.getItem("project"));

        handleDataUpdate([structuredClone(newAssumptions)],
          [oldAssumptions],
          'pnl/' + project.id + '/months' ,
          'pnLMonthDtoList',
          'pnLCategoryDtoList',
          'pnLDto');

      });
    },
  ]
});
