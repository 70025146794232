import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";


export function actualiseFixedAssets(fixedAssets) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(fixedAssets, function(fixedAsset, i) {

    _.forEach(fixedAsset.fixedAssetCategoryDtos, function(category, x) {

      if (_.isEmpty(category.fixedAssetMonthDtos)) {
        return;
      }

      category.fixedAssetMonthDtos[firstBalanceForecast].cellType =
        category.fixedAssetMonthDtos[firstBalanceForecast - 1].cellType;

      category.fixedAssetMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.fixedAssetMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.fixedAssetMonthDtos[firstBalanceForecast].valueOverriden = null;

    });

  });

  return fixedAssets;

}