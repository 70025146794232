import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  LinearProgress, Stack, Button
} from "@mui/material";
import { spacing } from "@mui/system";
import Settings from "../../../../components/Settings";
import CashflowAnnualInput from "./components/CashflowAnnualInput";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import ExportBar from "../../../presentation/PowdrLanding/ExportBar";
import ExportBarFooter from "../../../presentation/PowdrLanding/ExportBarFooter";
import { exportScreen } from "../../SharedComponents/utils/ExportUtils";
import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../SharedState/ZoomState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { createExport } from "../../Export/calculations/Calculations";
import { cashFlowState } from "../../SharedState/CashflowState";
import { calculateCashflowBalanceAnnuals } from "./calculations/Calculations";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { isBalanceSheetImbalanced } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { pnlState } from "../../SharedState/PnLState";

const Divider = styled(MuiDivider)(spacing);


function CashflowAnnual() {

  const [loaded, setLoaded] = useState(false);
  const [cashflow, setCashflow] = useState([]);
  const componentRef = useRef();
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);
  const cashflowAtom = useRecoilValue(cashFlowState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  // const [recalculateCount, setRecalculateCount] = useState(0);
  const [recalculateImbalanceCount, setRecalculateImbalanceCount] = useState(0);

  const updatePowdrModelGlobalRef = useRef();

  async function loadCashflow() {

    let cashflowAnnuals = calculateCashflowBalanceAnnuals(structuredClone(cashflowAtom));
    setCashflow([cashflowAnnuals]);
    setLoaded(true);

    // if (recalculateCount < 2) {
    //   updatePowdrModelBalance()
    //   console.log('Auto recalculating, round: ' + recalculateCount);
    //   setRecalculateCount(current=> current + 1)
    // }
    //
    if (isBalanceSheetImbalanced(structuredClone(balanceAtom)) && recalculateImbalanceCount < 3) {
      console.log('Balance sheet is imbalanced, trying to recalc for the: ' + recalculateImbalanceCount + " time.")
      updatePowdrModelBalance()
      setRecalculateImbalanceCount(current=> current + 1)
    }
  }

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
  }

  useEffect(() => {

    loadCashflow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceAtom]);

  useEffect(() => {
    loadCashflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Blank" />

      <PageHeader pageName={"Cashflow"} parentName={"Annual Output"}/>

      <Divider my={6} />


      <ControlsBar
        addAssumptionElement={
          <Stack  direction="row">
            <Button disabled={zoomAtom !== 1} type="submit" variant="contained" onClick={() => exportScreen('Cashflow', componentRef)} sx={{marginBottom: 5}}>
              Export PNG
            </Button>


            <Button type="submit" variant="contained" onClick={() => createExport(cashflow[0].cashflowCategoryDtos, cashflow[0].cashflowDto, true)} sx={{marginBottom: 5, marginLeft : 5}}>Export Excel</Button>

          </Stack>
        }
      />


      {!loaded &&  (

        <div>
          <h1>Retrieving Cashflow ...</h1>
          <LinearProgress my={2} />
        </div>

      )}

      <div ref={componentRef}>

      <ExportBar name={getCurrentProject().name} ></ExportBar>

      {!!cashflow &&
        cashflow.map((cashflowItem) => (
          <CashflowAnnualInput
            tableRef={tableRef}
            key={cashflowItem.cashflowDto.id}
            cashflowItem={cashflowItem}
            cashflow={cashflow}
            setCashflow={setCashflow}
          ></CashflowAnnualInput>
        ))}

      <ExportBarFooter></ExportBarFooter>

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      </div>

      <Settings saving={null} />

    </React.Fragment>
  );
}

export default CashflowAnnual;
