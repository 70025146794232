import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import axios from "axios";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import useAuth from "../../../../hooks/useAuth";
import useProject from "../../../../hooks/useProject";
import { useNavigate } from "react-router-dom";
import AddDivisionalProjectsModal from "./AddDivisionalProjectsModal";
import ProjectSetup from "./ProjectSetup";

function MultiEntityTable() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setProject } = useProject();
  const [projects, setProjects] = useState([]);
  const [projectToEdit, setProjectToEdit] = useState();

  const [showAddProjects, setShowAddProjects] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedFolderProjects, setSelectedFolderProjects] = useState([]); //all projects in a folder

  async function loadProjects() {

    await axios.get('projectsByOwner/' + user.company).then(function(response) {
      setProjects(response.data);
      setProjectToEdit(response.data.find(project => project.id === getCurrentProject().id))

      //get the folder for this project and get all the projects in that folder
      setSelectedFolderProjects(response.data.filter(project => project.folderId === getCurrentProject().folderId));

      setSelectedProjects(getCurrentProject().projectMultiProjectList);
    })

  }

  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function viewProject(e) {


    //update the project viewing list
    axios.post('/projectSession/' + e.target.id + '/' + user.id);

    let url = 'projectsByOwner/' + user.company;

    axios.get(url).then(function (response2) {

      setProject(response2.data.find(project => project.id === parseInt(e.target.id)))
      navigate("/home");
      window.location.reload();

    });

  }

  return (
    <React.Fragment>
      {projectToEdit && projectToEdit?.projectType === 'PNL_DIVISIONAL' &&
        <Card mb={6} display={projectToEdit.projectType === 'PNL_DIVISIONAL' ? 'flow' : 'none'}>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Projects
              </Typography>
              <Typography variant="body2" gutterBottom>
                List of linked projects for this Divisional P&L Consolidation project
              </Typography>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Owner</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Start Date</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projects.filter(project => projectToEdit?.projectMultiProjectList.filter(multiProject => multiProject.project === project.id).length > 0).map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.companyName}</TableCell>
                          <TableCell align="right">{row.projectTypeDesc}</TableCell>
                          <TableCell align="right">{new Date(row.startDate).toDateString()}</TableCell>
                          <TableCell align="right"><Button
                            disabled={!row.initialised} id={row.id} onClick={viewProject}>View</Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }

      {projectToEdit && projectToEdit?.projectType === 'PNL_DIVISIONAL' &&
        <React.Fragment>
          <Button variant="contained" color="primary" sx={{marginTop: 5}} onClick={() => setShowAddProjects(!showAddProjects)}>Add Projects +</Button>
          <br/><br/>
          <ProjectSetup />
        </React.Fragment>
      }


      <AddDivisionalProjectsModal
        open={showAddProjects}
        setOpen={setShowAddProjects}
        selectedFolderProjects={selectedFolderProjects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      >
      </AddDivisionalProjectsModal>


    </React.Fragment>
  );
}

export default MultiEntityTable;
