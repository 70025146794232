import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { customBlue } from "../../../../../theme/variants";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const NetCashFlowChart = ({ cashflow, theme }) => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  let data = [
    {
      name: "Net Cash Flow",
      data: [],
    }
  ];

  async function loadProductData() {

    if (cashflow !== undefined) {


      let closingBalance = cashflow.cashflowCategoryDtos.find(cashflow => cashflow.name === 'Net Cashflow');

      let closingBalanceVals = closingBalance.cashflowMonthDtos.map(month => month.value);

      data[0].data = closingBalanceVals;


      setTableData(data);
      setShowTable(true);
    }

  }

  useEffect(() => {
    loadProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflow]);


  const options = {
    chart: {
      toolbar: {
        autoSelected: 'pan',
        show: true
      },
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
        barHeight: '85%',
      },
    },
    bar: {
      horizontal: false,
      endingShape: "rounded",
      columnWidth: "55%",
      distributed: true,
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return "£" + value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        }
      },
    },
    xaxis: {
      categories: ['Actuals', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
    },
    labels: ['Actuals', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],

    colors: [
      "#B1B1B1",
      customBlue[800],
      customBlue[600],
      customBlue[400],
      customBlue[200],
      customBlue[100],
    ],
  };


  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Net Cash Flow
        </Typography>
        <Typography variant="body2" gutterBottom>
          This chart shows a breakdown of all Net Cashflow over time
        </Typography>

        <Spacer mb={6} />

        { showTable !== false && (
          <ChartWrapper>
            <Chart options={options} series={tableData} type="bar" height="350" />
          </ChartWrapper>
        )}
      </CardContent>
    </Card>
  );
};

export default withTheme(NetCashFlowChart);
