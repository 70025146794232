import React, { useEffect, useState } from "react";

import useProject from "../../../../../hooks/useProject";
import Chart from "react-apexcharts";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";


const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

function getYearsForChart() {

  let years = [];

  let startDateStr = getCurrentProject().startDate;
  let startDate = new Date(startDateStr);

  startDate.setMonth(startDate.getMonth() + 11);

  years.push(Math.floor(startDate.getFullYear()));
  years.push(Math.floor(startDate.getFullYear() + 1));
  years.push(Math.floor(startDate.getFullYear() + 2));
  years.push(Math.floor(startDate.getFullYear() + 3));
  years.push(Math.floor(startDate.getFullYear() + 4));
  years.push(Math.floor(startDate.getFullYear() + 5));

  return years;

}

function getDataForChart(products) {

  const data = [];

  for (let i = 0; i < products.length; i++) {

    let product_ = {
      name: products[i][0].productDto.name,
      data : [],
    }

    for (let y = 0; y < products[i][0].productCategoryDtoList[0].productMonthDtoList.length; y++) {

      let revenue = products[i][0].productCategoryDtoList.find(category => category.name === "Revenue" );

      product_.data.push(
        Math.floor(revenue.productMonthDtoList[y].value)
      );

    }

    data.push(product_);
  }

  return data;
}


export default function RevenueBarChart(props) {

  const { key } = useProject();
  const { products } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(getDataForChart(products));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

// Define a unique color palette
  const uniqueColorPalette = [
    '#2449D1',
    '#5867F5',
    '#8186FF',
    '#A8A7FF',
    '#CEC9FF',
    '#F7EB06',
    '#BFB800',
    '#898800',
    '#585B00',
    '#373000',
    '#939CA4',
    '#727B83',
    '#535B63',
    '#363E44',
    '#1A2228',
    '#9AE9AE',
    '#6BB880',
    '#3D8A55',
    '#035E2D',
    '#003507',
    '#EC9B34',
    '#C17602',
    '#965400',
    '#6E3300',
    '#4C1200'
  ];

  const options = {
    chart: {
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: getYearsForChart(),
      labels: {
        formatter: function (val) {
          return val + "%"
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return "£" + value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: uniqueColorPalette, // Assigning the unique color palette to the chart

  };

  return (
    <React.Fragment key={key}>

      <Card mb={1}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Revenue
          </Typography>
          <Typography variant="body2" gutterBottom>
            This graph shows revenue mix for each year of the model. This information helps you identify what proportion of your revenue each product makes up and how that might change over time
          </Typography>

          <Spacer mb={6} />

          <Chart options={options} series={data} type="bar" height="350" />

        </CardContent>
      </Card>




    </React.Fragment>
  );
}