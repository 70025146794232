import React, { useState, useRef, useEffect } from "react";
import {
  Avatar, Box,
  Button, Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";

import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from "@mui/icons-material/Delete";
import { useRecoilState, useRecoilValue } from "recoil";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";



function EditHeadCount(props) {


  const updatePowdrModelGlobalRef = useRef();

  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();
  const [newValue, setNewValue] = useState("");

  const [headCountAtom, setHeadCountAtom] = useRecoilState(headcountsState);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const balanceAtom = useRecoilValue(balanceSheetState);

  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [projects, setProjects] = useState([]);
  const [selectedHeadcounts, setSelectedHeadcounts] = useState([]);
  const [headcounts, setHeadcounts] = useState([]);
  const [headcountsForProject, setHeadcountsForProject] = useState([]);

  const {headcount, open, setOpen, categories } = props;

  const validationSchema = Yup.object().shape({
    peopleCostName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(headCountAtom.filter(headcount_ => headcount_?.headCountDto?.name !== headcount?.name).map(function(i) {
        return i.headCountDto.name;
      }), "Headcount must have a unique name"),
  });

  function getDepartments(headCountCategoryDtos) {

    let departmentsList = new Set();

    for (let category in headCountCategoryDtos) {


        if (headCountCategoryDtos[category].departmentName !== "") {
          departmentsList.add(headCountCategoryDtos[category].departmentName);
        }
    }

    let departmentsArr = [];

    departmentsList.forEach(element => {
      departmentsArr.push({name: element})
    })

    return departmentsArr;
  }

  const initialValues = {
    headcountId : headcount?.id,
    peopleCostName: headcount?.name,
    assumptionFormat: headcount?.assumption,
    grossProfitLineFlag: headcount?.profitLine,
    departments: getDepartments(categories),
    selectedProject_: headcount?.linkedProjectId,
  };

  function updateHeadcount(values, project, overhead, setOpen, setSubmitting, setErrors, resetForm, setShowSpinner, setHeadCountAtom, balanceAtom, updatePowdrModelGlobalRef, selectedHeadcounts)
   {
     setShowSpinner(true)

    try {

      if (values.assumptionFormat !== "HEADCOUNT_DIVISIONAL_CONSOLIDATION") {
        if (values.departments.length < 1) {
          window.alert('You must add at least 1 department.');
          return;
        }
      }

      setSubmittingForm(true)
      axios
        .put("headcounts", {
          name: values.peopleCostName,
          project: project.id,
          assumption: values.assumptionFormat,
          percentageComplete: 0,
          overrideComplete: false,
          profitLine: values.grossProfitLineFlag,
          departments: values.departments,
          id: headcount.id,
          headcounts: selectedHeadcounts || []
        })
        .then(async function(response) {

          //update the atoms - Headcount & p&l
          await axios.get("headcounts/" + project.id).then(function(response) {
            setHeadCountAtom(response.data);
          });

          await axios.get("pnl/" + project.id).then(function(response) {
            updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, response.data);
          });


          setSubmittingForm(false);
          resetForm();

          setShowSpinner(false)
          setOpen(false);
        })
        .catch(function (error) {
          setShowSpinner(false)
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateHeadcount(values, project, headcount,  setOpen, setSubmitting, setErrors, resetForm, setShowSpinner, setHeadCountAtom, balanceAtom, updatePowdrModelGlobalRef, selectedHeadcounts);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateHeadcount(values, project, headcount,  setOpen, setSubmitting, setErrors, resetForm, setShowSpinner, setHeadCountAtom, balanceAtom, updatePowdrModelGlobalRef, selectedHeadcounts);
    }

  };

  useEffect(() => {

    loadProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ open]);

  useEffect( () => {

    if (selectedProject !== undefined && selectedProject !==  undefined) {

      axios.get("headcounts/" + selectedProject.id).then(function(response) {
        setHeadcountsForProject(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  async function loadProjects() {

    axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {

      //default group
      if (headcount.assumption === "HEADCOUNT_DIVISIONAL_CONSOLIDATION" && headcount.canDelete === false && headcount.name.endsWith("(Default Headcount Group)")) {
        setProjects(response.data.filter(project => project.id === headcount.linkedProjectId))
        setSelectedProject(response.data.find(project => project.id === headcount.linkedProjectId))
      } else {
        setProjects(response.data); //bespoke groups will display all the linked projects

        if (response.data.length > 0) {
          setSelectedProject(response.data.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0)[0]);
        }
      }

    })

    axios.get("headcounts/" + getProjectIdNotAsync().id).then(function(response) {
      setHeadcounts(response.data);
    });


    setSelectedHeadcounts(headcount?.headcounts);

    // if (selectedProject !== undefined && selectedProject !==  undefined && headcount.linkedProjectId !== null) {
    //
    //   axios.get("headcounts/" + headcount?.linkedProjectId).then(function(response) {
    //
    //     console.log(headcount?.headcounts)
    //
    //     setHeadcountsForProject(response.data);
    //     setSelectedHeadcounts(headcount?.headcounts);
    //   });
    // }


  }

  function findParentHeadcount(row) {
    const found = headcounts.find(headcount =>   headcount.headCountDto.headcounts.includes(row.headCountDto.id));
    return found ? found.headCountDto.name : '';
  }

  const handleSelectModel = (model) => {

    const currentIndex = selectedHeadcounts.indexOf(model.headCountDto.id);
    const newChecked = [...selectedHeadcounts];

    if (currentIndex === -1) {
      newChecked.push(model.headCountDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedHeadcounts(newChecked);
  };

  useEffect( () => {
    if (selectedProject !== undefined && selectedProject !==  undefined) {

      axios.get("headcounts/" + selectedProject.id).then(function(response) {
        setHeadcountsForProject(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  return (
    <React.Fragment>

      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            resetForm,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            formik,
          }) => (
            <Grid item xs={12}>
              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["HEADCOUNT_DIVISIONAL_CONSOLIDATION"].includes(values.assumptionFormat)}
                sx={{minHeight: 400}}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>


                  <DialogTitle id="form-dialog-title">Edit People Cost</DialogTitle>



                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Product...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (


                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      Enter the name of the People cost you wish to add.
                    </DialogContentText>


                    <TextField
                      error={Boolean(touched.peopleCostName && errors.peopleCostName)}
                      name="peopleCostName"
                      autoFocus
                      placeholder={"People Cost Name"}
                      margin="dense"
                      id="peopleCostName"
                      label="People Cost Name"
                      value={values.peopleCostName}
                      onChange={handleChange}
                      disabled={values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"}
                      type="text"
                      helperText={touched.peopleCostName && errors.peopleCostName}
                      fullWidth
                    />



                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Assumption Format
                        </FormLabel>



                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="assumptionFormat"
                          value={values.assumptionFormat}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="PRICE_X_QUANTITY"
                            control={<Radio />}
                            label="Salary cost x number of FTEs"
                            disabled={values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"}
                          />
                          <FormControlLabel
                            value="ANNUAL_GROWTH_RATE"
                            control={<Radio />}
                            label="Annual Growth Rate"
                            disabled={values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"}
                          />
                          <FormControlLabel
                            value="MONTHLY_GROWTH_RATE"
                            control={<Radio />}
                            label="Monthly Growth Rate"
                            disabled={values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"}
                          />
                          <FormControlLabel
                            value="DIRECT_INPUT"
                            control={<Radio />}
                            label="Direct Input"
                            disabled={values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"}
                          />
                          <FormControlLabel
                            value="HEADCOUNT_DIVISIONAL_CONSOLIDATION"
                            control={<Radio />}
                            label="Headcount Consolidation"
                            disabled={true}
                          />
                        </RadioGroup>


                        <FormLabel id="demo-row-radio-buttons-group-label">
                          P & L location:
                        </FormLabel>


                        <RadioGroup

                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="grossProfitLineFlag"
                          value={values.grossProfitLineFlag}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={"ABOVE"}
                            control={<Radio />}
                            label="Variable costs (Above Gross Profit)"
                          />
                          <FormControlLabel
                            value={"BELOW"}
                            control={<Radio />}
                            label="Overhead (Below Gross Profit, but Above EBITDA)"
                          />
                        </RadioGroup>

                      </Grid>

                      {values.assumptionFormat !== "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>


                          <DialogContentText>
                            Enter the name of the Department you wish to add.
                          </DialogContentText>


                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <TextField
                                error={Boolean(touched.departments && errors.departments)}
                                name="departmentName"
                                autoFocus
                                placeholder={"Department Name"}
                                margin="dense"
                                id="departmentName"
                                label="Department Name"
                                value={newValue}
                                onChange={(event) => {
                                  setNewValue(event.target.value);
                                }}
                                type="text"
                                helperText={touched.departmentName && errors.departmentName}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={2}>

                              <Button
                                mr={2}
                                variant="contained"
                                color="secondary"
                                sx={{ marginBottom: 2, marginTop: 4 }}
                                onClick={() => {
                                  if (newValue !== "") {
                                    setSubmittingForm(false)
                                    const newTodos = [
                                      ...values.departments,
                                      {
                                        name: newValue
                                      }
                                    ];
                                    values.departments = newTodos;
                                    setNewValue("");
                                  }
                                }}
                              >
                                <Add />
                              </Button>
                            </Grid>
                          </Grid>


                          <FieldArray
                            name="departments"
                            render={(arrayHelpers) => (

                              <List dense={true}>

                                {values.departments.map(({ name }, index) => (
                                  <ListItem key={Math.random() * 10000000}
                                            secondaryAction={
                                              <IconButton edge="end" aria-label="delete"
                                                          disabled={values.departments.length < 2} onClick={() => {
                                                //ask for confirmation
                                                //call a put endpoint that deletes the department
                                                setSubmittingForm(true)
                                                axios.put('/headcounts/removeDepartment/' + project.id + '/' + values.headcountId + '/' + name)
                                                  .then(async () => {


                                                    values.departments = values.departments.filter(dept => dept.name !== name);


                                                    resetForm();

                                                    await axios.get("headcounts/" + project.id).then(function(response) {
                                                      setHeadCountAtom(response.data);

                                                      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(null, null, response.data, null, null, null);
                                                    });
                                                    setSubmittingForm(false)
                                                    setOpen(false);
                                                  })
                                              }}>
                                                <DeleteIcon />
                                              </IconButton>
                                            }
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FolderIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={name}
                                    />
                                  </ListItem>
                                ))}

                              </List>

                            )}
                          />


                        </Grid>

                      }

                      {values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select a linked project to create your headcount consolidation group</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <br/>
                                <Typography variant="h6">Projects</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                      <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        onClick={() => setSelectedProject(row)}
                                        disabled={headcount.name.includes("(Default Headcount Group)")}
                                      >
                                        <ListItemText primary={row.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {values.assumptionFormat === "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select headcounts you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Headcounts</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {headcountsForProject.map((row) => (
                                      <ListItem
                                        key={row.headCountDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        disabled={headcount.name.includes("(Default Headcount Group)")}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedHeadcounts.indexOf(row.headCountDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.headCountDto.name} secondary={`Current Group: ${(findParentHeadcount(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }


                    </Grid>

                  </DialogContent>

                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}

export default EditHeadCount;
