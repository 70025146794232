import React, { useEffect } from "react";


const initialState = {
  saving: false,
};

const SavingContext = React.createContext(initialState);

function SavingProvider({ children }) {
  const [saving, _setSaving] = React.useState(initialState.saving);

  useEffect(() => {
    _setSaving(false);
  }, []);

  function isSaving() {
    return saving;
  }

  function setSaving(saving) {
    _setSaving(saving);
  };

  return (
    <SavingContext.Provider value={{ saving, setSaving, isSaving }}>
      {children}
    </SavingContext.Provider>
  );
}

export { SavingProvider, SavingContext };
