import getProjectId from "../../pages/powdr/SharedComponents/ProjectServices";
import axios from "axios";

export async function loadProductData(setProducts) {

  let project = getProjectId();

  var config = {
    method: "get",
    url: "revenuecos/" + project.id,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      setProducts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

}