import React from "react";
import styled from "@emotion/styled";

import {

  Container,
  Grid,
  Toolbar, AppBar as MuiAppBar
} from "@mui/material";



const AppBarContent = styled(MuiAppBar)`
  background: #346da6;
  color: ${(props) => props.theme.header.color};
`;

const AppBarComponent = () => (
  <React.Fragment>
    <AppBarContent position="relative" color="transparent" elevation={0}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center">
            <Grid item>
              <img src="/static/img/brands/logo-powdr.png" width={150} alt={"Powdr Logo"}/>
            </Grid>
            <Grid item xs />
            <Grid item>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBarContent>
  </React.Fragment>
);

export default AppBarComponent;
