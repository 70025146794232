import React  from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography

} from "@mui/material";
import { spacing } from "@mui/system";

import ProjectSetup from "./ProjectSetup";
import MultiEntityTable from "./MultiEntityTable";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";



const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);




function Config() {

  // const { setProject } = useProject();
  // const navigate = useNavigate();

  // const {refreshOverheadsShallowFromContext,
  //   refreshHeadcountsShallowFromContext, refreshPnlFromContext} = useContext(PowdrContext)

  //
  // function viewProject(project) {
  //
  //   setProject(project);
  //   refreshOverheadsShallowFromContext();
  //   refreshHeadcountsShallowFromContext();
  //   refreshPnlFromContext()
  // }


  // function deleteProject() {
  //
  //   //confirm deletion
  //   if (window.confirm("Are you sure you wish to delete this project") === true) {
  //     axios.delete('/project/' + getCurrentProject().id).then(function (response) {
  //       // set next available project as current project
  //       axios.get("project").then(function(project) {
  //          //sessionStorage.setItem("project", JSON.stringify(project.data));
  //           viewProject(project.data)
  //           navigate("/dashboard");
  //         //navigate to dashboard
  //       });
  //     })
  //   }
  //
  // }

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Config
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Config</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} display={getCurrentProject().projectType === 'SINGLE_ENTITY' ? 'flow' : 'none'}>
          <ProjectSetup />
        </Grid>
        <Grid item xs={12} display={getCurrentProject().projectType === 'PNL_DIVISIONAL' ? 'flow' : 'none'}>
          <MultiEntityTable />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Button variant="contained" color="error" onClick={deleteProject} >Delete Project </Button>*/}
        {/*</Grid>*/}
      </Grid>
    </React.Fragment>
  );
}

export default Config;
