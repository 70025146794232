import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import CurrentLiabilityNavBar from "./CurrentLiabilityNavBar";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import RowTitle from "../../../SharedComponents/RowTitle";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import Typography from "@mui/material/Typography";
import { Info } from "react-feather";
import Grid from "@mui/material/Grid";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { balanceCashflowPnlRecalculation } from "../../../../../components/Settings";
import { pnlState } from "../../../SharedState/PnLState";
import { Skeleton } from "@mui/material";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('Additional aged creditors accrued', 'New aged credit should be a positive value');
helpTitles.set('Aged credit released', 'Aged credit release should be a negative value');
helpTitles.set('Percentage of selected costs', 'Percentage of selected costs should be a negative percentage value to generate a positive  (debit balance) current asset ');
helpTitles.set('Opening Vat', 'VAT paid should be a positive number to represent VAT liabilities being paid (debited). If the company reclaims VAT a negative value should be input in this assumption');
helpTitles.set('VAT incurred', 'VAT paid should be a positive number to represent VAT liabilities being paid (debited). If the company reclaims VAT a negative value should be input in this assumption');
helpTitles.set('VAT paid', 'VAT paid should be a positive number to represent VAT liabilities being paid (debited). If the company reclaims VAT a negative value should be input in this assumption');
helpTitles.set('Closing VAT', 'VAT paid should be a positive number to represent VAT liabilities being paid (debited). If the company reclaims VAT a negative value should be input in this assumption');


function CurrentLiabilityInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);
  const setPnlAtom = useSetRecoilState(pnlState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    currentLiabilityItem,
    currentLiabilities,
    refreshData,
    setEditData,
    editData,
    setCurrentLiabilities,
    loadData,
    products,
    overheads,
    salaries,
    manualRecalculation,
    calcCurrentLiabilitiesWrapper,
  } = props;

  const deleteCurrentLiability = (handleClose) => {
    var config = {
      method: "delete",
      url: "currentliabilities",
      data: currentLiabilityItem.currentLiabilityDto,
    };

    axios(config)
      .then(async function(response) {
        // do something to trigger a redraw
        // console.log(response.data)
        setCurrentLiabilities([...response.data]);
        await balanceCashflowPnlRecalculation(setPnlAtom);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function getName(row) {

    let name;

    if (row.overrideName !== null && row.name === "Expense Name") {
      name = row.overrideName;
    } else {
      name = row.name;
    }

    if (row.name === 'Days Receivable') {
      name = row.overrideName;
    }

    return name;
  }

  function renderHeavyContent() {
    return (
      <div>
        <CurrentLiabilityNavBar
          item={currentLiabilityItem.currentLiabilityDto}
          categories={currentLiabilityItem.currentLiabilityCategoryDtos}
          deleteMethod={deleteCurrentLiability}
          refreshDataMethod={loadData}
          products={products}
          overheads={overheads}
          salaries={salaries}
          exportToExcel={{fileName: "Powdr-CurrentLiability-" + currentLiabilityItem.currentLiabilityDto.name + "-" + new Date().toDateString(),
            sheet : "Current Liability",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'currentliabilities/currentLiabilityShallow'}
          recalculateCurrentLiabilities={refreshData}
          manualRecalculation={manualRecalculation}
          calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}
        ></CurrentLiabilityNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={currentLiabilityItem.currentLiabilityDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>

            {currentLiabilityItem.currentLiabilityDto.assumption === 'VAT_LIABILITY' &&
              <Grid container spacing={2}>
                <Grid item xs="auto">
                  <Info fontSize="small" />
                </Grid>
                <Grid item xs={2} md={8}>
                  <Typography variant="subtitle1" gutterBottom color={"grey"}>
                    Vat liabilities require you to select what rates apply to products and expenses.
                    Click <strong>:</strong> to configure them.
                  </Typography>
                </Grid>
              </Grid>
            }


            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={currentLiabilityItem.currentLiabilityDto.name + "-ROW"}
                  id={currentLiabilityItem.currentLiabilityDto.name + "-ROW"}
                >
                  <TableCell
                    key={currentLiabilityItem.currentLiabilityDto.name + "-BASE"}
                    id={currentLiabilityItem.currentLiabilityDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{currentLiabilityItem.currentLiabilityDto.name}</h2>
                    <AssumptionLabel label={currentLiabilityItem.currentLiabilityDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={currentLiabilityItem.currentLiabilityDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {currentLiabilityItem.currentLiabilityCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)} sx={{paddingBottom: 10, marginBottom : 10}}>

                    <RowTitle name={getName(row)} alt={helpTitles.get(row.name)}></RowTitle>

                    {row.currentLiabilityMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={currentLiabilities}
                        refreshItemData={refreshData}
                        setItems={setCurrentLiabilities}
                        canOverride={row.allowValueOverride}
                        assumption={currentLiabilityItem}
                        categoryName={row.name}
                        parentName={currentLiabilityItem.currentLiabilityDto.name}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default CurrentLiabilityInput;
