
import _ from "lodash";

export function calculateBalanceAnnuals(balanceAtom) {

  if (_.isEmpty(balanceAtom)) {
    return [];
  }

  //go through each category and create annuals
  for (const balanceCategory of balanceAtom.balanceCategoryDtos) {


    buildYearTotalCategory(balanceCategory)

  }

  return balanceAtom;
}


function buildYearTotalCategory(balanceCategory) {

  let categoryMonths = [];

  if (balanceCategory.name === "" || balanceCategory.name === "space") {
    balanceCategory.balanceMonthDtos = [];
  } else {

    let yearIndex = 0;
    for (let i = 12; i <= 72;) {

      let yearTotal = balanceCategory.balanceMonthDtos[i].value;

      categoryMonths.push({
        id: yearIndex,
        value: yearTotal,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: 0,
      })
      i = i + 12;
      yearIndex++;

    }

    balanceCategory.balanceMonthDtos = categoryMonths;
  }
}