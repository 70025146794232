import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";


export const debtsState = atom({
  key: 'debtsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        //console.log('on set', newAssumptions, oldAssumptions)

        const project = JSON.parse(sessionStorage.getItem("project"));

        if (newAssumptions !== undefined && oldAssumptions !== undefined) {

          handleDataUpdate(structuredClone(newAssumptions),
            oldAssumptions,
            'debt/' + project.id + '/months',
            'debtMonthDtos',
            'debtCategoryDtos',
            'debtDto');

        }

      });
    },
  ]
});
