import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { refreshVatPackage, vatPackageState } from "../../SharedState/VatPackageState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { cashFlowState } from "../../SharedState/CashflowState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";
import _ from "lodash";
import { calculateBalanceSheet } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";
import { calculateCashflow } from "../../CashflowGroup/CashFlow/calculations/Calculations";
import { calculatePnl } from "../../PLGroup/PL/calculations/Calculations";
import { vatState } from "../../SharedState/VatState";
import { calculateCurrentAssets } from "../../AssumptionsGroup/CurrentAssets/calculations/Calculations";
import { calculateCurrentLiabilities } from "../../AssumptionsGroup/CurrentLiabilities/calculations/Calculations";
import { corporationTaxState } from "../../SharedState/CorporationTaxState";
import { currentAssetsAssumptionsMap, currentLiabilitiesAssumptionsMap, overheadAssumptionMap } from "../AssumptionMaps";
import { refreshAndCalculateDebts, updatePnlCorporationTax } from "./UpdatePowdrModelUtils";
import { debtsState } from "../../SharedState/DebtsState";
import {
  calculateRevenueAndCOS,
  calculateRevenueSummaryTotals
} from "../../AssumptionsGroup/RevenueAndCos/calculations/Calculations";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../SharedState/HeadcountsState";
import { calculateOverheads } from "../../AssumptionsGroup/Overheads/calculations/Calculations";
import { overheadsState } from "../../SharedState/OverheadsState";
import { overheadsConsolidationState } from "../../SharedState/OverheadsConsoliationState";
import { revenueAndCostConsolidationState } from "../../SharedState/RevenueAndCostConsolidationState";

/**
 * {Used for updating Overhead related model changes}
 *
 * If an Overhead is changed, then we need to recalculate a range of things across Powdr
 *
 * Steps:
 *
 * 1. Copy over values to the P&L
 * 2. Recalculate and Save P&L changes
 * 2.5 Revenue and COS page & Overhead
 * 3. Recalculate and Save VatPackage
 * 4.1 Recalculate Current Assets (Percentage of Overheads Assumptions)
 * 4.2 Recalculate Current Liabilities (passing in the new VatPackage and the Overheads)
 * 5. Copy over the Current Liabilities & Current Asset values to the Balance Sheet
 * 5.5 Calculate Debt
 * 6. Recalculate and Save the Balance Sheet
 * 7. Recalculate and Save the cashflow
 * 8. TODO - recalculate Revenue and COS page & Overhead page & Debt page
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const UpdatePowdrModelFullStack = forwardRef((props, ref) => {

  //todo - current asset/liabilities & debt add this to them.
  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const [cashflowAtom, setCashflowAtom] = useRecoilState(cashFlowState);

  const [revenueAtom, setRevenueAtom] = useRecoilState(revenueAndCostState);
  const [overheadAtom, setOverheadAtom] = useRecoilState(overheadsState);
  const headcountAtom = useRecoilValue(headcountsState);
  //
  // const [fixedAssetAtom, setFixedAssetAtom] = useRecoilState(fixedAssetsState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);
  const debtAtom = useRecoilValue(debtsState);
  // const [equityAtom, setEquityAtom] = useRecoilState(equitiesState);
  //
  // const [updating, setUpdating] = useState(false);

  const setVatPackageAtom = useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  const corporationTaxAtom = useRecoilValue(corporationTaxState);

  const overheadsConsolidations = useRecoilValue(overheadsConsolidationState);
  const revenueAndCostConsolidation = useRecoilValue(revenueAndCostConsolidationState);

  useImperativeHandle(ref, () => ({


    updatePowdrModelFullStack(cellData,
                     assumptionList,
                     assumptionName,
                     assumptionToCategoryMap,
                     overheads_, products_) {


        const tick = performance.now();


        let products = products_ ? structuredClone(products_) : structuredClone(revenueAtom);
        let overheads = overheads_ ? structuredClone(overheads_) : structuredClone(overheadAtom);


        //1. Copy over values to the P&L
        let pnl = structuredClone(pnlAtom);

        //1.5
        calculateRevenueAndCOS(products, headcountAtom, revenueAndCostConsolidation);
        setRevenueAtom(structuredClone(products));
        pnl = calculateRevenueSummaryTotals(products, pnl, setPnlAtom);

        if (["headCount", "overhead"].includes(assumptionName)) {

          //todo - what about products? Will this happen automatically in the products screen

          updatePnl(assumptionList,
            assumptionToCategoryMap,
            assumptionName,
            pnl);
        }

        //2. Recalculate and Save P&L changes
        calculatePnl(pnl)

        //2.5
        calculateOverheads(overheads, pnl, products, headcountAtom, overheadsConsolidations);
        setOverheadAtom(structuredClone(overheads));

        updatePnl(overheads, overheadAssumptionMap, "overhead", pnl);

        //3. Recalculate and Save VatPackage
        let vatPackage = refreshVatPackage(products, overheads, structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        //4.1 Recalculate Current Assets (Percentage of Overheads Assumptions)
        let currentAssets = structuredClone(currentAssetAtom);
        calculateCurrentAssets(currentAssets, vatPackage, pnl)
        setCurrentAssetsAtom(currentAssets)


        let cashflow_ = structuredClone(cashflowAtom);

        //4.2 Recalculate Current Liabilities (passing in the new VatPackage and the Overheads)
        let currentLiabilities = structuredClone(currentLiabilitiesAtom);
        calculateCurrentLiabilities(structuredClone(corporationTaxAtom),
          structuredClone(currentLiabilitiesAtom),
          vatPackage,
          cashflow_,
          structuredClone(pnlAtom.pnLCategoryDtoList),
          getOpeningAgedCreditor()
          );
        setCurrentLiabilitiesAtom(currentLiabilities);

        //5. Copy over the Current Liabilities & Current Asset values to the Balance Sheet
        let balance = structuredClone(balanceAtom); //atoms are read only

        refreshAndCalculateDebts(balance, structuredClone(debtAtom));

        //copy Current Liabilities to the balance sheet
        updateBalance(currentAssets,
          currentAssetsAssumptionsMap,
          "CURRENT_ASSETS",
          "currentAsset",
          balance);

        //let pnl_ = structuredClone(pnlAtom)
        updatePnlCorporationTax(currentLiabilities, pnl)
        setPnlAtom(pnl);

        //copy Current Liabilities to the balance sheet
        updateBalance(currentLiabilities,
          currentLiabilitiesAssumptionsMap,
          "CURRENT_LIABILITIES",
          "currentLiability",
          balance);


        //6. Recalculate and Save the Balance Sheet
        calculateBalanceSheet(balance, pnl);

        //7. Recalculate and Save the cashflow
        let cashflow = calculateCashflow(structuredClone(cashflow_),
          balance,
          pnl);

        setCashflowAtom(cashflow);

        //Then recalculate the balance sheet with the NEW Cash At Bank values
        calculateBalanceSheet(balance, pnl);
        setBalanceAtom(structuredClone(balance));

        //8. Rev, Overheads, Debts
        let productsStep8 = structuredClone(products);
        calculateRevenueAndCOS(productsStep8, headcountAtom, revenueAndCostConsolidation);
        setRevenueAtom(structuredClone(productsStep8));
        pnl = calculateRevenueSummaryTotals(productsStep8, pnl, setPnlAtom);

        let overheadStep8 = structuredClone(overheads);
        calculateOverheads(overheadStep8, pnl, productsStep8, headcountAtom, overheadsConsolidations);
        setOverheadAtom(structuredClone(overheadStep8));

        refreshAndCalculateDebts(balance, structuredClone(debtAtom));

        const tock = performance.now();

        console.log(`Main full stack thread took ${tock - tick} ms`);

    }

  }));

  /**
   * Balance has an opening month value that occurs before the model begins, this method retrieves that for Trade creditors
   * @returns {*}
   */
  function getOpeningAgedCreditor() {
    let openingAgedCreditorCategory = balanceAtom.balanceCategoryDtos.find(category => category.overrideName === 'Trade creditors')
    return openingAgedCreditorCategory.balanceMonthDtos[0].value;
  }

  function updatePnl(assumptions, assumptionToCategoryMap, assumptionName, pnl) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let pnlCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && (category.headcountId === assumption[assumptionName + 'Dto'].id || category.overheadId === assumption[assumptionName + 'Dto'].id));

      let categoryToCopy;
      if (assumptionName === "headCount") {
        categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);
      } else {
        categoryToCopy = assumption[assumptionName + 'CategoryDtoList'].find(category => category.name === categoryName || category.overrideName === categoryName)
      }

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtoList'], function(monthDto, i) {
        pnlCategory.pnLMonthDtoList[i].value = monthDto.valueOverriden || monthDto.value;
      });

    });

  }


  function updateBalance(assumptions, assumptionToCategoryMap, balanceType, assumptionName, balance) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let balanceCategory = balance.balanceCategoryDtos.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && category.balanceType === balanceType);

      let categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtos'], function(monthDto, i) {
        balanceCategory.balanceMonthDtos[i +1].value = monthDto.value;
      });

    });

  }

  return (
    <div></div>
  );
});

export default UpdatePowdrModelFullStack;
