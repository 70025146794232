import React, { useEffect, useState } from "react";
import useProject from "../../../../../hooks/useProject";
import Chart from "react-apexcharts";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Spacer = styled.div(spacing);

function getYearsForChart() {
  let years = [];
  let startDateStr = getCurrentProject().startDate;
  let startDate = new Date(startDateStr);
  startDate.setMonth(startDate.getMonth() + 11);
  years.push(Math.trunc(startDate.getFullYear()));
  years.push(Math.trunc(startDate.getFullYear() + 1));
  years.push(Math.trunc(startDate.getFullYear() + 2));
  years.push(Math.trunc(startDate.getFullYear() + 3));
  years.push(Math.trunc(startDate.getFullYear() + 4));
  years.push(Math.trunc(startDate.getFullYear() + 5));
  return years;
}

function getDataForChart(overheads) {
  const data = [];
  for (let i = 0; i < overheads?.overheadCategoryDtoList.length; i++) {
    let overhead_ = {
      name: overheads.overheadCategoryDtoList[i].name,
      data: [],
    };
    for (let y = 0; y < overheads.overheadCategoryDtoList[i].pnLMonthDtoList.length; y++) {
      overhead_.data.push(
        overheads.overheadCategoryDtoList[i].pnLMonthDtoList[y].value.toFixed(2)
      );
    }
    data.push(overhead_);
  }
  return data;
}

export default function OverheadsGrossMarginChart(props) {
  const { key } = useProject();
  const { overheads } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(getDataForChart(overheads));
  }, [overheads]);

  // Define a unique color palette
  const uniqueColorPalette = [
    '#2449D1', '#5867F5', '#8186FF', '#A8A7FF', '#CEC9FF', '#F7EB06', '#BFB800',
    '#898800', '#585B00', '#373000', '#939CA4', '#727B83', '#535B63', '#363E44',
    '#1A2228', '#9AE9AE', '#6BB880', '#3D8A55', '#035E2D', '#003507', '#EC9B34',
    '#C17602', '#965400', '#6E3300', '#4C1200'
  ];

  const options = {
    chart: {
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: getYearsForChart(),
      labels: {
        formatter: function (val) {
          return val + "%"; // Y-axis labels are already integers, so no need to format for decimals
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: function (val) {
          return `${val.toFixed(0)}`; // Format Y-axis values as integers without decimal places
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val} %`; // Keep this for formatting the tooltip values with two decimal places and append a "%"
        },
      },
      // Attempt to move the tooltip to the left side
      // This might need adjustments or might not work as expected in all cases
      fixed: {
        enabled: true,
        position: 'topLeft',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: uniqueColorPalette, // Assigning the unique color palette to the chart
  };

  return (
    <React.Fragment key={key}>
      <Card mb={1}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Overheads breakdown as a percentage of total overheads by year
          </Typography>
          <Typography variant="body2" gutterBottom>
            This graph shows overhead mix for each year of the model. This information helps you identify what proportion of your overhead each product makes up and how that might change over time
          </Typography>
          <Spacer mb={6} />
          <Chart options={options} series={data} type="bar" height="350" />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
