import React from "react";
import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import YearNav from "./YearNav";
import styled from "@emotion/styled";
import { Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

function DeleteDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { productDto,  deleteMethod } = props;

  const deleteItem = () => {
    
    deleteMethod(handleClose);
    
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Delete onClick={handleClickOpen} sx={{ float: "right" }}></Delete>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deletion Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete : <strong>{productDto?.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteItem} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingBottom: 10,
  color: theme.palette.text.secondary,
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function InputNavBar(props) {
  const { item, deleteMethod, refreshDataMethod, showDelete = true } = props;

  return (
    <React.Fragment>
      <Card
        key={item.name.replace(" ", "-") + "PI"}
        sx={{ pb: 0 }}
        style={{ pb: 0 }}
        id={item.name.replace(" ", "-") + "PI"}
      >
        <CardContent sx={{ pb: 0 }}>
          <Grid
            container
            spacing={2}
            style={{ position: "sticky", left: 0, paddingBottom: 0 }}
          >
            {/* NAVIGATION A,1,2,3,4,5 */}
            <Grid item xs={4}>
              <Item
                style={{ position: "sticky", minWidth: 272 }}
                id={"YEAR-NAV"}
                key={"YEAR-NAV"}
              >
                <YearNav productName={item.name.replaceAll(" ", "-")}></YearNav>
              </Item>
            </Grid>
            {/* PERCENTAGE COMPLETE */}
            <Grid item xs={3} sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
              {showDelete &&
                <Item
                  style={{ position: "sticky", left: 0 }}
                  id={"LINEAR-PROGRESS"}
                  key={"LINEAR-PROGRESS"}
                >
                  Percentage Complete:{" "}
                  <LinearProgressWithLabel value={item.percentageComplete} />
                </Item>
              }
            </Grid>
            {/* DELETE PRODUCT */}
            <Grid item xs={2} id={"BLANK"} key={"BLANK"}></Grid>
            <Grid item xs={3} >
              {showDelete && 
              <Item id={"DELETE"} key={"DELETE"}>
                <DeleteDialog
                  productDto={item}
                  refreshDataMethod={refreshDataMethod}
                  deleteMethod={deleteMethod}
                ></DeleteDialog>
              </Item>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default InputNavBar;
