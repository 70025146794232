import _ from "lodash";

function calculateLeverageCovenants(covenants, debts, pnl, balance) {

  //find the cash at bank category
  let cashAtBankCategory = balance.balanceCategoryDtos.find(category => category.name === "Cash at bank");

  //find all leverage types
  const covenantsLeverage = covenants
    .filter(covenant => covenant.covenantDto.assumption === "LEVERAGE_COVENANT");

  _.forEach(covenantsLeverage, function(covenant) {

    let cashOnBalanceSheetCovenanceCategory = covenant.covenantCategoryDtos.find(category => category.name === "Cash On Balance Sheet");

    //now loop through the months for the debt and populate the covenantDebt with those values
    _.forEach(cashOnBalanceSheetCovenanceCategory?.covenantMonthDtos, function(monthDto, i) {
      monthDto.value = cashAtBankCategory.balanceMonthDtos[i + 1].value;
    });

    let debtTotals = new Array(72).fill(0);

    //we need to populate the debt values first
    const covenantDebts = covenant.covenantCategoryDtos.filter(category => ["Debt"].includes(category.name));

    _.forEach(covenantDebts, function(covenantDebt) {

      // find the debt that is linked to this coventantDebt
      const debtAssociated = debts.find(debt => debt.debtDto.id === covenantDebt.debtId);

      // find the debt total category (the bottom line)
      const debtClosing = debtAssociated.debtCategoryDtos.find(category => category.name === "Closing" || category.name === "Closing Borrowings"); //do get correct name

      //now loop through the months for the debt and populate the covenantDebt with those values
      _.forEach(covenantDebt.covenantMonthDtos, function(monthDto, i) {
        monthDto.value = Math.abs(debtClosing.debtMonthDtos[i].value);
        debtTotals[i] += Math.abs(debtClosing.debtMonthDtos[i].value)
      });

    });

    //And now the Debt Adjustment row
    const covenantDebtAdjustment = covenant.covenantCategoryDtos.find(category => ["Debt Adjustment"].includes(category.name));

    _.forEach(covenantDebtAdjustment.covenantMonthDtos, function(monthDto, i) {
      debtTotals[i] += monthDto.value
    });


    if (cashOnBalanceSheetCovenanceCategory) {

      _.forEach(cashOnBalanceSheetCovenanceCategory.covenantMonthDtos, function(monthDto, i) {

        debtTotals[i] = debtTotals[i] - cashOnBalanceSheetCovenanceCategory.covenantMonthDtos[i].value;
      });

    }

    //console.log('debt totals', debtTotals)



    const EBITDA = pnl.pnLCategoryDtoList.find(category => category.name === "EBITDA" && category.overrideName === "");

    //let covenant_DEBT_Adjustment = covenant.covenantCategoryDtos.find(category => category.name === "Debt Adjustment");
    let covenant_EBITDA = covenant.covenantCategoryDtos.find(category => category.name === "EBITDA");
    let covenant_EBITDA_Adjustment = covenant.covenantCategoryDtos.find(category => category.name === "EBITDA Adjustment");
    let covenant_LTM_EBITDA = covenant.covenantCategoryDtos.find(category => category.name === "LTM EBITDA");
    let covenant_CALCULATED_RATIO = covenant.covenantCategoryDtos.find(category => category.name === "Calculated Ratio");
    let covenant_LIMIT = covenant.covenantCategoryDtos.find(category => category.name === "Limit");
    let covenant_BREACH_INDICATOR = covenant.covenantCategoryDtos.find(category => category.name === "Breach Indicator");

    _.forEach(covenant.covenantCategoryDtos[0].covenantMonthDtos, function(monthDto, i) {

      //do calculations
      //then set the breach line

      covenant_EBITDA.covenantMonthDtos[i].value = EBITDA.pnLMonthDtoList[i].value; //Set the EBITDA

      if (i === 0) {
        covenant_LTM_EBITDA.covenantMonthDtos[i].value = //Set the LTM Ebitda
          covenant_EBITDA.covenantMonthDtos[i].value +  //EBITDA
          covenant_EBITDA_Adjustment.covenantMonthDtos[i].value; //EBITDA Adjustment
      } else if (i < 12) {
        covenant_LTM_EBITDA.covenantMonthDtos[i].value = //Set the LTM Ebitda
          covenant_LTM_EBITDA.covenantMonthDtos[i - 1].value + // LTM Ebitda last month
          covenant_EBITDA.covenantMonthDtos[i].value +  //EBITDA
          covenant_EBITDA_Adjustment.covenantMonthDtos[i].value;  //EBITDA Adjustment
      } else {

        let ltmValue = 0;

        for (let x = 0; x < 12; x++) {
          ltmValue += covenant_EBITDA.covenantMonthDtos[i - x].value;
          ltmValue += covenant_EBITDA_Adjustment.covenantMonthDtos[i - x].value;
        }


        covenant_LTM_EBITDA.covenantMonthDtos[i].value = ltmValue//Set the LTM Ebitda
      }

      let ratio = Number( //Set the Calculated Ratio
      debtTotals[i] /  //total debts
      covenant_LTM_EBITDA.covenantMonthDtos[i].value).toFixed(2);

      if (isFinite(ratio)){
        covenant_CALCULATED_RATIO.covenantMonthDtos[i].value = ratio
      } else {
        covenant_CALCULATED_RATIO.covenantMonthDtos[i].value = 0;
      }


      covenant_CALCULATED_RATIO.covenantMonthDtos[i].value >
      covenant_LIMIT.covenantMonthDtos[i].value ? covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 1 : covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 0;


    });
  });
  
}

function calculateCashflowCovenants(covenants, balance) {

  const covenantsCashflow = covenants
    .filter(covenant => covenant.covenantDto.assumption === "MINIMUM_CASH_COVENANT");

  //find the cash at bank category
  let cashAtBankCategory = balance.balanceCategoryDtos.find(category => category.name === "Cash at bank");

  _.forEach(covenantsCashflow, function(covenant) {

    let cashOnBalanceSheet = covenant.covenantCategoryDtos.find(category => category.name === "Cash On Balance Sheet");

    //now loop through the months for the debt and populate the covenantDebt with those values
    _.forEach(cashOnBalanceSheet.covenantMonthDtos, function(monthDto, i) {
      monthDto.value = cashAtBankCategory.balanceMonthDtos[i + 1].value;
    });

    let covenant_BREACH_INDICATOR = covenant.covenantCategoryDtos.find(category => category.name === "Breach Indicator");

    _.forEach(covenant.covenantCategoryDtos[0].covenantMonthDtos, function(monthDto, i) {

      //do calculations
      if (
      (covenant.covenantCategoryDtos[0].covenantMonthDtos[i].value +
      covenant.covenantCategoryDtos[1].covenantMonthDtos[i].value) > covenant.covenantCategoryDtos[3].covenantMonthDtos[i].value) {

        covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 0;
      } else {
        covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 1;
      }


    });
  });
  
}

export function calculateCovenants(covenants, debts, pnl, balance) {
  if (Array.isArray(debts) && debts.length === 0) {
    //console.log('debts are empty')
    return
  }
  
  //calculate leverage covenants
  calculateLeverageCovenants(covenants, debts, pnl, balance);
  //calculate cashflow covenants
  calculateCashflowCovenants(covenants, balance)
}