import axios from "axios";

export function getBalanceItemToEdit(balanceSheetItem, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {

  // get the type of the balance sheet item
  // get the external balance id
  // switch statement to call the appropriate end point
  // set the setItemToEdit
  // set the appropriate modal for edit
  // done
  switch(balanceSheetItem.balanceType) {
    case "FIXED_ASSETS":
      getFixedAsset(balanceSheetItem.balanceExternalId, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption);
      break;
    case "CURRENT_ASSETS":
      getCurrentAsset(balanceSheetItem.balanceExternalId, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption);
      break;
    case "CURRENT_LIABILITIES":
      getCurrentLiability(balanceSheetItem.balanceExternalId, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption);
      break;
    case "DEBT":
      getDebt(balanceSheetItem.balanceExternalId, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption);
      break;
    case "EQUITY":
      getEquity(balanceSheetItem.balanceExternalId, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption);
      break;
    default:
      break;
  }

}

//TODO - MAKE THIS ALL SHALLOW CALLS, WE'RE PULLING BACK THE WORLD FOR NO REASON.

function getFixedAsset(id, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {
  var config = {
    method: "get",
    url: "fixedassets/" + projectId,
  };

  setLoadingAssumption(true);

  axios(config)
    .then(function(response) {

      const fixedAsset = response.data
        .filter(fixedAsset => fixedAsset.fixedAssetDto.id === id)[0];

      setLoadingAssumption(false);

      setItemToEdit(fixedAsset.fixedAssetDto);
      setOpenEdit({ fixedEdit: true, currentEdit: false, liabilityEdit: false, debtEdit: false, equityEdit: false })
    })
    .catch(function(error) {

      setLoadingAssumption(false);
      console.log(error);
    });
}

function getCurrentAsset(id, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {
  var config = {
    method: "get",
    url: "currentassets/" + projectId,
  };

  setLoadingAssumption(true);

  axios(config)
    .then(function(response) {

      const currentAsset = response.data
        .filter(currentAsset => currentAsset.currentAssetDto.id === id)[0];

      setLoadingAssumption(false);

      setItemToEdit(currentAsset.currentAssetDto);
      setOpenEdit({ fixedEdit: false, currentEdit: true, liabilityEdit: false, debtEdit: false, equityEdit: false })
    })
    .catch(function(error) {
      setLoadingAssumption(false);
      console.log(error);
    });
}

function getCurrentLiability(id, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {
  var config = {
    method: "get",
    url: "currentliabilities/" + projectId,
  };

  setLoadingAssumption(true);

  axios(config)
    .then(function(response) {

      const currentLiability = response.data
        .filter(currentLiability => currentLiability.currentLiabilityDto.id === id)[0];

      setLoadingAssumption(false);

      setItemToEdit(currentLiability.currentLiabilityDto);
      setOpenEdit({ fixedEdit: false, currentEdit: false, liabilityEdit: true, debtEdit: false, equityEdit: false })
    })
    .catch(function(error) {
      setLoadingAssumption(false);
      console.log(error);
    });
}

function getDebt(id, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {
  var config = {
    method: "get",
    url: "debt/" + projectId,
  };

  setLoadingAssumption(true);

  axios(config)
    .then(function(response) {

      const debt = response.data
        .filter(debt => debt.debtDto.id === id)[0];

      setLoadingAssumption(false);

      setItemToEdit(debt.debtDto);
      setOpenEdit({ fixedEdit: false, currentEdit: false, liabilityEdit: false, debtEdit: true, equityEdit: false })
    })
    .catch(function(error) {
      setLoadingAssumption(false);
      console.log(error);
    });
}

function getEquity(id, setItemToEdit, setOpenEdit, projectId, setLoadingAssumption) {
  var config = {
    method: "get",
    url: "equity/" + projectId,
  };

  setLoadingAssumption(true);

  axios(config)
    .then(function(response) {

      const equity = response.data
        .filter(equity => equity.equityDto.id === id)[0];

      setLoadingAssumption(false);

      setItemToEdit(equity.equityDto);
      setOpenEdit({ fixedEdit: false, currentEdit: false, liabilityEdit: false, debtEdit: false, equityEdit: true })
    })
    .catch(function(error) {

      setLoadingAssumption(false);
      console.log(error);
    });
}


// get shallow entities


export function loadFixedAssetsNames(projectId, setFixedAssetNames) {
  var config = {
    method: "get",
    url: "fixedassets/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setFixedAssetNames(response.data.map(function(i) {
        return i.name;
      }))
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadCurrentAssetsNames(projectId, setCurrentAssetNames) {
  var config = {
    method: "get",
    url: "currentassets/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setCurrentAssetNames(response.data.map(function(i) {
        return i.name;
      }))
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadCurrentLiabilityNames(projectId, setCurrentLiabilityNames) {
  var config = {
    method: "get",
    url: "currentliabilities/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setCurrentLiabilityNames(response.data.map(function(i) {
        return i.name;
      }))
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadDebtNames(projectId, setDebtNames) {
  var config = {
    method: "get",
    url: "debt/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setDebtNames(response.data.map(function(i) {
        return i.name;
      }))
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadEquityNames(projectId, setEquityNames) {
  var config = {
    method: "get",
    url: "equity/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setEquityNames(response.data.map(function(i) {
        return i.name;
      }))
    })
    .catch(function(error) {
      console.log(error);
    });
}




export function loadOverheadNames(projectId, setOverheadNames) {
  var config = {
    method: "get",
    url: "overheads/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setOverheadNames(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadProducts(projectId, setProducts) {
  var config = {
    method: "get",
    url: "revenuecos/shallow/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setProducts(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function loadHeadCounts(projectId, setHeadcounts) {
  var config = {
    method: "get",
    url: "headcounts/" + projectId,
  };

  axios(config)
    .then(function(response) {
      setHeadcounts(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
}