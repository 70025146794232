import React, { useState, useEffect } from "react";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { debtsState } from "../../../SharedState/DebtsState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import useProject from "../../../../../hooks/useProject";
import { useRecoilState } from "recoil";

function AddDebt() {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const [assumptionFormatValue, setAssumptionFormatValue] = useState("SIMPLE_CORKSCREW");
  const [pikOnPik, setPikOnPik] = useState(false);
  const [debts, setDebts] = useState([]);
  const { project } = useProject();
  const [debtsAtom, setDebtsAtom] = useRecoilState(debtsState);
  const [balanceSheetAtom, setBalanceSheet] = useRecoilState(balanceSheetState);
  const [ablAssetsChosen, setAblAssetsChosen] = useState([]);

  useEffect(() => {

    setDebts(structuredClone(debtsAtom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsAtom]);

  const validationSchema = Yup.object().shape({
    debtName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(debtsAtom.map(function(i) {
        return i.debtDto.name;
      }), "Must have a unique name"),
  });

  const initialValues = {
    debtName: "",
    assumptionFormat: "",
    balancePositionFlag: "TANGIBLE",
  };

  const handleChangeAblFacility = (event) => {

    let chosenAssets = new Set([...ablAssetsChosen]);

    if (event.target.checked){
      chosenAssets.add(Number.parseInt(event.target.id))
    } else if (event.target.checked === false) {
      chosenAssets.delete(Number.parseInt(event.target.id))
    }

    setAblAssetsChosen(chosenAssets);
  }

  const handleChangeDebt = (event) => {

    const debtCh = debts
      .filter(debt => debt.debtDto.name === event.target.name)[0];

    debtCh.debtDto.checked = event.target.checked;

    //Clever way of getting the page to refresh
    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      setSubmittingForm(true)
      axios
        .post("debt", {
          name: values.debtName,
          project: getCurrentProject().id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          canDelete: true,
          pikOnPik: pikOnPik,
          debts: debts.filter(item => item.debtDto.checked === true).map(item => item.debtDto["id"]),
          ablAssets : [...ablAssetsChosen],
        })
        .then(async function(response) {

          //update the atoms - Debt & Balance
          await axios.get("debt/" + project.id).then(function(response) {
            setDebtsAtom(response.data);
          });

          await axios.get("balance/" + project.id).then(function(response) {
            setBalanceSheet(response.data);
          });


          setSubmittingForm(false);
          setAblAssetsChosen([]);
          resetForm();
          setOpen(false);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const handleChangePikOnPik = (event) => {
    setPikOnPik(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, minWidth : 160 }}
                onClick={() => setOpen(true)}
              >
                Debt
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Debt</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Debt...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      Enter the name of the debt you wish to add.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.debtName && errors.debtName)}
                      name="debtName"
                      autoFocus
                      placeholder={"Current Debt Name"}
                      margin="dense"
                      id="debtName"
                      label="Current Debt Name"
                      value={values.debtName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.debtName && errors.debtName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={assumptionFormatValue}
                      onChange={handleChangeAssumption}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                      <FormControlLabel
                        value="LOAN_DEBT"
                        control={<Radio />}
                        label="Loan"
                      />
                      <FormControlLabel
                        value="CID_DEBT"
                        control={<Radio />}
                        label="CID Facility"
                      />
                      <FormControlLabel
                        value="PIK_DEBT"
                        control={<Radio />}
                        label="PIK Facility"
                      />
                      <FormControlLabel
                        value="ABL_DEBT"
                        control={<Radio />}
                        label="ABL facility"
                      />
                    </RadioGroup>

                      </Grid>

                      {assumptionFormatValue === "ABL_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Assets To include in ABL facility
                            calculation</FormLabel>
                          <FormGroup>

                            {balanceSheetAtom &&
                              balanceSheetAtom.balanceCategoryDtos.filter(category => ['Fixed Assets', 'Current Assets'].includes(category.name) && category.totalField === false).map((category) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={[...ablAssetsChosen].includes(category.id)} onChange={handleChangeAblFacility}
                                                              name={category.overrideName} id={category.id + ""} />
                                                  }
                                                  label={category.overrideName}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {assumptionFormatValue === "PIK_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Debts To include in PIK Interest
                            calculation</FormLabel>
                          <FormGroup>

                            {debts &&
                              debts.filter(debtFormat => debtFormat.debtDto.assumption !== "PIK_DEBT").map((debt) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={debt.debtDto.checked} onChange={handleChangeDebt}
                                                              name={debt.debtDto.name} id={debt.debtDto.id + ""} />
                                                  }
                                                  label={debt.debtDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "PIK_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">PIK on PIK?</FormLabel>
                          <FormGroup>



                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="assumptionFormat"
                              value={pikOnPik}
                              onChange={handleChangePikOnPik}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }



                    </Grid>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddDebt;
