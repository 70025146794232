import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  AdjustOutlined,
  Looks3Outlined,
  Looks4Outlined,
  Looks5Outlined,
  LooksOneOutlined,
  LooksTwoOutlined,
} from "@mui/icons-material";

export default function YearNav(props) {
  const [alignment, setAlignment] = React.useState("left");
  const { productName } = props;

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    if (newAlignment != null && newAlignment.includes("table")) {
      const element = document.getElementById(newAlignment);
      element.scroll(-200000,0)
    } else {
      myFunction(newAlignment);
    }

  };

  function myFunction(productNameAndYearId) {
    if (productNameAndYearId != null) {
      const element = document.getElementById(productNameAndYearId);
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value={productName + "-table"} aria-label="left aligned">
        <AdjustOutlined />
      </ToggleButton>
      <ToggleButton value={productName + "-year0-10"} aria-label="centered">
        <LooksOneOutlined />
      </ToggleButton>
      <ToggleButton value={productName + "-year1-22"} aria-label="centered">
        <LooksTwoOutlined />
      </ToggleButton>
      <ToggleButton value={productName + "-year2-34"} aria-label="centered">
        <Looks3Outlined />
      </ToggleButton>
      <ToggleButton
        value={productName + "-year3-46"}
        aria-label="right aligned"
      >
        <Looks4Outlined />
      </ToggleButton>
      <ToggleButton value={productName + "-year4-58"} aria-label="justified">
        <Looks5Outlined />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
