import React, { useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, Grid,
  InputLabel, MenuItem, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableRow
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { refreshVatPackage, refreshVatPackageAPIcall, vatPackageState } from "../../../SharedState/VatPackageState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { vatState } from "../../../SharedState/VatState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { pnlState } from "../../../SharedState/PnLState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";


function ProductVatOutputAllocation(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { project } = useProject();

  // eslint-disable-next-line no-unused-vars
  const [currentVatRate, setCurrentVatRate] = React.useState();
  const { products, vatRates, calcCurrentLiabilitiesWrapper} = props;

  const setVatPackageAtom = useSetRecoilState(vatPackageState);

  const setProductsAtom = useSetRecoilState(revenueAndCostState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const currentAssetAtom = useRecoilValue(currentAssetsState);
  const vatRateAtom = useRecoilValue(vatState);

  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);

  const handleChange = (event) => {
    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let productId = parseInt(valAndIdArr[1]);

    const product = products.find(product => product.productDto.id === productId);

    // update the project vat output rate
    product.productDto.vatOutputRate = value
    // perform a shallow put request to update the vat output rate
    axios
      .put("revenuecos/productShallow",
        product.productDto
      )
      .then(async function(response) {

        await axios.get("revenuecos/" + project.id).then(function(response) {
          let products_ = response.data;
          setProductsAtom(structuredClone(response.data));

          //what about the vatPackage, shouldn't we recalculate that, since we won't have this new product in there?
          let vatPackage = refreshVatPackage(products_, structuredClone(overheadsAtom), structuredClone(vatRateAtom), currentAssetAtom);
          setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        });

        //global recalculation required
        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);

        let vatPackage = await refreshVatPackageAPIcall();
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh
        setCurrentVatRate(value + "-" + productId)
        calcCurrentLiabilitiesWrapper();
        //recalculateCurrentLiabilities();
        //manualRecalculation();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <React.Fragment>
      <DialogContentText>
        Product VAT allocation
      </DialogContentText>


      <TableContainer component={Paper}>
        {products &&
          <Table sx={{ m: 1, width: 300 }} aria-label="simple table" size="small" dense="true">
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.productDto.name + "-expense"}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {product.productDto.name}
                  </TableCell>
                  <TableCell align="right">

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.productDto.vatOutputRate + "-" + product.productDto.id}
                        label="Vat Rate"
                        onChange={handleChange}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + product.productDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>)
}



function ExpenseVatOutputAllocation(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { project } = useProject();

  // eslint-disable-next-line no-unused-vars
  const [currentVatRate, setCurrentVatRate] = React.useState();
  const { products, expenses, vatRates, calcCurrentLiabilitiesWrapper} = props;
  const setVatPackageAtom = useSetRecoilState(vatPackageState);
  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);

  const [overheadsAtom, setOverheadAtom] = useRecoilState(overheadsState);
  const currentAssetAtom = useRecoilValue(currentAssetsState);
  const vatRateAtom = useRecoilValue(vatState);

  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);

  const handleChangeProducts = (event) => {
    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let productId = parseInt(valAndIdArr[1]);

    const product = products.find(product => product.productDto.id === productId);

    // update the project vat output rate
    product.productDto.vatInputRate = value
    // perform a shallow put request to update the vat output rate
    axios
      .put("revenuecos/productShallow",
        product.productDto
      )
      .then(async function(response) {

        //get the products
        await axios.get("revenuecos/" + project.id).then(function(response) {
          let products_ = response.data;
          setProductsAtom(structuredClone(response.data));

          //what about the vatPackage, shouldn't we recalculate that, since we won't have this new product in there?
          let vatPackage = refreshVatPackage(products_, structuredClone(overheadsAtom), structuredClone(vatRateAtom), currentAssetAtom);
          setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        });

        //global recalculation required
        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);

        setCurrentVatRate(value + "-" + productId) // this is a hack, the drop downs weren't refreshing withint setting something
        //recalculateCurrentLiabilities();
        calcCurrentLiabilitiesWrapper();
        //manualRecalculation();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const handleChangeExpense = (event) => {
    let valAndIdArr = event.target.value.split("-");

    // get the vatRate id - done
    // get the product object
    let value = parseInt(valAndIdArr[0]);
    let expenseId = parseInt(valAndIdArr[1]);

    const expense = expenses.find(expense => expense.overheadDto.id === expenseId);

    // update the project vat output rate
    expense.overheadDto.vatInputRate = value
    // perform a shallow put request to update the vat output rate
    axios
      .put("overheads/overheadShallow",
        expense.overheadDto
      )
      .then(async function(response) {

        await axios.get("overheads/" + project.id).then(function(response) {
          let overheads_ = response.data;
          setOverheadAtom(structuredClone(response.data));

          //what about the vatPackage, shouldn't we recalculate that, since we won't have this new overhead in there?
          let vatPackage = refreshVatPackage(structuredClone(productsAtom), overheads_, structuredClone(vatRateAtom), currentAssetAtom);
          setVatPackageAtom(vatPackage); //basically this just triggers a refresh
        });

        //global recalculation required
        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);

        setCurrentVatRate(value + "-" + expenseId)
        calcCurrentLiabilitiesWrapper();

        // refreshVatPackageAPIcall().then(function(vatPackage) {
        //
        //   setVatPackageAtom(vatPackage)
        //   setCurrentVatRate(value + "-" + expenseId)
        //   calcCurrentLiabilitiesWrapper();
        //   //manualRecalculation();
        //
        // });

        // let vatPackage = await refreshVatPackageAPIcall();
        // setVatPackageAtom(vatPackage); //basically this just triggers a refresh
        // setCurrentVatRate(value + "-" + expenseId)
        // recalculateCurrentLiabilities();
        // manualRecalculation();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <DialogContentText>
        Expenses VAT allocation
      </DialogContentText>



      <TableContainer component={Paper}>
        {products &&
          <Table aria-label="simple table" size="small" dense="true">
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.productDto.name + "-expense"}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {product.productDto.name}
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.productDto.vatInputRate + "-" + product.productDto.id}
                        label="Vat Rate"
                        onChange={handleChangeProducts}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + product.productDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        {expenses &&
          <Table aria-label="simple table" size="small" dense="true">
            <TableBody>
              {expenses.map((expense) => (
                <TableRow
                  key={expense.overheadDto.name}
                  sx={{ "& td": { border: 0 } }}
                >
                  <TableCell scope="row">
                    {expense.overheadDto.name}
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Vat Rate</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={expense.overheadDto.vatInputRate + "-" + expense.overheadDto.id}
                        label="Vat Rate"
                        onChange={handleChangeExpense}
                      >
                        {vatRates &&
                          vatRates.map((vatRate) => (
                            <MenuItem key={Math.random() * 10000000} value={vatRate.id + "-" + expense.overheadDto.id}>{vatRate.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

    </React.Fragment>)
}

function updateCurrentLiability(values, project, currentliability, loadData, setOpen, setSubmitting, setErrors) {
  try {
    axios
      .put("currentliabilities", {
        name: values.currentLiabilityName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        canDelete: values.canDelete,
        id: currentliability.id,
        directProductCosts: currentliability.directProductCosts
      })
      .then(function(response) {
        //loadData();
        setOpen(false);
      })
      .catch(function(error) {
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}

function EditCurrentLiabilityVAT(props) {
  const { project } = useProject();
  const { currentLiabilityNames, loadData, currentliability, open, setOpen, products, expenses, vatRates, recalculateCurrentLiabilities, manualRecalculation, calcCurrentLiabilitiesWrapper} = props;

  const validationSchema = Yup.object().shape({
    currentLiabilityName: Yup.string()
      .min(5, "Must be at least 5 characters")
      .max(50, "Must be less than 50 characters")
      .required("Required")
      .notOneOf(currentLiabilityNames, "Must have a unique name"),
  });

  const initialValues = {
    currentLiabilityName: currentliability?.name,
    assumptionFormat: currentliability?.assumption,
    balancePositionFlag: currentliability?.position,
    canDelete: currentliability?.canDelete,
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    //todo changing the assumption type should throw a warning saying are you sure you want to do this
    // you will loose your data
    console.log('initialValues.assumptionFormat',initialValues.assumptionFormat)
    console.log('values.assumptionFormat',values.assumptionFormat)


    updateCurrentLiability(values, project, currentliability, loadData, setOpen, setSubmitting, setErrors);
  };



  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"md"}
              >
                <form>
                  <DialogTitle id="form-dialog-title">Edit VAT Liability</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0}}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                        <ProductVatOutputAllocation products={products} vatRates={vatRates} values={values} recalculateCurrentLiabilities={recalculateCurrentLiabilities} manualRecalculation={manualRecalculation} calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}></ProductVatOutputAllocation>
                      </Grid>
                      <Grid item xs>
                        <ExpenseVatOutputAllocation expenses={expenses} products={products} vatRates={vatRates} values={values} recalculateCurrentLiabilities={recalculateCurrentLiabilities} manualRecalculation={manualRecalculation} calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}></ExpenseVatOutputAllocation>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      Close
                    </Button>

                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
    </React.Fragment>
  );;
}

export default EditCurrentLiabilityVAT;
