import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";


import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ControlsBar from "../../SharedComponents/ControlsBar";
import AddCovenant from "./components/AddCovenant";
import { useRecoilValue } from "recoil";
import LoadingBar from "../../SharedComponents/LoadingBar";
import Settings from "../../../../components/Settings";
import getProjectId from "../../SharedComponents/ProjectServices";
import axios from "axios";
import { useAutosave } from "react-autosave";
import { debtsState } from "../../SharedState/DebtsState";
import CovenantInput from "./components/CovenantInput";
import { calculateCovenants } from "./calculations/Calculations";
import { pnlState } from "../../SharedState/PnLState";
import useAuth from "../../../../hooks/useAuth";
import PayForCovenants from "./components/PayForCovenants";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function Covenant() {
  const { user } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [company, setCompany] = useState();
  const [edited] = useState([]);
  const [covenants, setCovenants] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");
  const debtsAtom = useRecoilValue(debtsState);
  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);

  async function loadCovenant(recalculate = false) {

    let project = await getProjectId();

    const config = {
      method: "get",
      url: "covenant/" + project.id
    };

    axios(config)
      .then(function(response) {

        if (recalculate) {
          let covResponse = response.data;
          calculateCovenants(covResponse, debtsAtom, pnlAtom, balanceAtom);
          setCovenants(covResponse);
        } else {
          let covResponse = response.data;
          //calculateCovenants(covResponse, debtsAtom, pnlAtom, balanceAtom);
          setCovenants(covResponse);
          setLoaded(true);
        }

      })
      .catch(function(error) {
        console.log(error);
      });
  }

  // https://reactjs.org/docs/hooks-reference.html#usecallback
  const updateBlog = React.useCallback((covenant) => {

    setSaving(true);

    // filter covenant list to only contain ones that match the editedcovenant of ids
    const covenantToUpdate = covenant
      .filter(covenant => edited.includes(covenant.covenantDto.id));

    if (covenantToUpdate.length === 0) {
      setSaving(false);
      return;
    }

    var config = {
      method: "put",
      url: "covenant/" + covenantToUpdate[0].covenantDto.project,
      data: covenantToUpdate,
    };

    axios(config)
      .then(function (response) {
        setSaving(false);
        edited.length = 0;
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAutosave({ data: covenants, onSave: updateBlog, interval: 2000, saveOnUnmount: true });


  function refreshData(cellData) {

    if (!edited.includes(cellData.covenant)) {
      edited.push(cellData.covenant)
    }

    //todo calculateCovenants
    calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);
  }

  useEffect(() => {
    calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);
  }, [covenants, debtsAtom, pnlAtom, balanceAtom]);

  useEffect(() => {
    loadCovenant();

    if (user != null) {
      axios.get('company/' + user.company).then(function(response) {
        setCompany(response.data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Covenant" />
      <Typography variant="h3" gutterBottom display="inline">
        Covenant
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Covenant
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      {company &&
        company.projectType === 'PNL' &&
          <ControlsBar addAssumptionElement={
            <PayForCovenants/>
          }
          />
      }

      {company &&
        company.projectType !== 'PNL' &&
          <ControlsBar addAssumptionElement={
            <AddCovenant names={[]} loadData={loadCovenant}  debts={structuredClone(debtsAtom)}  ></AddCovenant>
          }
          />
      }

      <LoadingBar loaded={loaded} assumptionName={"Covenants"}/>

      {!!covenants &&
        covenants.map((covenant) => (
          <CovenantInput
            key={covenant.covenantDto.id}
            covenantItem={covenant}
            covenant={covenants}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setCovenants={setCovenants}
            loadData={loadCovenant}
          ></CovenantInput>
        ))}


      <Settings saving={saving} />

    </React.Fragment>
  );
}

export default Covenant;
