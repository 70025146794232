import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import Typography from "@mui/material/Typography";


function PayForProject() {

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>

      <Button
        mr={2}
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2, minWidth : 160 }}
        onClick={() => setOpen(true)}
      >
        Project
        <Add />
      </Button>

        <Dialog
          open={open}
          onClose={() => {setOpen(false);}}
          aria-labelledby="form-dialog-title"
          sx={{minWidth: 400}}
        >
        <DialogTitle id="form-dialog-title">
        <Typography paddingBottom={5}  sx={{
                          fontWeight: 700,
                          fontSize: 30

                        }}>
          Want More Projects?
        </Typography>
        </DialogTitle>

        <DialogContent>


          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={6}>
              Each Powdr license comes with 1 project by default. <strong>but you can purchase additional projects.</strong>
              <br/><br/><br/>Contact <a href = "mailto: nicola@powdr.co.uk">nicola@powdr.co.uk</a> for more information.
            </Grid>
            <Grid item md={6}>
              <img src="/static/img/brands/logo-powdr.png" width={250} alt={"Powdr Logo"}/>
            </Grid>
          </Grid>



        </DialogContent>

          <DialogActions>
            <Button onClick={() => {setOpen(false);}} color="primary">
              Close
            </Button>
          </DialogActions>

        </Dialog>
    </React.Fragment>
  );
}

export default PayForProject;
