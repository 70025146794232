import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton component={Link} to="/disclaimer">
              <ListItemText primary="Disclaimer" />
            </ListItemButton>
            {/*<ListItemButton component="a" href="#">*/}
            {/*  <ListItemText primary="Help Center" />*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton component="a" href="#">*/}
            {/*  <ListItemText primary="Privacy" />*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton component="a" href="#">*/}
            {/*  <ListItemText primary="Terms of Service" />*/}
            {/*</ListItemButton>*/}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <a href="https://powdr-144363163.hubspotpagebuilder.eu/free-demo-payment-options-2" style={{ textDecoration: 'none', color: 'grey' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ color: 'grey', marginRight: 40 }}>
                      {`© ${new Date().getFullYear()} - Powdr`}
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ color: 'grey', marginRight: 40, marginTop: -2 }}>
                      Registered Designs by UK IPO
                    </Typography>
                  }
                />
              </a>
            </ListItemButton>
          </List>
        </Grid>

      </Grid>
    </Wrapper>
  );
}

export default Footer;
