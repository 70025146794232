import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";

export function actualiseOverheads(overheads) {

  let firstForecast = getCurrentProject().firstForecast;

  //actualise each overhead
  _.forEach(overheads, function(overhead, i) {

    _.forEach(overhead.overheadCategoryDtoList, function(overheadCategory, x) {


      // if (overhead.overheadDto.assumption.equals("ANNUAL_GROWTH_RATE") //skip first 12 months if annual growth rate
      //   && firstForecast < 12) {
      //   return
      // }

      if (_.isEmpty(overheadCategory.overheadMonthDtoList)) {
        return;
      }

      overheadCategory.overheadMonthDtoList[firstForecast].cellType =
        overheadCategory.overheadMonthDtoList[firstForecast - 1].cellType;

      overheadCategory.overheadMonthDtoList[firstForecast].financialTypeEnum =
        overheadCategory.overheadMonthDtoList[firstForecast - 1].financialTypeEnum;

      overheadCategory.overheadMonthDtoList[firstForecast].valueOverriden = null;

    });

  });

  return overheads;
}
