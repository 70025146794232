import { createContext, useEffect, useReducer } from "react";

import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";
import { powdrConfig } from "../config";
import useProject from "../hooks/useProject";


axios.defaults.baseURL = powdrConfig.baseUrl;

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const SIGN_IN_VERIFIED = "SIGN_IN_VERIFIED";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerified: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isVerified: action.payload.isVerified,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_IN_VERIFIED:
      return {
        ...state,
        isVerified: true
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const { setProject, removeProject } = useProject();


  useEffect(() => {

    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const isVerified = window.localStorage.getItem("isVerified");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("security/user");

          const user  = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              isVerified: Boolean(isVerified),
              user,
            },
          });

        } else {
          window.localStorage.removeItem("isVerified");
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);





  const signIn = async (email, passwordStr) => {

    console.log('sign in')

    const response = await axios.post("security/authenticate", {
      username: email,
      password: passwordStr,
    });

    const { token, user } = response.data;

    setSession(token, user);

    await axios.get("project").then(function(project) {
      console.log('setting project', project)
      if (project !== undefined) {

        setProject(project.data);
        //sessionStorage.setItem("project", JSON.stringify(project.data));
      }
    })
      .catch(function(error) {
        console.log('cant find project', error);
      });


    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });

  };


  const verifySecurityCode = async (securityCode, userId) => {

    const response = await axios.post("security/validateSecurityCode", {
      authCode: securityCode,
      userId: userId,
    });

    //todo check return response is Boolean.True

    window.localStorage.setItem("isVerified","" + response.data);
    dispatch({
      type: SIGN_IN_VERIFIED,
      payload: {
      },
    });

    return response.data;

  };

  const signOut = async () => {
    console.log('sign out')
    setSession(null);

    window.sessionStorage.removeItem("project");

    removeProject();

    window.localStorage.removeItem("isVerified");
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => {

    axios.post('security/reset',{user: email});

    console.log(email);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        verifySecurityCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
