
import {
  buildPercentageOfTwoCategories,
  buildYearDifferenceCategory, buildYearDifferencePercentageCategory, buildYearPercentageDifferenceCategory,
  buildYearTotalCategory
} from "./Calculations";

export function createAnnualSummaryAnnualMonthlyDirect(product_) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";


  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);

  annualSummary.productCategoryDtoList.push(revenueCategory, directProductCosts, grossProfit, productMargin);


  return annualSummary;
}


export function createGrowthRatesAnnualMonthlyDirect(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";

  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);

  let revenueGrowth = buildYearPercentageDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth = buildYearPercentageDifferenceCategory("Direct product costs", directProductCosts, 0, "#FF0000", "#008000", "↑",  "↓");
  let grossProfitGrowth = buildYearPercentageDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth = buildYearPercentageDifferenceCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(revenueGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}

export function createYearOnYearChangeAnnualMonthlyDirect(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";

  let revenueCategory      = buildYearTotalCategory(product, "Revenue");
  let directProductCosts   = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit          = buildYearTotalCategory(product, "Gross Profit");
  let productMargin         = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);

  let revenueGrowth = buildYearDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth = buildYearDifferenceCategory("Direct product costs", directProductCosts, 0, "↑", "↓");
  let grossProfitGrowth = buildYearDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth = buildYearDifferencePercentageCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(revenueGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}






