import React, { useState } from "react";
import Card from "@mui/material/Card";
import { CardContent, Typography } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import HeadcountDashboardInput from "./HeadcountDashboardInput";
import DashboardKey from "../../Shared/DashboardKey";


export default function DepartmentAnalysis({departments}) {

  const [selectedView, setSelectedView] = useState('0'); // Initialize with the index as a string

  const handleViewChange = (event, newView) => {
    if (newView !== null) { // Prevent deselection
      setSelectedView(newView);
    }
  };

  return (
    <React.Fragment>


      <Card mb={1} sx={{marginBottom: 5}}>
        <CardContent>

          <Typography variant="h6" gutterBottom sx={{paddingBottom: 5}}>
            Departmental Analysis
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>

          <ToggleButtonGroup
            exclusive
            value={selectedView}
            onChange={handleViewChange}
            aria-label="dashboard view"
            sx={{paddingBottom: 5}}
          >
            <ToggleButton value="0" aria-label="Head counts">
              Departmental salaries
            </ToggleButton>
            <ToggleButton value="1" aria-label="growth rates">
              Growth Rates
            </ToggleButton>
            <ToggleButton value="2" aria-label="Percentage of revenue">
              Percentage of revenue
            </ToggleButton>
            <ToggleButton value="3" aria-label="Absolute change cost">
              Absolute change cost
            </ToggleButton>
          </ToggleButtonGroup>
            <DashboardKey /> {/* Right-aligned component */}
          </div>

          <div>
            {departments.map((item, index) => (
              <div key={index} style={{ display: selectedView === String(index) ? 'block' : 'none' }}>
                <HeadcountDashboardInput headcountItem={item} name={"Department Analysis"}/>
              </div>
            ))}

          </div>

        </CardContent>
      </Card>

    </React.Fragment>
  );
}