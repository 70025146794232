import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";


import {
  Drawer as MuiDrawer,
  ListItemButton,
  Typography,
} from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import useProject from "../../hooks/useProject";
import { powdrConfig } from "../../config";
import ProjectNameComponent from "./ProjectNameComponent";


const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;


const Title = styled(Typography)`
  color: whitesmoke;
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  background-color: ${(props) => props.theme.sidebar.header.background};
  text-transform: uppercase;
  display: block;
`;


const Sidebar = ({ itemsPowdrStaff, items, showFooter = true, ...rest  }) => {

  const { project } = useProject();

  useEffect(() => {

    console.log('powdrConfig.testServer', powdrConfig.testServer, powdrConfig.testServer === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">

        {powdrConfig.testServer === 'true' &&
          <img src="/static/img/brands/powdr-test-logo.png" width={150} alt={"Powdr Logo"} />
        }
        {powdrConfig.testServer === 'false' &&
          <img src="/static/img/brands/logo-powdr.png" width={150} alt={"Powdr Logo"}/>
        }

      </Brand>

      <Title variant="subtitle2">PROJECT</Title>
      {!!project && (
        <ProjectNameComponent project={project}></ProjectNameComponent>
      )}

      <SidebarNav items={items} />

      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
