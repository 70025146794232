import React, { useEffect } from "react";
import axios from "axios";

const initialState = {
  project: { projectName: "Powdr", owner: 0, id: 0, startDate: "2022-01-01" },
};

const ProjectContext = React.createContext(initialState);

function ProjectProvider({ children }) {
  const [project, _project] = React.useState(initialState.projectName);

  //todo need to make this asynchonous and use the await method to wait for the project id in the Powdr pages
  async function getProjectAsync() {
    console.log('getProjectAsync')
    const storedProject = sessionStorage.getItem("project");

    if (storedProject && storedProject !== 'undefined') {
      _project(JSON.parse(storedProject));
    } else {
      axios.get("project").then(function(response) {
        if (response !== undefined) {
          _project(response.data);
          sessionStorage.setItem("project", JSON.stringify(project));
        }
      });
    }

    return project;
  }


  function getProject() {

    //console.log('get project called')
    // uncommented this causes an error that the project.id is never populated
    // however leaving it commented causes a exception on the browser
    // const isAuthenticated = localStorage.getItem("isVerified");
    //
    // if (isAuthenticated == null) {
    //   return;
    // }

    const storedProject = sessionStorage.getItem("project");

    if (storedProject && storedProject !== 'undefined') {
      _project(JSON.parse(storedProject));
    } else {
      axios.get("project").then(function(response) {
        if (response !== undefined) {
          _project(response.data);
          sessionStorage.setItem("project", JSON.stringify(project));
        }
      });
    }
    
    return project;
  }

  function refreshProject(refreshPage = false) {
    console.log('refresh project')

    axios.get("project/" + project.id).then(function(response) {
      if (response !== undefined) {

        sessionStorage.setItem("project", JSON.stringify(response.data));

        if (refreshPage) {

          window.location.reload();
        }
      }
    });

    return project;
  }
  
  useEffect(() => {

    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setProject = (project) => {
    sessionStorage.setItem("project", JSON.stringify(project));
    _project(project);
  };

  const removeProject = () => {
    console.log('removeProject')
    _project(null);
  };


  return (
    <ProjectContext.Provider value={{ project, setProject, getProject, getProjectAsync, removeProject, refreshProject }}>
      {children}
    </ProjectContext.Provider>
  );
}

export { ProjectProvider, ProjectContext };
