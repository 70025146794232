import React, { useEffect }  from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';

const steps = ['Sign up',
  'Introduction Meeting',
  'Access Invoice Sent',
  'Access Invoice Paid'];

/**
 * This shows only the steps that are relevant to the customer, so it doesn't show the 'sent' steps for instance
 * that we would show on the powdr admin page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CompanyOnboardingLight(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  let {company} = props;

  useEffect(() => {
    let comp = {};

    if (company.meetingArranged === true) {
      comp['0'] = company.meetingArranged;
    }

    if (company.meetingCompleted === true) {
      comp['1'] = company.meetingCompleted;
    }

    if (company.engagementLetterRequested === true) {
      comp['2'] = company.accessInvoiceSend;
    }

    if (company.engagementLetterSend === true) {
      comp['3'] = company.accessInvoicePaid;
    }

    setCompleted( comp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };


  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };


  const handleStep = (step) => () => {
    setActiveStep(step);
  };



  // const handleReset = () => {
  //
  //   setActiveStep(0);
  //   setCompleted({});
  //
  //   company.meetingArranged = false;
  //   company.meetingCompleted = false;
  //   company.engagementLetterRequested = false;
  //   company.engagementLetterSend = false;
  //   company.engagementLetterSignedAndReceived = false;
  //   company.accessInvoiceSend = false;
  //   company.accessInvoicePaid = false;
  //
  //   axios.put('company', company)
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you are ready to go
            </Typography>
            {/*<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>*/}
            {/*  <Box sx={{ flex: '1 1 auto' }} />*/}
            {/*  <Button onClick={handleReset}>Reset</Button>*/}
            {/*</Box>*/}
          </React.Fragment>
        ) : (
          <React.Fragment>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}