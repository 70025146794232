import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const currentLiabilitiesState = atom({
  key: 'currentLiabilitiesState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        const project = JSON.parse(sessionStorage.getItem("project"));


       handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
         'currentliabilities/' + project.id + '/months',
          'currentLiabilityMonthDtos',
          'currentLiabilityCategoryDtos',
         'currentLiabilityDto');

      });
    },
  ]
});
