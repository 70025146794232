
import {
  buildDivisionOfTwoCategories, buildPercentageOfTwoCategories,
  buildYearDifferenceCategory, buildYearDifferencePercentageCategory, buildYearPercentageDifferenceCategory,
  buildYearTotalCategory, buildYearTotalCategoryAverage,
} from "./Calculations";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";

function determineNumberFTEName(product) {

  let name = product.productCategoryDtoList[0].name;

  if (product.productDto.assumption === "SALES_PER_DAY_REVENUE") {
    name = "Days in the year";
  }

  return name;
}

export function createAnnualSummarySalesPerPersonBuilderSalesPerDay(product_, ) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";
  let salesPerFTSName = determingFTEName(product);
  let averageNumberOfFTEsName = determineNumberFTEName(product);

  let averageNumberOfFTEs       = buildYearAverageOrTotal(product, product.productCategoryDtoList[0].name, averageNumberOfFTEsName);
  let quantitySold              = buildYearTotalCategory(product, "Quantity Sold");
  let salesPerFTE               = buildDivisionOfTwoCategories(salesPerFTSName, quantitySold, averageNumberOfFTEs, 2);
  let revenueCategory           = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold     = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold, 2);
  let directProductCosts        = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold      = buildDivisionOfTwoCategories("Cost Price per unit sold (ex-VAT)", directProductCosts, quantitySold, 2);
  let grossProfit               = buildYearTotalCategory(product, "Gross Profit");
  let productMargin             = buildPercentageOfTwoCategories("Product Margin",  revenueCategory, grossProfit);

  annualSummary.productCategoryDtoList.push(averageNumberOfFTEs, salesPerFTE, quantitySold, salesPricePerUnitSold, revenueCategory, costPricePerUnitSold, directProductCosts, grossProfit, productMargin);

  return annualSummary;
}


export function createGrowthRatesSalesPerPersonBuilderSalesPerDay(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";
  let salesPerFTSName           = determingFTEName(product);
  let averageNumberOfFTEsName   = determineNumberFTEName(product);

  let averageNumberOfFTEs       = buildYearTotalCategoryAverage(product, product.productCategoryDtoList[0].name, averageNumberOfFTEsName);
  let quantitySold              = buildYearTotalCategory(product, "Quantity Sold");
  let salesPerFTE               = buildDivisionOfTwoCategories(salesPerFTSName, quantitySold, averageNumberOfFTEs);
  let revenueCategory           = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold     = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold);
  let directProductCosts        = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold      = buildDivisionOfTwoCategories("Cost Price per unit sold (ex-VAT)", directProductCosts, quantitySold);
  let grossProfit               = buildYearTotalCategory(product, "Gross Profit");
  let productMargin             = buildPercentageOfTwoCategories("Product Margin", grossProfit, revenueCategory);


  let averageNumberOfFTEsGrowth       = buildYearPercentageDifferenceCategory(averageNumberOfFTEsName, averageNumberOfFTEs);
  let salesPerFTEsGrowth              = buildYearPercentageDifferenceCategory(salesPerFTSName, salesPerFTE);
  let quantitySoldFTEsGrowth          = buildYearPercentageDifferenceCategory("Quantity Sold", quantitySold);
  let salesPricePerUnitSoldGrowth     = buildYearPercentageDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold);
  let revenueGrowth                   = buildYearPercentageDifferenceCategory("Revenue", revenueCategory);
  let costPricePerUnitSoldGrowth      = buildYearPercentageDifferenceCategory("Cost Price per unit sold (ex-VAT)", costPricePerUnitSold, 0, "#FF0000", "#008000");
  let directProductCostsGrowth        = buildYearPercentageDifferenceCategory("Direct product costs", directProductCosts, 0, "#FF0000", "#008000");
  let grossProfitGrowth               = buildYearPercentageDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth             = buildYearPercentageDifferenceCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(averageNumberOfFTEsGrowth, salesPerFTEsGrowth, quantitySoldFTEsGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}

function determingFTEName(product) {
  let salesPerFTSName = "Sales Per FTE";
  if (product.productDto.assumption === "HEADS_TO_REVENUE") {
    salesPerFTSName = "Sales Per Head";
  } else if (product.productDto.assumption === "SALES_PER_DAY_REVENUE") {
    salesPerFTSName = "Sales Per Day";
  } else if (product.productDto.assumption === "PRICE_X_QUANTITY_BUILDER") {
    salesPerFTSName = product.productDto.variableA;
  }
  return salesPerFTSName;
}

export function createYearOnYearChangeSalesPerPersonBuilderSalesPerDay(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";
  let salesPerFTSName           = determingFTEName(product);
  let averageNumberOfFTEsName   = determineNumberFTEName(product);

  let averageNumberOfFTEs       = buildYearTotalCategoryAverage(product, product.productCategoryDtoList[0].name, averageNumberOfFTEsName);
  let quantitySold              = buildYearTotalCategory(product, "Quantity Sold");
  let salesPerFTE               = buildDivisionOfTwoCategories(salesPerFTSName, quantitySold, averageNumberOfFTEs);
  let revenueCategory           = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold     = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold);
  let directProductCosts        = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold      = buildDivisionOfTwoCategories("Cost Price per unit sold (ex-VAT)", directProductCosts, quantitySold);
  let grossProfit               = buildYearTotalCategory(product, "Gross Profit");
  let productMargin             = buildPercentageOfTwoCategories("Product Margin", grossProfit, revenueCategory);



  let averageNumberOfFTEsGrowth       = buildYearDifferenceCategory(averageNumberOfFTEsName, averageNumberOfFTEs);
  let salesPerFTEsGrowth              = buildYearDifferenceCategory(salesPerFTSName, salesPerFTE);
  let quantitySoldFTEsGrowth          = buildYearDifferenceCategory("Quantity Sold", quantitySold);
  let salesPricePerUnitSoldGrowth     = buildYearDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold, 2);
  let revenueGrowth                   = buildYearDifferenceCategory("Revenue", revenueCategory);
  let costPricePerUnitSoldGrowth      = buildYearDifferenceCategory("Cost Price per unit sold (ex-VAT)", costPricePerUnitSold, 2, "↑", "↓");
  let directProductCostsGrowth        = buildYearDifferenceCategory("Direct product costs", directProductCosts, 0, "↑", "↓");
  let grossProfitGrowth               = buildYearDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth             = buildYearDifferencePercentageCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(averageNumberOfFTEsGrowth, salesPerFTEsGrowth, quantitySoldFTEsGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}


// Define a JavaScript function called days_of_a_year with parameter year
function days_of_a_year(year)
{
  // Return 366 if the given year is a leap year, otherwise return 365
  return isLeapYear(year) ? 366 : 365;
}

// Define a JavaScript function called isLeapYear with parameter year
function isLeapYear(year) {
  // Return true if the given year is divisible by 400 or divisible by 4 but not divisible by 100
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

function buildSalesPerYear(product, categoryName, displayName) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  let projectStartDate = new Date(getCurrentProject().startDate);
  projectStartDate.setDate(1);

  let yearIndex = 0;
  for (let i=0; i < 72;){

    const daysInYear = days_of_a_year(projectStartDate.getFullYear() + yearIndex + 1); // we add the 1 because projectStartDate is the start of the year, we want the end which is startDate + 12 months

    category.productMonthDtoList.push({
      id: yearIndex,
      value: daysInYear,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    i = i + 12;
    yearIndex++;
  }

  return category;
}

function buildYearAverageOrTotal(product, categoryName, displayName) {

  if (product.productDto.assumption === "SALES_PER_DAY_REVENUE") {
    return buildSalesPerYear(product, categoryName, displayName);
  } else {
    return buildYearTotalCategoryAverage(product, categoryName, displayName);
  }

}