import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const currentAssetsState = atom({
  key: 'currentAssetsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        const project = JSON.parse(sessionStorage.getItem("project"));

        handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
          'currentassets/' + project.id + '/months',
          'currentAssetMonthDtos',
          'currentAssetCategoryDtos',
          'currentAssetDto');

      });
    },
  ]
});
