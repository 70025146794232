import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography, LinearProgress
} from "@mui/material";
import { spacing } from "@mui/system";

import { getZoom, zoomIn, zoomOut } from "../../SharedComponents/utils/Utils";
import axios from "axios";
import { useAutosave } from "react-autosave";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import Settings from "../../../../components/Settings";
import getProjectId from "../../SharedComponents/ProjectServices";
import VatConfigInput from "../SettingsVat/components/VatConfigInput";
import { useSetRecoilState } from "recoil";
import { corporationTaxState } from "../../SharedState/CorporationTaxState";


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CorporationTaxConfig() {

  const [zoomScale, setZoomScale] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [edited] = useState([]);
  const [vats, setVats] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const setCorporationTaxAtom = useSetRecoilState(corporationTaxState);

  async function loadVat() {
    setZoomScale(getZoom)


    let project = await getProjectId();

    var config = {
      method: "get",
      url: "vat/corporationtax/" + project.id,
    };

    axios(config)
      .then(function(response) {
        //list of products
        setVats(response.data);
        setCorporationTaxAtom(structuredClone(response.data))
        setLoaded(true)
      })
      .catch(function(error) {
        console.log(error);
      });

  }

  // https://reactjs.org/docs/hooks-reference.html#usecallback
  const updateBlog = React.useCallback((vat) => {

    setSaving(true);

    // filter vat list to only contain ones that match the editedvat of ids
    const vatToUpdate = vat
      .filter(vat => edited.includes(vat.vatDto.id));

    if (vatToUpdate.length === 0) {
      setSaving(false);
      return;
    }

    var config = {
      method: "put",
      url: "vat/" + vatToUpdate[0].vatDto.project,
      data: vatToUpdate,
    };

    axios(config)
      .then(function (response) {
        setSaving(false);
        edited.length = 0;
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAutosave({ data: vats, onSave: updateBlog, interval: 2000, saveOnUnmount: true });

  function refreshData(cellData) {
    //todo we need to add all the calculation work here
    //is it pure growth
    //is it price x quantity
    //calculate percentage complete
    //then set the products which will trigger a save

    if (!edited.includes(cellData.vat)) {
      edited.push(cellData.vat)
    }
    //
    // calculatePercentageComplete(vats)
    //
    // addValueToRestOfYear(cellData, vats);
    //
    // calculateVat(vats)

    setCorporationTaxAtom(structuredClone(vats))

  }

  useEffect(() => {
    loadVat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    loadVat();

    // calculatePercentageComplete(vats)
    //
    // calculateVat(vats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Corporation Tax Config
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Corporation Tax Config</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
        </Grid>

        <Grid item>
          <ZoomOut fontSize={"large"} onClick={() => {
            zoomOut();
            setZoomScale(getZoom);
          }}/>
          <ZoomIn fontSize={"large"} onClick={() => {
            zoomIn()
            setZoomScale(getZoom);
          }}/>
        </Grid>
      </Grid>

      {!loaded &&  (

        <div>
          <h1>Retrieving Corporation Tax...</h1>
          <LinearProgress my={2} />
        </div>

      )}

      {!!vats &&
        vats.map((vat) => (
          <VatConfigInput
            key={vat.vatDto.id}
            vatItem={vat}
            vat={vats}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setVat={setVats}
            loadData={loadVat}
            pageZoom={zoomScale}
          ></VatConfigInput>
        ))}

      <Settings saving={saving} />


    </React.Fragment>
  );
}

export default CorporationTaxConfig;
