import React  from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function PayForDuplicateProject({open, setOpen}) {

  return (
    <React.Fragment>

        <Dialog
          open={open}
          onClose={() => {setOpen(false);}}
          aria-labelledby="form-dialog-title"
          sx={{minWidth: 400}}
        >
          <DialogTitle id="form-dialog-title">
            <Typography  sx={{
              fontWeight: 700,
              fontSize: 30

            }}>
              Add Duplicates
            </Typography>
          </DialogTitle>

        <DialogContent>

          <Grid container paddingTop={10}>
            <Grid item xs={6} paddingRight={5}>
              Duplicate is an extra feature you can add to your Powdr account. <strong>it allows you to create new scenarios.</strong>
              <br/><br/>
              Duplicate models can help you to see your business under <strong>different conditions</strong>,
              like testing your business’ <strong>resilience</strong> to different economic conditions
              or help you plan the release of a new product
              <br/><br/>Contact <a href = "mailto: nicola@powdr.co.uk">nicola@powdr.co.uk</a> for more information.
            </Grid>
            <Grid item xs={6}>
              <Box
                width={250}
                component="img"
                src="/static/img/payDuplicate.png"
                alt="duplicate feature"
                sx={{ boxShadow: '10px 10px 5px grey' }}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={() => {setOpen(false);}} color="primary">
              Close
            </Button>
          </DialogActions>


        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default PayForDuplicateProject;
