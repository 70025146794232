import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import { ThemeProvider } from "./contexts/ThemeContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { SavingProvider } from "./contexts/SavingContext";
import { PowdrProvider } from "./contexts/PowdrContext";
import { RecoilRoot } from "recoil";


ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <ProjectProvider>
        <SavingProvider>
          <PowdrProvider>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </PowdrProvider>
        </SavingProvider>
      </ProjectProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
