import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const BrowserAlert = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  // Detect non-Chrome browsers
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  return (
    !isChrome ? (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ marginBottom: matches ? 20 : 0 }}
      >
        <Alert icon={<TravelExploreIcon></TravelExploreIcon>} severity="warning" variant="filled">
           Some Powdr features are not supported on this browser.
          For the best experience and optimal performance with our software, we highly recommend using Google Chrome as your web browser.
        </Alert>
      </Snackbar>
    ) : null
  );
};

export default BrowserAlert;
