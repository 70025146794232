import React, { useState, useRef, useEffect } from "react";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Actualise from "./Actualise";
import ActualiseBalance from "./ActualiseBalance";

function ActualiseDialog(props) {
  const { open, setOpen, actualiseType} = props;

  const actualiseRef = useRef();
  const actualiseBalanceRef = useRef();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const actualisePnL = async () => {
      if (saving === true) {

        if (actualiseType === "pnl") {
          await actualiseRef.current.actualisePnL();
        }

        if (actualiseType === "balance") {
          await actualiseBalanceRef.current.actualiseBalance();
        }

        setSaving(false);
        setOpen(false);
      }
    };

    actualisePnL();
    // eslint-disable-next-line
  }, [setOpen, saving]);


  return (
    <React.Fragment>

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
              >

                  <DialogTitle id="form-dialog-title">Actualise Next Available Month</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <DialogContentText>
                      Do you wish to turn the next available Forecast month into an Actual
                      <br/>
                      For Example:
                    </DialogContentText>

                    <img src="/static/img/forecast-to-actual.png" width={400} alt={"Powdr Logo"}/>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={saving}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={saving}
                      onClick={() => setSaving(true)}
                    >
                      {saving ? <CircularProgress size={24} /> : 'Actualise'}
                    </Button>
                  </DialogActions>

              </Dialog>

      <Actualise ref={actualiseRef}/>
      <ActualiseBalance ref={actualiseBalanceRef}/>

    </React.Fragment>
  );
}

export default ActualiseDialog;
