import React from "react";
import { loadProductData } from "../components/api/productsApi";
import { loadPnL, loadShallowHeadcounts, loadShallowOverheads } from "../apiPowdr/pnl";

const PowdrContext = React.createContext();

function PowdrProvider({ children }) {
  const [products, _setProducts] = React.useState([]);
  const [pnl, _setPnl] = React.useState();
  const [overheads, _setOverheads] = React.useState([]);
  const [overheadsShallow, _setOverheadsShallow] = React.useState();
  const [headcountsShallow, _setHeadcountShallow] = React.useState();

  async function getHeadcountsShallowFromContext() {

    if (headcountsShallow === undefined) {
      const headcountsShallow_ = await loadShallowHeadcounts();

      if (headcountsShallow_.data.length > 0) {
        _setHeadcountShallow(headcountsShallow_.data);
        return headcountsShallow_.data;
      } else {
        return [];
      }

    } else {
      return headcountsShallow;
    }
  }

  async function refreshHeadcountsShallowFromContext() {

    _setHeadcountShallow(undefined)
    return await getHeadcountsShallowFromContext();
  }

  async function getOverheadsShallowFromContext() {

    if (overheadsShallow === undefined) {
      const overheadsShallow_ = await loadShallowOverheads();

      if (overheadsShallow_.data.length > 0) {
        _setOverheadsShallow(overheadsShallow_.data);
        return overheadsShallow_.data;
      } else {
        return [];
      }

    } else {
      return overheadsShallow;
    }
  }


  async function refreshOverheadsShallowFromContext() {

    _setOverheadsShallow(undefined)
    return await getOverheadsShallowFromContext();
  }



  async function getPnlFromContext() {

    if (pnl === undefined) {
      const pnl_ = await loadPnL();

      if (pnl_.data.length > 0) {
        _setPnl(pnl_.data[0]);
        return pnl_.data[0];
      } else {
        return {};
      }

    } else {
      return pnl;
    }
  }



  async function refreshPnlFromContext() {

    _setPnl(undefined)
    return await getPnlFromContext();
  }


  function setPnl(pnl_) {
    _setPnl(pnl_);
  }


  function getProducts() {
    return products;
  }

  async function refreshProducts() {
     await loadProductData(setProducts)
  }

  function setProducts(products) {
    console.log('setting products')
    _setProducts(products);
  }

  function getOverheads() {
    return overheads;
  }

  function setOverheads(overheads) {
    _setOverheads(overheads);
  }

  return (
    <PowdrContext.Provider value={{
      products,
      overheads,
      pnl,
      setPnl,
      setProducts,
      setOverheads,
      getOverheads,
      getProducts,
      refreshProducts,
      getPnlFromContext,
      refreshPnlFromContext,
      getOverheadsShallowFromContext,
      refreshOverheadsShallowFromContext,
      getHeadcountsShallowFromContext,
      refreshHeadcountsShallowFromContext,
      }}>
      {children}
    </PowdrContext.Provider>
  );
}

export { PowdrProvider, PowdrContext };
