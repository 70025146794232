import React, { useEffect, useState, useRef }  from "react";
import PageHeader from "../../SharedComponents/PageHeader";
import Divider from "@mui/material/Divider";
import _ from "lodash";
import { calculateCashflow } from "../../CashflowGroup/CashFlow/calculations/Calculations";
import { useRecoilValue } from "recoil";
import { cashFlowState } from "../../SharedState/CashflowState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { pnlState } from "../../SharedState/PnLState";
import CashflowMovement from "./components/CashflowMovement";
import { totalUpItems } from "../RevenueDashboard/calculations/Calculations";
import EbitdaVsNetCashflowChart from "./components/EbitdaVsNetCashflowChart";
import WorkingCapitalVsDebtChart from "./components/WorkingCapitalVsDebtChart";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";


export default function CashflowDashboard() {

  const updatePowdrModelGlobalRef = useRef();

  const cashflowAtom = useRecoilValue(cashFlowState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const [currentAssets, setCurrentAssets] = useState();
  const [currentLiabilities, setCurrentLiabilities] = useState();
  const [debts, setDebts] = useState();

  useEffect(() => {

    let cashflow_ = calculateCashflow(
      structuredClone(cashflowAtom),
      structuredClone(balanceAtom), pnlAtom);

    setCurrentAssets(getCategory("Movement In Other Current Assets", cashflow_));
    setCurrentLiabilities(getCategory("Movement In Other Current Liabilities", cashflow_));
    setDebts(getCategory("Net Debt Received/Paid", cashflow_));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowAtom]);


  useEffect(() => {

    updatePowdrModelBalance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
  }

  function getCategory(movementInOtherCurrentAssets, cashflow_) {
    if (_.isNil(cashflow_) || _.isEmpty(cashflow_)) {
      return undefined;
    }

    let category = cashflow_.cashflowCategoryDtos.find(category => category.name === movementInOtherCurrentAssets);

    _.forEach(category.movementDetails.categories, function (row) {

      row.cashflowMonthDtos = buildYearTotalCategory(row)

    });

    return category;
  }


  function buildYearTotalCategory(category, resolution = 0) {

    let cashflowMonthDtos = [];


    let yearIndex = 0;
    for (let i=0; i < 72;){

      let yearTotal = totalUpItems(category.cashflowMonthDtos, i);

      cashflowMonthDtos.push({
        id: yearIndex,
        value:  yearTotal ,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: resolution,
      })
      i = i + 12;
      yearIndex++;

    }

    return cashflowMonthDtos;
  }



  return (
    <React.Fragment>

      <PageHeader pageName={"Cashflow Dashboard"} parentName={"Dashboard"}/>

      <Divider my={6} sx={{marginBottom: 5}}/>

        <EbitdaVsNetCashflowChart cashflowData={structuredClone(cashflowAtom)}></EbitdaVsNetCashflowChart>

        <WorkingCapitalVsDebtChart cashflowData={structuredClone(cashflowAtom)}></WorkingCapitalVsDebtChart>

        <CashflowMovement row={currentAssets}></CashflowMovement>

        <CashflowMovement row={currentLiabilities}></CashflowMovement>

        <CashflowMovement row={debts}></CashflowMovement>

        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

    </React.Fragment>
  );

}