import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil";

import { actualiseOverheads } from "./calculations/overheads";
import { actualiseProducts } from "./calculations/products";
import { actualiseHeadcounts } from "./calculations/headcounts";
import { actualisePnl } from "./calculations/pnl";
import { getCurrentProject, setCurrentProject } from "../SharedComponents/ProjectServices";
import axios from "axios";
import { pnlState } from "../SharedState/PnLState";
import { revenueAndCostState } from "../SharedState/RevenueAndCostState";
import { overheadsState } from "../SharedState/OverheadsState";
import { headcountsState } from "../SharedState/HeadcountsState";
import { projectState } from "../SharedState/ProjectState";

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Actualise = forwardRef((props, ref) => {

  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);
  const [overheadAtom, setOverheadAtom] = useRecoilState(overheadsState);
  const [headcountAtom, setHeadcountAtom] = useRecoilState(headcountsState);
  const setProjectAtom = useSetRecoilState(projectState);

  async function updateFirstForecastValue() {
    let project = getCurrentProject();
    project.firstForecast = project.firstForecast + 1;
    setCurrentProject(project);
    //do a put to the database
    await axios.put("/project", project).then(r => console.log("project first forecast updated"));
    setProjectAtom(project);
  }

  useImperativeHandle(ref, () => ({

    async actualisePnL() {

      const tick = performance.now();

      //call take snapshot
      await axios.get('/actualise/' + getCurrentProject().id).then(function(response) {
        console.log('snapshot created! Now we can actualise this project');
      });

      let pnlActualised = actualisePnl(structuredClone(pnlAtom));
      setPnlAtom(pnlActualised);

      let overheadsActualised = actualiseOverheads(structuredClone(overheadAtom))
      setOverheadAtom(overheadsActualised);

      let productsActualised = actualiseProducts(structuredClone(productsAtom))
      setProductsAtom(productsActualised);

      let headcountsActualised = actualiseHeadcounts(structuredClone(headcountAtom));
      setHeadcountAtom(headcountsActualised);

      //increment the first forecast by 1
      await updateFirstForecastValue();

      const tock = performance.now();

      console.log(`Main full stack thread took ${tock - tick} ms`);

    }

  }));

  return (
    <div></div>
  );
});

export default Actualise;
