import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";


export function actualiseEquity(equities) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(equities, function(equity, i) {

    _.forEach(equity.equityCategoryDtos, function(category, x) {

      if (_.isEmpty(category.equityMonthDtos)) {
        return;
      }

      category.equityMonthDtos[firstBalanceForecast].cellType =
        category.equityMonthDtos[firstBalanceForecast - 1].cellType;

      category.equityMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.equityMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.equityMonthDtos[firstBalanceForecast].valueOverriden = null;

    });

  });

  return equities;
}