import _ from "lodash";
import {
  calculatePercentageGrowth,
} from "../../RevenueDashboard/calculations/Calculations";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";

export function calculateOverheadsAboveAnalysis(pnl, totalCategoryName, overheadsToIgnore) {

  let overheads = pnl.pnLCategoryDtoList.filter(category => (category.name === "Overheads"
    && category.position === "ABOVE"
    && !_.isNil(category.overheadId)) || category.overrideName === totalCategoryName || category.overrideName === "Direct product costs");

  let overheadsAboveFiltered = overheads.filter(overhead => !overheadsToIgnore.includes(overhead.overrideName));

  return calculateOverheadsAnalysis(pnl, overheadsAboveFiltered);
}

export function calculateOverheadsBelowAnalysis(pnl, totalCategoryName, overheadsToIgnore) {


  let overheads = pnl.pnLCategoryDtoList.filter(category => (category.name === "Overheads"
    && category.position === "BELOW"
    && !_.isNil(category.overheadId)) || category.overrideName === totalCategoryName);

  let overheadsAboveFiltered = overheads.filter(overhead => !overheadsToIgnore.includes(overhead.overrideName));

  return calculateOverheadsAnalysis(pnl, overheadsAboveFiltered);
}


function calculateOverheadsAnalysis(pnl, overheads) {

  let pnlOverheadGroups = [];


  let revenueCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === "Revenue from products");


    //Get all the overheads that are above the above gross profit

    //Percentage of revenue - calculations
    let percentageOfRevenue = createPercentageOfRevenue(overheads, revenueCategory);

    //Growth rate           - calculations
    let growthRates         = createGrowthRates(overheads);

    //Absolute change       - calculations
    let yearOnYearChange    = createYearOnYearChange(overheads)


  pnlOverheadGroups.push(percentageOfRevenue, growthRates, yearOnYearChange);

  return pnlOverheadGroups;
}


export function calculateOverheadsAnalysisChart(pnl, overheadsToIgnore) {

  //Overheads - Direct Costs
  //And the 'Total direct costs' row
  let overheadsAbove = pnl.pnLCategoryDtoList.filter(category => (category.name === "Overheads"
    && !_.isNil(category.overheadId))  || category.overrideName === "Direct product costs" );

  let revenueCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === "Revenue from products");


  //Get all the overheads that are above the above gross profit
  //remove any default groups that are empty
  let overheadsAboveFiltered = overheadsAbove.filter(overhead => !overheadsToIgnore.includes(overhead.overrideName));

  //Percentage of revenue - calculations
  return createPercentageOfRevenue(overheadsAboveFiltered, revenueCategory);
}




function createPercentageOfRevenue(overheadsAbove, revenueCategory) {

  //calculate the percentage of revenue that a overhead represents
  //build a new growth rates
  let summary = {
    overheadCategoryDtoList: []
  };

  summary.analysisAssumptionDesc = "Percentage of revenue";

  //loop over each overhead
  for (let i=0; i < overheadsAbove.length; i++) {

    let yearTotalCategory = buildYearTotalCategory(overheadsAbove[i], overheadsAbove[i].overrideName);


    let categoryGrowth = buildYearPercentageRevenueCategory(overheadsAbove[i].overrideName, yearTotalCategory, revenueCategory);

    summary.overheadCategoryDtoList.push(categoryGrowth);
  }

  return summary;
}

function createGrowthRates(overheadsAbove) {

  //build a new growth rates
  let summary = {
    overheadCategoryDtoList: []
  };

  summary.analysisAssumptionDesc = "Growth Rates";

  //loop over each overhead
  for (let i=0; i < overheadsAbove.length; i++) {

    let yearTotalCategory = buildYearTotalCategory(overheadsAbove[i], overheadsAbove[i].overrideName);

    let categoryGrowth = buildYearPercentageDifferenceCategory(overheadsAbove[i].overrideName, yearTotalCategory,  "#FF0000", "#008000");

    summary.overheadCategoryDtoList.push(categoryGrowth);
  }

  return summary;
}

function createYearOnYearChange(overheadsAbove) {

  //build a new growth rates
  let summary = {
    overheadCategoryDtoList: []
  };

  summary.analysisAssumptionDesc = "Year on year change";

  //loop over each overhead
  for (let i=0; i < overheadsAbove.length; i++) {

    let yearTotalCategory = buildYearTotalCategory(overheadsAbove[i], overheadsAbove[i].overrideName);

    let categoryYearChange = buildYearDifferenceCategory(overheadsAbove[i].overrideName, yearTotalCategory, "#FF0000", "#008000");

    summary.overheadCategoryDtoList.push(categoryYearChange);
  }

  return summary;

}


function buildYearDifferenceCategory(name, categorySource, favourable= "#008000", adverse = "#FF0000") {

  let category = {
    id : categorySource.id,
    name: name,
    pnLMonthDtoList : [],
    totalField : ["Total costs of sales", "Total Overheads"].includes(name)
  }

  category.pnLMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "INTEGER"
  })

  for (let i=1; i < categorySource.pnLMonthDtoList.length; i++) {

    let yearTotal = categorySource.pnLMonthDtoList[i].value - categorySource.pnLMonthDtoList[i - 1].value;

    let val = isNaN(yearTotal) ? 0 : yearTotal;

    category.pnLMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "INTEGER",
      textColour: val < 0 ? favourable : adverse,
      showMovementArrow: true,
      positiveMovementSymbol : "↓",
      negativeMovementSymbol : "↑",
    })
  }

  return category;
}


function buildYearTotalCategory(overheadCategory, categoryName, displayName=categoryName) {

  let category = {
    id : overheadCategory.id,
    name: displayName,
    pnLMonthDtoList : []
  }

  //console.log(product.productDto.name)

  let yearIndex = 0;
  for (let i=0; i < 6; i++){


    category.pnLMonthDtoList.push({
      id: yearIndex,
      value:  overheadCategory.pnLMonthDtoList[i].value,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
      textColour: overheadCategory.pnLMonthDtoList[i].value >= 0 ? "#008000" : "#FF0000",
      showMovementArrow: true,
    })

    yearIndex++;

  }

  return category;
}


function buildYearPercentageDifferenceCategory(name, categorySource, favourable= "#008000", adverse = "#FF0000") {

  let category = {
    id : categorySource.id,
    name: name,
    pnLMonthDtoList : [],
    totalField : ["Total costs of sales", "Total Overheads"].includes(name)
  }

  category.pnLMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "PERCENTAGE"
  })

  for (let i=1; i < 6; i++) {

    let yearTotal = calculatePercentageGrowth(categorySource.pnLMonthDtoList[i - 1].value, categorySource.pnLMonthDtoList[i].value);

    let val = isNaN(yearTotal) ? 0 : yearTotal;

    category.pnLMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      textColour: val > 0 ? favourable : adverse,
      showMovementArrow: true,
    })
  }

  return category;
}


function buildYearPercentageRevenueCategory(name, categorySource, revenueCategory) {

  let category = {
    id : categorySource.id,
    name: name,
    pnLMonthDtoList : [],
    totalField : ["Total costs of sales", "Total Overheads"].includes(name)
  }

  category.pnLMonthDtoList.push({
    id: 0,
    value: percentage(categorySource.pnLMonthDtoList[0].value, revenueCategory.pnLMonthDtoList[0].value),
    month: 0,
    monthIndex: 0,
    cellType: "CALCULATED",
    valueFormat: "PERCENTAGE",
  })

  for (let i=1; i < 6; i++) {

    let yearTotal = percentage(categorySource.pnLMonthDtoList[i].value, revenueCategory.pnLMonthDtoList[i].value);

    let val = isNaN(yearTotal) ? 0 : yearTotal;

    category.pnLMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
    })
  }

  return category;
}