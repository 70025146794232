import { atom } from 'recoil';

export const drawerState = atom({
  key: 'drawerState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const drawerContentState = atom({
  key: 'drawerContentState', // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});