import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axiosInstance from "./axios";
import axios from "axios";

const isValidToken = (accessToken) => {

  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  

  return decoded.exp > currentTime;
};


// function refreshToken() {
//
//   //console.log('refreshToken')
//
//   var accessToken = localStorage.getItem("accessToken", accessToken);
//
//   if (!isValidToken){
//     var config = {
//       method: "get",
//       url: "security/refresh-token",
//     };
//
//     axios(config)
//       .then(function (response) {
//         //list of products
//         const { token } = response.data
//
//         setSession(token);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }
//
// }

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken, user) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    if (user !== undefined) {
      localStorage.setItem("lastUser", JSON.stringify(user));
    }
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    axiosInstance.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401) {
        window.location = '/auth/sign-in';
      }
    });

    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401) {
        window.location = '/auth/sign-in';
      }
    });


    // Add a request interceptor
    // axios.interceptors.request.use(function (config) {
    //   // Do something before request is sent
    //
    //   //todo if request is 401 then redirect to login screen
    //   //refreshToken();
    //
    //   return config;
    // }, function (error) {
    //   // Do something with request error
    //   return Promise.reject(error);
    // });
    

  } else {
    localStorage.removeItem("accessToken");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { verify, sign, isValidToken, setSession };
