import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditOverhead from "./EditOverhead";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { Skeleton } from "@mui/material";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { refreshVatPackage, vatPackageState } from "../../../SharedState/VatPackageState";
import { currentLiabilitiesState } from "../../../SharedState/CurrentLiabilitiesState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { vatState } from "../../../SharedState/VatState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { pnlState } from "../../../SharedState/PnLState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('PERCENTAGE_OF_REVENUE', 'Expenses/debits require a negative input assumption');
helpTitles.set('DIRECT_INPUT', 'Expenses/debits require a negative input value');



function OverheadInput(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { key } = useProject();

  const { project } = useProject();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const setCurrentLiabilitiesAtom = useSetRecoilState(currentLiabilitiesState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);
  const setOverheadsAtom = useSetRecoilState(overheadsState);
  const productsAtom = useRecoilValue(revenueAndCostState);
  const setPnlAtom = useSetRecoilState(pnlState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    overheadItem,
    overheads,
    refreshOverheadData,
    setEditData,
    editData,
    setOverheads,
    loadOverheadData,
  } = props;

  const deleteOverhead = (handleClose) => {
    var config = {
      method: "delete",
      url: "overheads",
      data: overheadItem.overheadDto,
    };

    axios(config)
      .then(async function(response) {

        let overheads_ = response.data;
        setOverheadsAtom(structuredClone(overheads_));

        let vatPackage = refreshVatPackage(structuredClone(productsAtom), structuredClone(overheads_), structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage);

        await axios.get("pnl/" + project.id).then(function(response) {
          setPnlAtom(response.data);
        });

        await axios.get("currentassets/" + project.id).then(function(response) {
          setCurrentAssetsAtom(response.data);
        });

        await axios.get("currentliabilities/" + project.id).then(function(response) {
          setCurrentLiabilitiesAtom(response.data);
        });

        // deduct the count from the notifications
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function editThisOverhead() {
    setEditData(overheadItem.overheadDto.id);
  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={overheadItem.overheadDto}
          categories={overheadItem.overheadCategoryDtoList}
          deleteMethod={deleteOverhead}
          refreshDataMethod={loadOverheadData}
          editAssumptionComponent={<EditOverhead
                                                 overhead={overheadItem.overheadDto}
                                                 open={openEdit}
                                                 setOpen={setOpenEdit}>
          </EditOverhead>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-Overhead-" + overheadItem.overheadDto.name + "-" + new Date().toDateString(),
            sheet : "Product",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'overheads/overheadShallow'}
          type={"OVERHEAD"}
        ></PowdrNavBar>


        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={overheadItem.overheadDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead onClick={editThisOverhead}>
                <TableRow
                  key={overheadItem.overheadDto.name + "-ROW"}
                  id={overheadItem.overheadDto.name + "-ROW"}
                >
                  <TableCell
                    key={overheadItem.overheadDto.name + "-BASE"}
                    id={overheadItem.overheadDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{overheadItem.overheadDto.name}</h2>
                    <AssumptionLabel label={overheadItem.overheadDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={overheadItem.overheadDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody onClick={editThisOverhead}>
                {overheadItem.overheadCategoryDtoList.map((row) => (
                  <TableRow key={row.id} id={row.id}>

                    <RowTitle name={row.overrideName || row.name} alt={helpTitles.get(overheadItem.overheadDto.assumption)}></RowTitle>

                    {row.overheadMonthDtoList.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={overheadItem.overheadDto.id}
                        editTableEnabled={editData}
                        items={overheads}
                        refreshItemData={refreshOverheadData}
                        setItems={setOverheads}
                        canOverride={row.allowValueOverride}
                        owner={overheadItem.overheadDto}
                        assumption={overheadItem}
                        categoryName={row.name}
                        parentName={overheadItem.overheadDto.name}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}

export default OverheadInput;
