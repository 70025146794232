import React from "react";

import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle
} from "@mui/material";

export default function ValidateProjectModal(props) {
    const { open, setOpen, validationData } = props;

    const handleClose = () => {

      setOpen(false)
    };

    return (
      <div>
        <Dialog
          open={open}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpen(false)
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Project Validation
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">


                This project is {validationData && validationData.valid && <strong>Valid</strong>}
                {validationData && !validationData.valid && <strong>Invalid</strong>}

              <br/>    <br/>
            </DialogContentText>



            {validationData && validationData.validationErrors && validationData.validationErrors.map((row) => (
              <DialogContentText id="alert-dialog-des4cription">
                <strong>{row}</strong>
              </DialogContentText>
            ))}


          </DialogContent>


          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

