import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUpComplete() {
  return (
    <React.Fragment>

      <Wrapper>
        <Helmet title="Sign Up Complete" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Sign Up Complete!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          A Powdr representative will be in contact very shortly to arrange a meeting and tour of Powdr.
          <br/>
          Keep an eye on your junk mail, you should now have an email from us which we need you to respond to.
        </Typography>
        <Button
          component={Link}
          to="/"
          fullWidth
          color="primary"
        >

          OK
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUpComplete;
