import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { powdrConfig } from "../../config";

const twoFactor = JSON.parse(powdrConfig.twoFactor);

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, isVerified } = useAuth();

  // console.log('isAuthenticated', isAuthenticated);
  // console.log('isInitialized', isInitialized);
  // console.log('isVerified', isVerified);

  // if (getCurrentProject() === null) {
  //   return <Navigate to="/" />;
  // }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }


  if (!isVerified
    && isInitialized
    && isAuthenticated
    && twoFactor) {
    return <Navigate to="/auth/verify-user" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
