import React, { useEffect, useRef, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import PowdrTableHeader from "../../../SharedComponents/PowdrTableHeader";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import { generateXAnnualAxisHeadersAnnual } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Skeleton } from "@mui/material";

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

export default function CashflowMovement({ row  = undefined }) {
  const tableRef = useRef(null);
  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
  }, []);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show

      if (row !== undefined) {
        setIsLoading(false);
      }

  }, [row]);

  return (
    <React.Fragment key={"cashflow"}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );

  function renderHeavyContent() {
    return (
      <React.Fragment>
        <Card
          mb={6}
          sx={{
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 5,
            marginBottom: 5,
          }}
        >
          <PowdrNavBar
            item={row}
            categories={row.movementDetails.categories}
            showExportToExcel={true}
            showProgressBar={false}
            showEdit={false}
            showDelete={false}
            showNavigation={false}
            isAnnual={true}
            exportToExcel={{
              fileName: "Powdr-Cashflow-" + row.name + "-" + new Date().toDateString(),
              sheet: "Debt",
              currentTableRef: tableRef.current,
            }}
          />

          <CardContent sx={{ paddingTop: 0 }}>
            <Table size="small" aria-label="purchases" ref={tableRef}>
              <PowdrTableHeader
                name={row.name}
                XaxisHeaders={XaxisHeaders}
                typeColour={"info"}
              />
              <TableBody>
                {row.movementDetails &&
                  row.movementDetails.categories.map((movement) => (
                    <TableRow
                      key={movement.id}
                      id={movement.id}
                      sx={{
                        height: 50,
                        borderStyle: movement.totalField === true && ["Movement In Other Current Liabilities", "Movement In Other Current Assets", "Net Debt Received/Paid"].includes(movement.name) ? "double" : "none",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                        style={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#F7F9FC",
                          zIndex: 10,
                        }}
                      >
                        {movement.name}
                      </TableCell>
                      {movement.cashflowMonthDtos.map((month) => (
                        <PowdrCell
                          key={month.id + "-CELL"}
                          cellData={month}
                          ownerId={movement.id}
                        />
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}
