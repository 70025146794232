import _ from "lodash";

export function calculatePercentageComplete(dataSetDtoList, itemName, itemCategoryListName, itemMonthName) {
  try {
    _.forEach(dataSetDtoList, function(dataSetDto) {
        dataSetDto[itemName + "Dto"].PercentageComplete = 0;
        var totalNumberOfEntries = 0;
        var percentageComplete = 0

        _.forEach(
          dataSetDto[itemCategoryListName],
          function(categoryDto) {

            _.forEach(
              categoryDto[itemMonthName],
              function(monthDto) {


                if (monthDto.cellType === "CALCULATED") {
                  percentageComplete++;
                }

                if (monthDto.touched === true) {
                  percentageComplete++;
                }

                if (monthDto.cellType === "DISABLED") {
                  percentageComplete++;
                }

                totalNumberOfEntries++;

              }
            );

          }
        );
        dataSetDto[itemName + "Dto"].percentageComplete = ((percentageComplete / totalNumberOfEntries) * 100)
      }
    );
  } catch (e) {
    console.log(e)
  }

}