import _ from "lodash";

import { getCurrentProject } from "../../../SharedComponents/ProjectServices";

import { createDecimal, parseNumberOrZero } from "../../../SharedComponents/utils/NumbersUtils";
import { checkAllValuesZero } from "../../../SharedComponents/utils/Utils";
const Decimal = require('decimal.js');

function calculateTotals(balance, totalToCalculate, type) {

  const balanceCategories = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.balanceType === type
      && balanceCategory.totalField === false);

  const balanceTotal = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === totalToCalculate
      && balanceCategory.totalField === true);

  // reset the values for the totals to 0 before calculating them.
  balanceTotal[0].balanceMonthDtos.forEach(balanceMonthDto => {
    balanceMonthDto.value = new Decimal(0);
  });

  balanceCategories.forEach(balanceCategory => {
    balanceCategory.balanceMonthDtos.forEach((balanceMonthDto, i) => {
      // Use Decimal.js for summing up the numbers
      balanceTotal[0].balanceMonthDtos[i].value = Decimal.add(createDecimal(balanceTotal[0].balanceMonthDtos[i].value), createDecimal(balanceMonthDto.value));
    });
  });

  // Format the number, removing any leading zeros.
  balanceTotal[0].balanceMonthDtos.forEach(balanceMonthDto => {


    balanceMonthDto.value = parseNumberOrZero(balanceMonthDto.value.toNumber().toString().replace(/^0+/, ''));
  });
}



function calculateTotalsCurrentAssets(balance, totalToCalculate, type) {

  const balanceCategories = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.balanceType === type)
    .filter(balanceCategory => balanceCategory.totalField === false
      || (balanceCategory.name === 'Cash at bank' && balanceCategory.balanceType === 'CURRENT_ASSETS'));

  const balanceTotal = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === totalToCalculate
      && balanceCategory.totalField === true);

  // reset the values for the totals to 0 before calculating them.
  balanceTotal[0].balanceMonthDtos.forEach(balanceMonthDto => {
    balanceMonthDto.value = new Decimal(0);
  });

  balanceCategories.forEach(balanceCategory => {
    balanceCategory.balanceMonthDtos.forEach((balanceMonthDto, i) => {
      // Use Decimal.js for summing up the numbers
      balanceTotal[0].balanceMonthDtos[i].value = Decimal.add(createDecimal(balanceTotal[0].balanceMonthDtos[i].value), createDecimal(balanceMonthDto.value));
    });
  });

  // Convert Decimal objects back to numbers
  balanceTotal[0].balanceMonthDtos.forEach(balanceMonthDto => {
    balanceMonthDto.value = parseNumberOrZero(balanceMonthDto.value);
  });
}




/**
 *
 * P&L reserve is the total cumulative net profit of the company ever. Therefore every new financial year you
 * add Current year P&L Gain/loss from the final period of last year to this number.
 *
 * @param balance
 */
function calculatePandLReserve(balance, pnl) {

  let firstForecast = (getCurrentProject().firstBalanceForecast + 1);

  // don't progress if inputs are not there
  if (pnl === null || pnl === undefined) {
    return
  }

  let pnlNetProfit = pnl.pnLCategoryDtoList.find(category => category.name === 'Net Profit' && category.overrideName === '');

  // go to the last month of the actuals (index 11)
  const currentYearGainLoss = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.name === "Current Year P&L Gain/(Loss)"
      && balanceCategory.totalField === true);

  const pAndLReserve = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.name === "P&L reserve"
      && balanceCategory.totalField === true);

  let firstForecastMonth = pAndLReserve.balanceMonthDtos.find(month => month.financialTypeEnum === 'FORECAST')


  //we take the last years P&L Reserve + Current Year P&L Gain/(Loss) and add them together.
  //so if January is the start month, then we add December to December
  let startOfYears = [13, 25, 37, 49, 61]; //balance has 1 more column (opening)

  startOfYears = modifyArray(startOfYears, firstForecastMonth.monthIndex -1)

  //reset the forecast to match the pnl net profit
  for (let i = firstForecastMonth.monthIndex; i < currentYearGainLoss.balanceMonthDtos.length; ++i) {
    pAndLReserve.balanceMonthDtos[i].value = pnlNetProfit.pnLMonthDtoList[i - 1].value;
  }

  //if the forecast is less than 12 months
  if (firstForecastMonth.monthIndex < 13) {

    for (let i = firstForecastMonth.monthIndex; i < 13; ++i) {
      if (i >= firstForecast) {
        pAndLReserve.balanceMonthDtos[i].value = pAndLReserve.balanceMonthDtos[firstForecastMonth.monthIndex - 1].value;
        //+ currentYearGainLoss.balanceMonthDtos[firstForecastMonth.monthIndex - 1].value;
      }
    }
  }



  // Formula - P and L Reserve - Current Year Gain and Loss
  let valueForRestOfYear = 0;

  for (let i = 13; i < currentYearGainLoss.balanceMonthDtos.length; ++i) {

    if (startOfYears.includes(i)) { //if we are the start of the year
      if (i >= firstForecast) {
        pAndLReserve.balanceMonthDtos[i].value = pAndLReserve.balanceMonthDtos[i - 1].value + currentYearGainLoss.balanceMonthDtos[i - 1].value;
      }
      valueForRestOfYear = pAndLReserve.balanceMonthDtos[i].value;
    } else {

      if (i >= firstForecast) {
        pAndLReserve.balanceMonthDtos[i].value = valueForRestOfYear;
      }

    }

  }

}

function calculateNetAssets(balance) {

  //Total fixed assets + total current assets _ total current liabilities + total long term liabilities
  const totalFixedAssets = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "TOTAL FIXED ASSETS")[0];

  const totalCurrentAssets = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "TOTAL CURRENT ASSETS")[0];

  const totalCurrentLiabilities = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "TOTAL CURRENT LIABILITIES")[0];

  const totalDebt = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "TOTAL DEBT")[0];

  const netAssets = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "Net Assets")[0];


    _.forEach(netAssets.balanceMonthDtos, function(balanceMonthDto, i) {

      let netAssetsDecimal =
        createDecimal(totalFixedAssets.balanceMonthDtos[i].value).add(
        createDecimal(totalCurrentAssets.balanceMonthDtos[i].value)).add(
        createDecimal(totalCurrentLiabilities.balanceMonthDtos[i].value)).add(
        createDecimal(totalDebt.balanceMonthDtos[i].value));

      netAssets.balanceMonthDtos[i].value = parseNumberOrZero(netAssetsDecimal.toNumber().toString().replace(/^0+/, ''));
    });


}

function calculateCheckLine(balance) {

  const netAssets = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.overrideName === "Net Assets");

  const totalEquity = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.overrideName === "TOTAL EQUITY");

  const checkLine = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.overrideName === "Check Line");

  _.forEach(checkLine.balanceMonthDtos, function(balanceMonthDto, i) {

    let checkLineDecimal = Decimal.sub(
      createDecimal(netAssets.balanceMonthDtos[i].value),
      createDecimal(totalEquity.balanceMonthDtos[i].value));

    checkLine.balanceMonthDtos[i].value = parseNumberOrZero(checkLineDecimal.toNumber().toFixed(2).toString().replace(/^0+/, ''));
  });

}

export function isBalanceSheetImbalanced(balance) {

  let imbalanced = false;

  const checkLine = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.overrideName === "Check Line");

  let firstForecast = (getCurrentProject().firstBalanceForecast + 1);

  _.forEach(checkLine.balanceMonthDtos, function(balanceMonthDto, i) {

    if (i > firstForecast) {
      //get the last actual - check line value, every forecast check line value should equal the last actuals checkline value,
      //if it doesn't return false
      if (balanceMonthDto.value !== 0) {
        imbalanced = true;
        console.log('balance sheet is imbalanced')
      }
    }

  });

  return imbalanced;
}

function calculateTotalEquity(balance, type) {

  calculateTotals(balance, "TOTAL EQUITY", "EQUITY");

  const totalEquity = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "TOTAL EQUITY"
      && balanceCategory.totalField === true)[0];

  const plReserve = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "P&L reserve")[0];

  const currentYearGL = balance.balanceCategoryDtos
    .filter(balanceCategory => balanceCategory.overrideName === "Current Year P&L Gain/(Loss)")[0];

  _.forEach(totalEquity.balanceMonthDtos, function(balanceMonthDto, i) {

    let newEquityTotal = Decimal.add(createDecimal(plReserve.balanceMonthDtos[i].value), createDecimal(currentYearGL.balanceMonthDtos[i].value)).add(createDecimal(totalEquity.balanceMonthDtos[i].value));

    totalEquity.balanceMonthDtos[i].value = parseNumberOrZero(newEquityTotal.toNumber().toString().replace(/^0+/, ''));;
  });

}

function calculateCurrentYearGainLoss(balance, pnl) {

  let firstForecast = (getCurrentProject().firstBalanceForecast + 1);

  // don't progress if inputs are not there
  if (pnl === null || pnl === undefined) {
    return
  }

  let pnlNetProfit = pnl.pnLCategoryDtoList.find(category => category.name === 'Net Profit' && category.overrideName === '');

  // go to the last month of the actuals (index 11)
  const currentYearGainLoss = balance.balanceCategoryDtos
    .find(balanceCategory => balanceCategory.name === "Current Year P&L Gain/(Loss)"
      && balanceCategory.totalField === true);


  if (firstForecast < 12) { //only apply to the first year only

    currentYearGainLoss.balanceMonthDtos[firstForecast].value = currentYearGainLoss.balanceMonthDtos[firstForecast - 1].value + pnlNetProfit.pnLMonthDtoList[firstForecast - 1].value;

    for (let i = firstForecast + 1; i < 13; ++i) {

      //get the previous months current year p&l gain/loss
      currentYearGainLoss.balanceMonthDtos[i].value = pnlNetProfit.pnLMonthDtoList[i - 1].value + currentYearGainLoss.balanceMonthDtos[i - 1].value

    }
  }

  //we take the last years P&L Reserve + Current Year P&L Gain/(Loss) and add them together.
  //so if January is the start month, then we add December to December
  let startOfYears = [13, 25, 37, 49, 61]; //balance has 1 more column (opening)


  // Formula - P and L Reserve - Current Year Gain and Loss
  let valueForRestOfYear = 0;

  //RESET the results
  for (let i = 13; i < currentYearGainLoss.balanceMonthDtos.length; ++i) {
    if (i >= firstForecast) {
      currentYearGainLoss.balanceMonthDtos[i].value = pnlNetProfit.pnLMonthDtoList[i - 1].value;
    }
  }

  startOfYears = modifyArray(startOfYears, firstForecast -1)

  for (let i = 13; i < currentYearGainLoss.balanceMonthDtos.length; ++i) {

    if (startOfYears.includes(i)) { //if we are the start of the year+
      valueForRestOfYear = currentYearGainLoss.balanceMonthDtos[i].value;
    } else {
      if (i >= firstForecast) {
        currentYearGainLoss.balanceMonthDtos[i].value = currentYearGainLoss.balanceMonthDtos[i].value + valueForRestOfYear;
      }
      valueForRestOfYear = currentYearGainLoss.balanceMonthDtos[i].value;
    }

  }
  
}

/**
 * If we have a actual that is greater than 12 months, then we need to remove the months that are less than the first forecast
 * and add the first forecast to the array
 *
 * i.e. Arrays.asList(13, 25, 37, 49, 61);
 * and the firstBalanceForecast is 17
 * then the new array will look like
 * Arrays.asList(17, 25, 37, 49, 61);
 *
 * @param list
 * @param threshold
 */
function modifyArray(arr, value) {
  // Filter the array to remove elements less than the value
  const filteredArr = arr.filter(item => item >= value);

  // Add the value to the start of the array only if at least one item was removed
  if (filteredArr.length < arr.length) {
    filteredArr.unshift(value);
  }

  return filteredArr;
}

export function calculateBalanceSheet(balance, pnl) {

  if (balance !== undefined) {

    calculateTotals(balance, "TOTAL FIXED ASSETS", "FIXED_ASSETS");
    calculateTotalsCurrentAssets(balance, "TOTAL CURRENT ASSETS", "CURRENT_ASSETS");
    calculateTotals(balance, "TOTAL CURRENT LIABILITIES", "CURRENT_LIABILITIES");
    calculateTotals(balance, "TOTAL DEBT", "DEBT");
    calculateTotalEquity(balance,  "EQUITY");

    calculateNetAssets(balance);
    calculateCheckLine(balance);

    calculateCurrentYearGainLoss(balance, pnl);
    calculatePandLReserve(balance, pnl);
  }
}


/**
 * when entering data into the balance sheet, when we enter the last value in the actuals, to
 * copy over to the forecast cells (which will be calculated), this should only happen if all the rows
 * in the forecast section are 0 and untouched. The minute we start filling in this data on the assumptions
 * pages, we'll want to preserve the calculated values and not these.
 * @param balance
 */
export function populateForecastValues(balance, cellData) {

  //It will have to save the balance, which copies over the values to the debt (or other assumption)
  //then it will have to run the calculate on those assets and then save them and then update the balance.
  //


  if (cellData === undefined) {
    return
  }

  //from the cell get the category
  let categoryId = cellData.category;

  //from balance, find the category and it's monthlys
  let category = balance.balanceCategoryDtos.find(category => category.id === categoryId);

  if (['SIMPLE_CORKSCREW_WITH_INPUT_DA',
        'SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE',
        'SIMPLE_CORKSCREW'].includes(category.balanceExternalType)) { // we only copy across CORKSCREW subtypes

    //if all the forecast monthlys are 0, then we can prepopulate them with the last actual
    let lastActualMonth = category.balanceMonthDtos.findLast(month => month.financialTypeEnum === 'ACTUAL')
    let firstForecastMonth = category.balanceMonthDtos.find(month => month.financialTypeEnum === 'FORECAST')

    let allZero = checkAllValuesZero(category.balanceMonthDtos, firstForecastMonth.monthIndex)

    //take the last actual and copy it to the forecast values
    if (allZero) {
      populateArrayValues(category.balanceMonthDtos, lastActualMonth.value, firstForecastMonth.monthIndex)
    }
  }

  //done

  //question - what happens if you change the value of the last actual a second or third time, should it repopulate?
  //how could we tell if the assumption has changed? We can't easily, so for now it doesn't recaluate
  //you can however edit it in the assumption page and it will recalculate there.
}

function populateArrayValues(arr, lastActualValue, firstForecastIndex) {
  for (let i = firstForecastIndex; i < arr.length; i++) {
    arr[i].value = lastActualValue;
  }
}



/**
 * Compares two arrays of objects and returns a new array containing only the objects
 * from `array1` whose specified sub-array has changed in `array2`.
 * @param {Array} array1 The first array of objects to compare
 * @param {Array} array2 The second array of objects to compare
 * @param {string} subArrayName The name of the sub-array property to compare
 * @param {string} idPropertyName (Optional) The name of the ID property in the sub-array objects
 * @returns {Array} An array of objects that have changed sub-arrays in `array2`
 */
export function getChangedSubArrays(array1, array2, subArrayName, idPropertyName) {
  // Ensure that the required parameters are provided
  if (!array1 || !array2 || !subArrayName) {
    throw new Error('Missing required parameters');
  }

  // Create an array to store the objects with changed sub-arrays
  const changedObjects = [];

  // Iterate over each object in `array1`
  array1.forEach((obj1) => {
    // Find the corresponding object in `array2` by ID
    const obj2 = array2.find((o) => o.id === obj1.id);

    // If there is no corresponding object, skip to the next iteration
    if (!obj2) {
      return;
    }

    // Get the sub-arrays from each object
    const monthlyDtos1 = obj1[subArrayName];
    const monthlyDtos2 = obj2[subArrayName];

    // If either sub-array is missing or empty, skip to the next iteration
    if (!monthlyDtos1 || !monthlyDtos2) {
      return;
    }

    // Create an array to store the items in the sub-array that have changed
    const changedSubArray = [];

    // If an ID property is specified, compare sub-array items by ID
    if (idPropertyName) {
      monthlyDtos1.forEach((monthly1) => {
        // Find the corresponding item in the other sub-array by ID
        const monthly2 = monthlyDtos2.find((i) => i[idPropertyName] === monthly1[idPropertyName]);

        if (monthly1.category === 723340) {
          console.log(monthly1.value, monthly2.value);
        }

        // if (monthly1.value !== monthly2.value) {
        //   console.log(monthly1.value, monthly2.value);
        // }
        // If there is no corresponding item or the items are identical, skip to the next iteration
        if (!monthly2 || JSON.stringify(monthly1) === JSON.stringify(monthly2)) {
          return;
        }


        // Add the item to the list of changed items
        changedSubArray.push(monthly1);
      });
    }

    // If there are changed items, add the object to the list of changed objects
    if (changedSubArray.length > 0) {
      changedObjects.push(obj1);
    }
  });

  // Return the list of changed objects
  return changedObjects;
}

