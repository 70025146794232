import  React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid, LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { equitiesState } from "../../../SharedState/EquitiesState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";

function AddEquity() {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  const [equityAtom, setEquities] = useRecoilState(equitiesState);

  const [assumptionFormatValue, setAssumptionFormatValue] =
    useState("SIMPLE_CORKSCREW");

  const validationSchema = Yup.object().shape({
    equityName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(equityAtom.map(function(i) {
        return i?.equityDto?.name;
      }), "Must have a unique name"),
  });

  const initialValues = {
    equityName: "",
    assumptionFormat: "",
    balancePositionFlag: "TANGIBLE",
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {

      setSubmittingForm(true)
      axios
        .post("equity", {
          name: values.equityName,
          project: project.id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          canDelete: true,
        })
        .then(async function(response) {

          //update the atoms - Equity & Balance
          await axios.get("equity/" + project.id).then(function(response) {
            setEquities(response.data);
          });

          await axios.get("balance/" + project.id).then(function(response) {
            setBalanceSheet(response.data);
          });

          setSubmittingForm(false);
          resetForm();
          setOpen(false);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 , minWidth : 160}}
                onClick={() => setOpen(true)}
              >
                Equity
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Equity</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Equity...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <DialogContentText>
                      Enter the name of the equity you wish to add.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.equityName && errors.equityName)}
                      name="equityName"
                      autoFocus
                      placeholder={"Current Equity Name"}
                      margin="dense"
                      id="equityName"
                      label="Current Equity Name"
                      value={values.equityName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.equityName && errors.equityName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup

                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={assumptionFormatValue}
                      onChange={handleChangeAssumption}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                    </RadioGroup>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddEquity;
