import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddOverhead from "./components/AddOverhead";
import OverheadInput from "./components/OverheadInput";
import {
  calculateOverheads,
} from "./calculations/Calculations";
import Settings from "../../../../components/Settings";
import PageHeader from "../../SharedComponents/PageHeader";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import { useRecoilState, useRecoilValue } from "recoil";
import { overheadsState } from "../../SharedState/OverheadsState";
import { pnlState } from "../../SharedState/PnLState";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { headcountsState } from "../../SharedState/HeadcountsState";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import UpdatePowdrModelFullStack from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelFullStack";
import { overheadAssumptionMap } from "../../SharedComponents/AssumptionMaps";
import OverrideValueModal from "../../SharedComponents/OverrideValueModal";
import { overheadsConsolidationState } from "../../SharedState/OverheadsConsoliationState";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";

const Divider = styled(MuiDivider)(spacing);

function Overheads() {

  const updatePowdrFullStackRef = useRef();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [overheads, setOverheads] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [overheadsAtom, setOverheadsAtom] = useRecoilState(overheadsState);

  const pnlAtom = useRecoilValue(pnlState);

  const revenueAtom = useRecoilValue(revenueAndCostState);
  const headcountAtom = useRecoilValue(headcountsState);
  const overheadsConsolidations = useRecoilValue(overheadsConsolidationState);


  function loadOverheads() {

    // reset the badge
    processAlert(setOpenAlert, 1);

    let overheads_ = structuredClone(overheadsAtom);

    calculatePercentageComplete(overheads_, 'overhead', 'overheadCategoryDtoList', 'overheadMonthDtoList');

    calculateOverheads(overheads_, pnlAtom, revenueAtom, headcountAtom, overheadsConsolidations)

    setOverheads(overheads_);
  }

  function updatePorwdrModel(cellData) {
    updatePowdrFullStackRef.current.updatePowdrModelFullStack(cellData,
      overheads,
      "overhead",
      overheadAssumptionMap,
      overheads, structuredClone(revenueAtom));
  }


  function refreshOverheadData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(overheads, 'overhead', 'overheadCategoryDtoList', 'overheadMonthDtoList');

      calculateOverheads(cellData.assumptions, pnlAtom, revenueAtom, headcountAtom, overheadsConsolidations)

      updatePorwdrModel(cellData);

      setOverheadsAtom(structuredClone(overheads));
    }

  }


  useEffect(() => {
    loadOverheads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overheadsAtom]);

  return (
    <React.Fragment>

      <PageHeader pageName={"Overheads"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"overheads"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
        <AddOverhead/>
      }/>

      {!!overheads &&
        overheads.filter(overhead => !(
          getCurrentProject().hideDefaultGroups === true &&
          overhead.overheadDto.overheads.length === 0 &&
          overhead.overheadDto.name.endsWith("(Default Overhead Group)")
        )).map((overhead) => (
          <OverheadInput
            key={overhead.overheadDto.id}
            overheadItem={overhead}
            overheads={overheads}
            refreshOverheadData={refreshOverheadData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setOverheads={setOverheads}
            loadOverheadData={loadOverheads}
          ></OverheadInput>
        ))}


      <OverrideValueModal items={structuredClone(overheads)} assumptionType={"OVERHEAD"}/>

      <UpdatePowdrModelFullStack ref={updatePowdrFullStackRef}/>
      <Settings/>
    </React.Fragment>
  );
}

export default Overheads;
