
import {
  blankCategory,
  buildDivisionOfTwoCategories,
  buildPercentageOfTwoCategories, buildYearDifferenceCategory, buildYearDifferencePercentageCategory,
  buildYearTotalCategory, isValidNumber
} from "./Calculations";

function buildYearStarting(product) {

  let category = {
    name: "Opening Revenue",
    productMonthDtoList : []
  }

  //loop through, grab the 1st cell value, then advance 12 items and grab the second, repeat this until we have 6 years opening
  let quantitySoldCateogryOriginal = product.productCategoryDtoList.find(category => category.name === "Opening Revenue");

  let yearIndex = 0;
  for (let i=0; i < 72;){
    let yearOpening = quantitySoldCateogryOriginal.productMonthDtoList[i].value;

    category.productMonthDtoList.push({
      id: yearIndex,
      value: yearOpening,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    i = i + 12;
    yearIndex++;
  }

  return category;
}

function buildYearEnding(product, categoryName, displayName=categoryName) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  //loop through, grab the 1st cell value, then advance 12 items and grab the second, repeat this until we have 6 years opening
  let quantitySoldCateogryOriginal = product.productCategoryDtoList.find(category => category.name === categoryName);

  let yearIndex = 0;
  for (let i=11; i < 72;){
    let yearOpening = quantitySoldCateogryOriginal.productMonthDtoList[i].value;

    category.productMonthDtoList.push({
      id: yearIndex,
      value: yearOpening,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    i = i + 12;
    yearIndex++;
  }

  return category;
}

function buildAnnualisedCategory(displayName, closingRecurringRevCategory) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  let yearIndex = 0;
  for (let i=0; i < closingRecurringRevCategory.productMonthDtoList.length; i++){

    let val = closingRecurringRevCategory.productMonthDtoList[i].value * 12;

    category.productMonthDtoList.push({
      id: yearIndex,
      value: val,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    yearIndex++;
  }

  return category;
}

export function createAnnualSummarySubscription(product_, ) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";

  let openingRevenueCategory          = buildYearStarting(product);
  let newRevenueCategory              = buildYearTotalCategory(product, "New Revenue");
  let priceIncreaseCategory           = buildYearTotalCategory(product, "Price Increase");
  let adjustmentRevUpliftCategory     = buildYearTotalCategory(product, "Adjustment (revenue uplift)");
  let lostCustomersCategory           = buildYearTotalCategory(product, "Churn - lost customers");
  let priceErosionCategory            = buildYearTotalCategory(product, "Churn - price erosion");
  let adjustmentRevDecreaseCategory   = buildYearTotalCategory(product, "Adjustment (revenue decrease)");
  let closingRecurringRevCategory     = buildYearEnding(product, "Closing recurring revenue");

  let revenueCategory                 = buildYearTotalCategory(product, "Closing recurring revenue", "Revenue");
  let directProductCostsCategory      = buildYearTotalCategory(product, "Direct product costs");
  let grossProfitCategory             = buildYearTotalCategory(product, "Gross Profit");
  let productMarginCategory           = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfitCategory);
  let newCustomersCategory            = buildYearTotalCategory(product, "Quantity Sold", "New customers");
  let churnCategory                   = buildYearTotalCategory(product, "Quantity lost", "Quantity lost");
  let totalCustomersYearEndCategory   = buildYearEnding(product, "Total Customers", "Total customers at year end");

  let averageRevenuePerCustomerCategory         = buildDivisionOfTwoCategories("Average revenue per customer per month", closingRecurringRevCategory, totalCustomersYearEndCategory);
  let annualisedYearEndRevenueCustomerCategory  = buildAnnualisedCategory("Annualised year end revenue", closingRecurringRevCategory);
  let priceIncreaseTotalCategory                = buildDivisionOfTwoCategories("Average price increase per customer", priceIncreaseCategory, openingRevenueCategory);
  let priceErosionTotalCategory                 = buildDivisionOfTwoCategories("Average price erosion per customer", priceErosionCategory, openingRevenueCategory);


  annualSummary.productCategoryDtoList.push(
    openingRevenueCategory,
    newRevenueCategory,
    priceIncreaseCategory,
    adjustmentRevUpliftCategory,
    lostCustomersCategory,
    priceErosionCategory,
    adjustmentRevDecreaseCategory,
    closingRecurringRevCategory,
    blankCategory("Financials in model"),
    revenueCategory,
    directProductCostsCategory,
    grossProfitCategory,
    productMarginCategory,
    blankCategory(),
    annualisedYearEndRevenueCustomerCategory,
    blankCategory("Customers in the year"),
    newCustomersCategory,
    churnCategory,
    totalCustomersYearEndCategory,
    blankCategory("Stats per customer"),
    averageRevenuePerCustomerCategory,
    priceIncreaseTotalCategory,
    priceErosionTotalCategory
  );

  return annualSummary;
}


export function createGrowthRatesSubscription(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";

  let openingRevenueCategory          = buildYearStarting(product);
  let newRevenueCategory              = buildYearTotalCategory(product, "New Revenue");
  let priceIncreaseCategory           = buildYearTotalCategory(product, "Price Increase");
  let adjustmentRevUpliftCategory     = buildYearTotalCategory(product, "Adjustment (revenue uplift)");
  let lostCustomersCategory           = buildYearTotalCategory(product, "Churn - lost customers");
  let priceErosionCategory            = buildYearTotalCategory(product, "Churn - price erosion");
  let adjustmentRevDecreaseCategory   = buildYearTotalCategory(product, "Adjustment (revenue decrease)");
  let closingRecurringRevCategory     = buildYearEnding(product, "Closing recurring revenue");

  let revenueCategory                 = buildYearTotalCategory(product, "Closing recurring revenue");
  let directProductCostsCategory      = buildYearTotalCategory(product, "Direct product costs");
  let grossProfitCategory             = buildYearTotalCategory(product, "Gross Profit");
  let productMarginCategory           = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfitCategory);

  let newCustomersCategory            = buildYearTotalCategory(product, "Quantity Sold", "New customers");
  let churnCategory                   = buildYearTotalCategory(product, "Quantity lost", "Quantity lost");
  let totalCustomersYearEndCategory   = buildYearEnding(product, "Total Customers", "Total customers at year end");

  let averageRevenuePerCustomerCategory         = buildDivisionOfTwoCategories("Average revenue per customer per month", closingRecurringRevCategory, totalCustomersYearEndCategory);
  let annualisedYearEndRevenueCustomerCategory  = buildAnnualisedCategory("Annualised year end revenue", closingRecurringRevCategory);
  let priceIncreaseTotalCategory                = buildDivisionOfTwoCategories("Price increase", priceIncreaseCategory, openingRevenueCategory, 0, "#FF0000", "#008000");
  let priceErosionTotalCategory                 = buildDivisionOfTwoCategories("Price erosion", priceErosionCategory, openingRevenueCategory, 0, "#FF0000", "#008000");




  let openingRevenue                  = buildYearPercentageDifferenceCategory("Opening Revenue", openingRevenueCategory);
  let newRevenue                      = buildYearPercentageDifferenceCategory("New Revenue", newRevenueCategory);
  let priceIncrease                   = buildYearPercentageDifferenceCategory("Price Increase", priceIncreaseCategory);
  let adjustmentRevUplift             = buildYearPercentageDifferenceCategory("Adjustment (revenue uplift)", adjustmentRevUpliftCategory);
  let lostCustomers                   = buildYearPercentageDifferenceCategory( "Churn - lost customers", lostCustomersCategory, "#FF0000", "#008000");
  let priceErosion                    = buildYearPercentageDifferenceCategory( "Churn - price erosion", priceErosionCategory,  "#FF0000", "#008000");
  let adjustmentRevDecrease           = buildYearPercentageDifferenceCategory( "Adjustment (revenue decrease)", adjustmentRevDecreaseCategory);
  let closingRecurringRev             = buildYearPercentageDifferenceCategory( "Closing recurring revenue",  closingRecurringRevCategory);

  let revenue                         = buildYearPercentageDifferenceCategory( "Revenue", revenueCategory);
  let directProductCosts              = buildYearPercentageDifferenceCategory( "Direct product costs", directProductCostsCategory,  "#FF0000", "#008000", "↓",  "↑");
  let grossProfit                     = buildYearPercentageDifferenceCategory( "Gross Profit", grossProfitCategory);
  let productMargin                   = buildYearPercentageDifferenceCategory("Product Margin", productMarginCategory);

  let newCustomers                    = buildYearPercentageDifferenceCategory( "Quantity Sold", newCustomersCategory);
  let churn                           = buildYearPercentageDifferenceCategory( "Quantity lost", churnCategory);
  let totalCustomersYearEnd           = buildYearPercentageDifferenceCategory( "Total Customers", totalCustomersYearEndCategory);

  let averageRevenuePerCustomer         = buildYearPercentageDifferenceCategory("Average revenue per customer per month", averageRevenuePerCustomerCategory);
  let annualisedYearEndRevenueCustomer  = buildYearPercentageDifferenceCategory("Annualised year end revenue", annualisedYearEndRevenueCustomerCategory);
  let priceIncreaseTotal                = buildYearPercentageDifferenceCategory("Average price increase per customer", priceIncreaseTotalCategory);
  let priceErosionTotal                 = buildYearPercentageDifferenceCategory("Average price erosion per customer", priceErosionTotalCategory);


  summary.productCategoryDtoList.push(
   openingRevenue,
   newRevenue    ,
   priceIncrease,
   adjustmentRevUplift,
   lostCustomers,
   priceErosion,
   adjustmentRevDecrease,
   closingRecurringRev,
   blankCategory("Financials in model"),
   revenue ,
   directProductCosts,
   grossProfit,
   productMargin,
   blankCategory(),
   annualisedYearEndRevenueCustomer,
   blankCategory("Customers in the year"),
   newCustomers,
   churn,
   totalCustomersYearEnd,
   blankCategory("Stats per customer"),
   averageRevenuePerCustomer,
   priceIncreaseTotal,
   priceErosionTotal
  );

  return summary;
}

export function createYearOnYearChangeSubscription(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";


  let openingRevenueCategory          = buildYearStarting(product);
  let newRevenueCategory              = buildYearTotalCategory(product, "New Revenue");
  let priceIncreaseCategory           = buildYearTotalCategory(product, "Price Increase");
  let adjustmentRevUpliftCategory     = buildYearTotalCategory(product, "Adjustment (revenue uplift)");
  let lostCustomersCategory           = buildYearTotalCategory(product, "Churn - lost customers");
  let priceErosionCategory            = buildYearTotalCategory(product, "Churn - price erosion");
  let adjustmentRevDecreaseCategory   = buildYearTotalCategory(product, "Adjustment (revenue decrease)");
  let closingRecurringRevCategory     = buildYearEnding(product, "Closing recurring revenue");

  let revenueCategory                 = buildYearTotalCategory(product, "Closing recurring revenue");
  let directProductCostsCategory      = buildYearTotalCategory(product, "Direct product costs");
  let grossProfitCategory             = buildYearTotalCategory(product, "Gross Profit");
  let productMarginCategory           = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfitCategory);

  let newCustomersCategory            = buildYearTotalCategory(product, "Quantity Sold", "New customers");
  let churnCategory                   = buildYearTotalCategory(product, "Quantity lost", "Quantity lost");
  let totalCustomersYearEndCategory   = buildYearEnding(product, "Total Customers", "Total customers at year end");

  let averageRevenuePerCustomerCategory         = buildDivisionOfTwoCategories("Average revenue per customer per month", closingRecurringRevCategory, totalCustomersYearEndCategory);
  let annualisedYearEndRevenueCustomerCategory  = buildAnnualisedCategory("Annualised year end revenue", closingRecurringRevCategory);
  let priceIncreaseTotalCategory                = buildDivisionOfTwoCategories("Price increase", priceIncreaseCategory, openingRevenueCategory);
  let priceErosionTotalCategory                 = buildDivisionOfTwoCategories("Price erosion", priceErosionCategory, openingRevenueCategory);




  let openingRevenue                  = buildYearDifferenceCategory("Opening Revenue", openingRevenueCategory);
  let newRevenue                      = buildYearDifferenceCategory("New Revenue", newRevenueCategory);
  let priceIncrease                   = buildYearDifferenceCategory("Price Increase", priceIncreaseCategory);
  let adjustmentRevUplift             = buildYearDifferenceCategory("Adjustment (revenue uplift)", adjustmentRevUpliftCategory);
  let lostCustomers                   = buildYearDifferenceCategory( "Churn - lost customers", lostCustomersCategory);
  let priceErosion                    = buildYearDifferenceCategory( "Churn - price erosion", priceErosionCategory);
  let adjustmentRevDecrease           = buildYearDifferenceCategory( "Adjustment (revenue decrease)", adjustmentRevDecreaseCategory);
  let closingRecurringRev             = buildYearDifferenceCategory( "Closing recurring revenue",  closingRecurringRevCategory);

  let revenue                         = buildYearDifferenceCategory( "Revenue", revenueCategory);
  let directProductCosts              = buildYearDifferenceCategory( "Direct product costs", directProductCostsCategory, 0, "↑", "↓");
  let grossProfit                     = buildYearDifferenceCategory( "Gross Profit", grossProfitCategory);
  let productMargin                   = buildYearDifferencePercentageCategory("Product Margin", productMarginCategory);

  let newCustomers                    = buildYearDifferenceCategory( "Quantity Sold", newCustomersCategory);
  let churn                           = buildYearDifferenceCategory( "Quantity lost", churnCategory);
  let totalCustomersYearEnd           = buildYearDifferenceCategory( "Total Customers", totalCustomersYearEndCategory);

  let averageRevenuePerCustomer         = buildYearDifferenceCategory("Average revenue per customer per month", averageRevenuePerCustomerCategory);
  let annualisedYearEndRevenueCustomer  = buildYearDifferenceCategory("Annualised year end revenue", annualisedYearEndRevenueCustomerCategory);
  let priceIncreaseTotal                = buildYearDifferenceCategory("Average price increase per customer", priceIncreaseTotalCategory);
  let priceErosionTotal                 = buildYearDifferenceCategory("Average price erosion per customer", priceErosionTotalCategory);


  summary.productCategoryDtoList.push(
    openingRevenue,
    newRevenue    ,
    priceIncrease,
    adjustmentRevUplift,
    lostCustomers,
    priceErosion,
    adjustmentRevDecrease,
    closingRecurringRev,
    blankCategory("Financials in model"),
    revenue ,
    directProductCosts,
    grossProfit,
    productMargin,
    blankCategory(),
    annualisedYearEndRevenueCustomer,
    blankCategory("Customers in the year"),
    newCustomers,
    churn,
    totalCustomersYearEnd,
    blankCategory("Stats per customer"),
    averageRevenuePerCustomer,
    priceIncreaseTotal,
    priceErosionTotal
  );

  return summary;
}




function buildYearPercentageDifferenceCategory(name, categorySource, favourable="#008000", adverse="#FF0000") {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  category.productMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "PERCENTAGE"
  })

  for (let i=1; i < categorySource.productMonthDtoList.length; i++) {

    let yearTotal = calculatePercentageGrowth(categorySource.productMonthDtoList[i - 1].value, categorySource.productMonthDtoList[i].value);

    let val = isValidNumber(yearTotal);

    category.productMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      textColour: val >= 0 ? favourable : adverse,
      showMovementArrow: true,
    })
  }

  return category;
}


function calculatePercentageGrowth(oldValue, newValue) {
  if (oldValue === 0) {
    //console.log('Old value is 0, percentage growth is undefined.');
    return; // Prevent division by zero
  }

  const percentageGrowth = ((newValue - oldValue) / oldValue) * 100;
  return percentageGrowth;
}




