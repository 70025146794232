export const cardStyles = {
  width: "100%",
  overflowX: "auto",
  overflow: "scroll",
  paddingTop: 0,
};

export const cellStyles = {
  minWidth: 200,
  maxWidth: 200,
  width: 200,
  backgroundColor: "#F7F9FC",
  position: "sticky",
  left: 0,
  zIndex: 10, // Ensure the header is above other content
}

export const assumptionTitleBlock = {
  minWidth: 200,
  maxWidth: 200,
  width: 200,
  backgroundColor: "#F7F9FC",
  zIndex: 10,
};

export const tableWidth = {
  width: "100%",
  overflowX: "auto",
  overflow: "scroll",
  paddingTop: 0,
};