import React, { useEffect, useState }  from 'react';
import axios from "axios";
import { Box, Checkbox, List, ListItem, ListItemText, Typography } from "@mui/material";

export default function DivisionalPnL({companyId = null,
                                        selectedProjects, setSelectedProjects,
                                        selectedFolder, setSelectedFolder}) {

  const [folders, setFolders] = useState([]);



  useEffect( () => {
    if (companyId !== null) {
      axios.get('/folder/' + companyId + '/foldersOnly?admin=false').then(function(response) {
        setFolders(response.data);
      });
    }
  }, [companyId]);


  useEffect( () => {


    if (selectedFolder !== undefined && folders !==  undefined) {

      let folder = folders.find(folder => folder.id === selectedFolder);

      if (folder !== undefined) {
        setSelectedFolder(folder);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  const handleSelectModel = (model) => {
    const currentIndex = selectedProjects.indexOf(model.id);
    const newChecked = [...selectedProjects];
    if (currentIndex === -1) {
      newChecked.push(model.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedProjects(newChecked);
  };

  return (

    <React.Fragment>

      <Box sx={{ display: 'flex', gap: 2, minHeight: 400, maxHeight: 400, paddingTop: 2, minWidth: 700 }}>
        <Box sx={{minWidth: '50%'}}>
          <Typography variant="h6">Folders</Typography>
          <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
            <List>
              {folders.map((folder) => (
                <ListItem
                  key={folder.id}
                  button
                  selected={selectedFolder.id === folder.id}
                  onClick={() => setSelectedFolder(folder.id)}
                >
                  <ListItemText primary={folder.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box sx={{minWidth: '50%'}}>
          <Typography variant="h6">Projects</Typography>
          <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
            <List>
              {selectedFolder?.projectDtos?.map((project) => (
                <ListItem
                  key={project.id}
                  button
                  disabled={project.projectType !== 'PNL'}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={() => handleSelectModel(project)}
                      checked={selectedProjects.indexOf(project.id) !== -1}
                    />
                  }
                >
                  <ListItemText primary={project.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>


      </Box>



    </React.Fragment>

  );

}