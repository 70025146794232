import _ from "lodash";

export function calculateSummaryTotals(debts, setProductSummary, firstForecast, overdraftCharges, setPnlAtom, pnlAtom) {

  if (pnlAtom.length === 0) {
    return
  }
    const pnl = structuredClone(pnlAtom);



  // we need to create an object that the product summary component can use to display the totals for our products

  if (pnl === undefined) {
    return;
  }

  let productSummary = {
    productSummaryDto: { name: "Debt Summary", id: 999 },
    productSummaryCategoryDtoList: [
      {
        id: 0,
        name: 'Total Interest Charge',
        productSummaryMonthDtoList: []
      },
      {
        id: 1,
        name: 'Total Overdraft Charge',
        productSummaryMonthDtoList: []
      },
      {
        id: 2,
        name: 'Total (P&L , Cash Flow)',
        productSummaryMonthDtoList: []
      },
      {
        id: 3,
        name: 'Total (PIK)',
        productSummaryMonthDtoList: []
      },
      {
        id: 4,
        name: 'Total (PIK Paid)',
        productSummaryMonthDtoList: []
      }
    ]
  };

  // create 72 columns for each summary month dto list
  // and calculate the totals for each of them

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[0].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[1].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[2].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[3].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[4].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  let pikInterestCategory = pnl.pnLCategoryDtoList.find(category => category.name === "PIK Interest")


  // pull actuals pik interest from the pnl
  if (pikInterestCategory) {
    for (let x = 0; x < firstForecast; x++) {

      productSummary
        .productSummaryCategoryDtoList[3]
        .productSummaryMonthDtoList[x].value = pikInterestCategory.pnLMonthDtoList[x].value; //find all the pik interest and add them up.

    }
  }


  // pull actuals depreciation from the pnl
  let netInterestExpenseCategory = pnl.pnLCategoryDtoList.find(category => category.name === "Net Interest Expense")
  //
  if (netInterestExpenseCategory) {
    for (let x = 0; x < firstForecast; x++) {

      productSummary
        .productSummaryCategoryDtoList[0]
        .productSummaryMonthDtoList[x].value = netInterestExpenseCategory.pnLMonthDtoList[x].value; //find all the depreciations and add them up.

    }
  }

  //
  // // fill the rest in with the calculated depreciation
  let pnlUpdateRequired = false;

  for (let x = firstForecast; x < 72; x++) {

    let total = totalInterestCharge(debts, x); //find all the depreciations and add them up.

    productSummary
      .productSummaryCategoryDtoList[0]
      .productSummaryMonthDtoList[x].value = total;


    //netInterestExpenseCategory.pnLMonthDtoList[x].value = total;
  }



  //get the calculated overdraft charges

  for (let x = 0; x < (72 - firstForecast); x++) {

    productSummary
      .productSummaryCategoryDtoList[1]
      .productSummaryMonthDtoList[x + firstForecast].value = overdraftCharges[x];

    //netInterestExpenseCategory.pnLMonthDtoList[x + firstForecast].value += overdraftCharges[x];


  }


  for (let x = 0; x < firstForecast; x++) {


    productSummary
      .productSummaryCategoryDtoList[2]
      .productSummaryMonthDtoList[x].value = netInterestExpenseCategory.pnLMonthDtoList[x].value;

  }


  //total up all the pik assumptions
  for (let x = firstForecast; x < 72; x++) {

    let total = totalPiks(debts, x); //find all the depreciations and add them up.

    productSummary
      .productSummaryCategoryDtoList[3]
      .productSummaryMonthDtoList[x].value = total;

    if (pikInterestCategory) {

      if (pikInterestCategory.pnLMonthDtoList[x].value !== total) {
        console.log('update required for pik interest')
        pnlUpdateRequired = true;
      }

      pikInterestCategory.pnLMonthDtoList[x].value = total;
    }

  }


  //total up all the piks PAID assumptions
  for (let x = 0; x < 72; x++) {

    let total = totalPiksPaid(debts, x); //find all the depreciations and add them up.

    productSummary
      .productSummaryCategoryDtoList[4]
      .productSummaryMonthDtoList[x].value = total;

  }


  //This loop is a check to see if the value in the summary is different to the p & l to dermine if we should do a write to the p & l
  for (let x = 0; x < (72 - firstForecast); x++) {


    let initialPnlValue = netInterestExpenseCategory.pnLMonthDtoList[x + firstForecast].value;

    let total = productSummary
      .productSummaryCategoryDtoList[0]
      .productSummaryMonthDtoList[x + firstForecast].value + overdraftCharges[x];

    productSummary
      .productSummaryCategoryDtoList[2]
      .productSummaryMonthDtoList[x + firstForecast].value = total;


    netInterestExpenseCategory.pnLMonthDtoList[x + firstForecast].value = total;

    if (initialPnlValue !== total) {
      //console.log(initialPnlValue, total)
      pnlUpdateRequired = true;
    }

  }



  //
  //
  //update the pnl with the depreciation totals
  if (pnlUpdateRequired) {
    //console.log('update required')
    setPnlAtom(structuredClone(pnl));
  }

  setProductSummary(productSummary.productSummaryCategoryDtoList);

  return productSummary;

}

function totalPiks(debts, x) {

  let pikDebts = debts.filter(debt => debt.debtDto.assumption === 'PIK_DEBT');

  let total = 0;
  //go through all the items
  _.forEach(pikDebts, function(pikDebt) {

    let closingCategory = pikDebt.debtCategoryDtos.find(category => category.name === 'PIK')

    if (closingCategory) {
      total += closingCategory.debtMonthDtos[x].value;
    }

  });

  return total;

}

function totalPiksPaid(debts, x) {

  let pikDebts = debts.filter(debt => debt.debtDto.assumption === 'PIK_DEBT');

  let total = 0;
  //go through all the items
  _.forEach(pikDebts, function(pikDebt) {

    let closingCategory = pikDebt.debtCategoryDtos.find(category => category.name === 'New paid')

    if (closingCategory) {
      total += closingCategory.debtMonthDtos[x].value;
    }

  });

  return total;

}

function totalInterestCharge(debtDataSetDtoList, x) {

  let total = 0;
  //go through all the items
  _.forEach(debtDataSetDtoList, function(debtDataSetDto) {
    //get the deprecation category

    let interestChrageCategory = debtDataSetDto.debtCategoryDtos.find(category => category.name === "Interest Charge")

    if (debtDataSetDto.debtDto.assumption === "CID_DEBT" || debtDataSetDto.debtDto.assumption === "ABL_DEBT") { //note to self, this looks redundant ?
        interestChrageCategory = debtDataSetDto.debtCategoryDtos.find(category => category.name === "Interest Charges")
    }

    //get the month that matches the x
    if (interestChrageCategory) {

      //add it to the total
      total += interestChrageCategory.debtMonthDtos[x].value;
    }

  });

  //return the total
  return Math.trunc(total);
}