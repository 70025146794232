import React from "react";
import {
  Grid,
} from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { getZoom, zoomIn, zoomOut } from "./utils/Utils";
import { useSetRecoilState } from "recoil";
import { zoomState } from "../SharedState/ZoomState";

function ControlsBar({ addAssumptionElement, element2, hideZoom=false }) {
  const setZoomAtom = useSetRecoilState(zoomState);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          {addAssumptionElement}
        </Grid>

        <Grid item>
          {element2}
          {!hideZoom && (
            <>
              <ZoomOut fontSize={"large"} onClick={() => {
                zoomOut();
                setZoomAtom(getZoom);
              }}/>
              <ZoomIn fontSize={"large"} onClick={() => {
                zoomIn();
                setZoomAtom(getZoom);
              }}/>
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ControlsBar;
