import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const balanceSheetState = atom({
  key: 'balanceSheetState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        //console.info('ON SET BALANCE SHEET', newAssumptions, oldAssumptions)

        const project = JSON.parse(sessionStorage.getItem("project"));

        if (newAssumptions !== undefined && oldAssumptions !== undefined) {

          handleDataUpdate([structuredClone(newAssumptions)],
            [oldAssumptions],
            'balance/' + project.id + '/months',
            'balanceMonthDtos',
            'balanceCategoryDtos',
            'balanceDto');

        }

      });
    },
  ]
});
