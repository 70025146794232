import { Chip as MuiChip } from "@mui/material";
import styled from "@emotion/styled";


export const AssumptionLabel = styled(MuiChip)`
  height: 20px;
  overflow: hidden;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
          props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};

  & .MuiChip-label {
    display: block;
    white-space: nowrap;  /* prevent text wrap */
  }

  &:hover {
    height: auto;  /* expand height on hover */
    & .MuiChip-label {
      white-space: normal;  /* allow text wrap on hover */
    }
  }
`;
