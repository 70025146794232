import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List, ListItem, ListItemText
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCurrentProject, setCurrentProject } from "../../SharedComponents/ProjectServices";
import axios from "axios";
import { useNavigate } from "react-router-dom";



export default function AddDivisionalProjectsModal({open, setOpen,
                                                     selectedFolderProjects,
                                                     selectedProjects}) {

  const navigate = useNavigate();

  const [selectedNewFolderProjects, setSelectedNewFolderProjects] = useState([]); //list of projects to be added to the division

  const handleClose = () => {

    setOpen(false);

  }

  const handleOk = () => {

    let projectUpdated = getCurrentProject();
    projectUpdated.projectMultiProjectList = selectedNewFolderProjects;

    console.log(projectUpdated)

    axios.put('/project/addDivisional', projectUpdated).then(function (response) {

        //update the session project
        setCurrentProject(response.data)
        navigate("/home");
        window.location.reload();
      }
    )

  };

  const handleSelectModel = (model) => {

    const currentIndex = selectedNewFolderProjects.findIndex(entry => entry.project === model.id);
    const newChecked = [...selectedNewFolderProjects];

    if (!isProjectSelected(model.id)) {
      newChecked.push({parentProject: getCurrentProject().id, project: model.id});                      //add item

    } else {
      newChecked.splice(currentIndex, 1); //remove item
    }

    setSelectedNewFolderProjects(newChecked);
  };

  const isProjectSelected = (project) => {
    return selectedNewFolderProjects.find(projectC => projectC.project === project) ? true : false;
  };

  const isDisabledProject = (project) => {
    //todo - also need to disable if the date of the project does not match the divisional date!
    return selectedProjects.find(projectC => projectC.project === project.id) ? true : false;
  };

  /**
   * Sets the selected new folder projects to include the existing projects for this division
   */
  useEffect(() => {
    setSelectedNewFolderProjects(selectedProjects)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Add Projects To Division</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
            <List>
              {selectedFolderProjects?.filter(project => project.projectType === 'PNL').map((project) => (
                <ListItem
                  key={project.id}
                  button
                  disabled={project.projectType !== 'PNL' || isDisabledProject(project)}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={() => handleSelectModel(project)}
                      checked={isProjectSelected(project.id)}
                    />
                  }
                >
                  <ListItemText primary={project.name} />
                </ListItem>
              ))}
            </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          SAVE
        </Button>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
