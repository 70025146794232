import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const fixedAssetsState = atom({
  key: 'fixedAssetsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({ onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        // Disabled for now, this was just a POC to see if it would work.
        //console.log('ON SET FIXED ASSETS', newAssumptions)
        const project = JSON.parse(sessionStorage.getItem("project"));

        handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
          'fixedassets/' + project.id + '/months',
          'fixedAssetMonthDtos',
          'fixedAssetCategoryDtos',
          'fixedAssetDto');

      });
    },
  ]
});
