
import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { pnlState } from "../pages/powdr/SharedState/PnLState";
import { overheadsState } from "../pages/powdr/SharedState/OverheadsState";
import axios from "axios";
import LoadingModal from "../pages/powdr/SharedComponents/LoadingModal";
import { headcountsState } from "../pages/powdr/SharedState/HeadcountsState";
import { debtsState } from "../pages/powdr/SharedState/DebtsState";
import { equitiesState } from "../pages/powdr/SharedState/EquitiesState";
import { fixedAssetsState } from "../pages/powdr/SharedState/FixedAssetsState";
import { currentAssetsState } from "../pages/powdr/SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../pages/powdr/SharedState/CurrentLiabilitiesState";
import { revenueAndCostState } from "../pages/powdr/SharedState/RevenueAndCostState";
import { balanceSheetState } from "../pages/powdr/SharedState/BalanceSheetState";
import { cashFlowState } from "../pages/powdr/SharedState/CashflowState";
import { refreshVatPackage, vatPackageState } from "../pages/powdr/SharedState/VatPackageState";
import { vatState } from "../pages/powdr/SharedState/VatState";
import { corporationTaxState } from "../pages/powdr/SharedState/CorporationTaxState";
import { projectLoadingState } from "../pages/powdr/SharedState/ProjectLoadingState";
import BrowserAlert from "../pages/powdr/SharedComponents/BrowserAlert";
import Chatbot from "../pages/powdr/SharedComponents/Chatbot";
import { overheadsConsolidationState } from "../pages/powdr/SharedState/OverheadsConsoliationState";
import { revenueAndCostConsolidationState } from "../pages/powdr/SharedState/RevenueAndCostConsolidationState";
import { headcountsConsolidationState } from "../pages/powdr/SharedState/HeadcountsConsolidationState";
import UpdatePowdrModelGlobal from "../pages/powdr/SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (min-width: 1001px) {
    max-width: calc(100% - 258px);
  }
  
  
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {

  const updatePowdrModelGlobalRef = useRef();

  const [mobileOpen, setMobileOpen] = useState(false);

  const setPnl = useSetRecoilState(pnlState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);
  const setCashflow = useSetRecoilState(cashFlowState);
  const setRevenueAndCost = useSetRecoilState(revenueAndCostState);
  const setOverheads = useSetRecoilState(overheadsState);
  const setHeadcounts = useSetRecoilState(headcountsState);
  const setFixedAssetAtom = useSetRecoilState(fixedAssetsState);
  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const setCurrentLiabilities = useSetRecoilState(currentLiabilitiesState);
  const setDebts = useSetRecoilState(debtsState);
  const setEquities = useSetRecoilState(equitiesState);
  const setVatPackageAtom = useSetRecoilState(vatPackageState);
  const setVatRateAtom = useSetRecoilState(vatState);
  const setCorporationTaxAtom = useSetRecoilState(corporationTaxState);

  //DIVISIONAL CONSOLIDATION STATES
  const [overheadConsolidation, setOverheadConsolidation] = useRecoilState(overheadsConsolidationState);
  const [revenueAndCostConsolidation, setRevenueAndCostConsolidation] = useRecoilState(revenueAndCostConsolidationState);
  const [headcountsConsolidation, setHeadcountsConsolidation] = useRecoilState(headcountsConsolidationState);

  const [loadingProject, setLoadingProject] = useRecoilState(projectLoadingState);

  // window.addEventListener("error", async (errorEvent) => {
  //
  //   console.log('ERROR FOUND!')
  //
  //   const project = await getProjectId();
  //   const errorDescription = `An error occurred: ${errorEvent.message}`;
  //   const stackTrace = errorEvent.error ? errorEvent.error.stack : 'Stack trace not available';
  //
  //   const auditDto = {
  //     actionedBy: "Powdr", // Adjust based on how you identify users
  //     timestamp: new Date().toISOString(),
  //     description: errorDescription,
  //     eventType: "POWDR_ERROR",
  //     payloadObject: stackTrace,
  //     projectId: project.id,
  //     projectName: "Your Project Name", // Adjust as necessary
  //   };
  //
  //   // Replace `http://your-backend.com/api/audits` with the actual endpoint
  //   axios.post(`audit/${project.id}`, auditDto)
  //     .then(response => console.log('Audit logged successfully', response))
  //     .catch(error => console.error('Error logging audit', error));
  // });

  async function collateConsolidatedData(project) {
    const projectList = project.projectMultiProjectList;

    // Use Promise.all to handle all overheads requests concurrently
    const overheadPromises = projectList.map(linkedProject =>
      axios.get("overheads/" + linkedProject.project)
    );
    const overheads = await Promise.all(overheadPromises);
    const newOverheadsConsolidation = overheads.reduce((acc, response) => acc.concat(response.data), structuredClone(overheadConsolidation));
    setOverheadConsolidation(newOverheadsConsolidation);

    // Use Promise.all for revenuecos requests
    const revenuePromises = projectList.map(linkedProject =>
      axios.get("revenuecos/" + linkedProject.project)
    );
    const revenues = await Promise.all(revenuePromises);
    const newRevenueAndCostConsolidation = revenues.reduce((acc, response) => acc.concat(response.data), structuredClone(revenueAndCostConsolidation));
    setRevenueAndCostConsolidation(newRevenueAndCostConsolidation);

    // Use Promise.all for headcounts requests
    const headcountPromises = projectList.map(linkedProject =>
      axios.get("headcounts/" + linkedProject.project)
    );
    const headcounts = await Promise.all(headcountPromises);
    const newHeadcountsConsolidation = headcounts.reduce((acc, response) => acc.concat(response.data), structuredClone(headcountsConsolidation));
    setHeadcountsConsolidation(newHeadcountsConsolidation);
  }

  async function loadSessionStoredProject() {


    let sessionProject = sessionStorage.getItem("project")

    if (sessionProject !== undefined
      && sessionProject !== null
      && sessionProject !== "undefined") {

    const project = JSON.parse(sessionStorage.getItem("project"));

        setLoadingProject(true)

        let vatRate = [];
        let overheads = [];
        let products = [];
        let currentAssets = [];

        await axios.get("vat/corporationtax/" + project.id).then(function(response) {setCorporationTaxAtom(response.data);});
        await axios.get("vat/vatrate/" + project.id).then(function(response) {setVatRateAtom(response.data); vatRate = structuredClone(response.data)});
        await axios.get("pnl/" + project.id).then(function(response) {setPnl(response.data);});
        await axios.get("balance/" + project.id).then(function(response) {setBalanceSheet(response.data);});
        await axios.get("cashflow/" + project.id).then(function(response) {setCashflow(response.data);});
        await axios.get("revenuecos/" + project.id).then(function(response) {setRevenueAndCost(response.data); products = structuredClone(response.data)});
        await axios.get("overheads/" + project.id).then(function(response) {setOverheads(response.data); overheads = structuredClone(response.data)});
        await axios.get("headcounts/" + project.id).then(function(response) {setHeadcounts(response.data);});
        await axios.get("fixedassets/" + project.id).then(function(response) {setFixedAssetAtom(response.data);});
        await axios.get("currentassets/" + project.id).then(function(response) {setCurrentAssets(response.data); currentAssets = structuredClone(response.data)});
        await axios.get("currentliabilities/" + project.id).then(function(response) {setCurrentLiabilities(response.data);});
        await axios.get("debt/" + project.id).then(function(response) {setDebts(response.data);});
        await axios.get("equity/" + project.id).then(function(response) {setEquities(response.data);});

        let vatPackage = refreshVatPackage(products, overheads, vatRate, currentAssets);
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        //if this is a P&L divisional consolidation project, then we need to import the revenue, overheads & headcounts for EACH project that this model includes.
        if (project.projectType === 'PNL_DIVISIONAL') {


          let overheadsConsolidated = [];
          let productsConsolidated = [];
          let headcountsConsolidated = []


          await collateConsolidatedData(project, overheadsConsolidated, productsConsolidated, headcountsConsolidated);

          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();

        }

      setLoadingProject(false);



    } else {
      setLoadingProject(false)
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    loadSessionStoredProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      <Chatbot></Chatbot>
      <LoadingModal open={loadingProject} title={'Loading Project'} description={'Loading Project Resources, please wait...'} />

      <CssBaseline />

      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent sx={{ display: { xs: "max-width: 100%", lg: "calc(100% - 258px)" } }}>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
          <BrowserAlert></BrowserAlert>
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
