import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { cardStyles, cellStyles } from "../../../SharedComponents/styles/SharedStyles";
import getProjectId, { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditEquity from "./EditEquity";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { equitiesState } from "../../../SharedState/EquitiesState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { Skeleton } from "@mui/material";

const Card = styled(MuiCard)(spacing);

const helpTitles = new Map();

helpTitles.set('Addition', 'Additions should be a positive value');
helpTitles.set('Deduction', 'Deductions should be a negative value');



function EquityInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);

  const setEquities = useSetRecoilState(equitiesState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  const {
    equityItem,
    equity,
    refreshData,
    setEditData,
    editData,
    setEquity,
    loadData
  } = props;

  const deleteEquity = (handleClose) => {
    var config = {
      method: "delete",
      url: "equity",
      data: equityItem.equityDto,
    };
    axios(config)
      .then(async function(response) {

        setEquity([...response.data]);
        setEquities(structuredClone(response.data));

        let project = await getProjectId();

        await axios.get("balance/" + project.id).then(function(response) {
          setBalanceSheet(response.data);
        });
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={equityItem.equityDto}
          categories={equityItem.equityCategoryDtos}
          deleteMethod={deleteEquity}
          refreshDataMethod={loadData}
          editAssumptionComponent={<EditEquity equityNames={[]}
                                               loadData={loadData}
                                               equity={equityItem.equityDto}
                                               open={openEdit}
                                               setOpen={setOpenEdit}>
          </EditEquity>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-Equity-" + equityItem.equityDto.name + "-" + new Date().toDateString(),
            sheet : "Product",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'equity/equityShallow'}
          type={"EQUITY"}
        ></PowdrNavBar>

        <Card mb={6} sx={{...cardStyles}}
              id={equityItem.equityDto.name.replaceAll(" ", "-") + "-table"}>
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={equityItem.equityDto.name + "-ROW"}
                  id={equityItem.equityDto.name + "-ROW"}
                >
                  <TableCell
                    key={equityItem.equityDto.name + "-BASE"}
                    id={equityItem.equityDto.name + "-BASE"}
                    sx={{...cellStyles}}>
                    <h2>{equityItem.equityDto.name}</h2>
                    <AssumptionLabel label={equityItem.equityDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={equityItem.equityDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {equityItem.equityCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={row.name} alt={helpTitles.get(row.name)}></RowTitle>

                    {row.equityMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={equity}
                        refreshItemData={refreshData}
                        setItems={setEquity}
                        assumption={equityItem}
                        categoryName={row.name}
                        parentName={equityItem.equityDto.name}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    )
  }


  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default EquityInput;
