import { atom } from 'recoil';
import { refreshApiCall } from "./RecoilUtils";

export const corporationTaxState = atom({
  key: 'corporationTaxState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({setSelf}) => {
      refreshApiCall(setSelf, "vat/corporationtax");
    }
  ]
});
