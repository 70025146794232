import { TableCell, TableHead, TableRow } from "@mui/material";
import { assumptionTitleBlock } from "./styles/SharedStyles";
import { AssumptionLabel } from "./AssumptionLabel";
import React from "react";
import MonthHeader from "./MonthHeader";


export default function PowdrTableHeader(props) {

  const { name, assumption, analysisAssumption, XaxisHeaders, typeColour } = props;

  return (
    <React.Fragment>
      <TableHead>
        <TableRow key={name+"_ROW"} id={name+"_ROW"}>

          <TableCell
            key={"BASE"}
            id={"BASE"}
            style={{ position: "sticky", left: 0 }}
            sx={{...assumptionTitleBlock}}
          >
            <h2>{name}</h2>
            {!!analysisAssumption &&
              <AssumptionLabel label={analysisAssumption} color={typeColour} />
            }

            {!!assumption &&
            <AssumptionLabel label={assumption} color={"success"} />
            }

          </TableCell>

          {XaxisHeaders.map((yHeader, index) => (
            <MonthHeader key={index} yHeader={yHeader} name={name}
                         index={index}></MonthHeader>
          ))}

        </TableRow>
      </TableHead>
    </React.Fragment>
  );


}