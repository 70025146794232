import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Button, Paper, Typography } from "@mui/material";

import VerifyUserInput from "../../components/auth/VerifyUserInput";
import axios from "axios";
import useAuth from "../../hooks/useAuth";


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;


function VerifyUser() {

  const { user } = useAuth();


  function refreshSecurityCode() {

    axios.post("security/refreshSecurityCode", {
      securityCode: "",
      userId: user.id,
    }).then(function (response) {
      window.alert('Security Code Refreshed, please check your mobile phone.')
    });

  };


  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Verify User" />

        <Typography component="h1" variant="h3" align="center" gutterBottom>
          Verify Account
        </Typography>

        <Typography component="h1" variant="h3" align="center" gutterBottom>
          <Button size="large" align="center" onClick={refreshSecurityCode}>
            <Typography color={"blue"} component="h1" variant="h4" align="center" gutterBottom>
              Resend
            </Typography>
          </Button>
        </Typography>

        <Typography component="h2" variant="body" align="center">
          Enter the verification code you have received on your mobile phone
        </Typography>

        <VerifyUserInput />
      </Wrapper>
    </React.Fragment>
  );
}

export default VerifyUser;
