import useProject from "../../../../hooks/useProject";
import React, { useState } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { addMonthsToDate, getMonthsBetween } from "../../SharedComponents/utils/Utils";

const validationSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  startYear: Yup.string().required("Required"),
  startMonth: Yup.string().required("Required"),
});

function ProjectSetup(props) {
  const { project, setProject } = useProject();
  const [initialValues] = useState({
    projectName: project?.name,
    startYear: "" + new Date(project?.startDate).getFullYear(),
    startMonth: "" + (new Date(project?.startDate).getMonth()),
    startForecastYear: "" + addMonthsToDate(new Date(project?.startDate), project?.firstForecast).getFullYear(),
    startForecastMonth: "" + (addMonthsToDate(new Date(project?.startDate), project?.firstForecast).getMonth()),
    hideDefaultGroups: project?.hideDefaultGroups,
  });

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const startDate_ = new Date();
      startDate_.setFullYear(values.startYear, values.startMonth, 1);

      const firstForecastDate_ = new Date();
      firstForecastDate_.setFullYear(values.startForecastYear, values.startForecastMonth, 1);

      let noMonths = getMonthsBetween(startDate_, firstForecastDate_);

      var data = {
        name: values.projectName,
        owner: project.owner,
        id: project.id,
        projectType: project.projectType,
        projectMultiProjectList: project.projectMultiProjectList,
        disabled: false,
        deleted: false,
        license: project.license,
        firstForecast: noMonths,
        firstBalanceForecast: noMonths,
        initialised: project.initialised,
        startDate: startDate_,
        hideDefaultGroups: values.hideDefaultGroups,
        folderId: project.folderId,
      };

      axios
        .put("project", data)
        .then(function (response) {
          setProject(response.data);
          setStatus({ sent: true });
        })
        .catch(function (error) {
          setStatus({ sent: false });
        });

      setSubmitting(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Project Setup
        </Typography>
        <Typography variant="body2" gutterBottom>
          Update your project's name
        </Typography>

        {initialValues == null ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit}>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your project has been successfully updated! (If you have updated the <strong>first forecast values</strong>, then this may take several minutes to complete in the background)
                  </Alert>
                )}
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.projectName && errors.projectName)}
                      margin="dense"
                      name="projectName"
                      label="Project Name"
                      type="text"
                      value={values.projectName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {project && project?.projectType === 'PNL_DIVISIONAL' &&
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Field name="hideDefaultGroups">
                        {({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                onChange={(event) => setFieldValue(field.name, event.target.checked)}
                                name="hideDefaultGroups"
                              />
                            }
                            label="Hide Default Groups (*when all values are 0)"
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                }

                <DialogActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
}

export default ProjectSetup;
