import React from 'react';
import { Box, ListItemButton, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
import styled from "@emotion/styled";

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const fontSizeCalculator = (text) => {
  if (text.length < 25) {
    return '35px';
  }
  if (text.length >= 25 && text.length < 75) {
    return '25px';
  }
  return '16px';
};

const ProjectNameTypography = styled(Typography)`
  font-size: ${(props) => props.fontSize};
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  font-weight: bold;
`;

const ProjectNameComponent = ({ project }) => {
  const fontSize = fontSizeCalculator(project.name);

  return (
    <Brand component={NavLink} to="/projectsetup">
      <Box ml={1} sx={{ width: '100%', maxWidth: '600px', maxHeight: '300px', paddingBottom: 5, paddingTop : 5 }}>
        <ProjectNameTypography fontSize={fontSize}>
          {project.name}
        </ProjectNameTypography>
        <Typography
          variant="body1"
          align="center"
          display={project.projectType === 'PNL_DIVISIONAL' ? 'block' : 'none'}
        >
          {project?.projectMultiProjectList?.length} project(s) linked
        </Typography>
      </Box>
    </Brand>
  );
};

export default ProjectNameComponent;
