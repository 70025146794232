import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  LinearProgress, Button, Stack
} from "@mui/material";
import { spacing } from "@mui/system";

import Settings from "../../../../components/Settings";
import PnLAnnualInput from "./components/PnLAnnualInput";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";

import ExportBar from "../../../presentation/PowdrLanding/ExportBar";
import ExportBarFooter from "../../../presentation/PowdrLanding/ExportBarFooter";
import { exportScreen } from "../../SharedComponents/utils/ExportUtils";
import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../SharedState/ZoomState";
import { createExport } from "../../Export/calculations/Calculations";
import { pnlState } from "../../SharedState/PnLState";
import { calculatePnlAnnuals } from "./calculations/Calculations";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

const Divider = styled(MuiDivider)(spacing);

function PLAnnual() {

  const pnlAtom = useRecoilValue(pnlState);
  // const balanceAtom = useRecoilValue(balanceSheetState);

  const [pnlLoaded, setPnlLoaded] = useState(false);
  const [pnl, setPnl] = useState([]);
  const componentRef = useRef();
  const tableRef = useRef(null);

  const zoomAtom = useRecoilValue(zoomState);

  const updatePowdrModelGlobalRef = useRef();
  // const [recalculateCount, setRecalculateCount] = useState(0);
  // const [recalculateImbalanceCount, setRecalculateImbalanceCount] = useState(0);

  async function loadPnL(){
    //calculate annuals
    let pnlAnnuals = calculatePnlAnnuals(structuredClone(pnlAtom));
    setPnl([pnlAnnuals]);
    setPnlLoaded(true);

    // if (recalculateCount < 2) {
    //   updatePowdrModelBalance()
    //   console.log('Auto recalculating, round: ' + recalculateCount);
    //   setRecalculateCount(current=> current + 1)
    // }
    //
    // if (isBalanceSheetImbalanced(structuredClone(balanceAtom)) && recalculateImbalanceCount < 3) {
    //   console.log('Balance sheet is imbalanced, trying to recalc for the: ' + recalculateImbalanceCount + " time.")
    //   updatePowdrModelBalance()
    //   setRecalculateImbalanceCount(current=> current + 1)
    // }
  }

  // function updatePowdrModelBalance() {
  //   updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
  // }

  useEffect(() => {
    loadPnL();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlAtom]);

  useEffect(() => {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
  },[]);

  return (
    <React.Fragment >

      <Helmet title="Blank" />

      <PageHeader pageName={"Profits & Loss"} parentName={"Annual Output"}/>

      <Divider my={6} />


      <ControlsBar
        addAssumptionElement={
          <Stack  direction="row">
            <Button disabled={zoomAtom !== 1}  type="submit" variant="contained" onClick={() => exportScreen('P&L', componentRef)} sx={{marginBottom: 5}}>
              Export PNG
            </Button>

            <Button type="submit" variant="contained" onClick={() => createExport(pnl[0].pnLCategoryDtoList, pnl[0].pnLDto, true)} sx={{marginBottom: 5, marginLeft : 5}}>Export Excel</Button>

          </Stack>
        }
      />

      {!pnlLoaded &&  (

        <div>
          <h1>Retrieving P & L...</h1>
          <LinearProgress my={2} />
        </div>

      )}

      <div ref={componentRef}>

      <ExportBar name={getCurrentProject().name} ></ExportBar>

      {!!pnl &&
        pnl.map((pnlitem) => (
          <PnLAnnualInput
            tableRef={tableRef}
            key={pnlitem.pnLDto.id}
            pnLItem={pnlitem}
            pnl={pnl}
            setPnl={setPnl}
          ></PnLAnnualInput>
        ))}

      <ExportBarFooter></ExportBarFooter>

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      </div>

      <Settings saving={null} />

    </React.Fragment>
  );
}

export default PLAnnual;
