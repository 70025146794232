import React, { useEffect, useState } from "react";
import axios from "axios";
import useProject from "../../../../hooks/useProject";

import PageHeader from "../../SharedComponents/PageHeader";
import styled from "@emotion/styled";
import { Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import SnapTable from "./components/SnapTable";
import LoadingModal from "../../SharedComponents/LoadingModal";


const Divider = styled(MuiDivider)(spacing);

export default function Snapshot() {

  const { projectId } = useParams(); // Access projectId from the URL


  const { project } = useProject();
  const [snapshotsData, setSnapshotsData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    let idToUser = project.id;

    if (projectId != null) {
      idToUser = projectId;
    }


    axios.get('/snapshot/' + idToUser).then(function (response) {
      setSnapshotsData(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createSnapshot() {

    let idToUser = project.id;
    setLoading(true)

    axios.put('/snapshot/' + project.id).then(function (response) {
      axios.get('/snapshot/' + idToUser).then(function (response) {
        setSnapshotsData(response.data);
        setLoading(false)
      });
    });
  }

  return (
    <React.Fragment>

      <PageHeader pageName={"Project Snapshots"} parentName={"Snapshot"}/>

      <Divider my={6} />

      <LoadingModal open={loading} title={'Creating Snapshot'} description={'Creating snapshot of project, please wait...'} />

      <Button onClick={() => createSnapshot()}
              mr={2}
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
      >Create Snapshot</Button>

      {!!snapshotsData &&
        <SnapTable data={snapshotsData}></SnapTable>
      }

    </React.Fragment>
  )

}