import { atom } from 'recoil';

/**
 * List of users currently viewing or have recently viewed this project
 * @type {RecoilState<boolean>}
 */
export const usersViewingState = atom({
  key: 'usersViewingState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
