import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import RowTitle from "../../../SharedComponents/RowTitle";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import VatConfigNavBar from "./VatConfigNavBar";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};


function VatConfigInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const zoomAtom = useRecoilValue(zoomState);

  const {
    vatItem,
    vat,
    refreshData,
    setEditData,
    editData,
    setVat,
    loadData,
  } = props;

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  return (
    <React.Fragment key={key}>
      <VatConfigNavBar
        item={vatItem.vatDto}
        categories={vatItem.vatCategoryDtos}
        refreshDataMethod={loadData}
      ></VatConfigNavBar>

      <Card
        mb={6}
        sx={{
          ...tableWidth,
          overflowX: "auto",
          overflow: "scroll",
          paddingTop: 0,
        }}
        id={vatItem.vatDto.name.replaceAll(" ", "-") + "-table"}
      >
        <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
          <Table>
            {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
            <TableHead>
              <TableRow
                key={vatItem.vatDto.name + "-ROW"}
                id={vatItem.vatDto.name + "-ROW"}
              >
                <TableCell
                  key={vatItem.vatDto.name + "-BASE"}
                  id={vatItem.vatDto.name + "-BASE"}
                  style={{ position: "sticky", left: 0 }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    width: 200,
                    backgroundColor: "#F7F9FC",
                  }}
                >
                  <h2>{vatItem.vatDto.name}</h2>
                  <AssumptionLabel label={vatItem.vatDto.assumptionDesc} color="success" />
                </TableCell>
                {XaxisHeaders.map((yHeader, index) => (
                  <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={vatItem.vatDto.name} index={index}></MonthHeader>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>

              {vatItem.vatCategoryDtos.map((row) => (
                <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                  <RowTitle name={row.name}></RowTitle>

                  {row.vatMonthDtos.map((month) => (
                    <PowdrCell
                      key={month.id + "-CELL"}
                      cellData={month}
                      ownerId={row.id}
                      editTableEnabled={editData}
                      items={vat}
                      refreshItemData={refreshData}
                      setItems={setVat}
                    ></PowdrCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default VatConfigInput;
