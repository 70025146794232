import React, { useRef } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { balanceSheetState } from "../SharedState/BalanceSheetState";
import { pnlState } from "../SharedState/PnLState";
import UpdatePowdrModelGlobal from "./UpdatePowdr/UpdatePowdrModelGlobal";

export default function DeleteModal(props) {

    const updatePowdrModelGlobalRef = useRef();

    const balanceAtom = useRecoilValue(balanceSheetState);
    const pnlAtom = useRecoilValue(pnlState);

    const { deleteMethod, open, setOpen, name } = props;
    const [showSpinner, setShowSpinner] = React.useState(false);

    const deleteItem = () => {

      setShowSpinner(true);

      deleteMethod(handleClose);

      //now do a global recalculate
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
    };

    const handleClose = () => {
      setShowSpinner(false);
      setOpen(false)
    };


    return (
      <div>
        <Dialog
          open={open}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpen(false)
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Deletion Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete : <strong>{name}</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={deleteItem} color="primary" autoFocus>
              {showSpinner ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      </div>
    );
  }

