import _, { isArray } from "lodash";

export function findCashflowCategory(cashflow, categoryName) {
  return cashflow.cashflowCategoryDtos.find(category => category.name === categoryName);
}

export function findBalanceCategory(balance, categoryName) {
  return balance.balanceCategoryDtos.find(category => category.overrideName === categoryName || category.name === categoryName);
}

export function findPnlCategory(pnl, categoryName) {
  return pnl.pnLCategoryDtoList.find(category => category.overrideName === categoryName || category.name === categoryName);
}

function copyBalanceMovementRows(cashflow, balance, cashflowCategoryName, balanceCategoryName) {

  let cashflowCategory = findCashflowCategory(cashflow, cashflowCategoryName);

  let balanceCategory = findBalanceCategory(balance, balanceCategoryName);

  _.forEach(cashflowCategory.cashflowMonthDtos, function(cashflowMonthDto, i) {

    let movement = balanceCategory.balanceMonthDtos[i].value - balanceCategory.balanceMonthDtos[i + 1].value;

    cashflowMonthDto.value = movement;
  });

}

function netDebtReceivedPaidMovementRows(cashflow, balance, pnl) {

  let cashflowCategory = findCashflowCategory(cashflow, "Net Debt Received/Paid");

  let balanceCategory = findBalanceCategory(balance, "TOTAL DEBT");

  let pnlCategoryPik = findPnlCategory(pnl, "PIK Interest");

  _.forEach(cashflowCategory.cashflowMonthDtos, function(cashflowMonthDto, i) {

    let movement;

    if (pnlCategoryPik) {
      movement = (balanceCategory.balanceMonthDtos[i].value - balanceCategory.balanceMonthDtos[i + 1].value) + pnlCategoryPik.pnLMonthDtoList[i].value;
    } else {
      movement = balanceCategory.balanceMonthDtos[i].value - balanceCategory.balanceMonthDtos[i + 1].value; //some old projects don't have a PIK category in the P&L
    }

    cashflowMonthDto.value = movement;
  });

  cashflowCategory.movementDetails = debtMovementsIndividual(cashflow, balance, pnl);
}

export function debtMovementsIndividual(cashflow, balance, pnl) {

  //get all the debts that aren't "TOTAL DEBT"
  let debtCategories = balance.balanceCategoryDtos.filter(category => category.overrideName !== "TOTAL DEBT" && category.name === "Debt");

  let debtMovements = {
    categories: []
  };

  //Calculate the movement for each and create a record in the array
  _.forEach(debtCategories, function(debt, x) {

    //build the category
    let category = {
      name: debt.overrideName + " Movement",
      id: debt.id,
      cashflowMonthDtos : []
    }

    _.forEach(debt.balanceMonthDtos, function(balanceMonthDto, i) {

      if (i < debt.balanceMonthDtos.length - 1) {
        category.cashflowMonthDtos.push(
          {
            cellType: "CALCULATED",
            id: balanceMonthDto.id,
            month: balanceMonthDto.month,
            monthIndex: balanceMonthDto.monthIndex,
            value: debt.balanceMonthDtos[i].value - debt.balanceMonthDtos[i + 1].value,
            valueFormat: "INTEGER"
          }
        );
      }

    });

    debtMovements.categories.push(category);

  });

  //now do the Total Debt Movement
  let balanceCategory = findBalanceCategory(balance, "TOTAL DEBT");

  let pnlCategoryPik = findPnlCategory(pnl, "PIK Interest");

  let category = {
    name: "Net Debt Received/Paid",
    id: 9999,
    totalField: true,
    cashflowMonthDtos : []
  }

  _.forEach(balanceCategory.balanceMonthDtos, function(balanceMonthDto, i) {

    let movement;

    if (i < balanceCategory.balanceMonthDtos.length - 1) {

      if (pnlCategoryPik) {
        movement = (balanceCategory.balanceMonthDtos[i].value - balanceCategory.balanceMonthDtos[i + 1].value) + pnlCategoryPik.pnLMonthDtoList[i].value;
      } else {
        movement = balanceCategory.balanceMonthDtos[i].value - balanceCategory.balanceMonthDtos[i + 1].value; //some old projects don't have a PIK category in the P&L
      }

      category.cashflowMonthDtos.push(
        {
          cellType: "CALCULATED",
          id: balanceMonthDto.id,
          month: balanceMonthDto.month,
          monthIndex: balanceMonthDto.monthIndex,
          value: movement,
          valueFormat: "INTEGER"
        }
      );
    }

  });

  debtMovements.categories.push(category);

  //return the array
  return debtMovements;
}

function copyClosingBalance(cashflow, balance) {

  let cashflowClosingCashBalance = findCashflowCategory(cashflow, "Closing Cash Balance");

  let cashAtBankBalance = findBalanceCategory(balance, "Cash at bank");

  _.forEach(cashflowClosingCashBalance.cashflowMonthDtos, function(cashflowMonthDto, i) {
    if (i !== cashflowClosingCashBalance.cashflowMonthDtos.length) {
      cashAtBankBalance.balanceMonthDtos[i + 1].value = cashflowMonthDto.value;
    }
  });

}

function calculateNetCashflow(cashflow) {

  let cashflowNetCashflow = findCashflowCategory(cashflow, "Net Cashflow");

  let cashflowEBITDA = findCashflowCategory(cashflow, "EBITDA");

  let cashflowFromOperatingActivities = findCashflowCategory(cashflow, "Cashflow From Operating Activities");

  let cashflowFromInvestingActivities = findCashflowCategory(cashflow, "Cashflow From Investing Activities");

  let cashflowFromFinanceActivities = findCashflowCategory(cashflow, "Cashflow From Financing Activities");

  _.forEach(cashflowNetCashflow.cashflowMonthDtos, function(cashflowMonthDto, i) {

    cashflowMonthDto.value =
      cashflowEBITDA.cashflowMonthDtos[i].value +
      cashflowFromOperatingActivities.cashflowMonthDtos[i].value +
      cashflowFromInvestingActivities.cashflowMonthDtos[i].value +
      cashflowFromFinanceActivities.cashflowMonthDtos[i].value;

  });

}

function copyPnlRows(cashflow, pnl, cashflowCategoryName, pnlCategoryName) {

  let cashflowCategory = findCashflowCategory(cashflow, cashflowCategoryName);

  let pnlCategory = findPnlCategory(pnl, pnlCategoryName);

  if (pnlCategory === undefined || cashflowCategory === undefined) {
    return;
  }

  _.forEach(cashflowCategory.cashflowMonthDtos, function(cashflowMonthDto, i) {
    cashflowMonthDto.value = pnlCategory.pnLMonthDtoList[i].value;
  });

}

function calculateTaxPaid(cashflow, pnl, balance) {

  let cashflowTaxPaid = findCashflowCategory(cashflow, "Tax Paid");

  let corporationTaxBalance = findBalanceCategory(balance, "Corporation Tax");

  let corporationTaxPnl = findPnlCategory(pnl, "Corporation Tax");

  _.forEach(cashflowTaxPaid.cashflowMonthDtos, function(cashflowMonthDto, i) {

    cashflowMonthDto.value =
      (corporationTaxBalance.balanceMonthDtos[i].value + corporationTaxPnl.pnLMonthDtoList[i].value) -
          corporationTaxBalance.balanceMonthDtos[i + 1].value;

  });

}

function currentAssetMovementIndividual(cashflow, balance, cashflowCurrentAssetsTotals) {

  let currentAssetCategories = balance.balanceCategoryDtos.filter(category => !["Inventory",
    "Trade debtors", "Cash at bank", "TOTAL CURRENT ASSETS"].includes(category.overrideName) && category.balanceType === "CURRENT_ASSETS");

  let movements = {
    categories: []
  };

  //Calculate the movement for each and create a record in the array
  _.forEach(currentAssetCategories, function(currentAssetCategory, x) {

    //build the category
    let category = {
      name: currentAssetCategory.overrideName + " Movement",
      id: currentAssetCategory.id,
      cashflowMonthDtos : []
    }

    _.forEach(currentAssetCategory.balanceMonthDtos, function(balanceMonthDto, i) {

      if (i < currentAssetCategory.balanceMonthDtos.length - 1) {
        category.cashflowMonthDtos.push(
          {
            cellType: "CALCULATED",
            id: balanceMonthDto.id,
            month: balanceMonthDto.month,
            monthIndex: balanceMonthDto.monthIndex,
            value: currentAssetCategory.balanceMonthDtos[i].value - currentAssetCategory.balanceMonthDtos[i + 1].value,
            valueFormat: "INTEGER"
          }
        );
      }

    });

    movements.categories.push(category);

  });

  //now do the "Movement In Other Current Assets"
  let categoryTotal = {
    name: "Movement In Other Current Assets",
    id: 9999,
    totalField: true,
    cashflowMonthDtos : []
  }

  _.forEach(cashflowCurrentAssetsTotals.cashflowMonthDtos, function(cashflowMonthDto, i) {

    categoryTotal.cashflowMonthDtos.push(
      {
        cellType: "CALCULATED",
        id: cashflowMonthDto.id,
        month: cashflowMonthDto.month,
        monthIndex: cashflowMonthDto.monthIndex,
        value: cashflowMonthDto.value,
        valueFormat: "INTEGER"
      }
    );

  });

  movements.categories.push(categoryTotal);

  return movements;
}

function calculateMovementInOtherCurrentAssets(cashflow, balance) {

  let currentAssetCategories = balance.balanceCategoryDtos.filter(category => !["Inventory",
    "Trade debtors", "Cash at bank", "TOTAL CURRENT ASSETS"].includes(category.overrideName) && category.balanceType === "CURRENT_ASSETS");

  let cashflowCurrentAssets = findCashflowCategory(cashflow, "Movement In Other Current Assets");

  //go through all the current assets and total them up, then do the movements
  let currentAssetTotals = [];

  _.forEach(currentAssetCategories, function(category, x) {
    _.forEach(category.balanceMonthDtos, function(balanceMonth, i) {
      if (currentAssetTotals[i] === undefined) {
        currentAssetTotals[i] = 0;
      }
      currentAssetTotals[i] += isNaN(balanceMonth.value) ? 0 : balanceMonth.value;
    });
  });

  //copy the movements
  _.forEach(cashflowCurrentAssets.cashflowMonthDtos, function(cashflowMonthDto, i) {

    let movement = currentAssetTotals[i] - currentAssetTotals[i + 1];
    cashflowMonthDto.value = movement;
  });

  cashflowCurrentAssets.movementDetails = currentAssetMovementIndividual(cashflow, balance, cashflowCurrentAssets);
}

function currentLiabiliityMovementsIndividual(cashflow, balance, cashflowCurrentLiabilityTotals) {

  let currentLiabilityCategories = balance.balanceCategoryDtos.filter(category => !["VAT", "NIC and PAYE", "Corporation Tax", "Trade creditors", "TOTAL CURRENT LIABILITIES"].includes(category.overrideName) && category.balanceType === "CURRENT_LIABILITIES");

  let movements = {
    categories: []
  };

  //Calculate the movement for each and create a record in the array
  _.forEach(currentLiabilityCategories, function(currentLiabilityCategory, x) {

    //build the category
    let category = {
      name: currentLiabilityCategory.overrideName + " Movement",
      id: currentLiabilityCategory.id,
      cashflowMonthDtos : []
    }

    _.forEach(currentLiabilityCategory.balanceMonthDtos, function(balanceMonthDto, i) {

      if (i < currentLiabilityCategory.balanceMonthDtos.length - 1) {
        category.cashflowMonthDtos.push(
          {
            cellType: "CALCULATED",
            id: balanceMonthDto.id,
            month: balanceMonthDto.month,
            monthIndex: balanceMonthDto.monthIndex,
            value: currentLiabilityCategory.balanceMonthDtos[i].value - currentLiabilityCategory.balanceMonthDtos[i + 1].value,
            valueFormat: "INTEGER"
          }
        );
      }

    });

    movements.categories.push(category);

  });

  //now do the "Movement In Other Current Liabilities"
  let categoryTotal = {
    name: "Movement In Other Current Assets",
    id: 9999,
    totalField: true,
    cashflowMonthDtos : []
  }

  _.forEach(cashflowCurrentLiabilityTotals.cashflowMonthDtos, function(cashflowMonthDto, i) {

    categoryTotal.cashflowMonthDtos.push(
      {
        cellType: "CALCULATED",
        id: cashflowMonthDto.id,
        month: cashflowMonthDto.month,
        monthIndex: cashflowMonthDto.monthIndex,
        value: cashflowMonthDto.value,
        valueFormat: "INTEGER"
      }
    );

  });

  movements.categories.push(categoryTotal);

  return movements;
}

function calculateCurrentLiabilities(cashflow, balance) {

  let currentLiabilityCategories = balance.balanceCategoryDtos.filter(category => !["VAT", "NIC and PAYE", "Corporation Tax", "Trade creditors", "TOTAL CURRENT LIABILITIES"].includes(category.overrideName) && category.balanceType === "CURRENT_LIABILITIES");

  let cashflowCurrentLiabilities = findCashflowCategory(cashflow, "Movement In Other Current Liabilities");

  //go through all the current assets and total them up, then do the movements
  let currentLiabilityTotals = [];

  _.forEach(currentLiabilityCategories, function(category, x) {
    _.forEach(category.balanceMonthDtos, function(balanceMonth, i) {
      if (currentLiabilityTotals[i] === undefined) {
        currentLiabilityTotals[i] = 0;
      }
      currentLiabilityTotals[i] += isNaN(balanceMonth.value) ? 0 : balanceMonth.value;
    });
  });

  //copy the movements
  _.forEach(cashflowCurrentLiabilities.cashflowMonthDtos, function(cashflowMonthDto, i) {

    let movement = currentLiabilityTotals[i] - currentLiabilityTotals[i + 1];
    cashflowMonthDto.value = movement;
  });

  cashflowCurrentLiabilities.movementDetails = currentLiabiliityMovementsIndividual(cashflow, balance, cashflowCurrentLiabilities);

}

function calculateCapex(cashflow, balance, pnl) {

  let cashflowCapex = findCashflowCategory(cashflow, "Capital Expenditure");

  let fixedAssetsBalance = findBalanceCategory(balance, "TOTAL FIXED ASSETS");

  let depreciationPnl = findPnlCategory(pnl, "Depreciation/Amortisation");

  let capexMonthSize = cashflowCapex.cashflowMonthDtos.length

  for (let i=1; i <= capexMonthSize; i++) {

    cashflowCapex.cashflowMonthDtos[i - 1].value =
      (fixedAssetsBalance.balanceMonthDtos[i - 1].value + depreciationPnl.pnLMonthDtoList[i - 1].value)
      - fixedAssetsBalance.balanceMonthDtos[i].value;

  }

}

function calculateEquity(cashflow, balance, pnl) {

  let movementInEquity = findCashflowCategory(cashflow, "Movement In Equity");

  let equityAssetsBalance = findBalanceCategory(balance, "TOTAL EQUITY");

  let netProfit = findPnlCategory(pnl, "Net Profit");

  let capexMonthSize = movementInEquity.cashflowMonthDtos.length

  for (let i=1; i <= capexMonthSize; i++) {

    movementInEquity.cashflowMonthDtos[i - 1].value =
      equityAssetsBalance.balanceMonthDtos[i].value
       - (equityAssetsBalance.balanceMonthDtos[i - 1].value + netProfit.pnLMonthDtoList[i - 1].value);

  }

}

function calculateCashflowFromOperatingActivities(cashflow) {

  let cashflowCategories = cashflow.cashflowCategoryDtos.filter(category =>
    ["Movement In Trade Debtors",
    "Movement In Inventory",
    "Movement In Other Current Assets",
    "Movement In Trade Creditors",
    "Movement In Vat",
    "Movement In PAYE",
    "Movement In Other Current Liabilities",
    "Exceptionals",
    "Tax Paid"].includes(category.name));

  let cashflowFromOperating = findCashflowCategory(cashflow, "Cashflow From Operating Activities");


  //go through all the current assets and total them up, then do the movements
  let totals = [];

  _.forEach(cashflowCategories, function(category, x) {
    _.forEach(category.cashflowMonthDtos, function(cashflowMonth, i) {
      if (totals[i] === undefined) {
        totals[i] = 0;
      }

      totals[i] += isNaN(Number(cashflowMonth.value)) ? 0 : cashflowMonth.value;
    });
  });

  //copy the totals
  _.forEach(cashflowFromOperating.cashflowMonthDtos, function(cashflowMonthDto, i) {
    cashflowMonthDto.value = totals[i];
  });

}

function calculateCashflowFromFinancingActivities(cashflow) {

  let cashflowCategories = cashflow.cashflowCategoryDtos.filter(category => ["Net Interest Paid", "Net Debt Received/Paid"].includes(category.name));

  let cashflowFromOperating = findCashflowCategory(cashflow, "Cashflow From Financing Activities");


  //go through all the current assets and total them up, then do the movements
  let totals = [];

  _.forEach(cashflowCategories, function(category, x) {
    _.forEach(category.cashflowMonthDtos, function(cashflowMonth, i) {
      if (totals[i] === undefined) {
        totals[i] = 0;
      }
      totals[i] += isNaN(Number(cashflowMonth.value)) ? 0 : cashflowMonth.value;
    });
  });

  //copy the totals
  _.forEach(cashflowFromOperating.cashflowMonthDtos, function(cashflowMonthDto, i) {
    cashflowMonthDto.value = totals[i];
  });

}

function calculateCashflowFromInvestingActivities(cashflow) {

  let cashflowCategories = cashflow.cashflowCategoryDtos.filter(category => ["Capital Expenditure", "Dividends", "Movement In Equity"].includes(category.name));

  let cashflowFromOperating = findCashflowCategory(cashflow, "Cashflow From Investing Activities");


  //go through all the current assets and total them up, then do the movements
  let totals = [];

  _.forEach(cashflowCategories, function(category, x) {
    _.forEach(category.cashflowMonthDtos, function(cashflowMonth, i) {
      if (totals[i] === undefined) {
        totals[i] = 0;
      }
      totals[i] += isNaN(Number(cashflowMonth.value)) ? 0 : cashflowMonth.value;
    });
  });

  //copy the totals
  _.forEach(cashflowFromOperating.cashflowMonthDtos, function(cashflowMonthDto, i) {
    cashflowMonthDto.value = totals[i];
  });

}

function calculateCashflowCorkscrew(cashflow, balance) {

  let cashflowClosingCashBalance = findCashflowCategory(cashflow, "Closing Cash Balance");

  let cashflowOpeningCashBalance = findCashflowCategory(cashflow, "Opening Cash Balance");

  let cashflowNetCashflow = findCashflowCategory(cashflow, "Net Cashflow");

  let cashAtBankBalance = findBalanceCategory(balance, "Cash at bank");

  _.forEach(cashflowClosingCashBalance.cashflowMonthDtos, function(cashflowMonthDto, i) {

    if (i === 0) {
      cashflowMonthDto.value =
        cashflowOpeningCashBalance.cashflowMonthDtos[i].value + cashflowNetCashflow.cashflowMonthDtos[i].value;

      cashflowOpeningCashBalance.cashflowMonthDtos[i].value =
        cashAtBankBalance.balanceMonthDtos[0].value;
    } else {

      //set the opening cashflow to be the previous months closing cashflow
      cashflowOpeningCashBalance.cashflowMonthDtos[i].value = cashflowClosingCashBalance.cashflowMonthDtos[i - 1].value;

      //closing cash balance = opening + net cashflow
      cashflowMonthDto.value = cashflowOpeningCashBalance.cashflowMonthDtos[i].value + cashflowNetCashflow.cashflowMonthDtos[i].value;
    }

  });

}

export function calculateCashflow(cashflow, balance, pnl) {

  if (cashflow   !== undefined && !isArray(cashflow)
      && balance !== undefined && !isArray(balance)
      && pnl     !== undefined && !isArray(pnl)) {

    //calculate Cash Flow

    //EBITDA - Set the EBITDA (Pulled from the P & L)
    copyPnlRows(cashflow, pnl, "EBITDA", "EBITDA");

    //Movement In Trade Debtors - this is the difference between trade debtor months
    copyBalanceMovementRows(cashflow, balance, "Movement In Trade Debtors", "Trade debtors");

    //Movement In Inventory - this is the difference between inventory months
    copyBalanceMovementRows(cashflow, balance, "Movement In Inventory", "Inventory");

    //Movement In Current Assets - this is the difference between Current Assets months
    calculateMovementInOtherCurrentAssets(cashflow, balance);

    //Movement In Trade Creditors - this is the difference between Trade Creditors months
    copyBalanceMovementRows(cashflow, balance, "Movement In Trade Creditors", "Trade creditors");

    //Movement In VAT - this is the difference between VAT months
    copyBalanceMovementRows(cashflow, balance, "Movement In Vat", "VAT");

    //Movement In PAYE - this is the difference between PAYE months
    copyBalanceMovementRows(cashflow, balance, "Movement In PAYE", "NIC and PAYE");

    //Movement In Current Liabilities - this is the difference between Current Liabilities months
    calculateCurrentLiabilities(cashflow, balance);

    //Exceptionals - this is the exceptions from the P&L
    copyPnlRows(cashflow, pnl, "Exceptionals", "Exceptionals");

    //Tax Paid - this is the corporation tax bills from the balance and p&l
    calculateTaxPaid(cashflow, pnl, balance);

    //Cashflow From Operating Activities - this is the total of all the rows above (excluding EBITDA?)
    calculateCashflowFromOperatingActivities(cashflow);

    //Capital Expenditure - this is the Trade creditors Capital Expenditure (I think)
    calculateCapex(cashflow, balance, pnl);

    //Dividends - this comes from the P&L
    copyPnlRows(cashflow, pnl, "Dividends", "Dividends");

    //Movement In Equity - this is the movement in the total equity (I think)
    calculateEquity(cashflow, balance, pnl);

    //Cashflow From Investing Activities - the rows above (Capital Expenditure - Movement In Equity)
    calculateCashflowFromInvestingActivities(cashflow);

    //Net Interest Paid - this come from the P&L
    copyPnlRows(cashflow, pnl, "Net Interest Paid", "Net Interest Expense");

    //Net Debt Received/Paid - Comes from the Debts summary section
    netDebtReceivedPaidMovementRows(cashflow, balance, pnl);

    //Cashflow From Financing Activities -  total of the rows about (Net Interest Paid, Net Debt Received/Paid)
    calculateCashflowFromFinancingActivities(cashflow)

    //Net Cashflow - is "EBITDA" + "Cashflow From Operating Activities" + "Cashflow From Investing Activities" + "Cashflow From Financing Activities"
    calculateNetCashflow(cashflow);

    //Opening is previous months closing
    calculateCashflowCorkscrew(cashflow, balance);

    //Closing Cash Balance - copy closing balance in to the balance sheet (Cash At bank)
    copyClosingBalance(cashflow, balance);

    return cashflow;
  }
}