import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const overheadsState = atom({
  key: 'overheadsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        // Disabled for now, this was just a POC to see if it would work.
        //console.log('ON SET OVERHEADS', newAssumptions, oldAssumptions)
        const project = JSON.parse(sessionStorage.getItem("project"));

        handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
          'overheads/' + project.id + '/months',
          'overheadMonthDtoList',
          'overheadCategoryDtoList',
          'overheadDto');

      });
    },
  ]
});

