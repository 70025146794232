import {
  buildDivisionOfTwoCategories,
  buildPercentageOfTwoCategories,
  buildYearDifferenceCategory, buildYearDifferencePercentageCategory,
  buildYearPercentageDifferenceCategory,
  buildYearTotalCategory,
  buildYearTotalCategoryAverage, isValidNumber
} from "./Calculations";


function buildHeadcountUtilisation(yearSelectedHeadcounts, yearQuantitySold, maxProductiveHoursYearly) {

  let category = {
    name: "Utilisation",
    productMonthDtoList : []
  }

  for (let i=0; i < 6; i++) {

    let yearTotal = ((yearQuantitySold.productMonthDtoList[i].value / maxProductiveHoursYearly.productMonthDtoList[i].value)
                          / (yearSelectedHeadcounts.productMonthDtoList[i].value / 12)) * 100;

    let val = isValidNumber(yearTotal);

    category.productMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
    })
  }

  return category;
}

function buildUtilisation(yearTotalMaxUnits, yearQuantitySold) {

  let category = {
    name: "Utilisation",
    productMonthDtoList : []
  }

  for (let i=0; i < 6; i++) {

    let yearTotal = (yearQuantitySold.productMonthDtoList[i].value / yearTotalMaxUnits.productMonthDtoList[i].value) * 100;

    let val = isValidNumber(yearTotal);

    category.productMonthDtoList.push({
      id: i,
      value: val,
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
    })
  }

  return category;
}

export function createAnnualSummaryHeads(product_) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";



  let averageNumberOfFTEs           = buildYearTotalCategoryAverage(product, product.productCategoryDtoList[0].name, "Selected Headcounts Average");
  let quantitySold                  = buildYearTotalCategory(product, "Quantity Sold");

  let revenueCategory               = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold         = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold, 2);
  let directProductCosts            = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold          = buildDivisionOfTwoCategories("Cost price per unit sold (ex-VAT)", directProductCosts, quantitySold, 2);
  let grossProfit                   = buildYearTotalCategory(product, "Gross Profit");
  let productMargin                 = buildPercentageOfTwoCategories("Product Margin",  revenueCategory, grossProfit);

  let utilisation;

  if (product_.productDto.assumption === 'HEADCOUNT_UTILISATION') {

    let maxProductiveHoursYearly = buildYearTotalCategory(product, product.productCategoryDtoList[1].name) //Max Productive Hours

    utilisation = buildHeadcountUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold, maxProductiveHoursYearly);
  } else {
    utilisation = buildUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold);
  }


  annualSummary.productCategoryDtoList.push(averageNumberOfFTEs, utilisation, quantitySold, salesPricePerUnitSold, revenueCategory, costPricePerUnitSold, directProductCosts, grossProfit, productMargin);

  return annualSummary;
}


export function createGrowthRatesHeads(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";

  let averageNumberOfFTEs           = buildYearTotalCategoryAverage(product, product.productCategoryDtoList[0].name, "Selected Headcounts Average");
  let quantitySold                  = buildYearTotalCategory(product, "Quantity Sold");

  let revenueCategory               = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold         = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold);
  let directProductCosts            = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold          = buildDivisionOfTwoCategories("Cost Price per unit sold (ex-VAT)", directProductCosts, quantitySold);
  let grossProfit                   = buildYearTotalCategory(product, "Gross Profit");
  let productMargin                 = buildPercentageOfTwoCategories("Product Margin",  revenueCategory, grossProfit);

  let utilisation;

  if (product_.productDto.assumption === 'HEADCOUNT_UTILISATION') {

    let maxProductiveHoursYearly = buildYearTotalCategory(product, product.productCategoryDtoList[1].name) //Max Productive Hours

    utilisation = buildHeadcountUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold, maxProductiveHoursYearly);
  } else {
    utilisation = buildUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold);
  }


  let averageNumberOfFTEsGrowth = buildYearPercentageDifferenceCategory(product.productCategoryDtoList[0].name, averageNumberOfFTEs);
  let utilisationGrowth = buildYearPercentageDifferenceCategory("Utilisation", utilisation);
  let quantitySoldFTEsGrowth = buildYearPercentageDifferenceCategory("Quantity Sold", quantitySold);
  let salesPricePerUnitSoldGrowth = buildYearPercentageDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold);
  let revenueGrowth = buildYearPercentageDifferenceCategory("Revenue", revenueCategory);
  let costPricePerUnitSoldGrowth = buildYearPercentageDifferenceCategory("Cost Price per unit sold (ex-VAT)", costPricePerUnitSold, 0, "#FF0000", "#008000");
  let directProductCostsGrowth = buildYearPercentageDifferenceCategory("Direct product costs", directProductCosts, 0, "#FF0000", "#008000");
  let grossProfitGrowth = buildYearPercentageDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth = buildYearPercentageDifferenceCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(averageNumberOfFTEsGrowth, utilisationGrowth, quantitySoldFTEsGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}

export function createYearOnYearChangeHeads(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";

  let averageNumberOfFTEs           = buildYearTotalCategoryAverage(product, product.productCategoryDtoList[0].name, "Selected Headcounts Average");
  let quantitySold                  = buildYearTotalCategory(product, "Quantity Sold");

  let revenueCategory               = buildYearTotalCategory(product, "Revenue");
  let salesPricePerUnitSold         = buildDivisionOfTwoCategories("Sales price per unit sold (ex-VAT)", revenueCategory, quantitySold, 2);
  let directProductCosts            = buildYearTotalCategory(product, "Direct product costs");
  let costPricePerUnitSold          = buildDivisionOfTwoCategories("Cost Price per unit sold (ex-VAT)", directProductCosts, quantitySold, 2);
  let grossProfit                   = buildYearTotalCategory(product, "Gross Profit");
  let productMargin                 = buildPercentageOfTwoCategories("Product Margin",  revenueCategory, grossProfit);

  let utilisation;

  if (product_.productDto.assumption === 'HEADCOUNT_UTILISATION') {

    let maxProductiveHoursYearly = buildYearTotalCategory(product, product.productCategoryDtoList[1].name) //Max Productive Hours

    utilisation = buildHeadcountUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold, maxProductiveHoursYearly);
  } else {
    utilisation = buildUtilisation(buildYearTotalCategory(product, product.productCategoryDtoList[0].name), quantitySold);
  }

  let averageNumberOfFTEsGrowth = buildYearDifferenceCategory(product.productCategoryDtoList[0].name, averageNumberOfFTEs);
  let utilisationGrowth = buildYearDifferenceCategory("Utilisation", utilisation);
  let quantitySoldFTEsGrowth = buildYearDifferenceCategory("Quantity Sold", quantitySold);
  let salesPricePerUnitSoldGrowth = buildYearDifferenceCategory("Sales price per unit sold (ex-VAT)", salesPricePerUnitSold, 2);
  let revenueGrowth = buildYearDifferenceCategory("Revenue", revenueCategory);
  let costPricePerUnitSoldGrowth = buildYearDifferenceCategory("Cost Price per unit sold (ex-VAT)", costPricePerUnitSold,  2,  "↑",  "↓");
  let directProductCostsGrowth = buildYearDifferenceCategory("Direct product costs", directProductCosts, 0, "↑", "↓");
  let grossProfitGrowth = buildYearDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth = buildYearDifferencePercentageCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(averageNumberOfFTEsGrowth, utilisationGrowth, quantitySoldFTEsGrowth, salesPricePerUnitSoldGrowth, revenueGrowth, costPricePerUnitSoldGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}




