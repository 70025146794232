import _ from "lodash";

export function calculateSummaryTotals(productDataSetDtoList, setProductSummary, firstForecast, setPnlAtom, pnlAtom) {


  if (productDataSetDtoList.length === 0) {
    return
  }

  if (pnlAtom.length === 0) {
    return
  }

  const pnl = structuredClone(pnlAtom);

  // we need to create an object that the product summary component can use to display the totals for our products

  if (pnl === undefined) {
    return;
  }

  let productSummary = {
    productSummaryDto: { name: "Fixed Asset Summary", id: 999 },
    productSummaryCategoryDtoList: [
      {
        id: 0,
        name: 'Total Depreciation/Amortisation',
        productSummaryMonthDtoList: []
      }
    ]
  };

  // create 72 columns for each summary month dto list
  // and calculate the totals for each of them

  for (let i = 0; i < 72; i++) {

    productSummary.productSummaryCategoryDtoList[0].productSummaryMonthDtoList.push(
      {
        cellType: "CALCULATED",
        key: 'revenue' + i,
        monthIndex: i,
        value: 0,
        valueFormat: "INTEGER",
      }
    );

  }

  // pull actuals depreciation from the pnl
  let depreciationCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === "Depreciation/Amortisation")

  if (depreciationCategory) {
    for (let x = 0; x < firstForecast; x++) {

      productSummary
        .productSummaryCategoryDtoList[0]
        .productSummaryMonthDtoList[x].value = depreciationCategory.pnLMonthDtoList[x].value; //find all the depreciations and add them up.


    }
  }

  // fill the rest in with the calculated depreciation
  let pnlDepreciationUpdateRequired = false;

  for (let x = firstForecast; x < 72; x++) {

    let total = totalDepreciation(productDataSetDtoList, x); //find all the depreciations and add them up.

    productSummary
      .productSummaryCategoryDtoList[0]
      .productSummaryMonthDtoList[x].value = total;

    if (depreciationCategory.pnLMonthDtoList[x].value !== total) {
      pnlDepreciationUpdateRequired = true;
    }
    depreciationCategory.pnLMonthDtoList[x].value = total;
  }


  //update the pnl with the depreciation totals
  if (pnlDepreciationUpdateRequired) {
    setPnlAtom(structuredClone(pnl));
  }

  setProductSummary(productSummary.productSummaryCategoryDtoList);

  return productSummary;

}

function totalDepreciation(fixedAssetDataSetDtoList, x) {

  let total = 0;
  //go through all the items
  _.forEach(fixedAssetDataSetDtoList, function(fixedAssetDataSetDto) {
    //get the deprecation category
    let deprecationCategory = fixedAssetDataSetDto.fixedAssetCategoryDtos.find(category => category.name === "Depreciation/Amortisation")
    //get the month that matches the x
    if (deprecationCategory) {

      //add it to the total
      total += deprecationCategory.fixedAssetMonthDtos[x].value;
    }

  });

  //return the total
  return total;
}