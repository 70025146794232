import React, { useState, useEffect } from 'react';
import { Modal, Box, List, ListItem, ListItemText, Typography, Button, Checkbox } from '@mui/material';
import axios from 'axios';
import _ from "lodash";
import useAuth from "../../../../hooks/useAuth";

const ModelFolderModal = ({ open = false, setOpen, processFolders, adminMode = false }) => {
  const [models, setModels] = useState([]);
  const [projects, setProjects] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const { user } = useAuth();

  useEffect( () => {

    if (user !== null & user !== undefined) {

      axios.get('/folder/' + user.company + '/foldersOnly?admin=' + adminMode).then(function(response) {

        response.data.push({ company: 0, id: -1, name: "-- No Folder --", parent: null, projectDtos: [] })

        setFolders(response.data);

        let projectsUrl = adminMode ? 'projects' : 'projectsByOwner/' + user.company

        axios.get(projectsUrl).then(function(response2) {

          setProjects(response2.data);

          let projects = [];

          _.forEach(response2.data, function(project, i) {

            projects.push({
              id: project.id,
              name: project.name,
              currentFolder: folders.find(folder => folder.id === project.folderId)?.name || ''
            });
          });

          setModels(projects);
        });
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSelectModel = (model) => {
    const currentIndex = selectedProjects.indexOf(model.id);
    const newChecked = [...selectedProjects];
    if (currentIndex === -1) {
      newChecked.push(model.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedProjects(newChecked);
  };

  const handleUpdate = () => {
    let folderToUpdate = folders.find(folder => folder.id === selectedFolder);
    let projectsToUpdate = projects.filter(project => selectedProjects.includes(project.id));
    folderToUpdate.projectDtos = projectsToUpdate;
    axios.put('/folder?admin=' + adminMode, folderToUpdate)
      .then(response => {

        setSelectedProjects([]);

        processFolders(response);

        setOpen(false); // Optionally close modal on success
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const modalBody = (
    <Box sx={{ display: 'flex', gap: 2, maxHeight: 'calc(100% - 96px)', paddingTop: 2 }}>
      <Box sx={{ width: '50%' }}>
        <Typography variant="h6">Models</Typography>
        <Box sx={{ maxHeight: '100%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
          <List>
            {models.map((model) => (
              <ListItem
                key={model.id}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectModel(model)}
                    checked={selectedProjects.indexOf(model.id) !== -1}
                  />
                }
              >
                <ListItemText primary={model.name} secondary={`Current Folder: ${model.currentFolder}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box sx={{ width: '50%' }}>
        <Typography variant="h6">Folders</Typography>
        <Box sx={{ maxHeight: '100%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
          <List>
            {folders.map((folder) => (
              <ListItem
                key={folder.id}
                button
                selected={selectedFolder === folder.id}
                onClick={() => setSelectedFolder(folder.id)}
              >
                <ListItemText primary={folder.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, height: 550, bgcolor: 'background.paper', boxShadow: 24, p: 4, overflow: 'hidden' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Move Models to Folders
        </Typography>
        {modalBody}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 8 }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={handleUpdate} disabled={!selectedFolder}>Update</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModelFolderModal;
