import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { getTotals } from "../../../SharedComponents/utils/PowdrTotals";
import { getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";
import {
  calculateRevenueAndCOS,
  calculateRevenueSummaryTotals
} from "../../RevenueAndCos/calculations/Calculations";


function calculateDirectInput(headcounts) {

  const directInputHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "DIRECT_INPUT");

  _.forEach(directInputHeadcounts, function(directInputHeadcount, i) {

    const departments = directInputHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price");

    // reset the values for the totals to 0 before calcuating them.

    const headcountTotal = directInputHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Total cost in P&L");


    _.forEach(headcountTotal[0].headCountMonthDtoList, function(monthDto, i) {
      if (i >= getCurrentProject().firstForecast) {
        monthDto.value = 0;
      }
    });



    _.forEach(departments, function(department) {

      _.forEach(department.headCountMonthDtoList, function(monthDto, i) {
        if (i >= getCurrentProject().firstForecast) {

          headcountTotal[0].headCountMonthDtoList[i].value += monthDto.value;
        }

      });

    });

  });

}


function calculateConsolidatedHeadcounts(headcounts, consolidatedHeadcounts) {

  let consolidatedHeadcountsAssumptions = headcounts.filter(headcount =>
    headcount.headCountDto.assumption === "HEADCOUNT_DIVISIONAL_CONSOLIDATION"
  );

  _.forEach(consolidatedHeadcountsAssumptions, function(consolidatedHeadcountsAssumption) {
    // Get all the headcounts (from the consolidated list) that link to this assumption
    let headcountsToSum = consolidatedHeadcounts.filter(headcount =>
      consolidatedHeadcountsAssumption.headCountDto.headcounts.includes(headcount.headCountDto.id)
    );

    let headcountTotals = new Array(72).fill(0);

    _.forEach(headcountsToSum, function(headcountToSum) {
      // Find the category 'Value In P&L' from headCountCategoryDtos
      let categoryToTotal = headcountToSum.headCountCategoryDtos.find(category =>
        category.name === 'Total cost in P&L'
      );

      if (categoryToTotal && categoryToTotal.headCountMonthDtoList) {
        _.forEach(categoryToTotal.headCountMonthDtoList, function(headcountMonthToSum, i) {
          if (headcountTotals[i] === undefined) {
            headcountTotals[i] = 0; // Initialize to 0 if undefined
          }
          headcountTotals[i] += getValueOrOverriddenValue(headcountMonthToSum);
        });
      }


    });

    let consolidatedValueInPnL = consolidatedHeadcountsAssumption.headCountCategoryDtos.find(category =>
      category.name === 'Total cost in P&L'
    );

    _.forEach(consolidatedValueInPnL.headCountMonthDtoList, function(headcountMonth, i) {

      headcountMonth.value = headcountTotals[i];
    });

  });

}

export function calculateHeadCounts(headcounts, products, pnlAtom, setPnlAtom, consolidatedProducts, consolidatedHeadcounts) {

  try {
    calculateAnnualGrowthRate(headcounts);
    calculateMonthlyGrowthRate(headcounts)
    calculatePriceTimesQuantity(headcounts);
    calculateDirectInput(headcounts);

    calculateConsolidatedHeadcounts(headcounts, consolidatedHeadcounts);

    let products_ = structuredClone(products);

    //changing an FTE will require the products to be updated (which in turn will update the P&L)
    calculateRevenueAndCOS(products_, headcounts, consolidatedProducts);

    calculateRevenueSummaryTotals(products_, pnlAtom, setPnlAtom);

  } catch (e) {
      console.log(e)
  }

}

function calculateAnnualGrowthRate(headcounts) {

  // loop through and find only pure annual growth rate overheads
  const pureAnnualGrowthHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "ANNUAL_GROWTH_RATE");
  // times the forecast August 2023 (percentage of revenue) by the actual (value in P & L) value


  _.forEach(pureAnnualGrowthHeadcounts, function(pureAnnualGrowthHeadcount, i) {

    // calculate annual growth rate
    //so now we have the ANNUAL GROWTH RATE headcounts
    //we need to determine how many departments this particular headcount has
    const departmentSize = pureAnnualGrowthHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price").length;

    _.forEach(pureAnnualGrowthHeadcount.headCountCategoryDtos, function(headCountCategoryDto, t) {

      if (headCountCategoryDto.name === "Growth Rate") {


        _.forEach(headCountCategoryDto.headCountMonthDtoList, function(headCountMonthDto, i) {
          if (i >= getCurrentProject().firstForecast
                && i > 11) {

            //todo need to loop this calculation for every department

            pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i].value
              = Math.ceil(getValueOrOverriddenValue(pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i - 12])
              + ((headCountMonthDto.value / 100) * getValueOrOverriddenValue(pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i - 12])))

          }
        });
      }
    });

    calculateAllFieldsAnnualGrowth(pureAnnualGrowthHeadcount)

  });



}

function calculateMonthlyGrowthRate(headcounts) {


  // loop through and find only pure annual growth rate overheads
  const pureMonthlyGrowthHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "MONTHLY_GROWTH_RATE");
  // times the forecast August 2023 (percentage of revenue) by the actual (value in P & L) value


  _.forEach(pureMonthlyGrowthHeadcounts, function(pureAnnualGrowthHeadcount, i) {

    // calculate annual growth rate
    //so now we have the ANNUAL GROWTH RATE headcounts
    //we need to determine how many departments this particular headcount has
    const departmentSize = pureAnnualGrowthHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price").length;

    _.forEach(pureAnnualGrowthHeadcount.headCountCategoryDtos, function(headCountCategoryDto, t) {

      if (headCountCategoryDto.name === "Growth Rate") {

        _.forEach(headCountCategoryDto.headCountMonthDtoList, function(headCountMonthDto, i) {
          if (i >= getCurrentProject().firstForecast) {

            //todo need to loop this calculation for every department

            pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i].value
              = Math.ceil(getValueOrOverriddenValue(pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i - 1])
              + ((headCountMonthDto.value / 100) * getValueOrOverriddenValue(pureAnnualGrowthHeadcount.headCountCategoryDtos[departmentSize + t + 1].headCountMonthDtoList[i - 1])))

          }
        });
      }
    });

    calculateAllFieldsAnnualGrowth(pureAnnualGrowthHeadcount)

  });

}


function calculateAllFieldsAnnualGrowth(priceXQuantityHeadcount) {

  const totalSalariesCategory = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Total Salaries");

  const criteriaGroups = [
    [
      { name: "Price", operator: "EQUALS" },
      { departmentName: "", operator: "NOT_EQUALS" }
    ]
  ];

  const totalSalaries = getTotals(criteriaGroups, priceXQuantityHeadcount, "headCountCategoryDtos", "headCountMonthDtoList", false);

  //copy over the totalSalaries to the totalSalariesCategory
  totalSalariesCategory.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: totalSalaries[i]
  }));


  //calculate Bonus, Other, Pension, NI
  const percentageTeamBonus = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Percentage team bonus");

  const percentageOtherExtra = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Percentage other extra");

  const bonus = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Bonus");

  const other = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Other");

  bonus.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (percentageTeamBonus.headCountMonthDtoList[i].value / 100)
  }));

  other.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (percentageOtherExtra.headCountMonthDtoList[i].value / 100)
  }));

  const pensionPercentage = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Pension Percentage");

  const niPerentage = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "NI Percentage");

  const pension = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Pension");

  const ni = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "NI");

  pension.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (pensionPercentage.headCountMonthDtoList[i].value / 100)
  }));

  ni.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (niPerentage.headCountMonthDtoList[i].value / 100)
  }));


  const totalCostInPandLCategory = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Total cost in P&L");


  _.forEach(totalCostInPandLCategory.headCountMonthDtoList, function(headCountMonthDto, i) {

    if (i >= getNumberOfActuals(priceXQuantityHeadcount.headCountDto.assumption)) {

      const totalSalary = totalSalariesCategory.headCountMonthDtoList[i].value;
      const bonusValue = bonus.headCountMonthDtoList[i].value;
      const otherValue = other.headCountMonthDtoList[i].value;
      const pensionValue = pension.headCountMonthDtoList[i].value;
      const niValue = ni.headCountMonthDtoList[i].value;

      const totalCost = totalSalary + bonusValue + otherValue + pensionValue + niValue;

      headCountMonthDto.value = totalCost;

    }

  });

  // totalCostInPandLCategory.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => {
  //
  //   if (i < getCurrentProject().firstForecast) {
  //     return dto;
  //   }
  //
  //   const totalSalary = dto.value;
  //   const bonusValue = bonus.headCountMonthDtoList[i].value;
  //   const otherValue = other.headCountMonthDtoList[i].value;
  //   const pensionValue = pension.headCountMonthDtoList[i].value;
  //   const niValue = ni.headCountMonthDtoList[i].value;
  //
  //   const totalCost = totalSalary + bonusValue + otherValue + pensionValue + niValue;
  //
  //   return { ...dto, value: totalCost };
  // });
}

function getNumberOfActuals(assumption) {
  let firstForecast = Number(getCurrentProject().firstForecast);

  if (assumption === "ANNUAL_GROWTH_RATE" && firstForecast < 12) {
    return 12;
  }

  return getCurrentProject().firstForecast
}


function calculateAllFields(priceXQuantityHeadcount) {

  const totalSalariesCategory = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Total Salaries");

  const criteriaGroups = [
    [
      { name: "Total base salary costs", operator: "EQUALS" },
      { departmentName: "", operator: "NOT_EQUALS" }
    ]
  ];

  const totalSalaries = getTotals(criteriaGroups, priceXQuantityHeadcount, "headCountCategoryDtos", "headCountMonthDtoList", false);

  //copy over the totalSalaries to the totalSalariesCategory
  totalSalariesCategory.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: totalSalaries[i]
  }));


  //calculate Bonus, Other, Pension, NI
  const percentageTeamBonus = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Percentage team bonus");

  const percentageOtherExtra = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Percentage other extra");

  const bonus = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Bonus");

  const other = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Other");

  bonus.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (percentageTeamBonus.headCountMonthDtoList[i].value / 100)
  }));

  other.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (percentageOtherExtra.headCountMonthDtoList[i].value / 100)
  }));

  const pensionPercentage = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Pension Percentage");

  const niPerentage = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "NI Percentage");

  const pension = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Pension");

  const ni = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "NI");

  pension.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (pensionPercentage.headCountMonthDtoList[i].value / 100)
  }));

  ni.headCountMonthDtoList = _.map(totalSalariesCategory.headCountMonthDtoList, (dto, i) => ({
    ...dto,
    value: dto.value * (niPerentage.headCountMonthDtoList[i].value / 100)
  }));


  const totalCostInPandLCategory = priceXQuantityHeadcount.headCountCategoryDtos
    .find(headCountCategoryDto => headCountCategoryDto.name === "Total cost in P&L");

  _.forEach(totalCostInPandLCategory.headCountMonthDtoList, function(headCountMonthDto, i) {

    if (i >= getCurrentProject().firstForecast) {

      const totalSalary = totalSalariesCategory.headCountMonthDtoList[i].value;
      const bonusValue = bonus.headCountMonthDtoList[i].value;
      const otherValue = other.headCountMonthDtoList[i].value;
      const pensionValue = pension.headCountMonthDtoList[i].value;
      const niValue = ni.headCountMonthDtoList[i].value;

      const totalCost = totalSalary + bonusValue + otherValue + pensionValue + niValue;

      headCountMonthDto.value = totalCost;

    }

  });

}

function calculatePriceTimesQuantity(headcounts) {


  // loop through and find only pure annual growth rate overheads
  const priceXQuantityHeadcounts = headcounts
    .filter(headcount => headcount.headCountDto.assumption === "PRICE_X_QUANTITY");


  //for each department we need to time the value in a month, by the quantity in that month
  _.forEach(priceXQuantityHeadcounts, function(priceXQuantityHeadcount, i) {

    const departmentSize = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price").length;

    const departmentPrices = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Price");

    const departmentQuantities = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Quantity");

    const departmentTotal = priceXQuantityHeadcount.headCountCategoryDtos
      .filter(headCountCategoryDto => headCountCategoryDto.departmentName !== "")
      .filter(headCountCategoryDto => headCountCategoryDto.name === "Total base salary costs");

    for (let t=0; t < departmentSize; t++) {

      _.forEach(departmentPrices[0].headCountMonthDtoList, function(headCountMonthDto, x) {

        departmentTotal[t].headCountMonthDtoList[x].value =
        departmentPrices[t].headCountMonthDtoList[x].value *
        departmentQuantities[t].headCountMonthDtoList[x].value;


      });

    }
    calculateAllFields(priceXQuantityHeadcount);


  });



}
