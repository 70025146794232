import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useRecoilValue} from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { vatPackageState } from "../../SharedState/VatPackageState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { cashFlowState } from "../../SharedState/CashflowState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";
import _ from "lodash";
import { calculateBalanceSheet } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";
import { calculateCashflow } from "../../CashflowGroup/CashFlow/calculations/Calculations";
import { areValuesEqual } from "../utils/Utils";
import { calculateCurrentAssets } from "../../AssumptionsGroup/CurrentAssets/calculations/Calculations";
import { calculateCurrentLiabilities } from "../../AssumptionsGroup/CurrentLiabilities/calculations/Calculations";
import { corporationTaxState } from "../../SharedState/CorporationTaxState";
import { calculateFixedAssets } from "../../AssumptionsGroup/FixedAssets/calculations/Calculations";
import { fixedAssetsState } from "../../SharedState/FixedAssetsState";
import { debtsState } from "../../SharedState/DebtsState";
import { equitiesState } from "../../SharedState/EquitiesState";
import { calculateDebt } from "../../AssumptionsGroup/Debt/calculations/Calculations";
import { calculateEquity } from "../../AssumptionsGroup/Equity/calculations/Calculations";
import {
  currentAssetsAssumptionsMap,
  currentLiabilitiesAssumptionsMap,
  debtAssumptionsMap, equityAssumptionsMap,
  fixedAssetAssumptionsMap
} from "../AssumptionMaps";
import {
  copyBalanceToCurrentAssets,
  copyBalanceToCurrentLiabilities, copyBalanceToDebts, copyBalanceToEquity,
  copyBalanceToFixedAssets, updatePnlCorporationTax
} from "./UpdatePowdrModelUtils";



/**
 * Used to recalculate after a Balance actual is entered
 *
 * Fixed Assets, Current Assets, Current Liabilities, Debt & Equity need to be recalculated when an Actual in the Balance is entered.
 * And then Cashflow
 *
 * Steps:
 *
 * 1.  Using Celldata, find the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity this row relates to (if any)
 * 2.  Copy over the values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
 * 3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
 * 4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity to the Balance Sheet
 * 5.  Recalculate the Balance Sheet with the new values in place
 * 6.  Recalculate and Save the cashflow
 * 7.  Save the Balance Sheet
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const UpdatePowdrModelBalance = forwardRef((props, ref) => {

  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const [cashflowAtom, setCashflowAtom] = useRecoilState(cashFlowState);

  const [debtAtom, setDebtAtom] = useRecoilState(debtsState);
  const [equityAtom, setEquityAtom] = useRecoilState(equitiesState);
  const [fixedAssetAtom, setFixedAssetAtom] = useRecoilState(fixedAssetsState);
  const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);

  const vatPackageAtom = useRecoilValue(vatPackageState);

  const corporationTaxAtom = useRecoilValue(corporationTaxState);


  function fixedAssetProcessing(assumptionType, cellData, balanceFromComponent) {
    if (assumptionType === "FIXED_ASSETS") {

      let fixedAssets = structuredClone(fixedAssetAtom);

      //find the matching fixed asset
      let balanceFixedAsset = cellData.assumptions[0];
      let fixedAsset = fixedAssets.find(fixedAsset_ => fixedAsset_.fixedAssetDto && fixedAsset_.fixedAssetDto.id === cellData.assumptions[0].balanceExternalId);

      //2. Copy over the balance values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
      copyBalanceToFixedAssets(balanceFixedAsset, fixedAsset);

      //3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
      calculateFixedAssets(fixedAssets);

      setFixedAssetAtom(fixedAssets);

      //4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity BACK to the Balance Sheet
      updateBalance(fixedAssets,
        fixedAssetAssumptionsMap,
        "FIXED_ASSETS",
        "fixedAsset",
        balanceFromComponent[0]);

    }
  }

  function debtProcessing(assumptionType, cellData, balanceFromComponent) {
    if (assumptionType === "DEBT") {

      let debts = structuredClone(debtAtom);

      //find the matching fixed asset
      let balanceFixedAsset = cellData.assumptions[0];
      let debt = debts.find(debt_ => debt_.debtDto && debt_.debtDto.id === cellData.assumptions[0].balanceExternalId);

      //2. Copy over the balance values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
      copyBalanceToDebts(balanceFixedAsset, debt);

      //3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
      calculateDebt(debts);

      setDebtAtom(debts);

      //4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity BACK to the Balance Sheet
      updateBalance(debts,
        debtAssumptionsMap,
        "DEBT",
        "debt",
        balanceFromComponent[0]);

    }
  }

  function equityProcessing(assumptionType, cellData, balanceFromComponent) {
    if (assumptionType === "EQUITY") {

      let equities = structuredClone(equityAtom);

      //find the matching fixed asset
      let balanceFixedAsset = cellData.assumptions[0];
      let equity = equities.find(equity_ => equity_.equityDto && equity_.equityDto.id === cellData.assumptions[0].balanceExternalId);

      //2. Copy over the balance values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
      copyBalanceToEquity(balanceFixedAsset, equity);

      //3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
      calculateEquity(equities);

      setEquityAtom(equities);

      //4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity BACK to the Balance Sheet
      updateBalance(equities,
        equityAssumptionsMap,
        "EQUITY",
        "equity",
        balanceFromComponent[0]);

    }
  }

  function currentAssetProcessing(assumptionType, cellData, balanceFromComponent) {
    if (assumptionType === "CURRENT_ASSETS") {

      let currentAssets = structuredClone(currentAssetAtom);

      //find the matching fixed asset
      let balanceFixedAsset = cellData.assumptions[0];
      let currentAsset = currentAssets.find(currentAsset_ => currentAsset_.currentAssetDto && currentAsset_.currentAssetDto.id === cellData.assumptions[0].balanceExternalId);

      //2. Copy over the balance values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
      copyBalanceToCurrentAssets(balanceFixedAsset, currentAsset);

      //3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
      calculateCurrentAssets(currentAssets, vatPackageAtom, pnlAtom)

      setCurrentAssetsAtom(currentAssets);

      //4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity BACK to the Balance Sheet
      updateBalance(currentAssets,
        currentAssetsAssumptionsMap,
          "CURRENT_ASSETS",
          "currentAsset",
        balanceFromComponent[0]);

    }
  }

  function currentLiabilitiesProcessing(assumptionType, cellData, balanceFromComponent, cashflow) {
    if (assumptionType === "CURRENT_LIABILITIES") {

      let currentLiabilities = structuredClone(currentLiabilitiesAtom);

      //find the matching fixed asset
      let balanceFixedAsset = cellData.assumptions[0];
      let currentLiability = currentLiabilities.find(currentLiability_ => currentLiability_.currentLiabilityDto && currentLiability_.currentLiabilityDto.id === cellData.assumptions[0].balanceExternalId);

      //2. Copy over the balance values to the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity - using the assumptionToCategoryMap
      copyBalanceToCurrentLiabilities(balanceFixedAsset, currentLiability, currentLiabilitiesAssumptionsMap);

      //3.  Recalculate and Save the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity
      calculateCurrentLiabilities(structuredClone(corporationTaxAtom),
        currentLiabilities,
        vatPackageAtom,
        cashflow,
        structuredClone(pnlAtom.pnLCategoryDtoList),
        getOpeningAgedCreditor()
        );

      setCurrentLiabilitiesAtom(currentLiabilities);

      //4.  Copy over the calculated values from the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity BACK to the Balance Sheet
      updateBalance(currentLiabilities,
        currentLiabilitiesAssumptionsMap,
        "CURRENT_LIABILITIES",
        "currentLiability",
        balanceFromComponent[0]);

      //5. copy over the Corporation Tax - New Tax Incurred Line to the P&L
      updatePnlCorporationTax(currentLiabilities, pnlAtom);

    }
  }

  useImperativeHandle(ref, () => ({


    updatePowdrModelBalance(cellData,
                     balanceFromComponent) {

      if (!areValuesEqual(cellData.previousValue, cellData.value)) {
        return;
      }

      const tick = performance.now();

      //1.  Using Celldata, find the Fixed Assets, Current Assets, Current Liabilities, Debt & Equity this row relates to (if any)
      let assumptionType = cellData.assumptions[0].balanceType;

      let cashflow_ = structuredClone(cashflowAtom);

      fixedAssetProcessing(assumptionType, cellData, balanceFromComponent);
      currentAssetProcessing(assumptionType, cellData, balanceFromComponent);
      currentLiabilitiesProcessing(assumptionType, cellData, balanceFromComponent, cashflow_);
      debtProcessing(assumptionType, cellData, balanceFromComponent);
      equityProcessing(assumptionType, cellData, balanceFromComponent);

      //5.  Recalculate the Balance Sheet with the new values in place
      calculateBalanceSheet(balanceFromComponent[0], pnlAtom);

      //7. Recalculate and Save the cashflow
      let cashflow = calculateCashflow(structuredClone(cashflow_),
        balanceFromComponent[0],
        pnlAtom);

      setCashflowAtom(cashflow);

      //Then recalculate the balance sheet with the NEW Cash At Bank values
      calculateBalanceSheet(balanceFromComponent[0], pnlAtom);

      //6.  Save the Balance Sheet
      setBalanceAtom(structuredClone(balanceFromComponent));

      setPnlAtom(structuredClone(pnlAtom));

      const tock = performance.now();

      console.log(`Main full stack thread took ${tock - tick} ms`);

    }

  }));



  /**
   * Balance has an opening month value that occurs before the model begins, this method retrieves that for Trade creditors
   * @returns {*}
   */
  function getOpeningAgedCreditor() {
    let openingAgedCreditorCategory = balanceAtom.balanceCategoryDtos.find(category => category.overrideName === 'Trade creditors')
    return openingAgedCreditorCategory.balanceMonthDtos[0].value;
  }


  function updateBalance(assumptions, assumptionToCategoryMap, balanceType, assumptionName, balance) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let balanceCategory = balance.balanceCategoryDtos.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && category.balanceType === balanceType);

      let categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtos'], function(monthDto, i) {
        balanceCategory.balanceMonthDtos[i + 1].value = monthDto.value;
      });

    });

  }

  return (
    <div></div>
  );
});

export default UpdatePowdrModelBalance;
