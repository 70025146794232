import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";


export function actualiseCurrentAssets(currentAssets) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(currentAssets, function(currentAsset, i) {

    _.forEach(currentAsset.currentAssetCategoryDtos, function(category, x) {

      if (_.isEmpty(category.currentAssetMonthDtos)) {
        return;
      }

      category.currentAssetMonthDtos[firstBalanceForecast].cellType =
        category.currentAssetMonthDtos[firstBalanceForecast - 1].cellType;

      category.currentAssetMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.currentAssetMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.currentAssetMonthDtos[firstBalanceForecast].valueOverriden = null;

    });

  });

  return currentAssets;

}