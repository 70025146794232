import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { refreshVatPackage, vatPackageState } from "../../SharedState/VatPackageState";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { cashFlowState } from "../../SharedState/CashflowState";
import { overheadsState } from "../../SharedState/OverheadsState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import _ from "lodash";
import { calculateBalanceSheet } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";
import { calculateCashflow } from "../../CashflowGroup/CashFlow/calculations/Calculations";
import { calculatePnl } from "../../PLGroup/PL/calculations/Calculations";
import { vatState } from "../../SharedState/VatState";
import { updatePnlCorporationTax } from "./UpdatePowdrModelUtils";
import {
  calculateLoanAsset,
  calculateRevenueSummaryTotals
} from "../../AssumptionsGroup/RevenueAndCos/calculations/Calculations";

const UpdatePowdrModel = forwardRef((props, ref) => {

  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const [cashflowAtom, setCashflowAtom] = useRecoilState(cashFlowState);

  const [revenueAtom, setRevenueAtom] = useRecoilState(revenueAndCostState);
  const overheadAtom = useRecoilValue(overheadsState);

  const currentAssetAtom = useRecoilValue(currentAssetsState);

  const setVatPackageAtom = useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  useImperativeHandle(ref, () => ({


    updatePowdrModel(cellData,
                     assumptionList,
                     assumptionName,
                     balanceType,
                     assumptionToCategoryMap,
                     pnlOnlyUpdate = false) {

        const tick = performance.now();

        let pnl = structuredClone(pnlAtom)
        let revenue = structuredClone(revenueAtom);

        //now that current asset loans can affect revenue, which intern can affect VAT calculations
        //we need to do the vat update later on in the stack
        if (balanceType === "CURRENT_ASSETS") {
          calculateLoanAsset(revenue, assumptionList);
          setRevenueAtom(revenue);
          pnl = calculateRevenueSummaryTotals(revenue, pnl, setPnlAtom);
        }

        //recalculate VAT
        let vatPackage = refreshVatPackage(structuredClone(revenue), structuredClone(overheadAtom), structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh

        let balance = structuredClone(balanceAtom); //atoms are read only

        if (balanceType === "CURRENT_LIABILITIES") {
          updatePnlCorporationTax(assumptionList, pnl)
        }

        if (pnlOnlyUpdate === false) {

          //copy values to the balance sheet
          updateBalance(assumptionList,
            assumptionToCategoryMap,
            balanceType,
            assumptionName,
            balance);

        }

        calculateBalanceSheet(balance, pnl);

        let cashflow = calculateCashflow(structuredClone(cashflowAtom),
          balance,
          pnl);

        setCashflowAtom(cashflow);

        //Then recalculate the balance sheet with the NEW Cash At Bank values
        calculateBalanceSheet(balance, pnl);

        setBalanceAtom(structuredClone(balance));

        //now calculate p & l
        if (["headCount", "overhead"].includes(assumptionName)) {

          updatePnl(assumptionList,
            assumptionToCategoryMap,
            assumptionName,
            pnl);
        }

        calculatePnl(pnl)

        setPnlAtom(pnl);

        const tock = performance.now();

        console.info(`Main thread took ${tock - tick} ms`);

    }

  }));


  function updatePnl(assumptions, assumptionToCategoryMap, assumptionName, pnl) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let pnlCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && (category.headcountId === assumption[assumptionName + 'Dto'].id || category.overheadId === assumption[assumptionName + 'Dto'].id));

      let categoryToCopy;
      if (assumptionName === "headCount") {
        categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);
      } else {
        categoryToCopy = assumption[assumptionName + 'CategoryDtoList'].find(category => category.name === categoryName || category.overrideName === categoryName)
      }

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtoList'], function(monthDto, i) {
        pnlCategory.pnLMonthDtoList[i].value = monthDto.value;
      });

    });

  }


  function updateBalance(assumptions, assumptionToCategoryMap, balanceType, assumptionName, balance) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let balanceCategory = balance.balanceCategoryDtos.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && category.balanceType === balanceType);

      let categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtos'], function(monthDto, i) {
        balanceCategory.balanceMonthDtos[i +1].value = monthDto.value;
      });

    });

  }

  return (
    <div></div>
  );
});

export default UpdatePowdrModel;
