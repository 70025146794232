import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import { getCurrentProject } from "../../pages/powdr/SharedComponents/ProjectServices";

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute } = props;

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;


    if (getCurrentProject()?.projectType === 'MULTI_ENTITY'
      && ['Assumptions', 'Checklist'].includes(page.title)) {

    } else {
      if (getCurrentProject() != null) {
        items.push(
          <SidebarNavListItem
            depth={depth}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            open={!!open}
            title={page.title}
            href={page.href}
          >
            <SidebarNavList depth={depth + 1} pages={page.children} />
          </SidebarNavListItem>
        );
      }
    }
  } else {

    if (getCurrentProject() === undefined) {

    } else if (getCurrentProject()?.projectType === 'MULTI_ENTITY'
      && ['Corporation Tax', 'VAT', 'Checklist', 'Export'].includes(page.title)) {

    } else {

      if (getCurrentProject() != null) {

        //if currentProject is license PNL then hide 'Fixed Assets','Current Assets','Current Liabilities','Debt','Equity','Balance Sheet','Cashflow'
        if (getCurrentProject().projectType === 'PNL') {

           if ( !['Fixed Assets',
             'Current Assets',
             'Current Liabilities',
             'Debt',
             'Equity',
             'Balance Sheet',
             'Cash Flow',
             'VAT',
             'Corporation Tax',
             'Covenant',
             'Overdraft Interest',
             'Cashflow Dashboard',
             'Headcount Dashboard',
             'Overhead Dashboard',
             'Revenue Dashboard',
             'Snapshots'].includes(page.title)) {
             items.push(
               <SidebarNavListItem
                 depth={depth}
                 href={page.href}
                 icon={page.icon}
                 key={page.title}
                 badge={page.badge}
                 title={page.title}
               />
             );
           }
        } else {
          items.push(
            <SidebarNavListItem
              depth={depth}
              href={page.href}
              icon={page.icon}
              key={page.title}
              badge={page.badge}
              title={page.title}
            />
          );
        }

      }


    }

  }

  return items;
};

export default reduceChildRoutes;
