import React, { useEffect, useState }  from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {

  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import { spacing } from "@mui/system";

import axios from "axios";

import { Briefcase, Home, MapPin } from "react-feather";
import CompanyOnboarding from "./components/CompanyOnboarding";
import getProjectId from "../SharedComponents/ProjectServices";
import useAuth from "../../../hooks/useAuth";
import ProjectsViewer from "../SharedComponents/ProjectFolder/ProjectsViewer";


const Spacer = styled.div(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

function Company() {

  const [companyToEdit, setCompanyToEdit] = useState();

  const [users, setUsers] = useState([]);
  const { user } = useAuth();

  async function loadCompanies() {

    let project = await getProjectId();

    axios.get('company/').then(function(response) {
      // setCompanies(response.data);

      let companyTemp = response.data.find(company => company.id === project.owner);
      setCompanyToEdit(companyTemp)

      //
      // axios.get('projectsByOwner/' + companyTemp.id).then(function(response) {
      //   setProjects(response.data);
      // })

    })


    await axios.get('/security/users/').then(function(response) {
      setUsers(response.data.users);
    })

  }



  function resetPassword() {

      axios.post('security/reset', { user: user.email }).then(function(response) {

        window.alert('Password update request successful, please check your email for further steps');
      })

  }

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function viewProject(e) {
  //
  //   //update the project viewing list
  //   await axios.post('/projectSession/' + e.target.id + '/' + user.id);
  //
  //   setProject(projects.find(project => project.id === parseInt(e.target.id)))
  //
  //   window.location.reload();
  // }

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Your Account
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Companies</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {/*{!!companyToEdit &&*/}
      {/*  <Stack spacing={4} direction="row" sx={{ paddingBottom: 6 }}>*/}
      {/*    <AddProject names={projects.map(function(proj) {*/}
      {/*      return proj.name*/}
      {/*    })} projects={projects} admin={false} company={companyToEdit.id} loadData={loadCompanies}></AddProject>*/}
      {/*  </Stack>*/}
      {/*}*/}

      {!!companyToEdit &&
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Company
                </Typography>

                <Spacer mb={4} />

                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Home />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Company Name{" "}
                    <Link href={companyToEdit.companyWebsite}>{companyToEdit.companyName}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Sector <Link href={companyToEdit.companyWebsite}>{companyToEdit.companySector}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <MapPin />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Corporate Website <Link href={companyToEdit.companyWebsite}>{companyToEdit.companyWebsite}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Approximate Revenue <Link
                    href={companyToEdit.companyWebsite}>{companyToEdit.approxRevenue?.toLocaleString()}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Company Enabled: <strong>{companyToEdit.disabled ? 'NO' : 'YES'}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    License: <strong>{companyToEdit.license}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Duplicates Feature: <strong>{companyToEdit.duplicate ? "Enabled" : "Disabled"}</strong>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Company Onboarding
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    This tracks the steps a company has to complete to be granted access to Powdr.
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <CompanyOnboarding company={companyToEdit}></CompanyOnboarding>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <ProjectsViewer></ProjectsViewer>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Users
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    List of users for this company
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>

                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">User Type</TableCell>
                            <TableCell align="right">Telephone</TableCell>
                            <TableCell align="right">Disabled</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!users && users.filter(user => user.company === companyToEdit.id).map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.firstName + " " + row.lastName}
                              </TableCell>
                              <TableCell align="right">{row.email}</TableCell>
                              <TableCell align="right">{row.userType}</TableCell>
                              <TableCell align="right">{row.mobileTelephone}</TableCell>
                              <TableCell align="right">{row.disabled ? 'YES' : 'NO'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*  <Card mb={6}>*/}
          {/*    <CardContent>*/}
          {/*      <Grid item xs={12}>*/}
          {/*        <Typography variant="h6" gutterBottom>*/}
          {/*          Enable/Disable Company*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="body2" gutterBottom>*/}
          {/*          This will disable this company, all its projects and users from accessing Powdr.*/}
          {/*        </Typography>*/}
          {/*      </Grid>*/}
          {/*      <Grid container spacing={6}>*/}
          {/*        <Grid item md={6}>*/}
          {/*          <Button disabled={companyToEdit.initialised} variant="contained" style={{ width: 200, height: 100 }}*/}
          {/*                  onClick={resetPassword}>*/}
          {/*            {companyToEdit.disabled ? 'Enable' : 'Disable'} Company*/}
          {/*          </Button>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Reset Password
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Click here to reset your password
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Button disabled={companyToEdit.initialised} variant="contained" style={{ width: 200, height: 50 }}
                            onClick={resetPassword}>
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}

export default Company;
