import React, {Suspense} from "react";


// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";


// Protected routes
import RevenueAndCos from "./pages/powdr/AssumptionsGroup/RevenueAndCos/RevenueAndCos";
import Overheads from "./pages/powdr/AssumptionsGroup/Overheads/Overheads";
import Config from "./pages/powdr/SettingsGroup/Settings/Config";
import PL from "./pages/powdr/PLGroup/PL/PL";
import CashFlow from "./pages/powdr/CashflowGroup/CashFlow/CashFlow";
import BalanceSheet from "./pages/powdr/BalanceSheetGroup/BalanceSheet/BalanceSheet";
import HeadCount from "./pages/powdr/AssumptionsGroup/HeadCount/HeadCount";
import FixedAssets from "./pages/powdr/AssumptionsGroup/FixedAssets/FixedAssets";
import CurrentAssets from "./pages/powdr/AssumptionsGroup/CurrentAssets/CurrentAssets";
import CurrentLiabilities from "./pages/powdr/AssumptionsGroup/CurrentLiabilities/CurrentLiabilities";
import Debt from "./pages/powdr/AssumptionsGroup/Debt/Debt";
import Dashboard from "./pages/powdr/Analysis/Dashboard/Dashboard";
import VerifyUser from "./pages/auth/VerifyUser";
import Equity from "./pages/powdr/AssumptionsGroup/Equity/Equity";
import PLAnnual from "./pages/powdr/PLGroup/PLAnnual/PLAnnual";
import BalanceSheetAnnual from "./pages/powdr/BalanceSheetGroup/BalanceSheetAnnual/BalanceSheetAnnual";
import CashflowAnnual from "./pages/powdr/CashflowGroup/CashFlowAnnual/CashFlowAnnual";
import VatConfig from "./pages/powdr/SettingsGroup/SettingsVat/VatConfig";
import CorporationTaxConfig from "./pages/powdr/SettingsGroup/SettingsCorporationTax/CorporationTaxConfig";
import Export from "./pages/powdr/Export/Export";
import PowdrProjects from "./pages/powdr/PowdrAdmin/Projects/PowdrProjects";
import Companies from "./pages/powdr/PowdrAdmin/Companies/Companies";
import Company from "./pages/powdr/Company/Company";
import SignUpComplete from "./pages/auth/SignUpComplete";
import SignUpDisabled from "./pages/auth/SignUpDisabled";
import UpdatePassword from "./pages/auth/UpdatePassword";
import OverdraftTaxConfig from "./pages/powdr/SettingsGroup/SettingsOverdraftTax/OverdraftTaxConfig";
import Home from "./pages/powdr/Home/Home";
import DisclaimerContent from "./pages/powdr/SharedComponents/Disclaimer/DisclaimerContent";
import Covenant from "./pages/powdr/Analysis/Covenance/Covenant";
import Welcome from "./pages/docs/Welcome";
import RevenueDashboard from "./pages/powdr/Analysis/RevenueDashboard/RevenueDashboard";
import OverheadDashboard from "./pages/powdr/Analysis/OverheadDashboard/OverheadDashboard";
import HeadcountDashboard from "./pages/powdr/Analysis/HeadcountDashboard/HeadcountDashboard";
import CashflowDashboard from "./pages/powdr/Analysis/CashflowDashboard/CashflowDashboard";
import Audit from "./pages/powdr/PowdrAdmin/Audit/Audit";
import Snapshot from "./pages/powdr/SettingsGroup/Snapshot/Snapshot";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "revenuedashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <RevenueDashboard />,
      },
    ],
  },
  {
    path: "overheaddashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OverheadDashboard />,
      },
    ],
  },
  {
    path: "headcountdashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HeadcountDashboard />,
      },
    ],
  },
  {
    path: "covenant",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Covenant />,
      },
    ],
  },
  // {
  //   path: "valuation",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <Valuation />,
  //     },
  //   ],
  // },
  {
    path: "cashflowdashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CashflowDashboard />,
      },
    ],
  },
  {
    path: "export",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Export />,
      },
    ],
  },
  {
    path: "home",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "revenueandcos",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <RevenueAndCos />,
      },
    ],
  },
  {
    path: "overheads",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Overheads />,
      },
    ],
  },
  {
    path: "projectsetup",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Config />,
      },
    ],
  },
  {
    path: "vatconfig",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <VatConfig />,
      },
    ],
  },
  {
    path: "snapshot",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Snapshot />,
      },
    ],
  },
  {
    path: "powdr-projects",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PowdrProjects />,
      },
    ],
  },
  {
    path: "project-audit",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Audit />,
      },
      {
        path: ":projectId",
        element: <Audit />,
      },
    ],
  },
  {
    path: "companies",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Companies />,
      },
    ],
  },
  {
    path: "company",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Company />,
      },
    ],
  },
  {
    path: "corporationtaxconfig",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CorporationTaxConfig />,
      },
    ],
  },
  {
    path: "overdrafttaxconfig",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OverdraftTaxConfig />,
      },
    ],
  },
  {
    path: "pnlannual",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PLAnnual />,
      },
    ],
  },
  {
    path: "cashflowannual",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CashflowAnnual />,
      },
    ],
  },
  {
    path: "balancesheetannual",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <BalanceSheetAnnual />,
      },
    ],
  },
  {
    path: "pnl",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PL />,
      },
    ],
  },
  {
    path: "cashflow",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CashFlow />,
      },
    ],
  },
  {
    path: "balancesheet",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <BalanceSheet />,
      },
    ],
  },
  {
    path: "headcount",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HeadCount />,
      },
    ],
  },
  {
    path: "fixedassets",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <FixedAssets />,
      },
    ],
  },
  {
    path: "currentassets",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CurrentAssets />,
      },
    ],
  },
  {
    path: "currentliabilities",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CurrentLiabilities />,
      },
    ],
  },
  {
    path: "debt",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Debt />,
      },
    ],
  },
  {
    path: "equity",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Suspense fallback={<div>Loading...</div>}><Equity /></Suspense>,
      },
    ],
  },
  {
    path: "disclaimer",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DisclaimerContent />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-up-complete",
        element: <SignUpComplete />,
      },
      {
        path: "sign-up-disabled",
        element: <SignUpDisabled />,
      },
      {
        path: "page-new-password",
        element: <UpdatePassword />
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "verify-user",
        element: <VerifyUser />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
