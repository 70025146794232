import React, { useEffect, useState } from "react";

import useProject from "../../../../../hooks/useProject";


import Chart from "react-apexcharts";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import _ from "lodash";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);


function invertNumber(num) {
  return num * -1;
}

/**
 * WE get the Net Debt Received/Paid and the Cashflow From Financing Activities data series
 * @param cashflowData
 * @returns {*[]}
 */
function getDataForChart(cashflowData) {

  if (cashflowData === undefined || cashflowData.length === 0){
    return []
  }

  //debt
  let cashflowFromFinancingCategory = cashflowData.cashflowCategoryDtos.find(category => category.name === "Cashflow From Financing Activities");
  //working capital
  let cashflowFromOperatingActivities = cashflowData.cashflowCategoryDtos.find(category => category.name === "Cashflow From Operating Activities");

  const data = [];

  let startDate = new Date(getCurrentProject().startDate);
  startDate.setDate(1);

  let workingCapitalValDataArr = [];
  let debtValDataArr = [];

  _.forEach(cashflowFromFinancingCategory.cashflowMonthDtos, function(cashflowFromFinancingMonth, i) {

    workingCapitalValDataArr.push([startDate.getTime(), cashflowFromOperatingActivities.cashflowMonthDtos[i].value]);
    debtValDataArr.push([startDate.getTime(), invertNumber(cashflowFromFinancingMonth.value)]);

    startDate.setMonth(startDate.getMonth() + 1);
    startDate.setDate(1);

  });

  data.push(
    {
      name: "Working Capital",
      data: workingCapitalValDataArr
    },
    {
      name: "Debt",
      data: debtValDataArr
    }
  )

  return data;
}

export default function WorkingCapitalVsDebtChart(props) {


  const { cashflowData } = props;

  const [data, setData] = useState([]);

  const { key } = useProject();


  useEffect(() => {
    setData(getDataForChart(cashflowData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowData]);



// Define a unique color palette
  const uniqueColorPalette = [
'#2449D1',
'#4C1200'
  ];


  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    markers: {
      size: 0,
      style: "hollow", // full, hollow, inverted
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      seriesName: "Revenue",

      labels: {
        formatter: function (value) {
          return "£" + value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        }
      },
    },
    tooltip: {
      x: {
        formatter: function(value) {
          return new Date(value).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }).replace(/ \d+$/, '');
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    colors: uniqueColorPalette, // Assigning the unique color palette to the chart

  };


  return (
    <React.Fragment key={key}>

      <Card mb={1}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Working Capital Vs Debt
          </Typography>
          <Typography variant="body2" gutterBottom>
            This chart shows a breakdown of Working Capital Vs Debt
          </Typography>

          <Spacer mb={6} />

          <Chart options={options} series={data} type="line" height="350" />

        </CardContent>
      </Card>



    </React.Fragment>
  );
}