import _ from "lodash";
import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";

export function zoomIn() {

  //set the local storage value
  const zoomScale = parseFloat(localStorage.getItem("zoom"));

  if (zoomScale < 1) {
    localStorage.setItem("zoom", zoomScale + 0.1);
  }
}

export function zoomOut() {

  //set the local storage value
  const zoomScale = parseFloat(localStorage.getItem("zoom"));

  if (zoomScale >= 0.4) {
    localStorage.setItem("zoom", zoomScale - 0.1);
  }

}

export function getZoom() {

  const zoomValue = localStorage.getItem("zoom");

  if (zoomValue == null || zoomValue === undefined) {
    localStorage.setItem("zoom", 1);
    return 1;
  }

  return parseFloat(zoomValue);

}


export function addMonthsToDate(date, months) {
  // create a new Date object that is a copy of the input date
  const newDate = new Date(date);

  // add the specified number of months to the copied date's month value
  newDate.setMonth(date.getMonth() + months);

  // return the modified date object
  return newDate;
}


export const hiddenWhenMobile = {
  display: { xs: 'none', md: 'block' },
};


export function getMonthsBetween(date1, date2) {
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

export function reverseSign(num) {
  if (typeof num !== 'number' || isNaN(num)) {
    return 0; // or throw an error, etc.
  }

  if (num === 0) {
    return 0; //no point in reversing the sign on 0
  }

  return num * -1;
}

export function getValueOrZero(item) {
  if (_.isNil(item) || isNaN(item)) {
    return 0;
  } else {
    return item;
  }
}


export function getNumberOfDaysInMonth(index, year) {
  // Define an array with months and the number of days in each month
  const months = [
    { name: 'January', days: 31 },
    { name: 'February', days: 28 }, // Assuming non-leap year
    { name: 'March', days: 31 },
    { name: 'April', days: 30 },
    { name: 'May', days: 31 },
    { name: 'June', days: 30 },
    { name: 'July', days: 31 },
    { name: 'August', days: 31 },
    { name: 'September', days: 30 },
    { name: 'October', days: 31 },
    { name: 'November', days: 30 },
    { name: 'December', days: 31 }
  ];

  if (index === 1 && checkLeapYear(year)) {
    return 29; //leap year february
  }

  // Validate the index
  if (index < 0 || index >= months.length) {
    return 31;
  }

  // Return the number of days for the given index
  return months[index].days;
}

// program to check leap year
function checkLeapYear(year) {

  //three conditions to find out the leap year
  // eslint-disable-next-line
  if ((0 === year % 4) && (0 !== year % 100) || (0 === year % 400)) {
    return true; //is leap year
  } else {
    return false; //is not a leap year
  }
}

export function checkAllValuesZero(arr, firstForecastIndex) {
  for (let i = firstForecastIndex; i < arr.length; i++) {
    if (arr[i].value !== 0) {
      return false;
    }
  }
  return true;
}

export function getValueOrOverriddenValue(month) {

  if (month.valueOverriden !== undefined && month.valueOverriden !== null) {
    return month.valueOverriden
  } else {
    if (isNaN(month.value) || month.value === null) {
      return 0;
    }

    return month.value;
  }


}

export function hasCellValueChanged(cellData) {

  //has been copy pasted?
  if (cellData.copyPaste !== undefined && cellData.copyPaste !== null) {
    if (cellData.copyPaste === true) {
      //console.log('value has changed', cellData)
      return true; //cell value has changed
    }
  }

  //has value changed?
  if (cellData.previousValue !== undefined && cellData.previousValue !== null) {
    if (cellData.value !== cellData.previousValue) {
      //console.log('value has changed', cellData)
      return true; //cell value has changed
    }
  }

  if (cellData.previousValueOverriden !== undefined && cellData.valueOverriden !== null) {
    if (cellData.valueOverriden !== cellData.previousValueOverriden) {
      //console.log('valueOverriden has changed', cellData)
      return true; //cell value has changed
    }
  }

  return false; //by default the value has not changed
}

export function areValuesEqual(value1, value2) {

  //null and undefined checks
  if (value1 === undefined || value2 === undefined) {
    return false; //we don't compare undefined values
  }

  if (value1 === null || value2 === null) {
    return false; //we don't compare null values
  }

  if (value1 !== value2) {
    return true;
  }

  return false; //catch all
}

export function formatNumber(number) {
  // Check if the number is an integer (no decimal places)
  if (Number.isInteger(number)) {
    return number; // Return the whole number
  } else {
    // If not an integer, format to 3 decimal places and convert back to number
    return parseFloat(number.toFixed(3));
  }
}

export const TableContainerPowdr = styled.div`
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: calc(100vh - 350px); /* Full viewport height minus 50px */
  max-width: 100%;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
`;


// Styling the TableCell within TableHead for sticky behavior
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    position: 'sticky',
    top: 0,
    maxWidth: 100,
    zIndex: 2, // Ensure the header is above other content
  },
}));