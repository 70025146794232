import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import _ from "lodash";


export function actualiseBalance(balance) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast + 1; //taking into account the Opening Balance

  _.forEach(balance.balanceCategoryDtos, function(category, x) {


    if (_.isEmpty(category.balanceMonthDtos)) {
      return;
    }

    category.balanceMonthDtos[firstBalanceForecast].cellType =
      category.balanceMonthDtos[firstBalanceForecast - 1].cellType;

    category.balanceMonthDtos[firstBalanceForecast].financialTypeEnum =
      category.balanceMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

  });

  return balance;
}