import React from "react";



export default function DisclaimerContent() {


    return (
     <React.Fragment>
       <strong>PLEASE READ THIS DISCLAIMER CAREFULLY</strong>
       <p>Access to Powdr is subject to the Terms and Conditions agreed between you and Powdr Limited, a copy of which is presented below. Any breach of the Terms and Conditions will result in immediate revocation of access to Powdr.</p>

       <p>Regardless, please note:</p>

       <p>A forecast, by its nature, is speculative and includes estimates and assumptions which may prove to be wrong. Actual results may, and frequently do, differ from those projected or forecast. Those differences may be material.</p>

       <p>Items which could impact actual results include, but are not limited to, unforeseen micro or macro-economic developments, business or industry events, personnel changes, casualty losses, or the inability of the Target to implement plans or programs.</p>

       <p>Projections are also based upon numerous assumptions, including business, economic and other market conditions. Many of these assumptions are beyond the control of the Client and are inherently subject to substantial uncertainty. Such assumptions involve significant elements of subjective judgment, which may or may not prove to be accurate, and consequently, no assurances can be made regarding the analyses or conclusions derived from financial information based upon such assumptions.</p>

       <p>All assumptions and financial data populated within Powdr is the responsibility of the Client. Powdr Limited makes no representation, warranty or guarantee regarding the accuracy or completeness of any Content.</p>

       <p>Terms and Conditions:</p>

       <p>These terms and conditions (Terms and Conditions), together with any and all other documents referred to herein, set out the terms of use under which you may access and use Powdr (&ldquo;Powdr&rdquo;). Please read these Terms and Conditions carefully and ensure that you understand them. You will be required to read and accept these Terms and Conditions when signing up for an Account and purchasing a Subscription. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Powdr immediately.</p>

       <p>Definitions and Interpretation</p>

       <p>1.1. In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>

       <p>&ldquo;Account&rdquo;</p>

       <p>means an account required to set up a Subscription and access and use Powdr, as detailed in clause 4;</p>

       <p>&ldquo;Add-Ons&rdquo;</p>

       <p>means additional features and access to Powdr detailed in the Order Form which are not included in your Subscription but which you may request be added during the term of your Subscription or in relation to a specific Project (as the case may be).</p>

       <p>&ldquo;Applicable Data Protection Laws&rdquo;</p>

       <p>means the General Data Protection Regulation EU 2016/679 to the extent the law of the European Union applies and Data Protection Act 2018 to the extent the law of United Kingdom applies;</p>

       <p>&ldquo;Authorised User(s)&rdquo;</p>

       <p>means the Client&rsquo;s authorised user(s) of Powdr for whom a User Subscription has been purchased;</p>

       <p>&ldquo;Confirmation&rdquo;</p>

       <p>means Our acceptance and confirmation of your Order;</p>

       <p>&ldquo;Content&rdquo;</p>

       <p>means any content excluding User Content such as and all text, images, audio, forms, models, calculations, data, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Powdr;</p>

       <p>&ldquo;Contract&rdquo;</p>

       <p>means the contract between Us and You for the purchase and sale of a Subscription to Powdr, as formed pursuant to clause 6;</p>

       <p>&ldquo;Core Hours&rdquo;</p>

       <p>means the period between 9.00 and 17.30 UK time Monday to Friday excluding public holidays recognised in England;</p>

       <p>&ldquo;Initial Fee&rdquo;</p>

       <p>means the upfront one-off fee (if any) charged covering the Initial Term of the Subscription set out in the Order Form;</p>

       <p>&ldquo;Initial Term&rdquo;</p>

       <p>means the term set out in months on the Order Form which commences on the Start Date;</p>

       <p>&ldquo;Order&rdquo;</p>

       <p>means your order for a Subscription relating to a Project (unless Your Subscription level provides for multiple Projects);</p>

       <p>&ldquo;Order Form&rdquo;</p>

       <p>means the document detailing the terms of your Subscription and the terms of your Contract and the details of the Project to which the Subscription relates (if applicable);</p>

       <p>&ldquo;Powdr&rdquo;</p>

       <p>means the web based application for financial forecasting and planning used by SME and corporate businesses known as &ldquo;Powdr&rdquo;;</p>

       <p>&ldquo;Project&rdquo;</p>

       <p>means the project described in the Order Form in relation to which the Subscription has been purchased by the Client (unless the Client&rsquo;s Subscription level provides for multiple Projects which is made clear on the Order Form);</p>

       <p>&ldquo;Services&rdquo;</p>

       <p>means technical and or professional services required to support a Project that fall outside the scope of the services which are included with the Subscription as detailed in the Order Form;</p>

       <p>&ldquo;Start Date&rdquo;</p>

       <p>means the earlier of the date on which your access to Powdr commences, or 30 days from the date of the Confirmation or such alternate date agreed between You and Us in writing;</p>

       <p>&ldquo;Subscription&rdquo;</p>

       <p>means a subscription for access to Powdr, purchased and based on an Order and Confirmation in accordance with these Terms and Conditions;</p>

       <p>&ldquo;Subscription Fee&rdquo;</p>

       <p>means the recurring monthly fees charged for continuation of the Subscription (plus any Add-Ons) as is referred to in the Order Form;</p>

       <p>&ldquo;User Content&rdquo;</p>

       <p>means documents and data uploaded by Authorised Users in or to Powdr;</p>

       <p>&ldquo;User Subscriptions&rdquo;</p>

       <p>the user subscriptions purchased by the Client pursuant to clause 4.4 which entitle Authorised Users to access and use Powdr;</p>

       <p>&ldquo;We/Us/Our&rdquo;</p>

       <p>means Powdr Limited registered in England under company number 14540652 whose registered office is Springfield Cottage Fence Lane, Newbold Astbury, Congleton, England, CW12 3NL;</p>

       <p>&ldquo;You/Your/Client&rdquo;</p>

       <p>means the company identified under Order for &ldquo;Client&rdquo; on the Order.</p>

       <p>Information About Us</p>

       <p>2.1. Powdr is owned and operated by Powdr Limited registered in England under company number 14540652 whose registered office is Springfield Cottage Fence Lane, Newbold Astbury, Congleton, England, CW12 3NL.</p>

       <p>Access to and Changes to Powdr</p>

       <p>3.1. Access to Powdr requires a Subscription. Upon purchasing a Subscription, We grant the Client a limited, non-exclusive, revocable, worldwide, non-transferable licence to access and use Powdr solely for its own business purposes in relation to the Project(s), subject to these Terms and Conditions and Powdr will be available to You for the duration of that Subscription and any and all subsequent renewals subject to these Terms and Conditions.</p>

       <p>3.2. We may from time to time make changes to Powdr to update, improve and modify certain functionality in particular:</p>

       <p>3.2.1. Minor changes may be required to make underlying technical alterations, for example, to fix an error or to address a security issue or to reflect changes in the law or other regulatory requirements. We will endeavour to make these changes outside of Core Hours. Such changes may result in Powdr being unavailable for a short period of time. We will have no obligation to notify You in advance about such changes. You will be notified of such changes (including, if applicable, anything that You need to do), at the time You next use the software, if they are likely to materially affect Your use of Powdr; and</p>

       <p>3.2.2. We reserve the right to continue to change and/or discontinue and/or develop and improve any or all of the features or functionality of Powdr over time, which could, in some cases, make significant changes to it. You will be kept fully informed of any significant changes. New releases implementing these significant changes will be implemented outside of Core Hours with at least 7 days&rsquo; notice via e mail.</p>

       <p>3.2.3. We do not however commit to deliver any new features or functionality for general availability.</p>

       <p>3.3. We will always aim to ensure that Powdr is available as described in clause 15 below. In certain limited cases, however, We may need to temporarily suspend availability to make certain changes outlined under clause 3.2. Unless We are responding to an emergency or an urgent issue, We will endeavour to inform You in advance of any planned interruptions to the availability of Powdr.</p>

       <p>3.4. The Powdr service provided by Us includes standard customer technical support services during the Core Hours in accordance with our policy in effect at the time. Other Services including consulting services or any other types of services may be ordered separately.</p>

       <p>3.5. By accepting these Terms and Conditions, You hereby undertake except to the extent permitted under this agreement:</p>

       <p>3.5.1. not to use Powdr to provide services to third parties or make it otherwise available to third parties without Our prior written consent;</p>

       <p>3.5.2. where a Subscription has been purchased for a particular Project, to only use Powdr for that Project which a Subscription has been purchased for; additional, separate Subscriptions are required for unrelated Projects.</p>

       <p>3.5.3. not to licence, sub-licence, sell, rent, transfer, assign, distribute or otherwise commercially exploit all or any part of Powdr;</p>

       <p>3.5.4. not to introduce or permit the introduction of any virus into our network and information systems;</p>

       <p>3.5.5. not attempt to circumvent the user authentication or security process for Powdr;</p>

       <p>3.5.6. not to copy, modify, duplicate, frame, mirror, republish, transmit, download or otherwise attempt to acquire or distribute all or any part of Powdr;</p>

       <p>3.5.7. not to disassemble, decompile or otherwise reverse compile or reverse engineer Powdr;</p>

       <p>3.5.8. not to allow or facilitate any use of Powdr that would constitute a breach of these Terms and Conditions; and</p>

       <p>3.5.9. not to embed or otherwise distribute Powdr on any website, FTP server or similar.</p>

       <p>3.6. In order to provide Your access to Powdr including but not limited to User Content, security access information and configuration services, You shall:</p>

       <p>3.6.1. Provide us with all necessary co-operation in relation to this Agreement; and</p>

       <p>3.6.2. Provide us with all necessary access to such information as We may require;</p>

       <p>3.6.3. Comply with all laws and regulations with respect to its activities under these Terms and Conditions;</p>

       <p>3.6.4. Carry out all other responsibilities imposed by or required under these Terms and Conditions in a timely and efficient manner;</p>

       <p>3.6.5. Ensure that the Authorised Users use Powdr in accordance with these Terms and Conditions;</p>

       <p>3.6.6. Obtain and maintain all necessary licences, consents and permissions necessary for Us and our contractors and agents to perform Our obligations under these Terms and Conditions;</p>

       <p>3.6.7. Ensure that your network and systems comply with the relevant specifications supplied by Us from time to time;</p>

       <p>3.6.8. Be solely responsible for procuring and maintaining and securing Your network communications and telecommunications links from its systems to Our platform and all problems, conditions, delays and all other loss or damage arising from or relating to Your network connections or telecommunications links or caused by the internet.</p>

       <p>3.6.9. Indemnify Us and hold Us harmless from and against any claims, actions proceedings, damages, losses, liabilities, costs and expenses arising out of or in connection with:</p>

       <p>i) your use of Powdr in a manner that is not consistent with these Terms and Conditions; or</p>

       <p>ii) any claim or allegation that User Content infringes, misappropriates or violates the rights of any third party.</p>

       <p>3.6.10. We will provide You with prompt written notice of any such claim but Our failure to do so does not relieve You of Your obligations under this clause.</p>

       <p>Accounts and Authorised Users</p>

       <p>4.1. An Account is required to establish a Subscription to use Powdr.</p>

       <p>4.2. When creating an Account, the information You provide must be accurate and complete. If any of Your information changes at a later date, it is Your responsibility to ensure that Your Account information is kept up-to-date.</p>

       <p>4.3. A Subscription shall entitle the Client to permit access to such number of initial Authorised Users as is set out in the Order Form.</p>

       <p>4.4. The Client may from time to time during the term of this agreement purchase additional User Subscriptions in excess of the number set out in the Order Form. If the Client wishes to purchase additional User Subscriptions it shall notify Us in writing. We shall evaluate such request and respond to approve or reject the request (such approval not to be unreasonably withheld). Where We approve the request, We will issue an invoice for the additional User Subscriptions which shall be payable upon receipt and We will activate the additional User Subscriptions within one day of payment being received. If such additional User Subscriptions are purchased by the Client part way through the Initial Term, or during the rolling monthly term which follows, such fees shall be pro-rated from the date of activation by the Supplier for the remainder of the Initial Term or the subsequent rolling monthly terms (as applicable).</p>

       <p>4.5. In relation to the Authorised Users, the Client undertakes that:</p>

       <p>4.5.1. the maximum number of Authorised Users that it authorises to access and use the Subscription shall not exceed the number of User Subscriptions it has purchased from time to time;</p>

       <p>4.5.2. it will not allow or suffer any User Subscription to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use Powdr;</p>

       <p>4.5.3. it shall maintain a written, up to date list of current Authorised Users and provide such list to Us within 5 Business Days of Our written request at any time or times;</p>

       <p>4.5.4. it shall permit Us or Our designated auditor to carry out an audit in order to establish the name and password of each Authorised User and such other information as may be reasonably necessary to audit compliance with this agreement. Each such audit may be conducted no more than once per quarter, at Our expense, and this right shall be exercised with reasonable prior notice, in such a manner as not to substantially interfere with the Client's normal conduct of business;</p>

       <p>4.5.5. if any of the audits referred to in clause 4.5.4 reveal that any password has been provided to any individual who is not an Authorised User, then without prejudice to Our other rights, the Client shall promptly disable such passwords and We shall not issue any new passwords to any such individual; and</p>

       <p>4.5.6. if any of the audits referred to in clause 4.5.4 reveal that the Client has underpaid Subscription Fees to Us, then without prejudice to Our other rights, the Client shall pay to Us an amount equal to such underpayment as calculated in accordance with the prices set out in the Order Form within 10 Business Days of the date of the relevant audit.</p>

       <p>4.6. The Client undertakes that it shall prevent any unauthorised access to or use of Powdr. In the event of any such unauthorised access, the Client shall promptly notify Us of the same and shall take all reasonable steps as required by Us to resolve the issue.</p>

       <p>4.7. The Authorised User&rsquo;s identity and security is Your responsibility as the Data Controller (as such term is defined in the Applicable Data Protection Laws).</p>

       <p>4.8. The Client shall ensure that all Authorised Users of Powdr shall keep a secure password. We recommend that Authorised Users choose a strong password for each Authorised User use of Your Subscription. It is Your responsibility to ensure that each Authorised User keeps all passwords confidential and safe. You shall ensure that each Authorised User must not share their password with any other Authorised User. The Client shall not share Your Subscription with another legal entity else unless You receive Our approval in writing. If You believe Your Subscription is being used without Your permission, please contact Us immediately. We will not be liable for any unauthorized use of Your Subscription. The Client is solely responsible for all activity occurring under its Authorised Users logins to the Powdr service.</p>

       <p>4.9. Any Personal Data provided both for Your account or in establishing Your Subscription will be collected, used, and held in accordance with Your rights and Our obligations under the Applicable Data Protection Laws and, as set out in clause 20.</p>

       <p>Subscriptions, Pricing and Availability</p>

       <p>5.1. Different types of Subscription are available and provide access to different features in Powdr. The level of Subscription will be set out in the Order Form and can be amended with our agreement.</p>

       <p>5.2. The Subscription shall commence on the Start Date and, shall continue for the Initial Term. After the Initial Term has ended, the Subscription shall automatically renew and become a rolling monthly contract terminable on 30 days&rsquo; prior written notice which may be given at any time by either party, unless:</p>

       <p>5.2.1. either party gives notice to terminate in writing to the other at least 30 days prior to the end of the Initial Term, in which case this agreement shall terminate on expiry of the Initial Term; or</p>

       <p>5.2.2. the agreement is otherwise terminated in accordance with clause 14 (Cancellation)</p>

       <p>5.3. All prices (including those for Add-Ons) are referred to in the Order Form.</p>

       <p>5.3.1. We reserve the right to change prices and to add, alter, or remove special offers from time to time and as necessary or where there has been an error or mistake.</p>

       <p>5.3.2. We reserve the right to update and increase prices from time to time and will give You at least 30 days&rsquo; prior notice of any price rises that will affect you.</p>

       <p>5.3.3. We may additionally increase Our prices if we experience any significant cost increase due to factors beyond Our control commensurate in providing You with the Powdr service relating to the Contract. A non-exhaustive list includes currency exchange rate fluctuations, the cost of using third party technology or hosting such as Amazon Web Services and increases in the cost of third party licences.</p>

       <p>5.4. All prices exclude VAT.</p>

       <p>Subscriptions&ndash; How Contracts Are Formed</p>

       <p>6.1. No part of our website or any other material constitutes a contractual offer capable of acceptance. By purchasing a Subscription, you are making Us a contractual offer that We may, at Our sole discretion, accept. Our acceptance is indicated by Us sending you a countersigned copy of the Order Form and an invoice for the Initial Fee (if any) and/or the first month&rsquo;s Subscription Fees. Only once We have sent you a Confirmation will there be a legally binding contract between Us and you (&ldquo;the Contract&rdquo;).</p>

       <p>6.2. If applicable, We will Invoice You the Initial Fee for the Subscription which shall be payable in advance of the Start Date. During the term of this agreement We will invoice the Subscription Fee for each calendar month on the first working day of the calendar month.</p>

       <p>6.3. Subject to the cancellation provisions in clause 14, once We have confirmed Your Order for a Subscription, You cannot change Your Subscription, without our consent.</p>

       <p>Payment</p>

       <p>7.1. All recurring payments relating to Subscriptions will be payable by You monthly in advance against Our invoice and due upon presentation of said invoice.</p>

       <p>7.2. We reserve the right to suspend Your Subscription and any related Projects if any payment is overdue.</p>

       <p>7.3. Interest shall accrue on a daily basis on such due amounts at an annual rate equal to 3% over the then current base lending rate of the Bank of England from time to time, commencing on the due date and continuing until fully paid, whether before or after judgment.</p>

       <p>7.4. All fees stated or referred to in this agreement:</p>

       <p>7.4.1. Shall be payable in pounds sterling;</p>

       <p>7.4.2. Are exclusive of value added tax which shall be added to Our invoice at the appropriate rate.</p>

       <p>Our Intellectual Property Rights and Licence</p>

       <p>8.1. Subject to the licence granted to Us under clause 11.3, You shall retain the ownership of copyright and other intellectual property rights in Your User Content (subject to any third-party rights in that User Content and the terms of any licence under which You use such User Content).</p>

       <p>8.2. All Content included in Powdr and the copyright and other intellectual property rights in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties. Except as expressly stated herein, this Agreement does not grant the Client any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of Powdr.</p>

       <p>Third party software</p>

       <p>10. Powdr utilises the Amazon Web Services (AWS) platform and We have been informed that all Your data stored in Powdr is held on servers based in the United Kingdom. The data processing agreement related to the Amazon Web Services platform is available at Cloud Computing Services - Amazon Web Services (AWS) .</p>

       <p>11. User Content</p>

       <p>11.1. You agree that You will be solely responsible for any and all User Content that You create or upload using Powdr. Specifically, You agree, represent and warrant that You have the right to create or upload the User Content and the right to use all materials of which it is comprised and that it will not contravene any aspect of Our Acceptable Usage Policy, detailed in clause 13.</p>

       <p>11.2. You agree that You will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by You under clause 11.1. You will be responsible for any loss or damage suffered by Us as a result of such breach.</p>

       <p>11.3. You (or Your licensors, as appropriate) retain ownership of Your User Content and all intellectual property rights subsisting therein. By creating or uploading User Content, You grant Us an unconditional, non-exclusive, fully transferable, royalty- free, perpetual, worldwide licence to use, store, archive, syndicate, publish, transmit, adapt, edit, reproduce, distribute, prepare derivative works from, display, perform and sub-licence Your User Content for the purposes of operating Powdr.</p>

       <p>12. Additional Services</p>

       <p>12.1. During the term of this agreement, You may elect to purchase Add-Ons including professional Services for use in relation to a Project.</p>

       <p>12.2. The fees payable for Add-Ons are as set out in the Order Form.</p>

       <p>12.3. The charges payable for Services will be at Our then prevailing rate.</p>

       <p>13. Acceptable Usage Policy</p>

       <p>13.1. You may only use Powdr in a manner that is lawful and that complies with the provisions of this clause 13. Specifically:</p>

       <p>13.1.1. You must ensure that You comply fully with any and all applicable local, national and international laws and/or regulations;</p>

       <p>13.1.2. You must not use Powdr in any way, or for any purpose, that is unlawful or fraudulent;</p>

       <p>13.1.3. You must not use Powdr to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software or any data of any kind; and</p>

       <p>13.1.4. You must not use Powdr in any way, or for any purpose, that is intended to harm any person or persons in any way.</p>

       <p>13.2. The following types of User Content is a non-exhaustive list of the types of User Consent that are not permitted on Powdr and you must not create, submit, communicate or otherwise do anything that:</p>

       <p>13.2.1. is sexually explicit;</p>

       <p>13.2.2. is obscene, deliberately offensive, hateful, or otherwise inflammatory;</p>

       <p>13.2.3. promotes violence;</p>

       <p>13.2.4. promotes or assists in any form of unlawful activity;</p>

       <p>13.2.5. discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</p>

       <p>13.2.6. is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</p>

       <p>13.2.7. is calculated or otherwise likely to deceive;</p>

       <p>13.2.8. is intended or otherwise likely to infringe (or threaten to infringe) another person&rsquo;s right to privacy;</p>

       <p>13.2.9. infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trademarks and database rights) of any other party; or</p>

       <p>13.2.10. is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</p>

       <p>14. Cancellation</p>

       <p>14.1. Except as provided under this clause 14, a Subscription cannot be cancelled during the Initial Term.</p>

       <p>14.2. You will be liable for an early termination charge if, during the Initial Term, You cancel your Subscription (except where you have a right to cancel under clause 14.3 of this Contract), or We cancel your Subscription under clause 14.5 of this Contract. The early termination charge will be the remaining charges due for the balance of Your Initial Term.</p>

       <p>14.3. You may cancel Your Subscription at any time in the following limited circumstances, and you may be entitled to a full or partial refund for any prepaid services not provided:</p>

       <p>14.3.1. We have informed you of an upcoming material change to Powdr or to these Terms and Conditions that You, acting reasonably, identify to Us, within 14 days of such notice, as either having a material adverse impact on your ability to use Powdr or a material impact on the balance of risk to you presented in the amended Terms and Conditions which We do not address to your satisfaction within 14 days of such notice; or</p>

       <p>14.3.2. There is a risk that the availability of Powdr may be significantly delayed due to events outside of Our control; in the case of a force majeure event the entitlement to cancellation is described in clause 23; or</p>

       <p>14.3.3. We have informed You that We have suspended, or are planning to suspend, availability of Powdr for a period greater than 5 working days, except where such suspension arises (i) as a result of a breach of these Terms and Conditions by You or (ii) where such delay is as described in clause 14.3.2 above; or</p>

       <p>14.3.4. We have materially breached these Terms and Conditions or have in any way failed to comply with Our legal obligations to you.</p>

       <p>14.4. In the case of cancellation by You under clause 14.3, You will continue to have access to Your Subscription and all associated Projects for the duration of the remainder of the Initial Term. Any and all refunds that may be due to You will be made no later than 10 working days after the date on which We acknowledge Your cancellation.</p>

       <p>14.5. We reserve the right to suspend or terminate Your Subscription if You materially breach these Terms and Conditions and such breach (if capable of remedy) is not remedied within 7 days after written notice of such breach. In the event of such breach by You, We may take one or more of the following actions:</p>

       <p>14.5.1. Suspend, whether temporarily or permanently, Your Subscription and any associated Projects;</p>

       <p>14.5.2. Remove any of Your User Content which violates the Acceptable Usage Policy;</p>

       <p>14.5.3. Issue You with a written warning;</p>

       <p>14.5.4. Take legal proceedings against You for reimbursement of any and all relevant costs on an indemnity basis resulting from Your breach;</p>

       <p>14.5.5. Take further legal action against You as appropriate;</p>

       <p>14.5.6. Disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or</p>

       <p>14.5.7. Any other actions which We deem reasonably appropriate (and lawful).</p>

       <p>14.6. We reserve the right to suspend or terminate Your Subscription if in the event of a change of control (within the meaning of section 1124 of the Corporation Tax Act 2010) Your legal entity comes under direct or indirect control of any entity competing with Us.</p>

       <p>14.7. We hereby exclude any and all liability arising out of any actions (including, but not limited to, those set out above) that We may take in response to breaches of these Terms and Conditions.</p>

       <p>14.8. Without affecting any other right or remedy available to it, either party may terminate this agreement with immediate effect by giving written notice to the other party if:-</p>

       <p>14.8.1. the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits inability to pay its debts or is deemed unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986 (IA 1986) as if the words &quot;it is proved to the satisfaction of the court&quot; did not appear in sections 123(1)(e) or 123(2) of the IA 1986 OR (being an individual) is deemed either unable to pay its debts or as having no reasonable prospect of so doing, in either case, within the meaning of section 268 of the IA 1986 OR (being a partnership) has any partner to whom any of the foregoing apply;</p>

       <p>14.8.2. the other party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party;</p>

       <p>14.8.3. the other party applies to court for, or obtains, a moratorium under Part A1 of the Insolvency Act 1986;</p>

       <p>14.8.4. a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of that other party other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party;</p>

       <p>14.8.5. an application is made to court, or an order is made, for the appointment of an administrator, or if a notice of intention to appoint an administrator is given or if an administrator is appointed, over the other party (being a company, partnership or limited liability partnership);</p>

       <p>14.8.6. the holder of a qualifying floating charge over the assets of that other party (being a company or limited liability partnership) has become entitled to appoint or has appointed an administrative receiver;</p>

       <p>14.8.7. a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed over the assets of the other party;</p>

       <p>14.8.8. a creditor or encumbrancer of the other party attaches or takes possession of, or a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of the other party's assets and such attachment or process is not discharged within 14 days;</p>

       <p>14.8.9. any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in clause 14.8.1 to clause 14.8.9 (inclusive);</p>

       <p>14.8.10. the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business;</p>

       <p>14.8.11. the other party's financial position deteriorates so far as to reasonably justify the opinion that its ability to give effect to the terms of this agreement is in jeopardy.</p>

       <p>14.9. On termination of this agreement for any reason:</p>

       <p>14.9.1. all access to and for Subscriptions, Projects and licences granted under this agreement shall immediately terminate and the Client shall immediately cease all use of Powdr;</p>

       <p>14.9.2. each party shall return and make no further use of any equipment, property, documentation and other items (and all copies of them) belonging to the other party;</p>

       <p>14.9.3. We may destroy or otherwise dispose of any of the User Content in our possession after 30 days, You will be able to access and download any such User Content during that period; and</p>

       <p>14.9.4. any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the agreement which existed at or before the date of termination shall not be affected or prejudiced.</p>

       <p>15. Availability of the Powdr service and support</p>

       <p>15.1. The Powdr software runs on Amazon Web Services and is available 7 days a week 24 hours a day 365 days per year subject to the availability of the Amazon Web Services platform and any downtime notified by Us.</p>

       <p>15.2. We shall use commercially reasonable endeavours to ensure that the full Powdr service, including support, is available on UK business days only between 9.00 and 17.30 except for unscheduled maintenance anticipated in accordance with these Terms and Conditions.</p>

       <p>16. Disclaimers</p>

       <p>16.1. No part of Powdr or any accompanying documentation (whether provided in electronic form or otherwise) constitutes advice on which You should rely and is provided for general information and forecasting purposes only. Powdr does not provide tax advice, and any calculations relating to tax are purely indicative to assist with investment and planning decisions. Professional or specialist advice should always be sought before taking any action or decisions relating to Your business, accounting and tax affairs.</p>

       <p>16.2. Insofar as is permitted by law, We make no representation, warranty, or guarantee that Powdr will meet Your requirements, that it will be fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware. We provide Powdr (and any related documentation) &quot;as is&quot;, &quot;with all faults&quot; and &quot;as available&quot;. You acknowledge and agree that computer systems aren't fault-free and We cannot guarantee use of Powdr will be uninterrupted, timely, or that it will be secure or error-free.</p>

       <p>16.3. We make reasonable efforts to ensure that the Content, excluding for the avoidance of doubt any User Content, is complete, accurate and up-to-date. We do not, however, make representations, warranties or guarantees (whether express or implied) that Powdr (and the Content therein) is complete, accurate or up-to-date.</p>

       <p>16.4. We are not responsible for the User Content or it&rsquo;s accuracy, or for any opinions, views, or values expressed in any User Content created or uploaded using Powdr. Any such opinions, views, or values are those of the relevant Authorised User, and do not reflect Our opinions, views, or values in any way.</p>

       <p>16.5. A forecast, by its nature, is speculative and includes estimates and assumptions which may prove to be wrong. Actual results may, and frequently do, differ from those projected or forecast. Those differences may be material. Items which could impact actual results include, but are not limited to, unforeseen micro or macro-economic developments, business or industry events, personnel changes, casualty losses, or the inability of the Target to implement plans or programs. The projections are also based upon numerous assumptions, including business, economic and other market conditions. Many of these assumptions are beyond the control of the Client and are inherently subject to substantial uncertainty. Such assumptions involve significant elements of subjective judgment, which may or may not prove to be accurate, and consequently, no assurances can be made regarding the analyses or conclusions derived from financial information based upon such assumptions.</p>

       <p>16.6. We are not responsible for any delays or other loss or damage resulting from the transfer of data over communications networks and facilities including the internet and the Client acknowledges that access to Powdr may be subject to limitations, delays and other problems inherent in the use of such communications facilities. The Client is responsible for its and its Authorised Users use or and access to Powdr.</p>

       <p>17. Security</p>

       <p>17.1. Each party shall:</p>

       <p>17.1.1. ensure at all times that appropriate safety and security systems, policies and procedures are maintained and enforced to prevent unauthorised access or damage to, and to ensure the business continuity of, the Powdr service and related networks or resources, with any changes to such information to be promptly notified to the other in writing;</p>

       <p>17.1.2. agree that if incidents are detected or discovered, each of them shall notify the other immediately and co-operate with the other to mitigate the incident and, particularly if the incident causes or threatens the loss of operational efficiency, loss or corruption of Content or User Content, or the reduced management of risks posed to the security of the parties, the parties shall assist each other to mitigate any losses. The costs of complying with this clause shall be apportioned between the parties on a pro rata basis according to fault.</p>

       <p>17.1.3. promptly respond to any requests for information, or inspection, made by a regulator with competent jurisdiction over a party (including in connection with any Applicable Data Protection Laws or cybersecurity legislation).</p>

       <p>17.2. We shall promptly notify You in writing of any actual or suspected loss or damage to User Content. In the event of any loss or damage to User Content, Your sole and exclusive remedy shall be for Us to use reasonable commercial endeavours to restore the lost or damaged User Content from the latest backup. We shall not be responsible for any loss, destruction, alteration or unauthorised access to or disclosure of User Content caused by any third party (except those third parties sub-contracted by Us to perform services related to User Content maintenance and back-up).</p>

       <p>18. Limitation of Liability</p>

       <p>18.1. Except where expressly provided in these Terms and Conditions, You assume sole responsibility for results obtained from the use by You of Powdr and any content, and for conclusions drawn from such use. We shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided by You to Us in connection with Powdr and content, or any actions taken by Us at Your direction.</p>

       <p>18.2. Subject to clause 18.6, We accept no liability for any foreseeable loss in contract, tort (including negligence), for breach of statutory duty, contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, loss of sales, loss of revenue and/or anticipated savings, loss of business opportunity, depletion of goodwill or reputation, and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising out of or in connection with the use of (or inability to use) Powdr or the use of or reliance upon any content (whether that Content is provided by Us or whether it is User Content) included in Powdr.</p>

       <p>18.3. To the fullest extent permissible by law, We exclude all representations, warranties, conditions and guarantees (whether express or implied) that may apply to Powdr or any Content and User Content included in Powdr.</p>

       <p>18.4. We exercise reasonable skill and care to ensure that Powdr is free from viruses and other malware. Subject to clause 18.6, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material that may adversely affect Your hardware, software, data or other material that occurs as a result of Your use of Powdr (including the downloading of any Content and User Content from it) or any other website or service that We may provide a link to.</p>

       <p>18.5. We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Powdr resulting from external causes or anything beyond our control including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.</p>

       <p>18.6. Nothing in these Terms and Conditions excludes or restricts Our liability in any situation where it would be unlawful for Us to do so including:</p>

       <p>18.6.1. fraud or fraudulent misrepresentation.</p>

       <p>18.6.2. for death or personal injury resulting from negligence; or</p>

       <p>18.6.3. for any other forms of liability which cannot be excluded or restricted by law.</p>

       <p>18.7. Our total aggregate liability in contract (tort (including negligence or breach of statutory duty)), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of Powdr under this Contract shall be limited to 100% of the value of all sums paid by You to Us for your Subscription during the 12 months immediately preceding the date on which the claim arose.</p>

       <p>18.8. Nothing in this Agreement excludes Your liability to Us for any breach, infringement, or misappropriation of any of Our (and/or of any of Our licensors&rsquo;) Intellectual Property Rights.</p>

       <p>18.9. We shall not be liable for any non-performance of the Powdr service which is caused by use contrary to Our instructions, or modification or alteration of by any party other than Us or our duly authorized contractors or agents. We do not warranty that the Client&rsquo;s use of the Powdr service will be uninterrupted or error-free.</p>

       <p>19. Viruses, Malware and Security</p>

       <p>19.1. We exercise all reasonable skill and care to ensure that Powdr is secure and free from viruses and other malware. We do not, however, guarantee that Powdr is secure or free from viruses or other malware and accept no liability in respect of the same, as detailed in clause 18.5.</p>

       <p>19.2. You are responsible for protecting Your hardware, software, data and other material from viruses, malware and other internet security risks.</p>

       <p>19.3. You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Powdr.</p>

       <p>19.4. You must not attempt to gain unauthorised access to any part of Powdr, the platform on which Powdr is run, or any other server, computer, or database connected to Powdr.</p>

       <p>19.5. You must not attack Powdr by means of a denial of service attack, a distributed denial of service attack, or by any other means.</p>

       <p>19.6. By breaching the provisions of clauses 19.3 to 19.5 You may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing Your identity to them. Your right to use Powdr will cease immediately in the event of such a breach and, where applicable, Your Account will be suspended and/or deleted.</p>

       <p>20. Data Protection</p>

       <p>20.1. For the purpose of this clause, the terms personal data, controller, processor, data subject, personal data, personal data breach and processing shall have the meaning given to them in Applicable Data Protection Laws.</p>

       <p>20.2. All personal data that We may collect (including, but not limited to, Your name and contact details and those of Your Authorised Users) will be collected, used, and held in accordance with the provisions of the Applicable Data Protection Laws and Your rights and Our obligations under those regulations and that Act.</p>

       <p>20.3. We may use Your personal data to:</p>

       <p>20.3.1. Reply to any communications that You send to Us;</p>

       <p>20.3.2. Send You important notices;</p>

       <p>20.3.3. Any reasonable business activity that serves Your and Our legitimate interests.</p>

       <p>20.4. We will not pass Your personal information on to any third parties without first obtaining Your express permission to do so.</p>

       <p>20.5. In providing the Powdr service We are acting as a processor as defined in the Applicable Data Protection Laws.</p>

       <p>20.6. Both parties will comply with all applicable requirements of Applicable Data Protection Laws. This clause is in addition to, and does not relieve, remove or replace, a party's obligations or rights under Applicable Data Protection Laws.</p>

       <p>20.7. The parties have determined that, for the purposes of Applicable Data Protection Laws:</p>

       <p>20.7.1. You shall act as controller in respect of the personal data and processing of Authorised Users;</p>

       <p>20.7.2. We shall process the personal data, as a processor on Your behalf and processing of Authorised Users; and</p>

       <p>20.8. By entering into this agreement, You consent to (and shall procure all required consents, from its personnel, representatives and agents, in respect of) all actions taken by Us in connection with the processing of personal data, provided these are in compliance with the Applicable Data Protection Laws.</p>

       <p>20.9. Without prejudice to the generality of clause 20.6, You will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data for the duration and purposes of this agreement.</p>

       <p>20.10. Without prejudice to the generality of clause 20.6, each party shall, in relation to personal data:</p>

       <p>20.10.1. In Our case, process that personal data only on Your documented instructions, unless We are required by Applicable Data Protection Laws to otherwise process that personal data.</p>

       <p>20.10.2. In each case implement appropriate the technical and organisational measures to protect against unauthorised or unlawful processing of personal Data and against accidental loss or destruction of, or damage to, personal data, having regard to the state of technological development and the cost of implementing any measures;</p>

       <p>20.10.3. ensure that any personnel engaged and authorised to process personal data have committed themselves to confidentiality or are under an appropriate statutory or common law obligation of confidentiality;</p>

       <p>20.10.4. assist each other insofar as this is possible, in responding to any request from a data subject and in ensuring compliance with its obligations under Applicable Data Protection Laws with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</p>

       <p>20.10.5. notify the other party without undue delay on becoming aware of a personal data breach involving the personal data;</p>

       <p>20.10.6. at Your written direction delete or return personal data and copies thereof to You within 180 days of termination of the agreement unless We are required by this agreement or Applicable Data Protection Laws to continue to process that personal data.</p>

       <p>20.11. You hereby provide prior, general authorisation for Us to:</p>

       <p>20.11.1. appoint processors to process the personal data, provided that We shall ensure that the terms on which we appoint such processors comply with Applicable Data Protection Laws and shall remain responsible for the acts and omission of any such processor as if they were Our acts and omissions; and</p>

       <p>20.12. Either party may, at any time on not less than 30 days' notice, revise clause 20 by replacing it (in whole or part) with any applicable standard clauses approved by the EU Commission or the UK Information Commissioner's Office or forming part of an applicable certification scheme or code of conduct (Amended Terms). Such Amended Terms shall apply when replaced by attachment to this agreement, but only in respect of such matters which are within the scope of the Amended Terms.</p>

       <p>21. General Terms</p>

       <p>21.1. We may transfer (assign) Our obligations and rights under these Terms and Conditions (and under the Contract, as applicable) to a third party (this may happen, for example, if We sell Our business). If this occurs, You will be informed by Us in writing. Your rights under these Terms and Conditions will not be affected and Our obligations under these Terms and Conditions will be transferred to the third party who will remain bound by them.</p>

       <p>21.2. You may not transfer (assign) Your obligations and rights under these Terms and Conditions (and under the Contract, as applicable) without Our express written permission.</p>

       <p>21.3. The Contract is between You and Us. It is not intended to benefit any other person or third party in any way and no such person or party will be entitled to enforce any provision of these Terms and Conditions.</p>

       <p>21.4. You confirm that the person signing the Order Form on Your behalf has the requisite authority to do so and bind You.</p>

       <p>21.5. If any of the provisions of these Terms and Conditions are found to be unlawful, invalid or otherwise unenforceable by any court or other authority, that / those provision(s) shall be deemed severed from the remainder of these Terms and Conditions. The remainder of these Terms and Conditions shall be valid and enforceable.</p>

       <p>21.6. No failure or delay by Us in exercising any of Our rights under these Terms and Conditions means that We have waived that right, and no waiver by Us of a breach of any provision of these Terms and Conditions means that We will waive any subsequent breach of the same or any other provision.</p>

       <p>21.7. We may make reasonable alterations to these Terms and Conditions that are minor or administration and do not fundamentally alter these Terms and Conditions at any time. Any such changes will become binding on You upon Your first use of Powdr after the changes have been notified to You and implemented. You will be notified of any changes to these terms by email.</p>

       <p>21.8. In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</p>

       <p>22. Law and Jurisdiction</p>

       <p>22.1. These Terms and Conditions shall be governed by, and construed in accordance with the law of England &amp; Wales.</p>

       <p>22.2. Any disputes concerning these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>

       <p>23. Force Majeure</p>

       <p>23.1. Neither party shall be in breach of this agreement nor liable for delay in performing, or failure to perform, any of its obligations under this agreement if such delay or failure result from events, circumstances or causes beyond its reasonable control. The time for performance of such obligations shall be extended accordingly. If the period of delay or non-performance continues for 30 days, the party not affected may terminate this agreement by giving 7 days written notice to the affected party.</p>

       <p>24. Confidentiality</p>

       <p>24.1. Confidential Information means all confidential information (however recorded or preserved) disclosed by a party or its Representatives (as defined below) to the other party and that party's Representatives in connection with the Powdr service, including but not limited to:</p>

       <p>24.1.1. the terms of this agreement or any agreement entered into in connection with this agreement;</p>

       <p>24.1.2. any information that would be regarded as confidential by a reasonable business person relating to:</p>

       <p>24.1.3. the business, assets, affairs, customers, clients, suppliers, plans, intentions, or market opportunities of the disclosing party (or of any member of the group of companies to which the disclosing party belongs); and</p>

       <p>24.1.4. the operations, processes, product information, know-how, designs, trade secrets or software of the disclosing party (or of any member of the group of companies to which the disclosing party belongs);</p>

       <p>24.1.5. any information developed by the parties in the course of carrying out this agreement and the parties agree that:</p>

       <p>24.1.6. details of Powdr Our Content and/or the Services, shall constitute Our Confidential Information; and</p>

       <p>24.1.7. User Content shall constitute Your Confidential Information; and</p>

       <p>24.1.8. Representatives means, in relation to a party, its employees, officers, contractors, subcontractors, representatives and advisers.</p>

       <p>24.2. The provisions of this clause shall not apply to any Confidential Information that:</p>

       <p>24.2.1. or becomes generally available to the public (other than as a result of its disclosure by the receiving party or its Representatives in breach of this clause);</p>

       <p>24.2.2. was available to the receiving party on a non-confidential basis before disclosure by the disclosing party;</p>

       <p>24.2.3. was, is or becomes available to the receiving party on a non-confidential basis from a person who, to the receiving party's knowledge, is not bound by a confidentiality agreement with the disclosing party or otherwise prohibited from disclosing the information to the receiving party; or</p>

       <p>24.2.4. the parties agree in writing is not confidential or may be disclosed; or</p>

       <p>24.2.5. is developed by or for the receiving party independently of the information disclosed by the disclosing party.</p>

       <p>24.3. Each party shall keep the other party's Confidential Information secret and confidential and shall not:</p>

       <p>24.3.1. use such Confidential Information except for the purpose of exercising or performing its rights and obligations under or in connection with this agreement (Permitted Purpose); or</p>

       <p>24.3.2. disclose such Confidential Information in whole or in part to any third party, except as expressly permitted by this clause 24.</p>

       <p>24.4. A party may disclose the other party's Confidential Information to those of its Representatives who need to know such Confidential Information for the Permitted Purpose, provided that:</p>

       <p>24.4.1. it informs such Representatives of the confidential nature of the Confidential Information before disclosure; and</p>

       <p>24.4.2. at all times, it is responsible for such Representatives' compliance with the confidentiality obligations set out in this clause.</p>

       <p>24.5. A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this clause 24.5, it takes into account the reasonable requests of the other party in relation to the content of such disclosure.</p>

       <p>24.6. A party may, provided it has reasonable grounds to believe that the other party is involved in activity that may constitute a criminal offence under the Bribery Act 2010, disclose Confidential Information to the Serious Fraud Office without first informing the other party of such disclosure.</p>

       <p>24.7. Each party reserves all rights in its Confidential Information. No rights or obligations in respect of a party's Confidential Information other than those expressly stated in this agreement are granted to the other party, or to be implied from this agreement.</p>

       <p>24.8. On termination or expiry of this agreement, each party shall:</p>

       <p>24.8.1. destroy or return to the other party all documents and materials (and any copies) containing, reflecting, incorporating or based on the other party's Confidential Information;</p>

       <p>24.8.2. subject to clause 14.9.3 and 20.10.6 erase all the other party's Confidential Information from computer and communications systems and devices used by it, including such systems and data storage services provided by third parties (to the extent technically and legally practicable); and</p>

       <p>24.8.3. certify in writing to the other party that it has complied with the requirements of this clause, provided that a recipient party may retain documents and materials containing, reflecting, incorporating or based on the other party's Confidential Information to the extent required by law or any applicable governmental or regulatory authority. The provisions of this clause shall continue to apply to any such documents and materials retained by a recipient party, subject to clause 14 (Cancellation).</p>

       <p>24.9. No party shall make, or permit any person to make, any public announcement concerning this agreement without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by law, any governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction.</p>

       <p>24.10. Except as expressly stated in this agreement, no party makes any express or implied warranty or representation concerning its Confidential Information.</p>

       <p>24.11. The above provisions of this clause 24 shall survive for a period of five years from termination or expiry of this agreement.</p>

     </React.Fragment>
    );
  }

