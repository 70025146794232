import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";


import {

  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography, Grid, Stack
} from "@mui/material";
import { spacing } from "@mui/system";

import Dashboard from "../Analysis/Dashboard/Dashboard";

import Box from "@mui/material/Box";
import { createMultipleSheets, createPnlOnlySheets } from "./calculations/Calculations";
import LoadingModal from "../SharedComponents/LoadingModal";
import { useRecoilValue } from "recoil";
import { pnlState } from "../SharedState/PnLState";
import { calculatePnlAnnuals } from "../PLGroup/PLAnnual/calculations/Calculations";
import { balanceSheetState } from "../SharedState/BalanceSheetState";
import { calculateCashflowBalanceAnnuals } from "../CashflowGroup/CashFlowAnnual/calculations/Calculations";
import { cashFlowState } from "../SharedState/CashflowState";
import { calculateBalanceAnnuals } from "../BalanceSheetGroup/BalanceSheetAnnual/calculations/Calculations";
import { getCurrentProject, getProjectIdNotAsync } from "../SharedComponents/ProjectServices";
import { overheadsState } from "../SharedState/OverheadsState";
import { headcountsState } from "../SharedState/HeadcountsState";


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function Export() {

  const [dashboard, setDashboard] = useState(false);
  const [exporting, setExporting] = useState(false);

  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const cashflowAtom = useRecoilValue(cashFlowState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const headCountAtom = useRecoilValue(headcountsState);


  const componentRef = useRef();

  useEffect(() => {

    let overheadsToIgnore = isEmptyDefaultGroup(overheadsAtom);
    let headcountsToIgnore = isEmptyHeadcountsDefaultGroup(headCountAtom);
    let itemsToIgnore = overheadsToIgnore.concat(headcountsToIgnore);

    if (dashboard === true) {

      setTimeout(() => {

        let pnlAnnuals = calculatePnlAnnuals(structuredClone(pnlAtom));
        let balanceAnnuals = calculateBalanceAnnuals(structuredClone(balanceAtom));
        let cashflowAnnuals = calculateCashflowBalanceAnnuals(structuredClone(cashflowAtom));

        let project = getProjectIdNotAsync();

        if (project.projectType === "PNL") {
          createPnlOnlySheets(componentRef, setDashboard, pnlAnnuals, itemsToIgnore);
        } else {
          createMultipleSheets(componentRef, setDashboard, pnlAnnuals, balanceAnnuals, cashflowAnnuals, itemsToIgnore);
        }

        setExporting(false)
      }, 3000); // 5000 milliseconds = 5 seconds



    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, pnlAtom, balanceAtom]);

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.overheadDto.name.endsWith("(Default Overhead Group)") && item.overheadDto.overheads.length === 0).map(item => item.overheadDto.name);
    } else {
      return [];
    }

  };

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyHeadcountsDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.headCountDto.name.endsWith("(Default Headcount Group)") && item.headCountDto.headcounts.length === 0).map(item => item.headCountDto.name);
    } else {
      return [];
    }

  };

  return (
    <React.Fragment>

      <LoadingModal open={exporting} title={'Exporting Powdr Model'} description={'Exporting Powdr, please wait...'} />

      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Export Project
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Export</Typography>
      </Breadcrumbs>

      <Divider my={6} />


      <Grid item>
       <Typography variant="h3"> Powdr Export Reports</Typography>
      </Grid>

      <br/>

      <Grid item>
        <Stack spacing={4} direction="row">
          <button onClick={() => {setExporting(true); setDashboard(true)}}>Export Powdr Model</button>
        </Stack>
      </Grid>

      {dashboard && (
        <Box ref={componentRef}
             sx={{
               minWidth: 1500,
               maxWidth: 1500, // Sets the maximum width to 500px
               mx: 'auto' // Centers the box horizontally
             }}
        >
          <Dashboard></Dashboard>
        </Box>
      )}

    </React.Fragment>
  );
}

export default Export;
