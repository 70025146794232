import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Divider as MuiDivider, Stack, Button, Skeleton
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../hooks/useProject";
import Settings from "../../../../components/Settings";
import BalanceInput from "./components/BalanceInput";
import axios from "axios";

import AddDebt from "../../AssumptionsGroup/Debt/components/AddDebt";
import AddFixedAsset from "../../AssumptionsGroup/FixedAssets/components/AddFixedAsset";
import AddCurrentAsset from "../../AssumptionsGroup/CurrentAssets/components/AddCurrentAsset";
import AddEquity from "../../AssumptionsGroup/Equity/components/AddEquity";
import AddCurrentLiability from "../../AssumptionsGroup/CurrentLiabilities/components/AddCurrentLiability";
import DeleteModal from "../../SharedComponents/DeleteModal";

import PageHeader from "../../SharedComponents/PageHeader";
import { moveCategory } from "./calculations/MoveUpDown";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import LoadingModal from "../../SharedComponents/LoadingModal";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { createExport } from "../../Export/calculations/Calculations";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import UpdatePowdrModelBalance from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelBalance";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { pnlState } from "../../SharedState/PnLState";
import { fixedAssetsState } from "../../SharedState/FixedAssetsState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";
import { debtsState } from "../../SharedState/DebtsState";
import { equitiesState } from "../../SharedState/EquitiesState";
import { projectLoadingState } from "../../SharedState/ProjectLoadingState";
import { isBalanceSheetImbalanced } from "./calculations/Calculations";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";


const Divider = styled(MuiDivider)(spacing);

function BalanceSheet() {

  const updatePowdrModelBalanceRef = useRef();
  const updatePowdrModelGlobalRef = useRef();

  const [balanceSheet, setBalanceSheet] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");
  const [categoryToDelete, setCategoryToDelete] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [recalculateCount, setRecalculateCount] = useState(0);
  const [recalculateImbalanceCount, setRecalculateImbalanceCount] = useState(0);

  const [loadingAssumption, setLoadingAssumption] = useState(false);

  const tableRef = useRef(null);

  const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const setFixedAssetAtom = useSetRecoilState(fixedAssetsState);
  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const setCurrentLiabilities = useSetRecoilState(currentLiabilitiesState);
  const setDebts = useSetRecoilState(debtsState);
  const setEquities = useSetRecoilState(equitiesState);
  const setProductsAtom = useSetRecoilState(revenueAndCostState);

  const loadingProject = useRecoilValue(projectLoadingState);


  const { project } = useProject();

  async function loadBalanceSheet() {

    setBalanceSheet([structuredClone(balanceAtom)])

    if (isBalanceSheetImbalanced(structuredClone(balanceAtom)) && recalculateImbalanceCount < 3) {
      console.log('Balance sheet is imbalanced, trying to recalc for the: ' + recalculateImbalanceCount + " time.")
      updatePowdrModelBalance()
      setRecalculateImbalanceCount(current=> current + 1)
    }
  }

  function move(balanceId_, direction) {

    let balanceUpdated = moveCategory(direction, balanceSheet[0], balanceId_)

    setBalanceSheet([...balanceUpdated]);

    if (project !== undefined) {
      var config = {
        method: "put",
        url: "balance/" + project.id + "/categoriesOnly",
        data : balanceUpdated
      };

      axios(config)
        .then(async function(response) {

          await axios.get("balance/" + project.id).then(function(response) {
            setBalanceAtom(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  function deleteCategory(category) {
    //set the pnlCategoryToDelete to pnlCategory
    setCategoryToDelete(category)
    //set the setOpenDeleteModal to true
    setOpenDeleteModal(true)
  }

  function deleteCategoryApiCall(handleClose) {

    let clone = structuredClone(categoryToDelete);
     clone.balanceMonthDtos = [];

    if (project !== undefined) {
      var config = {
        method: "delete",
        url: "balance/" + project.id,
        data : clone
      };

      axios(config)
        .then(async function(response) {

          await axios.get("fixedassets/" + project.id).then(function(response) {setFixedAssetAtom(response.data);});
          await axios.get("currentassets/" + project.id).then(function(response) {setCurrentAssets(response.data);});
          await axios.get("currentliabilities/" + project.id).then(function(response) {setCurrentLiabilities(response.data);});
          await axios.get("debt/" + project.id).then(function(response) {setDebts(response.data);});
          await axios.get("equity/" + project.id).then(function(response) {setEquities(response.data);});
          await axios.get("revenuecos/" + project.id).then(function(response) {setProductsAtom(response.data);});

          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(response.data, pnlAtom);

          //if object to delete is a Asset Loan then we do a page refresh, this is to reload the Vat Calculation
          window.location.reload();

          handleClose();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceSheet[0], pnlAtom);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      updatePowdrModelBalance()

    }

  }

  useEffect(() => {

      loadBalanceSheet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceAtom]);


  function renderHeavyContent() {
    return (
      <React.Fragment>

        <PageHeader pageName={"Balance Sheet"} parentName={"Monthly Output"}/>

        <Divider my={6} />

        <ControlsBar
          addAssumptionElement={
            <Grid item>

              <Stack spacing={4} direction="row">

                <AddFixedAsset/>
                <AddCurrentAsset/>
                <AddCurrentLiability/>
                <AddDebt/>
                <AddEquity/>

              </Stack>

            </Grid>
          }
          element2={
            <Button type="submit" variant="contained" onClick={() => createExport(balanceSheet[0].balanceCategoryDtos, balanceSheet[0].balanceDto, false, true)} sx={{marginBottom: 5}}>Export Excel</Button>
          }
          hideZoom={true}
        />

        {!!balanceSheet &&
          balanceSheet.map((balanceSheetItem) => (
            <BalanceInput
              tableRef={tableRef}
              key={balanceSheetItem.balanceDto.id}
              balanceSheetItem={balanceSheetItem}
              balanceSheet={balanceSheet}
              refreshData={refreshData}
              setEditData={setEditTableEnabled}
              editData={editTableEnabled}
              setBalanceSheet={setBalanceSheet}
              loadData={loadBalanceSheet}
              move={move}
              deleteCategory={deleteCategory}
              setLoadingAssumption={setLoadingAssumption}
            ></BalanceInput>
          ))}

        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

        <UpdatePowdrModelBalance ref={updatePowdrModelBalanceRef}/>
        <Settings/>

        <DeleteModal
          itemToDelete={categoryToDelete}
          deleteMethod={deleteCategoryApiCall}
          name={categoryToDelete.overrideName}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
        ></DeleteModal>

        <LoadingModal open={loadingAssumption} title={'Loading Assumption'} description={'Loading Assumption, please wait...'} />


      </React.Fragment>
    );

  }



  return (
    <React.Fragment key={"pnl"}>
      {loadingProject ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default BalanceSheet;
