import { atom } from 'recoil';
import { handleDataUpdate, refreshApiCall } from "./RecoilUtils";

export const vatState = atom({
  key: 'vatState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({setSelf}) => {
      refreshApiCall(setSelf, "vat/vatrate");
    },
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        //console.log('on set', newAssumptions, oldAssumptions)

        const project = JSON.parse(sessionStorage.getItem("project"));

        handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
          'vat/' + project.id + '/months' ,
          'vatMonthDtos',
          'vatCategoryDtos',
          'vatDto');

      });
    },
  ]
});

