import _ from "lodash";
import axios from "axios";
import { calculateCorkscrew } from "../../../SharedComponents/calculations/SimpleCorkscrew";
import { calculateCorkscrewWithDA } from "../../../SharedComponents/calculations/SimpleCorkscrewWithDA";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { calcPercent } from "../../../SharedComponents/utils/PercentageUtils";


export function calculateFixedAssets(fixedAssets, projectId) {


  //todo NOTE
  //for the first 12 months (actuals, we AREN'T applying the calculations)
  //as these are actual values
  //we ONLY apply the calculations for the forecast values onwards.

  try {
    calculateCorkscrew(fixedAssets, 'fixedAsset');
    calculateCorkscrewWithDA(fixedAssets, 'fixedAsset');
    calculateCorkscrewWithDAPercentage(fixedAssets);
  }
  catch (e) {
    console.debug(e)
  }
}


function calculateCorkscrewWithDAPercentage(fixedAssets) {

  // take the depreciation rate and deduct
  // calculate the percentage of the the opening balance
  // set that value of the depreciation
  // calculate the closing value

  const fixedAssetsCorkScrewDAPercent = fixedAssets
    .filter(fixedAsset => fixedAsset.fixedAssetDto.assumption === "SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  _.forEach(fixedAssetsCorkScrewDAPercent, function(fixedAsset) {
    _.forEach(fixedAsset.fixedAssetCategoryDtos[0].fixedAssetMonthDtos, function(fixedAssetMonthDto, i) {

        // set the opening balance from the previous closing balance
      if (i >= getCurrentProject().firstBalanceForecast) {
          fixedAsset.fixedAssetCategoryDtos[2].fixedAssetMonthDtos[i].value
            = fixedAsset.fixedAssetCategoryDtos[6].fixedAssetMonthDtos[i - 1].value;
        }


        //depreciation rate
        //opening balance
        fixedAsset.fixedAssetCategoryDtos[5].fixedAssetMonthDtos[i].value =           //deprecation/amortisation
          calcPercent(fixedAsset.fixedAssetCategoryDtos[2].fixedAssetMonthDtos[i].value,
            fixedAsset.fixedAssetCategoryDtos[0].fixedAssetMonthDtos[i].value);

      if (i >= getCurrentProject().firstBalanceForecast) {

        //get the value of the closing, from the previous month
        fixedAsset.fixedAssetCategoryDtos[6].fixedAssetMonthDtos[i].value =
          fixedAsset.fixedAssetCategoryDtos[2].fixedAssetMonthDtos[i].value +
          fixedAsset.fixedAssetCategoryDtos[3].fixedAssetMonthDtos[i].value +
          fixedAsset.fixedAssetCategoryDtos[4].fixedAssetMonthDtos[i].value +
          fixedAsset.fixedAssetCategoryDtos[5].fixedAssetMonthDtos[i].value;
          //fixedAsset.fixedAssetCategoryDtos[5].fixedAssetMonthDtos[i].value;
      }

    });
  });



}



export function markAsCompleted(dataSetDto) {

    console.log(dataSetDto)

    dataSetDto.fixedAssetDto.PercentageComplete = 0;

    _.forEach(
      dataSetDto.fixedAssetCategoryDtoList,
      function(categoryDto) {

        _.forEach(
          categoryDto.fixedAssetMonthDtoList,
          function(fixedAssetMonthDto) {

            if (fixedAssetMonthDto.cellType === "ENABLED" && fixedAssetMonthDto.touched === false) {
              fixedAssetMonthDto.value = 0;
              fixedAssetMonthDto.touched = true;
            }

          }
        );

      }
    );

    dataSetDto.fixedAssetDto.percentageComplete = 100;


    // do a put update here
    var config = {
      method: "put",
      url: "fixedassets/" + dataSetDto.fixedAssetDto.project,
      data: [dataSetDto],
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });

}
