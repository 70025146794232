import React from "react";

import {
  LinearProgress,
} from "@mui/material";

function LoadingBar({ loaded, assumptionName }) {
  return (
    <React.Fragment>
      {!loaded &&  (
        <div>
          <h1>Retrieving {assumptionName}...</h1>
          <LinearProgress my={2} />
        </div>
      )}
    </React.Fragment>
  );
}

export default LoadingBar;
