import React, { useEffect, useRef } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";
import { AuthProvider } from "./contexts/JWTContext";
import UpdatePowdrModelGlobal from "./pages/powdr/SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  const location = useLocation();
  const updatePowdrModelGlobalRef = useRef();

  useEffect(() => {
    const handleNavigation = () => {
      // Your function logic here
      //console.log("Navigating to:", location.pathname);
      //todo - only do it on certain url naviations
      //updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
      //console.log("Finshed Global recalc");
    };

    handleNavigation();
  }, [location]);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="Powdr"
          defaultTitle="Powdr - Financial Modelling Made Easy"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>{content}</AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </CacheProvider>
  );
}

export default App;
