import React, { useEffect }  from "react";
import { useNavigate } from "react-router-dom";

function Welcome() {

  const navigate = useNavigate();

  useEffect(() => {

    // Open the URL in a new tab
    window.open('https://powdr-144363163.hubspotpagebuilder.eu/partnr-post-form-page-0?hsLang=en-gb', '_blank');

    navigate('/home');

    // Disable ESLint rule for missing dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default Welcome;
