import _ from "lodash";
import { getCurrentProject } from "../ProjectServices";
import { createDecimal, parseNumberOrZero } from "../utils/NumbersUtils";


function calculateCorkscrewForMonth(assumption, itemName, i) {
  assumption[itemName + "CategoryDtos"][0][itemName + "MonthDtos"][i].value = assumption[itemName + "CategoryDtos"][3][itemName + "MonthDtos"][i - 1].value;

  //get the value of the closing, from the previous month
  assumption[itemName + "CategoryDtos"][3][itemName + "MonthDtos"][i].value = parseNumberOrZero(

      createDecimal(assumption[itemName + "CategoryDtos"][0][itemName + "MonthDtos"][i].value).plus(
      createDecimal(assumption[itemName + "CategoryDtos"][1][itemName + "MonthDtos"][i].value)).plus(
      createDecimal(assumption[itemName + "CategoryDtos"][2][itemName + "MonthDtos"][i].value)).toString()

  );
}

/**
 * FOR BALANCE ASSUMPTIONS ONLY
 * (FOR PNL THIS WILL NOT WORK AS ITS LOOKING AT THE BALANCE REFERENCE POINT)
 * @param assumptions
 * @param itemName
 */
export function calculateCorkscrew(assumptions, itemName) {

  // all the assumptions that are type 'SIMPLE_CORKSCREW'
  const assumptionCorkScrewList = assumptions
    .filter(assumption => assumption[itemName + 'Dto'].assumption === "SIMPLE_CORKSCREW");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(assumptionCorkScrewList, function(assumption) {

    // loop through all 72 months and run the calculations
    _.forEach(assumption[itemName + 'CategoryDtos'][0][itemName + 'MonthDtos'], function(assumptionMonthDto, i) {


      // only for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        calculateCorkscrewForMonth(assumption, itemName, i);
      }

    });
  });

}