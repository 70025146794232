import React, { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import axios from "axios";
import { Dialog, FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import ModelFolderModal from "./ModelFolderModal";
import getProjectId, { getCurrentProject } from "../ProjectServices";
import _ from "lodash"; //icons
import FolderIcon from '@mui/icons-material/Folder';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StopIcon from '@mui/icons-material/Stop';
import useProject from "../../../../hooks/useProject";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import ValidateProjectModal from "../ValidateProjectModal";
import { DeviceHub } from "@mui/icons-material";
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";

const ProjectsViewer = ({adminMode = false, showProject}) => {
  const [nodes, setNodes] = useState();

  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [validate, setValidate] = useState(false);
  const [validationData, setValidationData] = useState({ } );
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [addFolderDialogVisible, setAddFolderDialogVisible] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [duplicating, setDuplicating] = useState(false);
  const navigate = useNavigate();

  const { setProject } = useProject();
  const { user } = useAuth();

  async function processFolders(response) {
    //build up the nodes

    let modelsAndFolders = [];

    let modelsWithFolders = response.data.filter(folder => folder.name !== "PROJECT_WITHOUT_A_FOLDER");

    _.forEach(modelsWithFolders, function(folder, i) {

        let projectsToAdd = []

        _.forEach(folder.projectDtos, function(project, i) {

          projectsToAdd.push({
            key: project.id,
            data: {
              name: project.name,
              companyName: project.companyName,
              startDate: convertDateTime(project.startDate),
              lastEditDate: convertDateTime(project.lastEdit),
              projectType: projectTypeToString(project.projectType),
              initialised: project.initialised,
              type: "Model",
              actions: "View/Delete/Duplicate/Move"
            }
          });

        });

        modelsAndFolders.push({
          key: folder.id,
          data: {
            name: folder.name,
            projectType: "",
            type: "Folder",
            actions: "Add Folder"
          },
          children: projectsToAdd
        })

    });

    let noFolderModels = response.data.find(folder => folder.name === "PROJECT_WITHOUT_A_FOLDER");

    _.forEach(noFolderModels.projectDtos, function(project, i) {

      modelsAndFolders.push({
        key: project.id,
        data: {
          name: project.name,
          companyName: project.companyName,
          startDate: convertDateTime(project.startDate),
          lastEditDate: convertDateTime(project.lastEdit),
          projectType: projectTypeToString(project.projectType),
          initialised: project.initialised,
          type: "Model",
          actions: "View/Delete/Duplicate/Move"
        }
      });

    });

    modelsAndFolders.push(
      {
        key: "0",
        data: { name: "", projectType: "", type: "", actions: "Add Folder" }
      }
    );

    setNodes(modelsAndFolders);
  }

  useEffect(() => {

    if (user !== null & user !== undefined) {
      axios.get('/folder/' + user.company + "?admin=" + adminMode).then(function(response) {
        processFolders(response);
      })
    }

    axios.get('company/').then(function(response) {
      setCompanies(response.data);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function projectTypeToString(input) {

    if (input === 'SINGLE_ENTITY') {
      return 'P&L,Balance,Cashflow';
    } else if (input === 'PNL') {
      return 'P&L';
    } else if (input === 'PNL_DIVISIONAL') {
      return 'Divisional P&L Consolidation';
    } else {
      return 'Unknown';
    }
  }


  function convertDateTime(input) {

    if (input === null || input === undefined) {
      return "";
    }

    // Parse the input string into a Date object
    const date = new Date(input);

    // Extract day, month, year, hour, and minute
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear().toString().slice(2); // Get last two digits of the year

    // Format the date and time in DD/MM/YY HH:MM format
    return `${day}/${month}/${year}`;
  }

  const openAddFolderDialog = () => {
    setAddFolderDialogVisible(true);
  };

  const closeAddFolderDialog = () => {
    setNewFolderName('');
    setAddFolderDialogVisible(false);
  };

  const addFolder = async () => {

    let project = await getProjectId();

    let companyChosen;
    if (adminMode === true) {
      companyChosen = company;
    } else {
      companyChosen = project.owner;
    }

    const data = {
      id: 0,
      name: newFolderName,
      parent: null,
      company: companyChosen,
      projectDtos: []
    };

    axios.post('/folder?admin=' + adminMode, data)
      .then(response => {
        // Update your tree data here based on the response
        // You might need to fetch the updated list or update your state directly
        processFolders(response);
        closeAddFolderDialog();
      })
      .catch(error => console.error('There was an error!', error));
  };


  function nameBodyTemplate(node) {
    // Style to apply flex layout to a span


    if (node.data.actions === "Add Folder" && node.data.name !== "") {
      return (
        <span >
        <FolderIcon style={{ marginRight: '8px' }} />
        <span>{node.data.name}</span>
      </span>
      );
    }

    if (node.data.projectType === "P&L,Balance,Cashflow") {
      return (
        <span>
        <AccountTreeIcon style={{ marginRight: '8px' }} />
        <span>{node.data.name}</span>
      </span>
      );
    }

    if (node.data.projectType === "P&L") {
      return (
        <span >
        <StopIcon style={{ marginRight: '8px' }} />
        <span>{node.data.name}</span>
      </span>
      );
    }

    if (node.data.projectType === "Divisional P&L Consolidation") {
      return (
        <span >
        <DeviceHub style={{ marginRight: '8px' }} />
        <span>{node.data.name}</span>
      </span>
      );
    }

    // Default return if none of the
  }

  const viewProject = (projectId) => {

    //update the project viewing list
    axios.post('/projectSession/' + projectId + '/' + user.id);

    let url = 'projectsByOwner/' + user.company;
    if (adminMode === true) {
      url = 'projects'
    }

    axios.get(url).then(function (response2) {

      setProject(response2.data.find(project => project.id === parseInt(projectId)))

      window.location.reload();

    });

  }

  function deleteProject(projectId) {

    //You can only delete a project you are NOT currently viewing
    if (getCurrentProject().id === projectId) {
      window.alert("You can NOT delete a project you are currently viewing");
    } else {

      if (window.confirm("Are you sure you wish to delete this project") === true) {

        axios.delete('/project/' + projectId).then(function (response) {

          //refresh the project list after the deletion
          axios.get('/folder/' + user.company +  "?admin=" + adminMode).then(function(response) {
            processFolders(response);
          })

        })

      }

    }

  }

  function deleteFolder(folderId) {

    //get all the projects
    //if they have projects with this folders id then show a window alert to prevent the delete

    let url = 'projectsByOwner/' + user.company;
    if (adminMode === true) {
      url = 'projects'
    }

    //
    axios.get(url).then(function (response) {

      let projectsThatUserFolder = response.data.filter(project => project.folderId === folderId);

      if (projectsThatUserFolder !== undefined && projectsThatUserFolder.length > 0) {
        window.alert("Can NOT delete folder while projects exist in it, please move projects out of this folder");
      } else {
        axios.delete('/folder/' + user.company + "/folder/" + folderId + "?admin=" + adminMode).then(function (response) {
          //refresh the project list after the deletion
          axios.get('/folder/' + user.company + "?admin=" + adminMode).then(function(response) {
            processFolders(response);
          })
        })
      }

    });
  }

  const actionBodyTemplate = (node) => {

    if (node.data.actions === "Add Folder") {

      return <div style={{ textAlign: 'right' }}>
        { node.data.name === "" &&
          <Button label="Add Folder" onClick={() => openAddFolderDialog(node.key)}>Add Folder</Button>
        }
        { node.data.name !== "" &&
          <Button onClick={() => deleteFolder(node.key)}>Delete</Button>
        }
      </div>;
    }

    if (node.data.type === "Model") {


      if (user?.userType === "SUPERUSER" && adminMode) {
        return <div style={{ textAlign: 'right' }}>
          <Button onClick={() => validateProject(node.key)}>Validate</Button>
          <Button onClick={() =>  viewProject(node.key)} disabled={node.data.initialised === false}>View</Button>
          <Button onClick={() => settings(node.key)}>Settings</Button>
          <Button onClick={() => deleteProject(node.key)}>Delete</Button>
          <Button onClick={() => duplicateProject(node.key)}>Duplicate</Button>
          <Button onClick={() => navigateToProject(node.key)}>Audit</Button>
          <Button onClick={() => setEditDialogVisible(true)}>Move</Button>
        </div>;
      } else {
        return <div style={{ textAlign: 'right' }}>
          <Button onClick={() => viewProject(node.key)} disabled={node.data.initialised === false}>View</Button>
          <Button onClick={() => deleteProject(node.key)}>Delete</Button>
          <Button onClick={() => setEditDialogVisible(true)}>Move</Button>
        </div>;
      }

    }
  };

  const navigateToProject = (projectId) => {
    navigate(`/project-audit/${projectId}`);
  };

  function duplicateProject(projectId){

    setDuplicating(true)

    axios.get('/project/clone/' + projectId).then(function(response) {

      setDuplicating(false);


      axios.get('/folder/' + user.company  + "?admin=" + adminMode).then(function(response) {
        processFolders(response);
      })

    })

  }

  function settings(projectId) {
    showProject(projectId);
  }

  function validateProject(projectId) {

    //call validation end point
    axios.get('/project/validate/' + projectId).then(function (response) {

      setValidationData(response.data)
      setValidate(true)
    })

  }

  function handleClose() {
    setAddFolderDialogVisible(false)
  }

  const handleChange = (event) => {
    console.log(event.target.value)
    setCompany(event.target.value);
  };

  const renderAddFolderDialog = () => {


    // Enable the Add Folder button only if newFolderName has at least 4 characters
    const isAddButtonDisabled = newFolderName.length < 4;

    return (
      <div>
        <Dialog
          open={addFolderDialogVisible}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            Add Folder
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>Type the name of the new folder</Typography>
              <br/>
              <TextField
                name="folderName"
                autoFocus
                id="folderName"
                label="Folder Name Here"
                fullWidth
                required
                inputProps={{ minLength: 4 }}
                onChange={(e) => setNewFolderName(e.target.value)}
                type="text"
              />
              <br/><br/>
              {adminMode &&
              <Box>
                <DialogContentText>Company</DialogContentText>
              <br/>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={company}
                  label="Company"
                  onChange={handleChange}
                >
                  {!!companies  &&
                    companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>{company.companyName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
                }

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button onClick={addFolder} color="primary" autoFocus disabled={isAddButtonDisabled}>
              Add Folder
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };




  return (
    <div>
      <TreeTable value={nodes} reorderableColumns >
        <Column field="name" header="Name" body={nameBodyTemplate} expander sortable ></Column>
        {adminMode && <Column field="companyName" header="Company" sortable filter filterPlaceholder="Filter by company" style={{ width: '200px'}}></Column>}
        <Column field="startDate" header="Start Date"  sortable style={{ width: '150px'}}></Column>
        <Column field="lastEditDate" header="Last Edit"  sortable style={{ width: '150px'}}></Column>
        <Column field="projectType" header="Project Type" style={{ width: '200px'}}></Column>
        <Column field="type" header="Type" sortable style={{ width: '100px'}}></Column>
        <Column field="actions" header="Actions" body={actionBodyTemplate} style={{textAlign: 'right'}}></Column>
      </TreeTable>
      {renderAddFolderDialog()}
      <ModelFolderModal open={editDialogVisible} setOpen={setEditDialogVisible} processFolders={processFolders} adminMode={adminMode}></ModelFolderModal>
      <LoadingModal open={duplicating} title={'Duplicating Powdr Model'} description={'Duplicating Powdr Model, please wait...'} />
      <ValidateProjectModal
        open={validate}
        setOpen={setValidate}
        validationData={validationData}
      />
    </div>

  );
};

export default ProjectsViewer;
