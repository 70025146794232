import _ from "lodash";
import { createAnnualSummary, createGrowthRates, createYearOnYearChange } from "./CalculatePriceXQuantity";
import {
  createAnnualSummaryAnnualMonthlyDirect,
  createGrowthRatesAnnualMonthlyDirect, createYearOnYearChangeAnnualMonthlyDirect
} from "./CalculateAnnualMonthlyDirectInput";
import {
  createAnnualSummarySelectedRevenue,
  createGrowthRatesSelectedRevenue,
  createYearOnYearChangeSelectedRevenue
} from "./CalculateSelectedRevenue";
import {
  createAnnualSummarySalesPerPersonBuilderSalesPerDay,
  createGrowthRatesSalesPerPersonBuilderSalesPerDay, createYearOnYearChangeSalesPerPersonBuilderSalesPerDay
} from "./CalculateSalesPerPersonBuilderSalesPerDay";
import { createAnnualSummaryHeads, createGrowthRatesHeads, createYearOnYearChangeHeads } from "./CalculateHeads";
import {
  createAnnualSummarySubscription,
  createGrowthRatesSubscription,
  createYearOnYearChangeSubscription
} from "./CalculateSubscriptions";
import { percentage } from "../../../SharedComponents/utils/PercentageUtils";
import { getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";



function processProduct(product, productsAnalysed, ignoreHeadcountUtilization= false) {

  if (['PRICE_X_QUANTITY', 'PRICE_X_QUANTITY_COST_INFLATION'].includes(product.productDto.assumption)) {

    let annualSummary = createAnnualSummary(product);

    let growthRates = createGrowthRates(product);

    let yearOnYearChange = createYearOnYearChange(product)

    productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);

  }

  if (['ANNUAL_GROWTH_RATE', 'MONTHLY_GROWTH_RATE', 'DIRECT_INPUT', 'REVENUE_DIVISIONAL_CONSOLIDATION', 'LOAN_ASSET_SISTER_PRODUCT'].includes(product.productDto.assumption)) {

    let annualSummary = createAnnualSummaryAnnualMonthlyDirect(product);

    let growthRates = createGrowthRatesAnnualMonthlyDirect(product);

    let yearOnYearChange = createYearOnYearChangeAnnualMonthlyDirect(product)

    productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);

  }

  if (['PERCENTAGE_OF_ANOTHER_PRODUCT'].includes(product.productDto.assumption)) {

    let annualSummary = createAnnualSummarySelectedRevenue(product);

    let growthRates = createGrowthRatesSelectedRevenue(product);

    let yearOnYearChange = createYearOnYearChangeSelectedRevenue(product)

    productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);
  }


  if (['SALES_PER_DAY_REVENUE', 'PRICE_X_QUANTITY_BUILDER', 'HEADS_TO_REVENUE', 'REVENUE_AS_A_PERCENTAGE_OF_VALUE'].includes(product.productDto.assumption)) {

    let annualSummary = createAnnualSummarySalesPerPersonBuilderSalesPerDay(product);

    let growthRates = createGrowthRatesSalesPerPersonBuilderSalesPerDay(product);

    let yearOnYearChange = createYearOnYearChangeSalesPerPersonBuilderSalesPerDay(product)

    productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);
  }

  if (['HEADCOUNT_UTILISATION', 'NON_HEADCOUNT_UTILISATION'].includes(product.productDto.assumption)) {

    if (ignoreHeadcountUtilization === true && product.productDto.assumption === 'HEADCOUNT_UTILISATION') {
      //special case for the margin chart, basically we don't want them in the Margin Chart
    } else {

      let annualSummary = createAnnualSummaryHeads(product);

      let growthRates = createGrowthRatesHeads(product);

      let yearOnYearChange = createYearOnYearChangeHeads(product)

      productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);
    }
  }

  if (['SUBSCRIPTION_MODEL'].includes(product.productDto.assumption)) {

    let annualSummary = createAnnualSummarySubscription(product);

    let growthRates = createGrowthRatesSubscription(product);

    let yearOnYearChange = createYearOnYearChangeSubscription(product)

    productsAnalysed.push([annualSummary, growthRates, yearOnYearChange]);
  }


}

export function calculateRevenueDashboard(products, ignoreHeadcountUtilization = false) {

  let productsAnalysed = [];

  //we need to process each assumption
  _.forEach(products, function (product) {

    processProduct(product, productsAnalysed, ignoreHeadcountUtilization);


  });

  //for each assumption we create 3 analytics

  //we total up 12 month block as a single value

    //this is our value to process

  return productsAnalysed;
}


export function buildYearTotalCategory(product, categoryName, displayName=categoryName, resolution = 0) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  let quantitySoldCateogryOriginal = product.productCategoryDtoList.find(category => category.name === categoryName);

  //console.log(product.productDto.name)

  let yearIndex = 0;
  for (let i=0; i < 72;){

    let yearTotal = quantitySoldCateogryOriginal ? totalUpItems(quantitySoldCateogryOriginal.productMonthDtoList, i) : 0;

    category.productMonthDtoList.push({
      id: yearIndex,
      value:  yearTotal ,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
      resolution: resolution,
    })
    i = i + 12;
    yearIndex++;

  }

  return category;
}


export function blankCategory(name = "") {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  for (let i=0; i < 6; i++) {

    category.productMonthDtoList.push({
      id: i,
      value: 0,
      month: i,
      monthIndex: i,
      cellType: "DISABLED",
      valueFormat: "INTEGER"
    })
  }

  return category;
}



export function buildDivisionOfTwoCategories(name, category1, category2, resolution = 0) {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  for (let i=0; i < category1.productMonthDtoList.length; i++) {

    let yearTotal = category1.productMonthDtoList[i].value
      / category2.productMonthDtoList[i].value;

    category.productMonthDtoList.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "INTEGER",
      resolution: resolution,
    })
  }

  return category;
}

export function buildPercentageOfTwoCategories(name, category1, category2, resolution = 0) {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  for (let i=0; i < category1.productMonthDtoList.length; i++) {

    let yearTotal = percentage( category2.productMonthDtoList[i].value, category1.productMonthDtoList[i].value);

    category.productMonthDtoList.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      resolution: resolution,
    })
  }

  return category;
}


export function buildYearDifferencePercentageCategory(name, categorySource, resolution = 0) {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  category.productMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "PERCENTAGE",
    resolution: resolution
  })

  for (let i=1; i < categorySource.productMonthDtoList.length; i++) {

    let yearTotal = categorySource.productMonthDtoList[i].value - categorySource.productMonthDtoList[i - 1].value;

    category.productMonthDtoList.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      resolution: resolution,
      textColour: yearTotal >= 0 ? "#008000" : "#FF0000",
      showMovementArrow: true,
    })
  }

  return category;
}

export function buildYearDifferenceCategory(name, categorySource, resolution = 0, negativeMovementSymbol="↓", positiveMovementSymbol = "↑") {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  category.productMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "INTEGER",
    resolution: resolution
  })

  for (let i=1; i < categorySource.productMonthDtoList.length; i++) {

    let yearTotal = categorySource.productMonthDtoList[i].value - categorySource.productMonthDtoList[i - 1].value;

    category.productMonthDtoList.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "INTEGER",
      resolution: resolution,
      textColour: yearTotal >= 0 ? "#008000" : "#FF0000",
      showMovementArrow: true,
      negativeMovementSymbol : negativeMovementSymbol,
      positiveMovementSymbol : positiveMovementSymbol
    })
  }

  return category;
}

export function isValidNumber(numberToValidate) {

  let nan = isNaN(numberToValidate);

  let finite = isFinite(numberToValidate);

  let isUndefined = numberToValidate === undefined;

  if (nan || !finite || isUndefined) {
    return 0;
  } else {
    return numberToValidate
  }

}

export function buildYearPercentageDifferenceCategory(name, categorySource, resolution = 0, favourable= "#008000", adverse = "#FF0000", positiveMovementDefault = "↑", negativeMovementDefault = "↓") {

  let category = {
    name: name,
    productMonthDtoList : []
  }

  category.productMonthDtoList.push({
    id: 0,
    value: 0,
    month: 0,
    monthIndex: 0,
    cellType: "DISABLED",
    valueFormat: "PERCENTAGE",
    resolution: resolution
  })

  for (let i=1; i < 6; i++) {

    let yearTotal = calculatePercentageGrowth(categorySource.productMonthDtoList[i - 1].value, categorySource.productMonthDtoList[i].value);

    category.productMonthDtoList.push({
      id: i,
      value: isValidNumber(yearTotal),
      month: i,
      monthIndex: i,
      cellType: "CALCULATED",
      valueFormat: "PERCENTAGE",
      resolution: resolution,
      textColour: yearTotal >= 0 ? favourable : adverse,
      showMovementArrow: true,
      positiveMovementSymbol : positiveMovementDefault,
      negativeMovementSymbol : negativeMovementDefault,
    })
  }

  return category;
}

export function calculatePercentageGrowth(oldValue, newValue) {
  if (oldValue === 0) {
    //console.log('Old value is 0, percentage growth is undefined.');
    return; // Prevent division by zero
  }

  const percentageGrowth = ((newValue - oldValue) / oldValue) * 100;
  return percentageGrowth;
}


export function buildYearTotalCategoryAverage(product, categoryName, displayName=categoryName) {

  let category = {
    name: displayName,
    productMonthDtoList : []
  }

  let quantitySoldCateogryOriginal = product.productCategoryDtoList.find(category => category.name === categoryName);

  let yearIndex = 0;
  for (let i=0; i < 72;){
    let yearTotal = totalUpItems(quantitySoldCateogryOriginal.productMonthDtoList, i);

    category.productMonthDtoList.push({
      id: yearIndex,
      value: yearTotal / 12,
      month: yearIndex,
      monthIndex: yearIndex,
      cellType: "CALCULATED",
    })
    i = i + 12;
    yearIndex++;
  }

  return category;
}

export function totalUpItems(array, startIndex) {
  if (!_.isArray(array)) {
    throw new Error("Input is not an array.");
  }

  // Ensure startIndex is within bounds, and calculate end index
  startIndex = _.clamp(startIndex, 0, array.length);
  const endIndex = startIndex + 12;

  // Use _.slice to get the specific range of items
  const itemsSlice = _.slice(array, startIndex, endIndex);

  // Map each item to either 'valueOverridden' or 'value' based on availability
  const total = _.sum(_.map(itemsSlice, (item) => {
    return getValueOrOverriddenValue(item)
  }));

  //console.log("startIndex: " , startIndex, "endIndex: ", endIndex, "itemsSlice", itemsSlice, "total", total)

  return total;
}