import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";


import SignInComponent from "../../components/auth/SignIn";


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;


function SignIn() {

  return (
    <React.Fragment>

      <Wrapper>

        <Helmet title="Sign In" />
        {/*{!!lastUser &&  (*/}
        {/*<BigAvatar alt="Lucy" >*/}
        {/*  {!!lastUser && lastUser?.firstName.charAt(0) + lastUser?.lastName.charAt(0)}*/}
        {/*</BigAvatar>*/}
        {/*  )}*/}

        {/*{!!lastUser &&  (*/}
        {/*<Typography component="h1" variant="h4" align="center" gutterBottom>*/}
        {/*  Welcome back, {!!lastUser && lastUser.firstName}*/}
        {/*</Typography>*/}
        {/*  )}*/}
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
