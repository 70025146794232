import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
  Button, Skeleton
} from "@mui/material";
import { spacing } from "@mui/system";
import Settings from "../../../../components/Settings";
import CashflowInput from "./components/CashflowInput";

import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { createExport } from "../../Export/calculations/Calculations";
import {  useRecoilValue } from "recoil";
import { cashFlowState } from "../../SharedState/CashflowState";
import { projectLoadingState } from "../../SharedState/ProjectLoadingState";
import { calculateCashflow } from "./calculations/Calculations";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { pnlState } from "../../SharedState/PnLState";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { isBalanceSheetImbalanced } from "../../BalanceSheetGroup/BalanceSheet/calculations/Calculations";


const Divider = styled(MuiDivider)(spacing);

function Cashflow() {


  const [saving] = useState(false);

  const [cashflow, setCashflow] = useState([]);

  const cashflowAtom = useRecoilValue(cashFlowState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const loadingProject = useRecoilValue(projectLoadingState);

  const [editTableEnabled, setEditTableEnabled] = useState("");
  const tableRef = useRef(null);

  // const [recalculateCount, setRecalculateCount] = useState(0);
  const [recalculateImbalanceCount, setRecalculateImbalanceCount] = useState(0);

  const updatePowdrModelGlobalRef = useRef();

  function loadCashflow() {

    let cashflow_ = calculateCashflow(
      structuredClone(cashflowAtom),
      structuredClone(balanceAtom), pnlAtom);

    setCashflow([structuredClone(cashflow_)]);

    // if (recalculateCount < 2) {
    //   updatePowdrModelBalance()
    //   console.log('Auto recalculating, round: ' + recalculateCount);
    //   setRecalculateCount(current=> current + 1)
    // }
    //
    if (isBalanceSheetImbalanced(structuredClone(balanceAtom)) && recalculateImbalanceCount < 3) {
      console.log('Balance sheet is imbalanced, trying to recalc for the: ' + recalculateImbalanceCount + " time.")
      updatePowdrModelBalance()
      setRecalculateImbalanceCount(current=> current + 1)
    }
  }

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
  }

  useEffect(() => {

    loadCashflow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowAtom]);

  function renderHeavyContent() {

    return (
      <React.Fragment>

        <PageHeader pageName={"Cashflow"} parentName={"Monthly Output"}/>

        <Divider my={6} />

        <ControlsBar
          element2={
            <Button type="submit" variant="contained" onClick={() => createExport(cashflow[0].cashflowCategoryDtos, cashflow[0].cashflowDto)} sx={{marginBottom: 5}}>Export Excel</Button>
          }
          hideZoom={true}
        />


        {!!cashflow &&
          cashflow.map((cashflowItem) => (
            <CashflowInput
              tableRef={tableRef}
              key={cashflowItem.cashflowDto.id}
              cashflowItem={cashflowItem}
              cashflow={cashflow}
              setEditData={setEditTableEnabled}
              editData={editTableEnabled}
              setCashflow={setCashflow}
              loadData={loadCashflow}
            ></CashflowInput>
          ))}

        <Settings saving={saving} />

        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      </React.Fragment>
    );

  }

  return (
    <React.Fragment key={"cashflow"}>
      {loadingProject ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );

}

export default Cashflow;
