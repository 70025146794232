import _ from "lodash";
import { totalUpItems } from "../../../Analysis/RevenueDashboard/calculations/Calculations";

export function calculateCashflowBalanceAnnuals(cashflowAtom) {

  if (_.isEmpty(cashflowAtom)) {
    return [];
  }


  //go through each category and create annuals
  for (const cashflowCategoryDto of cashflowAtom.cashflowCategoryDtos) {

    if (cashflowCategoryDto.name === "Opening Cash Balance") {
      openingCashBalance(cashflowCategoryDto);
    } else
    if (cashflowCategoryDto.name === "Closing Cash Balance") {
      closingCashBalance(cashflowCategoryDto);
    } else
    {
      buildYearTotalCategory(cashflowCategoryDto);
    }

  }

  return cashflowAtom;

}


function buildYearTotalCategory(cashflowCategory) {

  let categoryMonths = [];

  if (cashflowCategory.name === "" || cashflowCategory.name === "space") {
    cashflowCategory.cashflowMonthDtos = [];
  } else {

    let yearIndex = 0;
    for (let i = 0; i < 72;) {

      let yearTotal = cashflowCategory ? totalUpItems(cashflowCategory.cashflowMonthDtos, i) : 0;

      categoryMonths.push({
        id: yearIndex,
        value: yearTotal,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: 0,
      })
      i = i + 12;
      yearIndex++;

    }

    cashflowCategory.cashflowMonthDtos = categoryMonths;
  }
}

function openingCashBalance(cashflowCategory) {

  let categoryMonths = [];

  if (cashflowCategory.name === "" || cashflowCategory.name === "space") {
    cashflowCategory.cashflowMonthDtos = [];
  } else {

    let yearIndex = 0;
    for (let i = 0; i < 72;) {

      let yearTotal = cashflowCategory.cashflowMonthDtos[i].value;

      categoryMonths.push({
        id: yearIndex,
        value: yearTotal,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: 0,
      })
      i = i + 12;
      yearIndex++;

    }

    cashflowCategory.cashflowMonthDtos = categoryMonths;
  }

}

function closingCashBalance(cashflowCategory) {

  let categoryMonths = [];

  if (cashflowCategory.name === "" || cashflowCategory.name === "space") {
    cashflowCategory.cashflowMonthDtos = [];
  } else {

    let yearIndex = 0;
    for (let i = 11; i < 72;) {

      let yearTotal = cashflowCategory.cashflowMonthDtos[i].value;

      categoryMonths.push({
        id: yearIndex,
        value: yearTotal,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: 0,
      })
      i = i + 12;
      yearIndex++;

    }

    cashflowCategory.cashflowMonthDtos = categoryMonths;
  }

}
