import React, { useEffect, useState }  from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {

  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Stack,
  Grid,
  CardContent,
  Card
} from "@mui/material";
import { spacing } from "@mui/system";

import axios from "axios";
import { Briefcase, Home, MapPin } from "react-feather";
import CompanyOnboarding from "./components/CompanyOnboarding";
import { AssumptionLabel } from "../../SharedComponents/AssumptionLabel";
import getProjectId from "../../SharedComponents/ProjectServices";
import LoadingBar from "../../SharedComponents/LoadingBar";
import AddProject from "../Projects/components/AddProject";


const Spacer = styled.div(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [companyToEdit, setCompanyToEdit] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  async function loadCompanies() {

    await getProjectId();

    await axios.get('company/').then(function(response) {
      setCompanies(response.data);
      setLoaded(true)
    })

    await axios.get('projects/').then(function(response) {
      setProjects(response.data);
    })

    await axios.get('/security/users/').then(function(response) {
      setUsers(response.data.users);
    })

  }

  function showCompany(e) {
    setCompanyToEdit(companies.find(company => company.id === parseInt(e.target.id)))
    setShowTable(false);
  }

  function hideCompany() {
    setShowTable(true);
  }

  function enableDisableCompany() {
    if (window.confirm("Are you sure you wish to enable/disable this company") === true) {
      companyToEdit.disabled = !companyToEdit.disabled;

      companyToEdit.meetingArranged = true;
      companyToEdit.meetingCompleted = true;
      companyToEdit.engagementLetterRequested = true;
      companyToEdit.engagementLetterSend = true;
      companyToEdit.engagementLetterSignedAndReceived = true;
      companyToEdit.accessInvoiceSend = true;
      companyToEdit.accessInvoicePaid = !companyToEdit.accessInvoicePaid;

      axios.put('company', companyToEdit).then(function(response) {
        loadCompanies()
      });

    }
  }

  function enableDuplicatesCompany() {
    if (window.confirm("Are you sure you wish to enable/disable duplicates for this company") === true) {
      companyToEdit.duplicate = !companyToEdit.duplicate;

      axios.put('company', companyToEdit).then(function(response) {
        loadCompanies()
      });



    }
  }

  useEffect(() => {
    if (companyToEdit?.id) {
      setCompanyToEdit(companies.find(company => company.id === companyToEdit?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Companies
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Companies</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <LoadingBar loaded={loaded} assumptionName={"Companies"}/>

      {loaded &&
        <Stack spacing={2} direction="row" sx={{ paddingBottom: 6 }}>


          {showTable && <Button variant="contained" onClick={() => {navigate("/auth/sign-up");}}>Add Company</Button>}

          {!showTable && <AddProject names={[]} companies={companies} loadData={loadCompanies} projects={projects} admin={true} company={1}></AddProject>}

          {/*<AddCompany names={[]} loadData={loadCompanies} companies={companies}></AddCompany>*/}
          {!showTable && <Button variant="contained" onClick={hideCompany} sx={{minWidth: 200}}>Show Company List</Button>}


        </Stack>
      }

      {!showTable && loaded &&

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Company
                </Typography>

                <Spacer mb={4} />

                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Home />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Company Name{" "}
                    <Link href={companyToEdit.companyWebsite}>{companyToEdit.companyName}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Sector <Link href={companyToEdit.companyWebsite}>{companyToEdit.companySector}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <MapPin />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Corporate Website <Link href={companyToEdit.companyWebsite}>{companyToEdit.companyWebsite}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Approximate Revenue <Link href={companyToEdit.companyWebsite}>{companyToEdit.approxRevenue?.toLocaleString()}</Link>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Company Enabled:  <strong>{companyToEdit.disabled ? 'NO' : 'YES'}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    License: <strong>{companyToEdit.license}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Duplicates Feature: <strong>{companyToEdit.duplicate ? "Enabled" : "Disabled"}</strong>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Company Onboarding
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    This tracks the steps a company has to complete to be granted access to Powdr.
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <CompanyOnboarding company={companyToEdit}></CompanyOnboarding>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Enable/Disable Duplicates
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    This will enable/disable this companies ability to duplicate projects
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Button disabled={companyToEdit.initialised} variant="contained" style={{ width: 200, height: 100 }} onClick={enableDuplicatesCompany}>
                      {companyToEdit.duplicate ? 'Disable' : 'Enable'} Duplicates
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Projects
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    List of projects for this company
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>

                    <TableContainer component={Paper}>
                      <Table  aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Owner</TableCell>
                            <TableCell align="right">Initialised</TableCell>
                            <TableCell align="right">Start Date</TableCell>
                            <TableCell align="right">Project Enabled</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projects.filter(project => project.owner === companyToEdit.id).map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">{row.companyName}</TableCell>
                              <TableCell align="right">{row.initialised ? 'YES' : 'NO'}</TableCell>
                              <TableCell align="right">{new Date(row.startDate).toDateString()}</TableCell>
                              <TableCell align="right">{row.disabled ? 'NO' : 'YES'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Users
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    List of users for this company
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>

                    <TableContainer component={Paper}>
                      <Table  aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">User Type</TableCell>
                            <TableCell align="right">Telephone</TableCell>
                            <TableCell align="right">Disabled</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!users && users.filter(user => user.company === companyToEdit.id).map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.firstName + " " + row.lastName}
                              </TableCell>
                              <TableCell align="right">{row.email}</TableCell>
                              <TableCell align="right">{row.userType}</TableCell>
                              <TableCell align="right">{row.mobileTelephone}</TableCell>
                              <TableCell align="right">{row.disabled? 'YES' : 'NO'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>




          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Enable/Disable Company
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    This will disable this company and all its projects, users won't be able to view or edit.
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Button disabled={companyToEdit.initialised} color={"error"}  variant="contained" style={{ width: 200, height: 100 }} onClick={enableDisableCompany}>
                      {companyToEdit.disabled ? 'Enable' : 'Disable'} Company
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


        </Grid>

      }


      {showTable && loaded &&
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Companies
                </Typography>
                <Typography variant="body2" gutterBottom>
                  List of companies with Powdr accounts
                </Typography>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Sector</TableCell>
                          <TableCell align="right">Website</TableCell>
                          <TableCell align="right">Approx Revenue</TableCell>
                          <TableCell align="right">Company Status</TableCell>
                          <TableCell align="right">Show Company</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companies.map((row) => (
                          <TableRow
                            key={row.companyName}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.companyName}
                            </TableCell>
                            <TableCell align="right">{row.companySector}</TableCell>
                            <TableCell align="right">{row.companyWebsite}</TableCell>
                            <TableCell align="right">{row.approxRevenue?.toLocaleString()}</TableCell>
                            <TableCell align="right">
                              <AssumptionLabel label={row.disabled ? 'Awaiting Activation' : 'Company Active'}
                                               color={row.disabled ? 'error' : 'success'} />

                            </TableCell>
                            <TableCell align="right"><Button id={row.id} onClick={showCompany}>Settings</Button></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      }




    </React.Fragment>
  );
}

export default Companies;
