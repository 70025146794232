import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid, LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { fixedAssetsState } from "../../../SharedState/FixedAssetsState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";

function AddFixedAsset() {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();

  const [fixedAssetsAtom, setFixedAssetsAtom] = useRecoilState(fixedAssetsState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  const [assumptionFormatValue, setAssumptionFormatValue] =
    useState("SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE");


  let validationSchema = Yup.object().shape({
    fixedAssetName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(
        (fixedAssetsAtom ?? []).map(function (i) {
          return i?.fixedAssetDto?.name;
        }),
        "Must have a unique name"
      ),
  });


  const initialValues = {
    fixedAssetName: "",
    assumptionFormat: "",
    balancePositionFlag: "TANGIBLE",
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      setSubmittingForm(true)
      axios
        .post("fixedassets", {
          name: values.fixedAssetName,
          project: project.id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          canDelete: true,
        })
        .then(async function(response) {

          //update the atoms - Fixed Asset & Balance
          await axios.get("fixedassets/" + project.id).then(function(response) {
            setFixedAssetsAtom(response.data);
          });

          await axios.get("balance/" + project.id).then(function(response) {
            setBalanceSheet(response.data);
          });

          setOpen(false);
          setSubmittingForm(false);
          resetForm();

        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, minWidth : 160 }}
                onClick={() => setOpen(true)}
              >
                Fixed Asset
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Fixed Asset</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Fixed Asset...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <DialogContentText>
                      Enter the name of the fixed asset you wish to add.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.fixedAssetName && errors.fixedAssetName)}
                      name="fixedAssetName"
                      autoFocus
                      placeholder={"Fixed Asset Name"}
                      margin="dense"
                      id="fixedAssetName"
                      label="Fixed Asset Name"
                      value={values.fixedAssetName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.fixedAssetName && errors.fixedAssetName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup

                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={assumptionFormatValue}
                      onChange={handleChangeAssumption}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW_WITH_INPUT_DA"
                        control={<Radio />}
                        label="Simple Corkscrew with input D/A"
                      />
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE"
                        control={<Radio />}
                        label="Simple Corkscrew with D/A as a percentage of opening balance"
                      />
                    </RadioGroup>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddFixedAsset;
