import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  LinearProgress, Stack, Button
} from "@mui/material";
import { spacing } from "@mui/system";
import Settings from "../../../../components/Settings";
import BalanceAnnualInput from "./components/BalanceAnnualInput";
import  { getCurrentProject } from "../../SharedComponents/ProjectServices";
import ExportBarFooter from "../../../presentation/PowdrLanding/ExportBarFooter";
import ExportBar from "../../../presentation/PowdrLanding/ExportBar";
import { exportScreen } from "../../SharedComponents/utils/ExportUtils";
import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../SharedState/ZoomState";
import { createExport } from "../../Export/calculations/Calculations";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { calculateBalanceAnnuals } from "./calculations/Calculations";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { isBalanceSheetImbalanced } from "../BalanceSheet/calculations/Calculations";
import { pnlState } from "../../SharedState/PnLState";
;

const Divider = styled(MuiDivider)(spacing);

function BalanceSheetAnnual() {
  const [loaded, setLoaded] = useState(false);
  const balanceAtom = useRecoilValue(balanceSheetState);

  const [balanceSheet, setBalanceSheet] = useState([]);
  const componentRef = useRef();
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);
  const pnlAtom = useRecoilValue(pnlState);
  //
  // const [recalculateCount, setRecalculateCount] = useState(0);
  const [recalculateImbalanceCount, setRecalculateImbalanceCount] = useState(0);

  const updatePowdrModelGlobalRef = useRef();

  async function loadBalanceSheet() {

    let balanceAnnuals = calculateBalanceAnnuals(structuredClone(balanceAtom));

    setBalanceSheet([balanceAnnuals]);
    setLoaded(true)

    // if (recalculateCount < 2) {
    //   updatePowdrModelBalance()
    //   console.log('Auto recalculating, round: ' + recalculateImbalanceCount);
    //   setRecalculateCount(current=> current + 1)
    // }
    //
    //
    if (isBalanceSheetImbalanced(structuredClone(balanceAtom)) && recalculateImbalanceCount < 3) {
      console.log('Balance sheet is imbalanced, trying to recalc for the: ' + recalculateImbalanceCount + " time.")
      updatePowdrModelBalance()
      setRecalculateImbalanceCount(recalculateCount=> recalculateCount + 1)
    }
  }

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(structuredClone(balanceAtom), pnlAtom);
  }

  useEffect(() => {
    loadBalanceSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceAtom]);


  return (
    <React.Fragment>
      <Helmet title="Blank" />

      <PageHeader pageName={"Balance Sheet"} parentName={"Annual Output"}/>

      <Divider my={6} />

      <ControlsBar
        addAssumptionElement={
          <Stack  direction="row">
            <Button disabled={zoomAtom !== 1} type="submit" variant="contained" onClick={() => exportScreen('Balance', componentRef)} sx={{marginBottom: 5}}>
              Export PNG
            </Button>

            <Button type="submit" variant="contained" onClick={() => createExport(balanceSheet[0].balanceCategoryDtos, balanceSheet[0].balanceDto, true)} sx={{marginBottom: 5, marginLeft : 5}}>Export Excel</Button>

          </Stack>
        }
      />


      {!loaded &&  (

        <div>
          <h1>Retrieving Balance Sheet...</h1>
          <LinearProgress my={2} />
        </div>

      )}

      <div ref={componentRef}>

      <ExportBar name={getCurrentProject().name} ></ExportBar>

      {!!balanceSheet &&
        balanceSheet.map((balanceSheetItem) => (
          <BalanceAnnualInput
            tableRef={tableRef}
            key={balanceSheetItem.balanceDto.id}
            balanceSheetItem={balanceSheetItem}
            balanceSheet={balanceSheet}
            setBalanceSheet={setBalanceSheet}
          ></BalanceAnnualInput>
        ))}

      <ExportBarFooter></ExportBarFooter>


        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      </div>

      <Settings saving={null} />

    </React.Fragment>
  );
}

export default BalanceSheetAnnual;
