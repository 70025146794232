import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead
} from "@mui/material";
import { spacing } from "@mui/system";

import useProject from "../../../../../hooks/useProject";

import PowdrCell from "../../../SharedComponents/PowdrCell";

import MonthHeader, {
  generateXAnnualAxisHeadersAnnual
} from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};



function BalanceAnnualInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const zoomAtom = useRecoilValue(zoomState);

  const {
    tableRef,
    balanceSheetItem,
    balanceSheet,
    refreshData,
    setEditData,
    setBalanceSheet,
    editData,
  } = props;

  useEffect(() => {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }
  
  return (
    <React.Fragment key={key}>

      <Card
        mb={6}
        sx={{
          ...tableWidth,
          overflowX: "auto",
          overflow: "scroll",
          paddingTop: 0,
        }}
        id={balanceSheetItem.balanceDto.name.replaceAll(" ", "-") + "-table"}
      >
        <CardContent  sx={{ paddingTop: 5, zoom: zoomAtom }}>
          <Table ref={tableRef}>
            {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
            <TableHead>
              <TableRow
                key={balanceSheetItem.balanceDto.name + "-ROW"}
                id={balanceSheetItem.balanceDto.name + "-ROW"}
              >
                <TableCell
                  key={balanceSheetItem.balanceDto.name + "-BASE"}
                  id={balanceSheetItem.balanceDto.name + "-BASE"}
                  style={{ position: "sticky", left: 0 }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    width: 200,
                    backgroundColor: "#F7F9FC",
                  }}
                >

                </TableCell>
                {XaxisHeaders.map((yHeader, index) => (
                  <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={balanceSheetItem.balanceDto.name} index={index}></MonthHeader>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {balanceSheetItem.balanceCategoryDtos.map((row) => (
                <TableRow key={row.id}
                          id={row.id}
                          sx={{borderStyle: row.totalField === true && ["TOTAL FIXED ASSETS", "TOTAL CURRENT ASSETS", "TOTAL CURRENT LIABILITIES", "TOTAL DEBT", "Net Assets", "P&L reserve", "Current Year P&L Gain/(Loss)", "TOTAL EQUITY", "Check Line"].includes(row.overrideName) ? "double" : "none", }}
                          onClick={() => handleClick(row.id)}>
                  <TableCell
                    component="th"
                    scope="row"
                    variant={"body"}
                    sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                      
                    }}
                  >
                    {row.overrideName === "" ? row.name : row.overrideName}
                  </TableCell>

                  {row.balanceMonthDtos.map((month) => (
                    <PowdrCell
                      key={month.id + "-CELL"}
                      cellData={month}
                      ownerId={row.id}
                      editTableEnabled={editData}
                      items={balanceSheet}
                      refreshItemData={refreshData}
                      setItems={setBalanceSheet}
                    ></PowdrCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default BalanceAnnualInput;
