import { exportComponentAsPNG } from "react-component-export-image";
import { getCurrentProject } from "../ProjectServices";

export function exportScreen(name, componentRef) {
  var x = document.getElementById("myDIV");
  x.style.display = "block";

  var footer = document.getElementById("myFOOTER");
  footer.style.display = "block";

  exportComponentAsPNG(componentRef, {fileName: "Powdr-" + getCurrentProject().name + "-" + name + "-" + new Date().toDateString()})
    .then(() => {x.style.display = "none"; footer.style.display = "none"})
}