import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from "@mui/material";


// Function to generate a consistent hash code from a string
const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

// Function to generate a complementary color from a string
const stringToColor = (str) => {
  const hash = hashCode(str);
  // Calculate complementary hue (180 degrees from the original)
  const hue = (hash % 360 + 180) % 360;
  // Choose saturation and lightness to make the color vivid and complementary
  const saturation = 70; // Choose a high saturation for vivid colors
  const lightness = 50; // A lightness of 50% gives a rich, balanced color
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return color;
};

// Function to get initials from a string
const getInitials = (name) => {
  const words = name.trim().split(/\s+/);
  const initials = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
  return initials;
};

// UserAvatar component that takes the user's display name
const UserAvatar = ({ userDisplayName }) => {
  // Generate the background color based on the initials
  const backgroundColor = stringToColor(getInitials(userDisplayName));
  const initials = getInitials(userDisplayName);

  return (
    <Tooltip title={userDisplayName + " is viewing this project"}>
      <Avatar id={userDisplayName} sx={{ backgroundColor }}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
