import axios from "axios";

export async function editVAT(setOpenEditVat, projectId, setProducts, setExpenses, setVatRates) {

  await getTopLevelProducts(projectId, setProducts)
  await getTopLevelOverheads(projectId, setExpenses)
  await getVatRates(projectId, setVatRates)

  setOpenEditVat(true);

}





async function getVatRates(projectId, setVatRates) {

  let vatRates = [];

  var config = {
    method: "get",
    url: "vat/vatrate/" + projectId,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      vatRates = response.data[0].vatCategoryDtos;
    })
    .catch(function (error) {
      console.log(error);
    });

  setVatRates(vatRates);

  return vatRates
}

async function getTopLevelProducts(projectId, setProducts) {

  var config = {
    method: "get",
    url: "revenuecos/" + projectId,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      setProducts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

}

async function getTopLevelOverheads(projectId, setExpenses) {

  var config = {
    method: "get",
    url: "overheads/" + projectId,
  };

  await axios(config)
    .then(function(response) {
      //list of products
      setExpenses(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });

}