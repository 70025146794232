
import {
  buildPercentageOfTwoCategories, buildYearDifferenceCategory, buildYearDifferencePercentageCategory,
  buildYearPercentageDifferenceCategory,
  buildYearTotalCategory
} from "./Calculations";

export function createAnnualSummarySelectedRevenue(product_) {

  let product = structuredClone(product_);

  //build a new annualSummaryObject
  let annualSummary = {
    productDto: product_.productDto,
    productCategoryDtoList: []
  };

  annualSummary.productDto.analysisAssumptionDesc = "Annual Summary";

  let selectedRevenue               = buildYearTotalCategory(product, "Selected Revenue");

  let revenueCategory               = buildYearTotalCategory(product, "Revenue");
  let directProductCosts            = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit                   = buildYearTotalCategory(product, "Gross Profit");

  let percentageOfRevenueCategory   = buildPercentageOfTwoCategories("Percentage of Revenue",  selectedRevenue, revenueCategory );
  let productMargin                 = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);

  annualSummary.productCategoryDtoList.push(selectedRevenue, percentageOfRevenueCategory, revenueCategory, directProductCosts, grossProfit, productMargin);

  return annualSummary;
}


export function createGrowthRatesSelectedRevenue(product_) {

  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Growth Rates";

  let selectedRevenueCategory       = buildYearTotalCategory(product, "Selected Revenue");

  let revenueCategory               = buildYearTotalCategory(product, "Revenue");
  let percentageRevenueCategory     = buildYearTotalCategory(product, "Percentage of Revenue");
  let directProductCosts            = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit                   = buildYearTotalCategory(product, "Gross Profit");

  let productMargin                 = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);

  let selectedRevenueGrowth         = buildYearPercentageDifferenceCategory("Selected Revenue", selectedRevenueCategory);
  let percentageOfRevenueGrowth     = buildYearPercentageDifferenceCategory("Percentage of Revenue", percentageRevenueCategory);
  let revenueGrowth                 = buildYearPercentageDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth      = buildYearPercentageDifferenceCategory("Direct product costs", directProductCosts, 0, "#FF0000", "#008000", "↑",  "↓");
  let grossProfitGrowth             = buildYearPercentageDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth           = buildYearPercentageDifferenceCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(selectedRevenueGrowth, percentageOfRevenueGrowth, revenueGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}

export function createYearOnYearChangeSelectedRevenue(product_) {
  let product = structuredClone(product_)

  //build a new growth rates
  let summary = {
    productDto: product.productDto,
    productCategoryDtoList: []
  };

  summary.productDto.analysisAssumptionDesc = "Year on year change";

  let selectedRevenueCategory        = buildYearTotalCategory(product, "Selected Revenue");
  let percentageRevenueCategory      = buildYearTotalCategory(product, "Percentage of Revenue");
  let revenueCategory                = buildYearTotalCategory(product, "Revenue");
  let directProductCosts             = buildYearTotalCategory(product, "Direct product costs");
  let grossProfit                    = buildYearTotalCategory(product, "Gross Profit");
  let productMargin                  = buildPercentageOfTwoCategories("Product Margin", revenueCategory, grossProfit);


  let selectedRevenueGrowth         = buildYearDifferenceCategory("Selected Revenue", selectedRevenueCategory);
  let percentageOfRevenueGrowth     = buildYearDifferenceCategory("Percentage of Revenue", percentageRevenueCategory, 2);
  let revenueGrowth                 = buildYearDifferenceCategory("Revenue", revenueCategory);
  let directProductCostsGrowth      = buildYearDifferenceCategory("Direct product costs", directProductCosts, 0, "↑", "↓");
  let grossProfitGrowth             = buildYearDifferenceCategory("Gross Profit", grossProfit);
  let productMarginGrowth           = buildYearDifferencePercentageCategory("Product Margin", productMargin);

  summary.productCategoryDtoList.push(selectedRevenueGrowth, percentageOfRevenueGrowth, revenueGrowth, directProductCostsGrowth, grossProfitGrowth, productMarginGrowth);

  return summary;
}













