import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import _ from "lodash";

import { customBlue } from "../../../../../theme/variants";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

// function percentage(partialValue, totalValue) {
//   let val = (100 * partialValue) / totalValue;
//   if (isNaN(val)){
//     return 0;
//   } else {
//     return val;
//   }
// }

const OverheadsChart = ({ pnlAnnual, theme }) => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  let data = [
    {
      name: "Overheads",
      data: [],
    }
  ];

  async function loadProductData() {

    if (pnlAnnual !== undefined) {

      let varArr = [];

      //let revenue = pnlAnnual.pnLCategoryDtoList.find(pnLCategory => ['Total revenue', 'Revenue from products'].includes(pnLCategory.overrideName));
      //let overheadTotals = pnlAnnual.pnLCategoryDtoList.find(pnLCategory => pnLCategory.overrideName === 'Total costs of sales');

      let overheadTotals = pnlAnnual.pnLCategoryDtoList.find(pnLCategory => pnLCategory.overrideName === 'Total Overheads');

      // _.forEach(overheadTotals.pnLMonthDtoList, function(overheadMonth, i) {
      //
      //   varArr.push(overheadMonth.value);
      //
      // });
      //
      // data[0].data = varArr;

      _.forEach(overheadTotals.pnLMonthDtoList, function(overheadMonth, i) {

        //data.push(overheadMonth.value);
        varArr.push(Math.abs(overheadMonth.value));

      });

      data[0].data = varArr;
      setTableData(data);
      setShowTable(true);
    }

  }

  useEffect(() => {
    loadProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlAnnual]);


  const options = {
    chart: {
      toolbar: {
        autoSelected: 'pan',
        show: true
      },
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
        barHeight: '85%',
      },
    },
    bar: {
      horizontal: false,
      endingShape: "rounded",
      columnWidth: "55%",
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return "£" + value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        }
      },
    },
    xaxis: {
      categories: ['Actuals', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
    },
    labels: ['Actuals', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
    colors: [
      "#B1B1B1",
      customBlue[800],
      customBlue[600],
      customBlue[400],
      customBlue[200],
      customBlue[100],
    ],
  };


  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Overheads
        </Typography>
        <Typography variant="body2" gutterBottom>
          This chart shows a breakdown of all overhead costs as a percentage of revenue over time.
        </Typography>

        <Spacer mb={6} />

        { showTable !== false && (
          <ChartWrapper>
            <Chart options={options} series={tableData} type="bar" height="350" />
          </ChartWrapper>
        )}
      </CardContent>
    </Card>
  );
};

export default withTheme(OverheadsChart);
